import React, { useEffect, useMemo, useState } from "react";
import StatisticPresentationer from "../../atoms/StatisticPresentationer";
import useStore from "modules/hooks/useStore";
import { observer } from "mobx-react";
import DataFormat from "../../atoms/DataFormat";
import Loading from "components/molecules/LoadingComponent";

function OverviewStatistic() {
  const {
    patient,
    statistics: { search: s },
    patient: {
      patientStatistic: { instituteTotal, kmasterTotal },
      search
    }
  } = useStore();

  const [loading, setLoading] = useState(false);

  useEffect(() => {

    //async를 사용하는 함수 따로 선언
    const fetchData = async () => {
      setLoading(false);

      try {
        // for DEBUG by jdy
        const response = await patient.loadStatistics();
        // console.log("patient.loadStatistics()");
      
      } catch (e) {
        console.log(e);
      }
      setLoading(true);

    };
    
    fetchData();
    

    return () => {
      patient.resetStatistics();
    };
  }, [s, search]);

  const name = useMemo(() => {
    return `${s.institute || "INST"} / KM`;
  }, [s]);

  const total = useMemo(() => {
    return (
      <>
        <DataFormat>{instituteTotal}</DataFormat>
        {" / "}
        <DataFormat>{kmasterTotal}</DataFormat>
      </>
    );
  }, [instituteTotal, kmasterTotal]);

  return (
    // <Loading loading={loading}>
      <StatisticPresentationer title={name} content={total} />
    // </Loading>
  );
}

export default observer(OverviewStatistic);
