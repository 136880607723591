import { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";

function CheckRouter(props) {
  const [show, setShow] = useState(true);
  const { location, showList } = props;

  useEffect(() => {
    setShow(
      !showList.filter(f => {
        return f.match(location.pathname);
      }).length > 0
    );
  }, [location.pathname]);

  return show ? props.children : null;
}

export default withRouter(CheckRouter);
