import React from "react";
import useMediaQuery from "@material-ui/core/useMediaQuery";

function TableHeightContainer({ children, maxHeight }) {
  const matches = useMediaQuery("(min-height:940px)");

  return (
    <div style={{ maxHeight: matches ? maxHeight : 150, overflowY: "auto" }}>
      {children}
    </div>
  );
}

export default TableHeightContainer;
