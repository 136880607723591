import React, { useRef, useEffect, useMemo, useState } from "react";
import { makeStyles } from "@material-ui/styles";
import { observer } from "mobx-react";
// import CustomDialog from "components/atoms/ui/CustomDialog";
import MuiTable from "components/atoms/MuiTable";
import TableTemplate from "components/organisms/TableTemplate";
import TableHeightContainer from "components/lib/TableHeightContainer";
import useStore from "modules/hooks/useStore";
import queryString from "query-string";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
  KeyboardTimePicker
} from "@material-ui/pickers";
import MatchButton from "components/molecules/MatchButton";
import DateFnsUtils from "@date-io/date-fns";

function getFormatDate(date) {
  var year = date.getFullYear(); //yyyy
  var month = 1 + date.getMonth(); //M
  month = month >= 10 ? month : "0" + month; //month 두자리로 저장
  var day = date.getDate(); //d
  day = day >= 10 ? day : "0" + day; //day 두자리로 저장
  return year + "-" + month + "-" + day;
}

const useStyles = makeStyles({
  manager: {
    width: "-webkit-fill-available",
    margin: "15px",
    border: "solid 1px #c6c6c6"
  },
  logbackdiv: {
    backgroundColor: "#ffffff",
    padding: "15px",
    paddingBottom: "0px"
  },
  timeDiv: {
    height: "45px",
    backgroundColor: "#f6f6f6",
    display: "flex",
    lineHeight: "3.5",
    margin: 10
    // marginBottom: 10
  },
  time: {
    display: "flex",
    alignItems: "center",
    marginLeft: "10px"
  },
  inputbox: {
    // paddingTop: "5px",
    width: "120px"
  }
  // runButton: {
  //   margin: "7.5px",
  //   width: "50px",
  //   height: "30px"
  // }
});

const columns = [
  {
    column: "Time",
    property: "timestamp",
    align: "right",
    sort: false,
    options: {
      link: {
        isLinked: false
      },
      isEditable: false,
      withCellExcute: ({ value }) => WrapperComponent => {
        return (
          <WrapperComponent>{getFormatDate(new Date(value))}</WrapperComponent>
        );
      }
    },
    style: { head: { width: 200 } }
  },
  {
    column: "Principal",
    property: "principal",
    align: "left",
    sort: false,
    options: {
      link: {
        isLinked: false
      },
      isEditable: false
    },
    style: { head: { width: 200 } }
  },
  {
    column: "Type",
    property: "type",
    align: "left",
    sort: false,
    options: {
      link: {
        isLinked: false
      },
      isEditable: false
    },
    style: { head: { width: 200 } }
  }
];

function AccessLog() {
  const classes = useStyles();
  const [startSelectedDate, setStartSelectedDate] = useState(new Date());

  const [endSelectedDate, setEndSelectedDate] = useState(new Date());
  // const [startSelectedDate, setStartSelectedDate] = useState();
  // const [endSelectedDate, setEndSelectedDate] = useState();
  const parentEl = useRef(null);
  const {
    account,
    account: { logList }
  } = useStore();

  useEffect(() => {
    // account.getLogList("?size=10");
    startSelectedDate.setDate(startSelectedDate.getDate() - 1);
    let query = {};
    if (startSelectedDate && endSelectedDate) {
      query.fromDate = startSelectedDate.toISOString();
      query.toDate = endSelectedDate.toISOString();
    }
    query.size = 10;
    account.getLogList(`?${queryString.stringify(query)}`);
  }, []);

  const handlePagination = e => {
    let query = {};
    if (startSelectedDate && endSelectedDate) {
      query.fromDate = startSelectedDate.toISOString();
      query.toDate = endSelectedDate.toISOString();
    }
    query.page = e.page;
    query.size = e.rowsPerPage;
    account.getLogList(`?${queryString.stringify(query)}`);
  };

  const size = useMemo(() => {
    return null;
  }, []);

  const starthandleDateChange = date => {
    setStartSelectedDate(date);
  };

  const endhandleDateChange = date => {
    setEndSelectedDate(date);
  };
  const run = () => {
    let query = {};
    query.fromDate = startSelectedDate.toISOString();
    query.toDate = endSelectedDate.toISOString();
    query.page = 0;
    query.size = 10;
    account.getLogList(`?${queryString.stringify(query)}`);
  };
  return (
    <>
      <div className={classes.timeDiv}>
        <div className={classes.time}>
          <div style={{ marginRight: 10 }}>User Management</div>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <div style={{ marginRight: 10 }}>From : </div>
            <KeyboardDatePicker
              // disableToolbar
              className={classes.inputbox}
              // inputVariant="outlined"
              value={startSelectedDate}
              onChange={starthandleDateChange}
              format="yy-MM-dd"
            />
            <KeyboardTimePicker
              // disableToolbar
              className={classes.inputbox}
              // inputVariant="outlined"
              value={startSelectedDate}
              onChange={starthandleDateChange}
              format="HH:mm"
            />
            <div style={{ margin: "0px 10px" }}> To :</div>
            <KeyboardDatePicker
              // disableToolbar
              className={classes.inputbox}
              // inputVariant="outlined"
              value={endSelectedDate}
              onChange={endhandleDateChange}
              format="yy-MM-dd"
            />
            <KeyboardTimePicker
              // disableToolbar
              className={classes.inputbox}
              // inputVariant="outlined"
              value={endSelectedDate}
              onChange={endhandleDateChange}
              format="HH:mm"
            />
          </MuiPickersUtilsProvider>
          <MatchButton style={{ width: 50, height: 25 }} onClick={run}>
            Search
          </MatchButton>
        </div>
      </div>
      <TableTemplate borderHide={true} headStyle={{ display: "none" }}>
        <div ref={parentEl}>
          <MuiTable
            id={`accesslog`}
            parentEl={parentEl.current}
            columns={columns}
            data={logList}
            // stickyHeader
            oddRow
            defaultPagination={{
              page: 0,
              rowsPerPage: 10,
              handlePatination: handlePagination
            }}
          ></MuiTable>
        </div>
      </TableTemplate>
    </>
  );
}

export default observer(AccessLog);
