import React from "react";
import TherapyRecommendationTable from "./TherapyRecommendationTable";
import ClinicalTrialRecommendationTable from "./ClinicalTrialRecommendationTable";
import ClinicalReviewTable from "./ClinicalReviewTable";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles({
  layout: {
    width: "100%",
    height: "100%"
  },
  container: {
    backgroundColor: "#fff",
    padding: 15,
    border: "1px solid #c6c6c6",
    overflowY: "auto",
    position: "absolute",
    top: 15,
    left: 15,
    right: 15,
    bottom: 0
  }
});

function ClinicalReview() {
  const classes = useStyles();

  return (
    <div className={classes.layout}>
      <div className={classes.container}>
        <TherapyRecommendationTable />
        <br />
        <ClinicalTrialRecommendationTable />
        <br />
        <ClinicalReviewTable />
      </div>
    </div>
  );
}

export default ClinicalReview;
