import React, { useState, useCallback, useEffect, useMemo } from "react";
import CustomDialog from "../../components/atoms/ui/CustomDialog";
import MatchButton from "components/molecules/MatchButton";
import useStore from "modules/hooks/useStore";
import { observer } from "mobx-react";
import GetAppIcon from "@material-ui/icons/GetApp";
import { makeStyles } from "@material-ui/core/styles";
import XLSX from 'xlsx';

const useStyle = makeStyles(theme => ({
  label: {
    margin: 5,
    padding: ".5em 1.75em",
    borderRadius: 1,
    boxShadow: "1px 1px 4px 0 rgba(121, 121, 121, 0.8)",
    backgroundColor: "#fcfcfc",
    cursor: "pointer"
  },
  input: {
    position: "absolute",
    width: 1,
    height: 1,
    padding: 0,
    margin: -1,
    overflow: "hidden",
    clip: "rect(0,0,0,0)",
    border: 0
  },
  rec: {
    width: 290,
    height: 25,
    // backgroundColor: "#e9e7e7",
    border: "none"
  }
}));

function OverviewExcelUploadBtn({ type, button }) {
  const classes = useStyle();
  const [open, setOpen] = useState(false);
  const [file, setFile] = useState(null);
  const {
    account,
    account: { downloadLink }
  } = useStore();

  useEffect(() => {
    if (open) account.downloadExcelTemplate({ type });
  }, [open]);

  const onClose = e => {
    e.preventDefault();
    setOpen(false);
    setFile(false);
  };

  const onClick = useCallback(() => {
    setOpen(true);
  }, []);

  const onChange = e => {
    setFile(e.target.files[0]);
  };


  /* 
	    변경일자:   2020-06-26
	    변경자 이름: 지신복 
	    변경 내용:  엑셀자료 값 체크 로직 추가
	    변경 사유:  엑셀자료 값 체크 로직이 없어 오류발생                             
	*/
  const onSubmit = e => {
    e.preventDefault();

    var name = file.name;
    const reader = new FileReader();
    reader.onload = (evt) => { // evt = on_file_select event
        /* Parse data */
        const bstr = evt.target.result;
        const wb = XLSX.read(bstr, {type:'binary'});
        /* Get first worksheet */
        const wsname = wb.SheetNames[0];
        const ws = wb.Sheets[wsname];
        /* Convert array of arrays */
        const data = XLSX.utils.sheet_to_json(ws, {header:1});

        if(type=='institution')
          for(var i=1; i<data.length; i++){
            console.log(data[i][1])
            
            if(typeof data[i][1] == 'undefined' || !data[i][1]){
              alert("Code 필수 입력값 입니다."); 
              return;
            }
            
            if(typeof data[i][2] == 'undefined' || !data[i][2]){
              alert("Name 필수 입력값 입니다.");
              return;
            }

            if(data[i][1].length!=3){
              alert("Code 3자리수 입니다.");
              return;
            }
          }

        if(type=='user')
          for(var i=1; i<data.length; i++){
            for(var j=0; j<7; j++){
              if(typeof data[i][j] == 'undefined' || !data[i][j]){
                alert(data[0][j]+" 필수 입력값 입니다."); 
                return;
              }
            } 
          }  

        account.uploadExcelTemplate({ type, file }).then(value => {
          if (value) {
            alert(value.data);
            setOpen(false);
          }
        });
        
    };
    reader.readAsBinaryString(file);
    
  };

  const actions = useMemo(() => {
    return (
      <>
        <MatchButton onClick={onClose} type="button" style={{ width: 100 }}>
          Cancel
        </MatchButton>
        <MatchButton type="submit" style={{ width: 100 }}>
          Upload
        </MatchButton>
      </>
    );
  }, []);

  return (
    <>
      {!button ? (
        <span
          style={{
            color: "#7d7d7d",
            textDecoration: "underline"
          }}
          onClick={onClick}
        >
          {`Import ${type}`}
        </span>
      ) : (
        <MatchButton
          onClick={onClick}
          style={{ width: 100 }}
        >{`Import`}</MatchButton>
      )}
      <CustomDialog
        open={open}
        onClose={onClose}
        title={`Import ${type}`}
        actions={actions}
        maxWidth={"xs"}
        onSubmit={onSubmit}
      >
        <span style={{ margin: 5, lineHeight: 1.4 }}>
          including user information such as passwords, Institution units, etc.
          Upload the Excel file to add users.
        </span>
        {/* <br /> */}
        <div style={{ margin: 5 }}>
          <input
            disabled="disabled"
            className={classes.rec}
            value={file ? file.name : ""}
          />
          <label htmlFor="ex_file" className={classes.label}>
            Find
          </label>
          <input
            id="ex_file"
            type="file"
            name="file"
            onChange={onChange}
            required
            className={classes.input}
          />
        </div>
        <br />
        <span style={{ margin: 5 }}>
          Please download the Excel file and fill out the form.
        </span>
        <div style={{ margin: 5, display: "flex", alignItems: "center" }}>
          <span
            style={{ marginRight: 5, display: "flex", alignItems: "center" }}
          >
            <GetAppIcon />
            Download
          </span>
          <a href={downloadLink} download={`import_${type}_template.xls`}>
            {`"import_${type}_template.xls"`}
          </a>
        </div>
      </CustomDialog>
    </>
  );
}

export default observer(OverviewExcelUploadBtn);
