import React from "react";
import { extendObservable, computed } from "mobx";
import ActionableDialogButton from "components/molecules/ActionableDialogButton";

class NgsSummaryModel {
  constructor(data) {
    extendObservable(this, data);
  }

  @computed
  get name() {
    const vv = `${this.gene}, ${this.variant}`;
    return this.hgvsc ? `${vv}: ${this.hgvsc}` : vv;
  }

  @computed
  get freqInKmaster() {
    return `${parseFloat((this.freq / this.totalFreq) * 100).toFixed(2)}% (${
      this.freq
    }/${this.totalFreq})`;
  }

  get ac() {
    return this.actionability ? (
      <ActionableDialogButton
        gene={this.gene}
        variant={this.variant}
        mutType={this.sequenceAlteration}
        hgvsc={this.hgvsc}
        type={"ac"}
      />
    ) : (
      "-"
    );
  }

  get pa() {
    return this.pathogenicity ? (
      <ActionableDialogButton
        gene={this.gene}
        variant={this.variant}
        mutType={this.sequenceAlteration}
        hgvsc={this.hgvsc}
        type={"pa"}
      />
    ) : (
      "-"
    );
  }
}

export default NgsSummaryModel;
