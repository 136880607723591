import React, { useCallback, useMemo } from "react";
import CustomButtonList from "../../atoms/ui/CustomButtonList";
import useStore from "modules/hooks/useStore";
import { observer } from "mobx-react";
import { makeStyles } from "@material-ui/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";

const useStyles = makeStyles({
  container: {
    marginTop: 5
  },
  title: {
    color: "#434343",
    margin: 10,
    fontSize: "1rem"
  },
  buttonConteiner: {
    marginTop: 5
  }
});

const endAgeProperty = "endAge";
const startAgeProperty = "startAge";
const ageList = [
  { name: "All", label: "All", key: {}, selected: undefined },
  {
    name: (
      <>
        <div>Child</div>
        <div>(Birth-17)</div>
      </>
    ),
    label: "Birth-17",
    key: {
      [endAgeProperty]: 17
    },
    selected: "17"
  },
  {
    name: (
      <>
        <div>Adult</div>
        <div>(18-64)</div>
      </>
    ),
    label: "18-64",
    key: {
      [startAgeProperty]: 18,
      [endAgeProperty]: 64
    },
    selected: "1864"
  },
  {
    name: (
      <>
        <div>Older</div>
        <div>(65+)</div>
      </>
    ),
    label: "65+",
    key: {
      [startAgeProperty]: 65
    },
    selected: "65"
  }
];

function AgeFilterButtonList() {
  const {
    patient,
    patient: {
      search,
      search: { endAge, startAge }
    }
  } = useStore();
  const classes = useStyles();
  const matches = useMediaQuery("(min-width:1500px)");

  const handleClick = useCallback(
    ({ key }) => {
      if (key === "All") {
        delete search.endAge;
        delete search.startAge;
      }
      if (!key.hasOwnProperty("endAge")) {
        delete search.endAge;
      }
      if (!key.hasOwnProperty("startAge")) {
        delete search.startAge;
      }
      patient.resetSearch({ ...search, ...key });
    },
    [search]
  );

  const selectedAge = useMemo(() => {
    return (endAge || startAge) && `${startAge || ""}${endAge || ""}`;
  }, [endAge, startAge]);

  const ageMatches = ageList.map(({ label, key, selected }) => {
    return {
      name: label,
      key,
      selected
    };
  });

  return (
    <div className={classes.container}>
      <span className={classes.title}>Age</span>
      <div className={classes.buttonConteiner}>
        <CustomButtonList
          data={matches ? ageList : ageMatches}
          onClick={handleClick}
          selected={selectedAge}
        />
      </div>
    </div>
  );
}

export default observer(AgeFilterButtonList);
