import React, {
  useEffect,
  useRef,
  useCallback,
  useState,
  useMemo
} from "react";
import MuiTable from "components/atoms/MuiTable";
import { withRouter } from "react-router-dom";
import useStore from "modules/hooks/useStore";
import { observer } from "mobx-react";
import TableTemplate from "components/organisms/TableTemplate";
import MatchButton from "components/molecules/MatchButton";

const columns = [
  {
    column: "Subject",
    title: "Subject",
    property: "subject",
    align: "left",
    sort: false,
    options: {
      link: {
        isLinked: true,
        style: {
          fontSize: "1rem",
          padding: 0,
          border: "none",
          backgroundColor: "transparent",
          cursor: "pointer",
          color: "#4588fc",
          textDecoration: "underline",
          fontFamily: "Roboto"
        }
      },
      isEditable: false
    }
  },
  {
    column: "Dx Age",
    title: "Dx Age",
    property: "dxAge",
    align: "right",
    sort: false,
    options: {
      link: {
        isLinked: false
      },
      isEditable: false
    }
  },
  {
    column: "Inst",
    title: "Institution",
    property: "institute",
    align: "left",
    sort: false,
    options: {
      link: {
        isLinked: false
      },
      isEditable: false
    }
  },
  {
    column: "Study",
    title: "Study",
    property: "study",
    align: "left",
    sort: false,
    options: {
      link: {
        isLinked: false
      },
      isEditable: false
    }
  },
  {
    column: "Sex",
    title: "Sex",
    property: "sex",
    align: "left",
    sort: false,
    options: {
      link: {
        isLinked: false
      },
      isEditable: false
    }
  },
  {
    column: "Cancer",
    title: "Cancer",
    property: "cancer",
    align: "left",
    sort: false,
    options: {
      link: {
        isLinked: false
      },
      isEditable: false
    }
  },
  {
    column: "Sample",
    title: "Sample",
    property: "samples",
    align: "left",
    sort: false,
    options: {
      link: {
        isLinked: false
      },
      isEditable: false
    }
  },
  {
    column: "Panel",
    title: "Panel",
    property: "panels",
    align: "left",
    sort: false,
    options: {
      link: {
        isLinked: false
      },
      isEditable: false
    }
  },
  {
    column: "SNV/INDEL",
    title: "SNV",
    property: "snvs",
    align: "right",
    sort: false,
    options: {
      link: {
        isLinked: true,
        style: {
          fontFamily: "Roboto",
          fontSize: "1rem",
          padding: 0,
          border: "none",
          backgroundColor: "transparent"
          // cursor: "pointer",
          // color: "#4588fc",
          // textDecoration: "underline"
        }
      },
      isEditable: false
    }
  },
  {
    column: "CNV",
    title: "CNV",
    property: "cnvs",
    align: "right",
    sort: false,
    options: {
      link: {
        isLinked: true,
        style: {
          fontFamily: "Roboto",
          fontSize: "1rem",
          padding: 0,
          border: "none",
          backgroundColor: "transparent"
          // cursor: "pointer",
          // color: "#4588fc",
          // textDecoration: "underline"
        }
      },
      isEditable: false
    }
  },
  {
    column: "SV",
    title: "SV",
    property: "svs",
    align: "right",
    sort: false,
    options: {
      link: {
        isLinked: true,
        style: {
          fontFamily: "Roboto",
          fontSize: "1rem",
          padding: 0,
          border: "none",
          backgroundColor: "transparent"
          // cursor: "pointer",
          // color: "#4588fc",
          // textDecoration: "underline"
        }
      },
      isEditable: false
    }
  },
  {
    column: "Review",
    title: "Review",
    property: "reviews",
    align: "left",
    sort: false,
    options: {
      link: {
        isLinked: false
      },
      isEditable: false
    }
  },
  {
    column: "AC",
    title: "Actionability",
    property: "acs",
    align: "center",
    sort: false,
    options: {
      link: {
        isLinked: false
      },
      isEditable: false
    }
  },
  {
    column: "PA",
    title: "Pathogenicity",
    property: "pas",
    align: "center",
    sort: false,
    options: {
      link: {
        isLinked: false
      },
      isEditable: false
    }
  }
];

function PersonTableOfPatient(props) {
  const {
    patient,
    patient: { followPatients, followPatientSearch }
  } = useStore();
  const [loading, setLoading] = useState(false);
  const [done, setDone] = useState(false);
  const parentEl = useRef(null);
  const node = useRef(null);
  const [selected, setSelect] = useState({});

  useEffect(() => {
    return () => {
      patient.resetFollowPatientSearch();
    };
  }, []);

  useEffect(() => {
    setLoading(false);
    if (!done) patient.loadFollowPatients();
    setTimeout(() => {
      setLoading(true);
      setDone(false);
    }, 500);
  }, [done, followPatientSearch]);

  const handlePagination = useCallback(({ page, rowsPerPage }) => {
    patient.setFollowPatientSearch({ page, size: rowsPerPage });
  }, []);

  const handleLink = useCallback(({ data, property }) => {
    patient.resetSearch({
      persons: data.id
    });
    if (property === "subject") {
      props.history.push({
        pathname: "/patient",
        state: { from: props.location.pathname }
      });
    }
  }, []);

  const table = useMemo(() => {
    return (
      <MuiTable
        id={`follows`}
        parentEl={parentEl.current}
        ref={node}
        columns={columns}
        data={followPatients}
        stickyHeader
        headerIcon={<></>}
        oddRow
        selectRow={{
          mode: "radio",
          selected,
          setSelect
        }}
        defaultPagination={{
          page: followPatientSearch.page,
          rowsPerPage: followPatientSearch.size,
          handlePatination: handlePagination
        }}
        handleLink={handleLink}
        loading={loading}
      />
    );
  }, [followPatients, loading, followPatientSearch, selected]);

  const title = useMemo(() => {
    return (
      <>
        <span>My Patients</span>
        {followPatients.totalElements > 0 && (
          <span
            style={{
              marginLeft: 5,
              fontSize: "0.8rem",
              color: "rgb(58, 58, 58)",
              fontWeight: 400
            }}
          >
            ({`Bookmarked ${followPatients.totalElements} patients`})
          </span>
        )}
      </>
    );
  }, [followPatients]);

  const onUnFollow = useCallback(() => {
    const selectedKeys = Object.keys(
      // node.current.selectionContext.state.selected
      selected
    );

    if (selectedKeys.length > 0) {
      patient.followById({ isFollow: true, id: selectedKeys.pop() });
      setDone(true);
      setSelect({});
    }
  }, [selected]);

  const unFollowBtn = useMemo(() => {
    return (
      <MatchButton onClick={onUnFollow} style={{ width: 150 }}>
        UnFollow
      </MatchButton>
    );
  }, [selected]);

  return (
    <TableTemplate title={title} borderHide={true} size={unFollowBtn}>
      <div ref={parentEl}>{table}</div>
    </TableTemplate>
  );
}

export default React.memo(withRouter(observer(PersonTableOfPatient)));
