import React, { useRef, useEffect, useCallback, useMemo } from "react";
import MuiTable from "components/atoms/MuiTable";
import TableHeightContainer from "components/lib/TableHeightContainer";
import TableTemplate from "../../../TableTemplate";
import useStore from "modules/hooks/useStore";
import { observer } from "mobx-react";

const columns = [
  {
    column: "Date",
    title: "Date",
    property: "createdDate",
    align: "right",
    sort: false,
    options: {
      link: {
        isLinked: false
      },
      isEditable: false
    },
    style: { head: { width: 110 } }
  },
  {
    column: "Reviewer",
    title: "Reviewer",
    property: "createdBy",
    align: "left",
    sort: false,
    options: {
      link: {
        isLinked: false
      },
      isEditable: false
    },
    style: { head: { width: 150 } }
  },
  {
    column: "Comment",
    title: "Comment",
    property: "comment",
    align: "left",
    sort: false,
    options: {
      link: {
        isLinked: false
      },
      isEditable: false
    }
  }
];

const type = "comment";
function ClinicalReviewTable() {
  const parentEl = useRef(null);
  const {
    review,
    review: { clinicalReviews }
  } = useStore();

  useEffect(() => {
    review.loadReviews(type);
  }, []);

  const size = useMemo(() => {
    return (
      clinicalReviews[type] &&
      `(${clinicalReviews[type].totalElements} ${type} reviews found)`
    );
  }, [clinicalReviews[type]]);

  return (
    <TableTemplate title="Additional Comments" size={size}>
      <div ref={parentEl}>
        <TableHeightContainer maxHeight={180}>
          <MuiTable
            id={`ClinicalTrials`}
            parentEl={parentEl.current}
            columns={columns}
            data={clinicalReviews[type]}
            stickyHeader
            oddRow
            defaultPagination={{
              mode: "scroll",
              page: 0,
              rowsPerPage: 10
            }}
          />
        </TableHeightContainer>
      </div>
    </TableTemplate>
  );
}

export default observer(ClinicalReviewTable);
