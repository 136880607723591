import React, { useRef, useMemo } from "react";
import useStore from "modules/hooks/useStore";
import { observer } from "mobx-react";
import MuiTable from "components/atoms/MuiTable";
import TableTemplate from "components/organisms/TableTemplate";
import useMediaQuery from "@material-ui/core/useMediaQuery";

const columns = {
  pa: [
    {
      column: "Guide Oncogenic K",
      title: "Guide Oncogenic K",
      property: "guideOncogenicK",
      align: "center",
      sort: false,
      options: {
        link: {
          isLinked: false
        },
        isEditable: false
      }
      // style: { head: { width: 60 } }
    },
    {
      column: "Normal Disease",
      title: "Normal Disease",
      property: "normalDisease",
      align: "center",
      sort: false,
      options: {
        link: {
          isLinked: false
        },
        isEditable: false
      }
      // style: { head: { width: 60 } }
    },

    {
      column: "Oncokb K",
      title: "Oncokb K",
      property: "oncogenicOncokbK",
      align: "center",
      sort: false,
      options: {
        link: {
          isLinked: false
        },
        isEditable: false
      }
      // style: { head: { width: 60 } }
    },

    {
      column: "Clinvar K",
      title: "Clinvar K",
      property: "oncogenicClinvarK",
      align: "center",
      sort: false,
      options: {
        link: {
          isLinked: false
        },
        isEditable: false
      }
      // style: { head: { width: 60 } }
    },

    {
      column: "Civic K",
      title: "Civic K",
      property: "oncogenicCivicK",
      align: "center",
      sort: false,
      options: {
        link: {
          isLinked: false
        },
        isEditable: false
      }
      // style: { head: { width: 60 } }
    },
    {
      column: "Version",
      title: "Version",
      property: "version",
      align: "center",
      sort: false,
      options: {
        link: {
          isLinked: false
        },
        isEditable: false
      }
      // style: { head: { width: 60 } }
    }
  ],
  ac: [
    {
      column: "Guide Level K",
      title: "Guide Level K",
      property: "guideLevelK",
      align: "center",
      sort: false,
      options: {
        link: {
          isLinked: false
        },
        isEditable: false
      }
      // style: { head: { width: 60 } }
    },
    {
      column: "Normal Disease",
      title: "Normal Disease",
      property: "normalDisease",
      align: "center",
      sort: false,
      options: {
        link: {
          isLinked: false
        },
        isEditable: false
      }
      // style: { head: { width: 60 } }
    },

    {
      column: "Drug Name",
      title: "Drug Name",
      property: "drugName",
      align: "center",
      sort: false,
      options: {
        link: {
          isLinked: false
        },
        isEditable: false
      }
      // style: { head: { width: 60 } }
    },

    {
      column: "Oncokb K",
      title: "Oncokb K",
      property: "levelOncokbK",
      align: "center",
      sort: false,
      options: {
        link: {
          isLinked: false
        },
        isEditable: false
      }
      // style: { head: { width: 60 } }
    },

    {
      column: "Clinvar K",
      title: " Clinvar K",
      property: "levelClinvarK",
      align: "center",
      sort: false,
      options: {
        link: {
          isLinked: false
        },
        isEditable: false
      }
      // style: { head: { width: 60 } }
    },

    {
      column: "Civic K",
      title: "Civic K",
      property: "levelCivicK",
      align: "center",
      sort: false,
      options: {
        link: {
          isLinked: false
        },
        isEditable: false
      }
      // style: { head: { width: 60 } }
    },
    {
      column: "Version",
      title: "Version",
      property: "version",
      align: "center",
      sort: false,
      options: {
        link: {
          isLinked: false
        },
        isEditable: false
      }
      // style: { head: { width: 60 } }
    }
  ]
};

const layoutStyle = {
  position: "absolute",
  // width: "100%",
  top: 0,
  // bottom: 0,
  right: 0,
  left: 5
};

// const headStyle = {
//   height: "1.2rem"
// };

function AnnoVariantGuide() {
  const parentEl = useRef(null);

  const {
    comm: { annotationType },
    study: { refSiteAnnotationsGuide }
  } = useStore();

  const matches = useMediaQuery("(min-height:940px)");

  const column = useMemo(() => {
    return columns[annotationType];
  }, [annotationType]);

  const size = useMemo(() => {
    let type = "Pathogenic";
    if (annotationType === "pa") {
      type = "Pathogenic";
    } else {
      type = "Actionable";
    }

    return (
      refSiteAnnotationsGuide.content && `(${refSiteAnnotationsGuide.content.length} ${type} found)`
    );
  }, [refSiteAnnotationsGuide.content]);

  const table = useMemo(() => {
    return (
      <TableTemplate
        title={"Variant Guide"}
        layoutStyle={layoutStyle}
        size={size}
        // headStyle={headStyle}
      >
        <div
          ref={parentEl}
          style={{ maxHeight: matches ? 750 : 550, overflowY: "auto" }}
        >
          <MuiTable
            id={`variantGuide`}
            parentEl={parentEl.current}
            columns={column || []}
            data={refSiteAnnotationsGuide}
            stickyHeader
            oddRow
            defaultPagination={{
              mode: "scroll",
              page: 0,
              rowsPerPage: 3
            }}
          ></MuiTable>
        </div>
      </TableTemplate>
    );
  }, [column]);

  return table;
}

export default observer(AnnoVariantGuide);
