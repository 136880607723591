import React from "react";
import useMediaQuery from "@material-ui/core/useMediaQuery";

function CustomInput({
  placeholder,
  onChange,
  value,
  type,
  useCustomStyle,
  inputStyle,
  name
}) {
  const classes = useCustomStyle && useCustomStyle();
  const matches = useMediaQuery("(min-width:1500px)");

  return (
    <input
      type={type}
      name={name}
      placeholder={placeholder}
      onChange={onChange}
      value={value}
      className={`${classes && classes.input} ${matches &&
        classes &&
        classes.input_1500}`}
      required
      style={inputStyle}
    />
  );
}

export default CustomInput;
