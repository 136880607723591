import React, { useMemo } from "react";
import useStore from "modules/hooks/useStore";
import { observer } from "mobx-react";
import MuiTable from "components/atoms/MuiTable";
import TableTemplate from "components/organisms/TableTemplate";

const columns = {
  snv: [
    {
      column: "Gene",
      title: "GENE",
      property: "gene",
      align: "center",
      sort: false,
      options: {
        link: {
          isLinked: false
        },
        isEditable: false
      }
      // style: { head: { width: 60 } }
    },
    {
      column: "Variant",
      title: "Variant",
      property: "variant",
      align: "center",
      sort: false,
      options: {
        link: {
          isLinked: false
        },
        isEditable: false
      }
      // style: { head: { width: 60 } }
    },

    {
      column: "Variant Type",
      title: "Variant Type",
      property: "variantType",
      align: "center",
      sort: false,
      options: {
        link: {
          isLinked: false
        },
        isEditable: false
      }
      // style: { head: { width: 60 } }
    }
  ],
  cnv: [
    {
      column: "Gene",
      title: "GENE",
      property: "gene",
      align: "center",
      sort: false,
      options: {
        link: {
          isLinked: false
        },
        isEditable: false
      }
      // style: { head: { width: 60 } }
    },
    {
      column: "Variant",
      title: "Variant",
      property: "variant",
      align: "center",
      sort: false,
      options: {
        link: {
          isLinked: false
        },
        isEditable: false
      }
      // style: { head: { width: 60 } }
    },

    {
      column: "Variant Type",
      title: "Variant Type",
      property: "variantType",
      align: "center",
      sort: false,
      options: {
        link: {
          isLinked: false
        },
        isEditable: false
      }
      // style: { head: { width: 60 } }
    }
  ],
  sv: [
    {
      column: "Gene",
      title: "GENE",
      property: "gene",
      align: "center",
      sort: false,
      options: {
        link: {
          isLinked: false
        },
        isEditable: false
      }
      // style: { head: { width: 60 } }
    },
    {
      column: "Variant",
      title: "Variant",
      property: "variant",
      align: "center",
      sort: false,
      options: {
        link: {
          isLinked: false
        },
        isEditable: false
      }
      // style: { head: { width: 60 } }
    },
    {
      column: "Variant Type",
      title: "Variant Type",
      property: "variantType",
      align: "center",
      sort: false,
      options: {
        link: {
          isLinked: false
        },
        isEditable: false
      }
      // style: { head: { width: 60 } }
    }
  ]
};

const layoutStyle = {
  position: "absolute",
  // width: "100%",
  top: 0,
  // bottom: 0,
  right: 0,
  left: 0
};

// const headStyle = {
//   height: "1.2rem"
// };

function AnnoVariantProfile() {
  const {
    comm: { annotationData, annotationType },
    study: { variantProfile }
  } = useStore();

  const column = useMemo(() => {
    const { variantType } = annotationData;
    let type = "snv";
    if (variantType === "CNV" || variantType === "cnv") {
      type = "cnv";
    } else if (variantType === "SV" || variantType === "sv") {
      type = "sv";
    }
    return columns[type];
  }, [annotationData]);

  const profileTitle = useMemo(() => {
    if (annotationType === "pa") {
      return "Variant Profile : Pathogenic " + `${annotationData.version}`;
    } else {
      return "Variant Profile : Actionable " + `${annotationData.version}`;
    }
    
  }, [annotationType]);

  const table = useMemo(() => {
    return (
      <TableTemplate
        title={profileTitle}
        layoutStyle={layoutStyle}
        // headStyle={headStyle}
      >
        <MuiTable
          id={`variantProfile`}
          // parentEl={parentEl.current}
          columns={column || []}
          data={variantProfile}
          // stickyHeader
          defaultPagination={{
            mode: "scroll"
            // page: 0,
            // rowsPerPage: 10
          }}
        ></MuiTable>
      </TableTemplate>
    );
  }, [column]);

  return table;
}

export default observer(AnnoVariantProfile);
