import React, { useEffect, useMemo, useState, useRef } from "react";
import InsHighChart from "../../atoms/ScrollChart/InsHighChart";
import NoScrollInsChart from "../../atoms/NoScrollChart/NoScrollInsChart";
import TableTemplate from "components/organisms/TableTemplate";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import useStore from "modules/hooks/useStore";
import Loading from "components/molecules/LoadingComponent";
import { observer } from "mobx-react";

// ScrollChart component를 CancerHighChart, VariantHighChart, InsHighChart의 3개로 나눔
// HighchartsReact의 key 값을 서로 다르게하기 위해서임. 
// by jdy , 2020-12

const layoutStyle = {
  position: "absolute",
  width: "33.25%",
  top: 0,
  bottom: 0,
  right: 0,
  left: "66.6%"
};

// modify by jdy 2020-12-07 , statistics.selectedVariant should be set before patient.insChart
function InsScrollChart() {
  const {
    patient,
    patient: { search, insChart },
    statistics: { selectedVariant }
  } = useStore();
  const mathes = useMediaQuery("(min-width:1500px)");
  const divRef = useRef();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(false);
    if (selectedVariant) {
      patient.loadInsChart().then(_ => {
        setLoading(true);
      });
    }
    return () => {
      patient.resetInsChart();
    };
  }, [search]);

  const bodyStyle = {
    position: "absolute",
    top: 39,
    bottom: 0,
    left: 0,
    right: 0
  };

  const height =
    divRef.current &&
    divRef.current.offsetHeight -
      divRef.current.childNodes[0].childNodes[0].offsetHeight -
      30;

  // const height = useMemo(() => {
  //   // return matches ? 335 : 235;
  //   return 315;
  // }, [matches]);

  const chart = useMemo(() => {
    return (
      Object.keys(insChart).length > 0 &&
      (insChart.series[0].data.length > 6.5 ? (
        <InsHighChart data={insChart} height={height} />
      ) : (
        <NoScrollInsChart data={insChart} height={height} />
      ))
    );
  }, [insChart, height]);

  const title = useMemo(() => {
    const selectedCases = mathes ? "Selected cases" : "";
    return (
      Object.keys(insChart).length > 0 && (
        <>
          <div
            style={{ marginBottom: 5 }}
          >{`By Institution (${insChart.series[0].data.length})`}</div>
          <div style={{ display: "flex", justifyContent: "center" }}>
            {/* {variantChart.series[1].data.reduce((a, b) => Math.max(a, b)) >
              0 && (
              <div style={{ display: "flex", marginRight: 10 }}>
                <div
                  style={{
                    width: "1rem",
                    height: "1rem",
                    backgroundColor: "rgb(67, 67, 72)",
                    marginRight: 5
                  }}
                />
                Selected variant in {search.institute}
              </div>
            )} */}
            <div style={{ display: "flex" }}>
              <div
                style={{
                  width: "1rem",
                  height: "1rem",
                  backgroundColor: "#d8d8d8",
                  marginRight: 5
                }}
              />
              {`${selectedCases} in K-MASTER`}
            </div>
          </div>
        </>
      )
    );
  }, [insChart, mathes]);

  return (
    <div ref={divRef}>
      <TableTemplate
        title={title}
        layoutStyle={layoutStyle}
        bodyStyle={bodyStyle}
      >
        <Loading loading={loading}>
          {chart}
          <div style={{ width: "100%", textAlign: "center" }}>
            {"Patients in Population"}
          </div>
        </Loading>
      </TableTemplate>
    </div>
  );
}

export default observer(InsScrollChart);
