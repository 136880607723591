import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/styles";
import CustomTab from "components/atoms/ui/CustomTab";
import usePersonStore from "modules/hooks/usePersonStore";
import useStore from "modules/hooks/useStore";
import { observer } from "mobx-react";
import NgsVariantPanel from "./NgsVariantPanel";

const useStyles = makeStyles({
  tabContainer: {
    position: "absolute",
    top: 50,
    bottom: 0,
    left: 0,
    right: 0
  },
  container: {
    overfolow: "auto",
    width: "100%",
    position: "absolute",
    bottom: 0,
    top: 35,
    backgroundColor: "#fff",
    borderTop: "1px solid #c6c6c6"
  },
  tabsRoot: {
    minHeight: 35,
    backgroundColor: "#ffffff"
  },
  tabRoot: {
    fontWeight: 400,
    // color: "#434343",
    minWidth: 160,
    minHeight: 35
  },
  panelRoot: {
    position: "absolute",
    top: 0,
    bottom: 0,
    right: 0,
    left: 0
  },
  scrollButtons: {
    width: 20
  },
  selected: {
    color: "#434343",
    fontWeight: 600
  },
  indicator: {
    backgroundColor: "#14a4e7",
    height: 5
  },
  disabled: {
    opacity: "0.7!important",
    color: "darkgrey"
  },
  wrapper: {
    fontSize: "0.9rem"
  }
});

function NgsVariantTab({ history, match }) {
  const { patient } = usePersonStore();
  const {
    patient: { ngsVariantTab }
  } = useStore();

  const handleChange = (e, newValue) => {
    history.push(`${match.url.replace(match.params.vtype, newValue)}`);
  };

  // useEffect(() => {
  //   if (ngsTabs.length > 0) {
  //     const [{ secId }] = ngsTabs.filter(f => {
  //       return f.value === match.params.date;
  //     });

  //     patient.loadNgs({
  //       id: match.url.split("/")[2],
  //       secId,
  //       variantType: match.params.vtype
  //     });
  //   }
  // }, [ngsTabs, match.params.date]);

  const defaultValue = match.params.vtype;

  return (
    <>
      <CustomTab
        columns={ngsVariantTab}
        onChange={handleChange}
        useStyles={useStyles}
        variant="scrollable"
        defaultValue={defaultValue}
      >
        <NgsVariantPanel value={defaultValue} />
      </CustomTab>
    </>
  );
}

export default observer(NgsVariantTab);
