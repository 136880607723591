import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import SampleTypeFilterList from "../SampleTypeFilterList";
import PrimarySiteTypeFilterList from "../PrimarySiteTypeFilterList";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
// 펼침 오무림을 위한 추가 by jdy, 2021-01
const styles = theme => ({
  padding: {
    padding: "0px!important",
    display: "inline"
  }
});

class SamplePrimary extends Component {
  
  render() {
    const { classes } = this.props;
    return (
      <ExpansionPanelDetails classes={{ root: classes.padding }}>
        <div>           
          <SampleTypeFilterList />
          <PrimarySiteTypeFilterList />
        </div>
      </ExpansionPanelDetails>
    );
  }
}

export default withStyles(styles)(SamplePrimary);
