import React from "react";

import { extendObservable, toJS } from "mobx";

class SyncLogModel {
  constructor(data) {
    extendObservable(this, toJS(data));
  }
}

export default SyncLogModel;
