import React from "react";
import { makeStyles } from "@material-ui/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";

const useStyles = makeStyles({
  title: {
    height: 38,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#7b7b7b",
    color: "#ffffff"
  },
  nonLeftContainer: {
    minWidth: "100%"
  },
  titleContainer: {
    display: "flex"
  },
  tabContainer: {
    width: 936,
    display: "inline-block",
    position: "absolute",
    bottom: 0,
    right: 0,
    top: 0,
    background: "#ffffff"
  },
  tabContainer_1500: {
    minWidth: 1150
  },
  content: {
    position: "absolute",
    top: "38px",
    bottom: 0,
    right: 0,
    left: 0
  },
  h2: {
    padding: 10,
    fontSize: "1.2rem",
    width: "100%"
  }
});

function CommContent(props) {
  const classes = useStyles();
  const matches = useMediaQuery("(min-width:1500px)");
  const { children, title, isLeft } = props;

  return (
    <div
      className={`${classes.tabContainer} ${matches &&
        isLeft &&
        classes.tabContainer_1500} ${!isLeft && classes.nonLeftContainer}`}
    >
      <div className={classes.title}>
        <div
          className={`${classes.nonLeftContainer} ${classes.titleContainer}`}
        >
          <h2 className={classes.h2}>{title}</h2>
        </div>
      </div>
      <div className={classes.content}>{children}</div>
    </div>
  );
}

export default CommContent;
