import React from "react";
import { Route, Redirect, Switch } from "react-router-dom";
import { NgsContent, NotFound } from "pages";

function NgsRoute() {
  return (
    <Route
      render={({ history, location, match }) => {
        history.goBack();
      }}
    />
  );
}

function NgsTabRouter(props) {
  const { match } = props;

  return (
    <Switch>
      <Route path={`${match.url}/:secId`} component={NgsContent} />
      <NgsRoute />
    </Switch>
  );
}

export default NgsTabRouter;
