import { useState, useCallback } from "react";

function useCheckState(defaultState) {
  const [state, setState] = useState(defaultState);

  const handleChangeState = useCallback(({ target: { checked } }) => {
    setState(checked);
  }, []);

  return [state, setState, handleChangeState];
}

export default useCheckState;
