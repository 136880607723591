import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/styles";
import CtsRouter from "shared/CtsTabRouter";
import { withRouter } from "react-router-dom";
import usePersonStore from "modules/hooks/usePersonStore";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Loading from "components/molecules/LoadingComponent";

const useStyles = makeStyles({
  layout: {
    width: "100%",
    height: "100%"
  },
  container_1500: {
    minWidth: 1090
  },
  container: {
    border: "1px solid #c6c6c6",
    position: "absolute",
    width: 876,
    top: 15,
    bottom: 0,
    left: 15,
    right: 15
  }
});

function ClinicalMatch({ history, match, location }) {
  const classes = useStyles();
  const {
    patient,
    patient: { cts }
  } = usePersonStore();
  const [loading, setLoading] = useState(false);
  const matches = useMediaQuery("(min-width:1500px)");

  // console.log("history", history, match, location);

  // if (
  //   history.action === "POP" &&
  //   location.pathname.split("/").pop() === "match"
  // ) {
  // console.log("match111", history);
  // console.log("match111", location);
  // history.goBack();
  // }

  useEffect(() => {
    setLoading(false);
    // console.log(history.action);
    // console.log("cts.length", cts.length);
    const firstNct =
      // cts.length > 0
      history.action === "PUSH" && cts.length > 0
        ? new Promise((resolve, reject) => resolve(cts[0]))
        : patient.loadCts(match.params.id);

    // const firstNct = patient.loadCts(match.params.id);

    if (null !== firstNct) {
      firstNct.then(value => {
        if (!location.pathname.split("/")[4]) {
          // console.log("match111", match);
          // patient.setClinicalTrial(value);
          // patient.loadClinicalTrial(value.nctId);
          patient.setTabUrl("match", `/${value.nctId}`);
          history.push(
            `${match.path
              .replace(":id", match.params.id)
              .replace(":tab", "match")}/${value.nctId}`
          );
        }
        setLoading(true);
      });
    }
    else {
      setLoading(true);
    }
 

    return () => {
      patient.setTabUrl("match", ``);
    };
  }, []);

  return (
    <div className={classes.layout}>
      <div
        className={`${classes.container} ${matches && classes.container_1500}`}
      >
        {
          <Loading loading={loading}>
            <CtsRouter match={match} />
          </Loading>
        }
      </div>
    </div>
  );
}

export default withRouter(ClinicalMatch);
