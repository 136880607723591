import React, { Component } from "react";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import Table from "@material-ui/core/Table";
import { withStyles } from "@material-ui/core/styles";
import DataFormat from "components/atoms/DataFormat";

const styles = theme => ({
  td: {
    fontSize: "1rem",
    borderRight: "1px solid #dedede",
    padding: "5px 5px",
    "&:last-child": {
      paddingRight: "5px"
    }
  },
  rtd: {
    padding: "0px",
    fontSize: "1rem",
    "&:last-child": {
      paddingRight: "0px"
    }
  },
  rtc: {
    fontSize: "1rem",
    padding: "5px 5px",
    "&:last-child": {
      paddingRight: "5px"
    }
  },
  rtr: {
    height: 20
  },
  table: {
    // marginLeft: "1px",
    borderRight: "1px solid #dedede",
    "& tr:nth-last-child(1)": {
      "& td": {
        borderBottom: "0px"
      }
    }
  },
  button: {
    fontSize: "1rem",
    padding: 0,
    border: "none",
    backgroundColor: "transparent",
    cursor: "pointer",
    color: "#4588fc",
    textDecoration: "underline"
  }
});

class DefaultCell extends Component {
  render() {
    const {
      data,
      column: { key, align },
      classes
    } = this.props;
    const value = data[key];
    return (
      <TableCell className={classes.td} align={align}>
        <DataFormat>
          {value || String(value) !== "undefined" ? String(value) : key}
        </DataFormat>
      </TableCell>
    );
  }
}

class NestedTableCell extends Component {
  render() {
    const {
      data,
      column: { key, link, align },
      classes
    } = this.props;
    const value = data[key];
    return (
      <TableCell classes={{ root: classes.rtd }}>
        <Table className={classes.table}>
          <TableBody className={classes.table}>
            {value.map((v, i) => {
              return (
                <TableRow key={i} className={classes.rtr}>
                  {link ? (
                    <LinkCell {...this.props} v={v} index={i} />
                  ) : (
                    <TableCell className={classes.rtc} align={align}>
                      <DataFormat type={{ string: "none" }}>
                        {String(v) || key}
                      </DataFormat>
                    </TableCell>
                  )}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableCell>
    );
  }
}

class LinkCell extends Component {
  handleClick = e => {
    e.preventDefault();
    const {
      data,
      // column: { key },
      onClick,
      // v,
      index
    } = this.props;
    onClick({
      link: index > -1 ? data["link"][index] : data["link"],
      panel: data["panel"]
    });
  };
  render() {
    const {
      data,
      column: { key, align },
      onClick,
      classes,
      v
    } = this.props;
    const value = data[key];
    return (
      <TableCell className={classes.td} align={align}>
        <button className={classes.button} onClick={this.handleClick}>
          <DataFormat>{v || value || key}</DataFormat>
        </button>
      </TableCell>
    );
  }
}

class CustomCell extends Component {
  render() {
    const {
      data,
      column: { key, link }
    } = this.props;
    return !Array.isArray(data[key]) ? (
      link ? (
        <LinkCell {...this.props} />
      ) : (
        <DefaultCell {...this.props} />
      )
    ) : (
      <NestedTableCell {...this.props} />
    );
  }
}
export default withStyles(styles)(CustomCell);
