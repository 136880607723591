import { action, observable } from "mobx";
import AccountRepository from "../repository/AccountRepository";
import AuthModel from "modules/model/AuthListModel";
import InsListModel from "modules/model/InsListModel";
import { queryString } from "dependencyLibrary";
import AccountInsTableModel from "../model/AccountIntTableModel";
import TableFormatModel from "../model/TableFormatModel";
import AccountUserTableModel from "../model/AccountUserTableModel";
import QuestionModel from "../model/QuestionModel";
import MyQuestionModel from "../model/MyQuestionModel";
import LineChartModel from "../model/LineChartModel";
import DateFormatModel from "../model/DateFormatModel";
import SyncLogModel from "../model/SyncLogModel";

const defaultQuestion = {
  title: "",
  contents: "",
  type: "Bug Report"
};

const defaultSearch = {
  size: 10,
  page: 0
};

const defaultQuestionSearch = {
  ...defaultSearch,
  sort: "id,desc"
};

const defaultUserSearch = {
  ...defaultSearch,
  sort: "activated,asc"
};

const defaultInsSearch = {
  ...defaultSearch,
  sort: "code,asc"
};

const defaultNotiSearch = {
  read: false
};

const defaultSyncSearch = Object.assign(defaultSearch, {
  size: 5
});

export default class AccountStore {
  @observable loggedIn = false;
  @observable user = {};
  @observable userList = {};
  @observable useSearch = {
    ...defaultUserSearch
  };
  // @observable userSelected = {};
  @observable insList = {};
  @observable insSearch = {
    ...defaultInsSearch
  };
  @observable allInsList = {};
  @observable logList = {};
  @observable userDetail = {};
  @observable insDetail = {};
  @observable authList = [];
  @observable questions = {};
  @observable question = {};
  @observable comment = {};
  @observable inputQuestion = { ...defaultQuestion };
  @observable inputQuestionComment = { ...defaultQuestion };
  @observable notifications = [];
  @observable notiiSearch = {
    ...defaultNotiSearch
  };
  @observable questionSearch = {
    ...defaultQuestionSearch
  };
  @observable overviewStat = {
    user: 0,
    institution: 0,
    lastUpdateDate: "",
    qaBoard: 0,
    activated: 0
  };
  @observable visitors = {};
  @observable visitorsCount = {};

  @observable downloadLink = "";
  @observable syncData = {
    cdm: {},
    vcf: {}
  };

  @observable syncUpdateData = {
    portal: {},
    match: {}
  };

  @observable syncSearch = {
    ...defaultSyncSearch
  };

  @observable syncUpdateSearch = {
    portal: { ...defaultSyncSearch },
    match: { ...defaultSyncSearch }
  };

  constructor(root) {
    this.root = root;
  }

  @action
  setLoggedIn(isLogged) {
    this.loggedIn = isLogged;
  }

  @action
  setUser(user) {
    this.user = user;
    //TODO: institute 설정
    // user.institute &&
    //   this.root.comm.setQueryString({
    //     institute: user.institute
    //   });
  }

  @action
  async getTestPortal () {
    try {

      let respStr = await AccountRepository.getTestPortal();

      console.log("Account getTestPortal : ", respStr);

      // S00123 -> 100123

      // patient.person.id = scrId;

      return true;

    } catch (e) {
      console.log("Account getTestPortal : failed", e);
      return false;
    }
  }

  @action
  async insUserLogin(loginInfo) {
    // FIXME: 에러 처리 부분
    try {
      const {
        data: { id_token, subjectId }
      } = await AccountRepository.insUserLogin(loginInfo);
      this.setAuthInHeader(id_token);

      if (!loginInfo.rememberMe) {
        sessionStorage.setItem("token", id_token);
        sessionStorage.setItem("date", Date.now());
      } else {
        localStorage.setItem("token", id_token);
      }

      this.setLoggedIn(!!id_token);
      this.loadUser();

      // 2021.11.24 add by jdy
      this.root.comm.setSubject(subjectId);

      return true;
      
    } catch (e) {
      console.log("insUserLogin", e);
      return false;
    }
  }


  @action
  async login(loginInfo) {
    // FIXME: 에러 처리 부분
    try {
      const {
        data: { id_token }
      } = await AccountRepository.requestLogin(loginInfo);
      this.setAuthInHeader(id_token);

      if (!loginInfo.rememberMe) {
        sessionStorage.setItem("token", id_token);
        sessionStorage.setItem("date", Date.now());
      } else {
        localStorage.setItem("token", id_token);
      }

      this.setLoggedIn(!!id_token);
      this.loadUser();
    } catch (e) {
      console.log("login", e);
    }
  }

  @action
  logout() {
    this.setLoggedIn(false);
    this.setUser(null);
    AccountRepository.setAuthInHeader(null);

    delete localStorage.token;
    delete sessionStorage.token;
    delete sessionStorage.date;

    this.root.patient.initSearch({});
    this.root.statistics.resetSelected();
    this.root.statistics.resetSearch();
  }

  @action
  async loadUser() {
    const { data } = await AccountRepository.loadUser();
    this.setUser(data);
  }

  @action
  setAuthInHeader(id_token) {
    AccountRepository.setAuthInHeader(id_token);
  }

  @action
  changePassword(query) {
    return AccountRepository.changePassword(query);
  }

  // @action
  // setUserSelected(data) {
  //   // console.log({ ...data });
  //   this.userSelected = "a";
  //   // this.userSelected = { ...data };
  // }

  @action
  async getUserList(query) {
    const search = queryString.stringify({ ...this.useSearch, ...query });
    const { data } = await AccountRepository.loadUserList(`?${search}`);
    this.userList = new TableFormatModel(
      data.content.map(m => new AccountUserTableModel(m)),
      data.totalElements
    );
  }

  @action
  async resetUserSearch() {
    this.useSearch = {
      ...defaultUserSearch
    };
  }

  @action
  async setUserSearch(query) {
    this.useSearch = {
      ...this.useSearch,
      ...query
    };
  }

  @action
  async getUser(query) {
    const { data } = await AccountRepository.loadUserList(query);
    this.userDetail = data;
  }

  @action
  async deleteUser(query) {
    await AccountRepository.deleteUser(query);
    this.getUserList();
  }

  @action
  async getInsList(query) {
    const search = queryString.stringify({ ...this.insSearch, ...query });
    const { data } = await AccountRepository.loadInsList(`?${search}`);
    this.insList = new TableFormatModel(
      data.content.map(m => new AccountInsTableModel(m)),
      data.totalElements
    );
  }

  @action
  async resetInsSearch() {
    this.insSearch = {
      ...defaultInsSearch
    };
  }

  @action
  async setInsSearch(query) {
    this.insSearch = {
      ...this.insSearch,
      ...query
    };
  }

  @action
  async getIns(query) {
    const { data } = await AccountRepository.loadInsList(query);
    this.insDetail = data;
  }

  @action
  setInsDetail(query) {
    this.insDetail = query;
  }

  @action
  async getAllInsList(query) {
    const { data } = await AccountRepository.loadInsList(query);
    this.allInsList = data.content.map(d => new InsListModel(d));
  }

  @action
  async getAllInsListOfNoAuth(query) {
    const { data } = await AccountRepository.fetchInsList(query);
    this.allInsList = data.content.map(d => new InsListModel(d));
  }

  @action
  async getLogList(query) {
    const { data } = await AccountRepository.loadLogList(query);
    this.logList = data;
  }

  @action
  async getAuthList() {
    const { data } = await AccountRepository.loadAuthList();
    this.authList = data.map(d => new AuthModel(d));
  }

  @action
  async changeUser(query) {
    await AccountRepository.changeUser(query);
    this.getUserList();
  }

  @action
  async changeIns(id, query) {
    await AccountRepository.changeIns(id, query);
    this.getInsList();
  }

  @action
  creatUser(query) {
    return AccountRepository.creatUser(query);
    //this.getUserList();
  }

  @action
  async registerUser(query) {
    try {
      const { data } = await AccountRepository.registerUser(query);
      return data;
    } catch (e) {
      console.log("error", e);
    }
  }

  @action
  async resrtPassword(query) {
    try {
      const { data } = await AccountRepository.resrtPassword(query);
      return data;
    } catch (e) {
      console.log("forgot email", e);
    }
  }

  @action
  async resrtPasswordFinish({ skey, newPassword }) {
    const key = queryString.parse(skey).key;
    const { data } = await AccountRepository.resrtPasswordFinish({
      key,
      newPassword
    });
    return data;
  }

  @action
  async creatIns(query) {
    await AccountRepository.creatIns(query);
    this.getInsList();
  }

  @action
  async deleteIns(id) {
    /* 
	    변경일자:   2020-06-15
	    변경자 이름: 지신복 
	    변경 내용:  try catch 추가
	    변경 사유:  화면 멈춤현상 발생                               
	  */       
    try{
      const { data } = await AccountRepository.deleteIns(id);
      return data;
    }catch(e){
      console.log(e)
    }
    
  }

  @action
  async loadQuestionList(query) {
    const search = queryString.stringify({ ...this.questionSearch, ...query });
    const { data } = await AccountRepository.fetchQuestionList(`?${search}`);
    this.questions = new TableFormatModel(
      data.content.map(m => new QuestionModel(m)),
      data.totalElements
    );
  }

  @action
  async resetQuestionSearch() {
    this.questionSearch = {
      ...defaultQuestionSearch
    };
  }

  @action
  async setQuestionSearch(query) {
    this.questionSearch = {
      ...this.questionSearch,
      ...query
    };
  }

  @action
  async loadMyQuestionList(query) {
    const search = queryString.stringify({
      ...this.questionSearch,
      ...query,
      email: this.user.email
    });
    const { data } = await AccountRepository.fetchMyQuestionList(`?${search}`);
    this.questions = new TableFormatModel(
      data.content.map(m => new MyQuestionModel(m)),
      data.totalElements
    );
  }

  @action
  async loadQuestion({ id }) {
    const query = queryString.stringify({ id });
    const { data } = await AccountRepository.fetchQuestion(`?${query}`);
    this.question = data;
    // console.log("question", this.question);
  }

  @action
  resetQuestion() {
    this.question = {};
  }

  @action
  setInputQuestion(obj) {
    this.inputQuestion = Object.assign(this.inputQuestion, {
      ...this.inputQuestion,
      ...obj
    });
  }

  @action
  resetInputQuestion() {
    this.inputQuestion = { ...defaultQuestion };
  }

  @action
  submitInputQuestion() {
    const { firstName, lastName, email } = this.user;

    return AccountRepository.insertQuestion({
      ...this.inputQuestion,
      firstName,
      lastName,
      email
    });
  }

  @action
  async loadQuestionComment({ id }) {
    const query = queryString.stringify({ qaId: id ? id : this.question.id });
    const { data } = await AccountRepository.fetchQuestionComment(`?${query}`);
    this.comment = data.pop();
    // console.log("comment", this.comment);
  }

  @action
  resetQuestionComment() {
    this.comment = {};
  }

  @action
  setQuestionComment(obj) {
    this.comment = Object.assign(this.comment, {
      ...obj
    });
  }

  @action
  async submitQuestionComment() {
    const { id, comment } = this.comment;
    const { firstName, lastName, email } = this.user;

    let method = "POST";
    let data = {
      comment
    };
    if (id) {
      method = "PUT";
      data = Object.assign(data, {
        id
      });
    } else {
      data = Object.assign(data, {
        firstName,
        lastName,
        email,
        qaId: this.question.id
      });
    }
    await AccountRepository.comment({ method, data });
    // this.loadQuestionComment({ id: this.question.id });
    this.loadQuestion({ id: this.question.id });
  }

  @action
  async loadNotificationList() {
    const search = queryString.stringify(this.notiiSearch);
    const { data } = await AccountRepository.fetchNotifications(`?${search}`);
    this.notifications = data;
  }

  @action
  setNotiSearch(obj) {
    this.notiiSearch = obj;
  }

  @action
  async modifyNotifications(payload, all) {
    await AccountRepository.modifyNotifications({
      payload,
      all
    });
    this.loadNotificationList();
  }

  @action
  async loadAdminStatus(query) {
    const { data } = await AccountRepository.fetchAdminStatus(query);
    this.overviewStat = Object.assign(this.overviewStat, {
      [query.type]: data
    });
  }

  @action
  async loadVisitorCount(type) {
    const { data } = await AccountRepository.fetchVisitorCount();
    this.visitors = data;
    this.getVisitor(() => this.visitors[type]);
  }

  @action
  getVisitor(f) {
    const data = typeof f() === "string" ? this.visitors[f()] : f();
    this.visitorsCount = {
      category: data.map(m => m.time),
      series: [new LineChartModel({ data, name: "Visitor", property: "value" })]
    };
  }

  @action
  activateUser(data) {
    return AccountRepository.activate(data);
  }

  @action
  async downloadExcelTemplate(param) {
    const { data } = await AccountRepository.downloadExcelTemplate(param);
    this.downloadLink = window.URL.createObjectURL(new Blob([data]));
  }

  @action
  async uploadExcelTemplate({ type, file }) {
    const data = new FormData();
    data.append("file", file);
    const ret = await AccountRepository.uploadExcelTemplate({ type, data });
    this.getUserList();
    return ret;
  }

  @action
  async loadSyncData({ type }) {
    const search = queryString.stringify(this.syncSearch);
    const { data } = await AccountRepository.fetchSyncData({
      search: `?${search}`
    });
    this.syncData["cdm"] = new TableFormatModel(
      data.content.map(
        m => new SyncLogModel(new DateFormatModel(m, "updateDatetime"))
      ),
      data.totalElements
    );
  }

  @action
  setSyncSearch(obj) {
    this.syncSearch = {
      ...this.syncSearch,
      ...obj
    };
  }

  @action
  resetSyncSearch() {
    this.syncSearch = {
      ...defaultSyncSearch
    };
  }

  @action
  async loadSyncUpdateData({ type, system }) {
    const search = queryString.stringify(this.syncUpdateSearch[system]);
    const { data } = await AccountRepository.fetchSyncData({
      system,
      search: `?${search}`
    });
    this.syncUpdateData[system] = new TableFormatModel(
      data.content.map(
        m => new SyncLogModel(new DateFormatModel(m, "updateDatetime"))
      ),
      data.totalElements
    );
  }

  @action
  setSyncUpdateSearch({ system, obj }) {
    this.syncUpdateSearch = {
      ...this.syncUpdateSearch,
      [system]: {
        ...this.syncUpdateSearch[system],
        ...obj
      }
    };
  }

  @action
  resetSyncUpdateSearch({ system }) {
    this.syncUpdateSearch[system] = {
      ...defaultSyncSearch
    };
  }

  @action
  async updateCdm({ type, system, ...rest }) {
    // TODO: 업데이트 버튼 이벤트 클릭 시 호출
    // 아래 리파지토리 메서드 작업해주세요. 현재 api 없음.
    const data = await AccountRepository.updateCdm(rest);
    return data;
  }

  @observable apiKey = {
    receive: {
      serverName: "",
      apiKey: "",
      apiSig: ""
    },
    publish: {
      serverName: "",
      apiKey: "",
      apiSig: ""
    }
  };

  @action
  async loadApiKey(type) {
    const { data } = await AccountRepository.fetchApiKey(type);
    this.apiKey = {
      ...this.apiKey,
      [type]: data.pop()
    };
  }

  @action
  async changeApiKey(type, { id, ...payload }) {
    const data = await AccountRepository.modifyApiKey({
      type,
      id,
      payload
    });
    return data;
  }
}
