// import LocalizedSite from "modules/store/LocalizedSiteStore";
import Study from "modules/store/StudyStore";
import Patient from "modules/store/PatientStore";
// import PatientDetail from "modules/store/PatientDetailStore";
// import Panel from "modules/store/PanelStore";
// import Home from "modules/store/HomeStore";
// import QuickSearch from "modules/store/QuickSearchStore";
// import Cancer from "modules/store/CancerStore";
// import AdvancedSearch from "modules/store/AdvancedSearchStore";
import Comm from "modules/store/CommStore";
import AccountStore from "./AccountStore";
import StatisticsStore from "./StatisticsStore";
import PatientReviewStore from "./PatientReviewStore";

class Store {
  constructor() {
    this.patient = new Patient(this);
    // this.patientDetail = new PatientDetail(this);
    // this.localizedSite = new LocalizedSite(this);
    this.study = new Study(this);
    // this.panel = new Panel(this);
    // this.home = new Home(this);
    // this.quickSearch = new QuickSearch(this);
    // this.cancer = new Cancer(this);
    this.comm = new Comm(this);
    // this.advanced = new AdvancedSearch(this);
    this.account = new AccountStore(this);
    this.statistics = new StatisticsStore(this);
    this.review = new PatientReviewStore(this);
  }
}

export default Store;
