import React from "react";
import CustomButton from "./CustomButton";

function CustomImageButton({ src, title, onClick, style }) {
  return (
    <CustomButton
      onClick={onClick}
      type="button"
      style={{ border: "hidden", background: "none" }}
    >
      <img src={src} alt="" title={title} style={style} />
    </CustomButton>
  );
}

export default CustomImageButton;
