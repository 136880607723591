import React, { useCallback, useState } from "react";
import CustomButton from "components/atoms/ui/CustomButton";
import { makeStyles } from "@material-ui/styles";
import { Link, withRouter } from "react-router-dom";
import useStore from "modules/hooks/useStore";
import CheckPermission from "components/lib/CheckPermission";
// import usePersonStore from "modules/hooks/usePersonStore";

const menu = [
  { label: "PATIENT", value: "patient", permission: "ALL" },
  { label: "EXPLORER", value: "explorer", permission: "ALL" },
  { label: "ADMIN", value: "admin", permission: "ROLE_ADMIN" }
];

const menuMatchView = [
  { label: "PATIENT VIEW", value: "patient-view", permission: "ALL" }
];

const useStyles = makeStyles({
  button: {
    fontSize: "1.23rem",
    marginRight: 15,
    border: "none",
    background: "none",
    cursor: "pointer",
    fontFamily: "roboto"
  },
  selected: {
    color: "#14a4e7"
  }
});

function HeaderMenu(props) {
  const { statistics, patient, patient:{isMatchView} } = useStore();
  // const {isMatchView} = this.props;

  const onClick = useCallback(e => {
    if (e.target.nodeName === "BUTTON") {
      patient.resetSearch({});
      statistics.resetSelected();
      statistics.resetSearch();
    }
  }, []);

  if (isMatchView) {
    return (

      <ul onClick={onClick}>
        {menuMatchView.map(({ label, value, permission }) => (
          <React.Fragment key={label}>
            <CheckPermission permission={permission}>
              <Link to={`/${value}`}>
                <CustomButton
                  type={"text"}
                  useCustomStyle={useStyles}
                  value={value}
                  key={label}
                  selected={props.location.pathname.split("/")[1] === value}
                >
                  {label}
                </CustomButton>
              </Link>
            </CheckPermission>
          </React.Fragment>
        ))}
      </ul>

    );
  }
  else {
    return (

      <ul onClick={onClick}>
        {menu.map(({ label, value, permission }) => (
          <React.Fragment key={label}>
            <CheckPermission permission={permission}>
              <Link to={`/${value}`}>
                <CustomButton
                  type={"text"}
                  useCustomStyle={useStyles}
                  value={value}
                  key={label}
                  selected={props.location.pathname.split("/")[1] === value}
                >
                  {label}
                </CustomButton>
              </Link>
            </CheckPermission>
          </React.Fragment>
        ))}
      </ul>

    );
  }


}

export default withRouter(HeaderMenu);
