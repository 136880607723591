import React, { useMemo } from "react";
import TableTemplate from "components/organisms/TableTemplate";
import useStore from "modules/hooks/useStore";
import { observer } from "mobx-react";

const bodyStyle = {
  overflowY: "auto",
  overflowX: "hidden",
  position: "absolute",
  borderLeft: "1px solid #dedede",
  borderBottom: "1px solid #dedede",
  borderRight: "1px solid #dedede",
  top: 39,
  bottom: 0,
  left: 0,
  right: 0
};

function Criteria() {
  const {
    patient: { criteria }
  } = useStore();

  // console.log(criteria.length > 0 && criteria);

  const content = useMemo(() => {
    return criteria.map((cri, i) => {
      return (
        <div key={i}>
          {cri.split("\n\n").map((m, i) => {
            return m.includes("Inclusion Criteria") ||
              m.includes("Exclusion Criteria") ? (
              <h4 key={i}>{m}</h4>
            ) : (
              <span key={i}>
                {m}
                <br />
              </span>
            );
          })}
        </div>
      );
    });
  }, [criteria]);

  return (
    <TableTemplate title={"Criteria"} bodyStyle={bodyStyle}>
      {content}
    </TableTemplate>
  );
}

export default observer(Criteria);
