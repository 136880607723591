class GVGuideModel {
  constructor(data) {
    // console.log(data.referenceSiteName);
    this.id = data.id; // pa:0, ac:1

    this.version = data.version;
    this.gene = data.gene;
    this.variant = data.variant;
    this.variantType = data.variantType;
    
    this.originalDisease = data.originalDisease;
    this.normalDisease = data.normalDisease;
    this.drugName = data.drugName;
    
    this.oncogenicClinvarK = data.oncogenicClinvarK;
    this.oncogenicOncokbK = data.oncogenicOncokbK;
    this.oncogenicCivicK = data.oncogenicCivicK;
    
    this.levelClinvarK = data.levelClinvarK;
    this.levelOncokbK = data.levelOncokbK;
    this.levelCivicK = data.levelCivicK;
    
    this.guideOncogenicK = data.guideOncogenicK;
    this.guideLevelK = data.guideLevelK;
  }
}

export default GVGuideModel;
