import React, { useRef, useEffect, useMemo, useCallback } from "react";
import { observer } from "mobx-react";
import { makeStyles } from "@material-ui/styles";
import MuiTable from "components/atoms/MuiTable";
import TableTemplate from "components/organisms/TableTemplate";
import TableHeightContainer from "components/lib/TableHeightContainer";
import useStore from "modules/hooks/useStore";
import queryString from "query-string";

const useStyles = makeStyles({
  manager: {
    width: "-webkit-fill-available",
    margin: "15px",
    border: "solid 1px #c6c6c6"
  }
});

const updateColumns = [
  {
    column: "Update Time",
    property: "",
    align: "left",
    sort: false,
    options: {
      link: {
        isLinked: false
      },
      isEditable: false
    },
    style: { head: { width: 200 } }
  },
  {
    column: "Version",
    property: "",
    align: "left",
    sort: false,
    options: {
      link: {
        isLinked: false
      },
      isEditable: false
    },
    style: { head: { width: 200 } }
  },
  {
    column: "Update Size",
    property: "",
    align: "left",
    sort: false,
    options: {
      link: {
        isLinked: false
      },
      isEditable: false
    },
    style: { head: { width: 200 } }
  },
  {
    column: "Update Result",
    property: "",
    align: "left",
    sort: false,
    options: {
      link: {
        isLinked: false
      },
      isEditable: false
    },
    style: { head: { width: 200 } }
  },
  {
    column: "Change Log",
    property: "",
    align: "right",
    sort: false,
    options: {
      link: {
        isLinked: false
      },
      isEditable: false
    },
    style: { head: { width: 200 } }
  }
];

const patchColumns = [
  {
    column: "Version",
    property: "",
    align: "left",
    sort: false,
    options: {
      link: {
        isLinked: false
      },
      isEditable: false
    },
    style: { head: { width: 200 } }
  },
  {
    column: "Update Size",
    property: "",
    align: "left",
    sort: false,
    options: {
      link: {
        isLinked: false
      },
      isEditable: false
    },
    style: { head: { width: 200 } }
  },
  {
    column: "Download Start Time",
    property: "",
    align: "left",
    sort: false,
    options: {
      link: {
        isLinked: false
      },
      isEditable: false
    }
  },
  {
    column: "Change Log",
    property: "",
    align: "left",
    sort: false,
    options: {
      link: {
        isLinked: false
      },
      isEditable: false
    }
  },
  {
    column: "Validation",
    property: "",
    align: "left",
    sort: false,
    options: {
      link: {
        isLinked: false
      },
      isEditable: false
    }
  }
];

const variantColumns = [
  {
    column: "Update Time",
    property: "",
    align: "left",
    sort: false,
    options: {
      link: {
        isLinked: false
      },
      isEditable: false
    },
    style: { head: { width: 200 } }
  },
  {
    column: "Version",
    property: "",
    align: "left",
    sort: false,
    options: {
      link: {
        isLinked: false
      },
      isEditable: false
    }
  },
  {
    column: "Update Result",
    property: "",
    align: "left",
    sort: false,
    options: {
      link: {
        isLinked: false
      },
      isEditable: false
    }
  },
  {
    column: "Change Log",
    property: "",
    align: "left",
    sort: false,
    options: {
      link: {
        isLinked: false
      },
      isEditable: false
    }
  }
];

function DataManage() {
  const classes = useStyles();
  const parentEl = useRef(null);
  const {
    account
    // account: { userList, insList }
  } = useStore();

  useEffect(() => {
    // account.getUserList('?size=5');
    // account.getInsList('?size=5');
  }, []);

  const userhandlePagination = useCallback(e => {
    let query = {};
    query.page = e.page;
    query.size = e.rowsPerPage;
    account.getUserList(query);
  }, []);

  const inshandlePagination = useCallback(e => {
    let query = {};
    query.page = e.page;
    query.size = e.rowsPerPage;
    account.getInsList(query);
  }, []);

  const size = useMemo(() => {
    return null;
  }, []);

  return (
    <div style={{ width: "100%", height: "100%" }}>
      <div
        style={{
          top: "15px",
          left: "15px",
          right: "15px",
          border: "1px solid #c6c6c6",
          bottom: 0,
          padding: "15px",
          position: "absolute",
          overflowY: "auto",
          backgroundColor: "#fff"
        }}
      >
        <TableTemplate
          borderHide={true}
          title="Clinical Data Update"
          size={size}
        >
          <div ref={parentEl}>
            {/* <TableHeightContainer> */}
            <MuiTable
              id={`UPDATE`}
              parentEl={parentEl.current}
              columns={updateColumns}
              data={{}}
              // stickyHeader
              oddRow
              defaultPagination={{
                page: 0,
                rowsPerPage: 5,
                handlePatination: userhandlePagination
              }}
            ></MuiTable>
            {/* </TableHeightContainer> */}
          </div>
        </TableTemplate>
        <TableTemplate
          borderHide={true}
          title="Clinical Data Patch Status"
          size={size}
        >
          <div ref={parentEl}>
            {/* <TableHeightContainer> */}
            <MuiTable
              id={`CLINICAL`}
              parentEl={parentEl.current}
              columns={patchColumns}
              data={{}}
              // stickyHeader
              oddRow
              defaultPagination={{
                page: 0,
                rowsPerPage: 5,
                handlePatination: inshandlePagination
              }}
            ></MuiTable>
            {/* </TableHeightContainer> */}
          </div>
        </TableTemplate>
        <TableTemplate
          borderHide={true}
          title="Variant Annotation History"
          size={size}
        >
          <div ref={parentEl}>
            {/* <TableHeightContainer> */}
            <MuiTable
              id={`VARIANT`}
              parentEl={parentEl.current}
              columns={variantColumns}
              data={{}}
              // stickyHeader
              oddRow
              defaultPagination={{
                page: 0,
                rowsPerPage: 5,
                handlePatination: inshandlePagination
              }}
            ></MuiTable>
            {/* </TableHeightContainer> */}
          </div>
        </TableTemplate>
      </div>
    </div>
  );
}

export default observer(DataManage);
