import React, { useRef, useEffect, useMemo, useState } from "react";
import MuiTable from "components/atoms/MuiTable";
import usePersonStore from "modules/hooks/usePersonStore";
import useStore from "modules/hooks/useStore";
import { observer } from "mobx-react";
import TableTemplate from "../../../TableTemplate";
import TableHeightContainer from "../../../../lib/TableHeightContainer";
import MatchButton from "components/molecules/MatchButton";
import CustomDialog from "components/atoms/ui/CustomDialog";

const columns = [
  {
    column: "Date",
    property: "date",
    align: "right",
    sort: false,
    options: {
      link: {
        isLinked: false
      },
      isEditable: false
    },
    style: { head: { width: 200 } }
  },
  {
    column: "Type",
    property: "treatment",
    align: "left",
    sort: false,
    options: {
      link: {
        isLinked: false
      },
      isEditable: false
    },
    style: { head: { width: 200 } }
  },
  {
    column: "Description",
    property: "detailTreatment",
    align: "left",
    sort: false,
    options: {
      link: {
        isLinked: false
      },
      isEditable: false
    }
  },
  {
    column: "Remarks",
    property: "remark",
    align: "left",
    sort: false,
    options: {
      link: {
        isLinked: false
      },
      isEditable: false
    },
    style: { head: { width: 200 } }
  }
];

function TreatmentTable() {
  const parentEl = useRef(null);
  const { patient, id } = usePersonStore();
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);

  const {
    patient: { treatments }
  } = useStore();


  const onClose = () => {
    setOpen(false);
  };

  const actions = useMemo(() => {
    return (
      <>
        <MatchButton style={{ width: "50%" }} onClick={onClose}>
          Close
        </MatchButton>
      </>
    );
  }, []);

  useEffect(() => {
    setLoading(false);
    // if (id) {
    //   patient.loadTreatements(id);
    //   setTimeout(() => {
    //     setLoading(true);
    //   }, 500);
    // }
    
    if (id) {
      patient.loadTreatements(id).then(value => {
        if(false === value) {
          // setOpen(true);
        }
        setLoading(true);
      });
    }
  }, [id]);

  const size = useMemo(() => {
    return (
      treatments.content && `(${treatments.content.length} treatments found)`
    );
  }, [treatments.content]);

  return (
    <>
    
      <TableTemplate title="Treatment" size={size}>
        <div ref={parentEl}>
          <TableHeightContainer maxHeight={180}>
            <MuiTable
              id={`treatments`}
              parentEl={parentEl.current}
              columns={columns}
              data={treatments}
              stickyHeader
              oddRow
              loading={loading}
              defaultPagination={{
                mode: "scroll",
                page: 0,
                rowsPerPage: 10
              }}
            ></MuiTable>
          </TableHeightContainer>
        </div>
      </TableTemplate>

      <CustomDialog
        open={open}
        title={"Thers is no subject infomation"}
        onClose={onClose}
        actions={actions}
        maxWidth={"md"}
      >
        <div>please check again.</div>
      </CustomDialog>
    </>

  );
}

export default observer(TreatmentTable);
