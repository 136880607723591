import React, { useCallback } from "react";
import CustomRadioButton from "../../atoms/ui/CustomRadiobutton";
import useStore from "modules/hooks/useStore";
import { observer } from "mobx-react";

function VariantFilterRadioButton({ value, variantType }) {
  const {
    patient,
    patient: { search },
    statistics,
    statistics: { selectedCancer, selectedVariant }
  } = useStore();

  const handleChange = useCallback(
    e => {
      statistics.setOpen(false);
      statistics.setSelectedVariant(e.target.value);
      delete search.snv;
      delete search.cnv;
      delete search.sv;

      patient.resetSearch({
        ...search,
        cancer: selectedCancer,
        [variantType.toLowerCase()]: value
      });
    },
    [selectedVariant]
  );

  return (
    <CustomRadioButton
      selectedValue={selectedVariant}
      handleChange={handleChange}
      value={value}
      name={"variants"}
    />
  );
}

export default observer(VariantFilterRadioButton);
