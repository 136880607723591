class NgsInfoModel {
  constructor(data) {
    this.icon = null;
    this.testId = data.secId || "-";
    this.panel = data.panel || "-";
    this.specimen = `${data.specimenId || "-"} / ${data.specimenType || "-"}`;
    this.vcfFilter = data.vcfFilter || "-";
  }
}

export default NgsInfoModel;
