import React, { useState, useMemo, useEffect } from "react";
import useStore from "modules/hooks/useStore";
import { observer } from "mobx-react";
import MatchButton from "components/molecules/MatchButton";
import { makeStyles } from "@material-ui/core/styles";

const useStyle = makeStyles(theme => ({
  name: {
    margin: 10,
    fontSize: "1rem"
  },
  value: {
    margin: 10
  },
  buttonContainer: {
    display: "flex",
    margin: 10
  },
  buttontitle: {
    paddingTop: "10px",
    fontSize: "11px",
    fontWeight: "500"
  },
  description: {
    width: "100%",
    height: "2rem",
    border: " solid 1px #dedede"
  }
}));

const EditUserPanel = observer(({ onClose, joinInfo, setJoinInfo }) => {
  const classes = useStyle();

  const onChange = ({ target: { name, value } }) => {
    setJoinInfo(
      Object.assign(joinInfo, {
        [name]: value
      })
    );
  };

  const nameBox = useMemo(() => {
    return (
      <input
        name="name"
        type="text"
        className={classes.description}
        onChange={onChange}
        value={joinInfo.name}
        required
      />
    );
  }, [joinInfo.name]);

  const codeBox = useMemo(() => {
    return (
      <input
        name="code"
        type="text"
        className={classes.description}
        onChange={onChange}
        value={joinInfo.code}
        required
      />
    );
  }, [joinInfo.code]);
  const emailBox = useMemo(() => {
    return (
      <input
        name="managerEmail"
        type="text"
        className={classes.description}
        onChange={onChange}
        value={joinInfo.email}
        required
      />
    );
  }, [joinInfo.email]);
  const addressBox = useMemo(() => {
    return (
      <input
        name="address"
        type="text"
        className={classes.description}
        onChange={onChange}
        value={joinInfo.address}
        required
      />
    );
  }, [joinInfo.address]);

  const phoneBox = useMemo(() => {
    return (
      <input
        name="telephone"
        type="text"
        className={classes.description}
        onChange={onChange}
        value={joinInfo.telephone}
        required
      />
    );
  }, [joinInfo.phone]);

  const manageNameBox = useMemo(() => {
    return (
      <input
        name="description"
        type="text"
        className={classes.description}
        onChange={onChange}
        value={joinInfo.description}
        required
      />
    );
  }, [joinInfo.description]);

  return (
    <div style={{ width: "50rem" }}>
      <div className={classes.tableDiv}>
        <div className={classes.tableDiv}>
          <div className={classes.name}>Code</div>
          <div className={classes.value}>{codeBox}</div>
        </div>
        <div className={classes.name}>Name</div>
        <div className={classes.value}>{nameBox}</div>
      </div>
      <div className={classes.tableDiv}>
        <div className={classes.name}>Address</div>
        <div className={classes.value}>{addressBox}</div>
      </div>
      <div className={classes.tableDiv}>
        <div className={classes.name}> Telephone</div>
        <div className={classes.value}>{phoneBox}</div>
      </div>
      <div className={classes.tableDiv}>
        <div className={classes.name}>Manager Email</div>
        <div className={classes.value}>{emailBox}</div>
      </div>
      <div className={classes.tableDiv}>
        <div className={classes.name}> Description</div>
        <div className={classes.value}>{manageNameBox}</div>
      </div>
      <div className={classes.buttonContainer}>
        <MatchButton style={{ width: "50%" }} onClick={onClose} type="button">
          Cancel
        </MatchButton>
        <MatchButton style={{ width: "50%" }} type="submit">
          Submit
        </MatchButton>
      </div>
    </div>
  );
});

function RegisterInsButton({ history }) {
  const { account } = useStore();
  const [joinInfo, setJoinInfo] = useState({});

  const onClose = () => {
    history.goBack();
  };

  /* 
	    변경일자:   2020-07-07
	    변경자 이름: 지신복 
	    변경 내용:  Institution 생성 성공시에만 화면 닫기 
	    변경 사유:  사용자 편리성 제공                               
	 */
  const onSubmit = e => {
    e.preventDefault();
    account.creatIns(joinInfo).then(value => {
      try{
        alert(value.data);
        onClose();
      }catch(e){
        console.log(e)
      }

    });
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        height: "100%"
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "baseline"
        }}
      >
        <h2 style={{ margin: 10, fontSize: "2rem" }}>Register Institution</h2>
        <form onSubmit={onSubmit}>
          <EditUserPanel
            onClose={onClose}
            joinInfo={joinInfo}
            setJoinInfo={setJoinInfo}
          />
        </form>
      </div>
    </div>
  );
}

export default observer(RegisterInsButton);
