import React from "react";
import useStore from "modules/hooks/useStore";
import ac from "assets/images/button/ac.svg";
import pa from "assets/images/button/pa.svg";

const icon = {
  ac,
  pa
};

function ActionableDialogButton({ children, type, ...rest }) {
  const { comm } = useStore();

  const handleClickOpen = () => {
    comm.setEvidenceType(type);
    comm.setEvidenceData(rest);
    comm.setActionableOpen(true);
  };

  return (
    <button
      onClick={handleClickOpen}
      style={{ border: "hidden", background: "none", cursor: "pointer" }}
    >
      {children}
      <img
        src={icon[type]}
        alt=""
        title={type === "ac" ? "Actionability" : "Pathogenicity"}
      />
    </button>
  );
}

export default ActionableDialogButton;
