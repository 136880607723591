import React, { useRef, useMemo } from "react";
// import MuiTable from "components/atoms/MuiTable";
import useStore from "modules/hooks/useStore";
import { observer } from "mobx-react";
// import TableTemplate from "../../../../TableTemplate";
import { makeStyles } from "@material-ui/styles";
// import DataFormat from "components/atoms/DataFormat";
import AnnoRefTable from "./annoRefTable";
import onco from "assets/images/siteicon/onco.png";
import CustomImageButton from "components/atoms/ui/CustomImageButton";

const useStyles = makeStyles({
  container: {
    overflow: "auto",
    position: "absolute",
    top: "3rem",
    bottom: 0,
    left: 20,
    right: 20
  },
  title: {
    margin: "0px 10px",
    fontSize: "1.1rem",
    color: "#454444"
  }
});

const url = {
  column: "URL",
  title: "Url",
  property: "url",
  align: "center",
  sort: false,
  options: {
    link: {
      isLinked: false
    },
    isEditable: false,
    withCellExcute: ({ value }) => WrapperComponent => {
      return (
        <WrapperComponent>
          <CustomImageButton
            src={onco}
            style={{ width: 20, height: 20, cursor: "pointer" }}
            onClick={() => {
              window.open(value);
            }}
            title={value}
          />
        </WrapperComponent>
      );
    }
  },
  style: { head: { width: 50 } }
};
const columns = {
  pa: [
    {
      column: "OriginalDisease",
      title: "OriginalDisease",
      property: "originalDisease",
      align: "center",
      sort: false,
      style: { head: { width: 100 } },
      options: {
        link: {
          isLinked: false
        },
        isEditable: false
      }
      // style: { head: { width: 150 } }
    },
    {
      column: "NormalDisease",
      title: "NormalDisease",
      property: "normalDisease",
      align: "center",
      sort: false,
      style: { head: { width: 100 } },
      options: {
        link: {
          isLinked: false
        },
        isEditable: false
      }
      // style: { head: { width: 150 } }
    },
    {
      column: "1st Transform",
      title: "Interpretation",
      property: "interpretation",
      align: "center",
      sort: false,
      style: { head: { width: 100 } },
      options: {
        link: {
          isLinked: false
        },
        isEditable: false
      }
      // style: { head: { width: 50 } }
    },
    {
      column: "Guide_Oncogenicity",
      title: "Oncogenicity",
      property: "oncogenicity",
      align: "center",
      sort: false,
      style: { head: { width: 100 } },
      options: {
        link: {
          isLinked: false
        },
        isEditable: false
      }
      // style: { head: { width: 50 } }
    },
    {
      column: "Version",
      title: "Version",
      property: "version",
      align: "center",
      sort: false,
      style: { head: { width: 100 } },
      options: {
        link: {
          isLinked: false
        },
        isEditable: false
      }
      // style: { head: { width: 50 } }
    }
    // {
    //   column: "Citation",
    //   title: "Citation",
    //   property: "citations",
    //   align: "center",
    //   sort: false,
    //   options: {
    //     link: {
    //       isLinked: false
    //     },
    //     isEditable: false
    //   },
    //   style: { head: { width: 170 } }
    // }
    // url
  ],
  ac: [
    {
      column: "OriginalDisease",
      title: "OriginalDisease",
      property: "originalDisease",
      align: "center",
      sort: false,
      style: { head: { width: 100 } },
      options: {
        link: {
          isLinked: false
        },
        isEditable: false
      }
      // style: { head: { width: 150 } }
    },
    {
      column: "NormalDisease",
      title: "NormalDisease",
      property: "normalDisease",
      align: "center",
      sort: false,
      style: { head: { width: 100 } },
      options: {
        link: {
          isLinked: false
        },
        isEditable: false
      }
      // style: { head: { width: 150 } }
    },
    {
      column: "DrugName",
      title: "DrugName",
      property: "drugName",
      align: "center",
      sort: false,
      style: { head: { width: 100 } },
      options: {
        link: {
          isLinked: false
        },
        isEditable: false
      }
      // style: { head: { width: 50 } }
    },
    {
      column: "1st Transform",
      title: "Interpretation",
      property: "interpretation",
      align: "center",
      sort: false,
      style: { head: { width: 100 } },
      options: {
        link: {
          isLinked: false
        },
        isEditable: false
      }
      // style: { head: { width: 50 } }
    },
    {
      column: "Guide_Level",
      title: "Level",
      property: "level",
      align: "center",
      sort: false,
      style: { head: { width: 100 } },
      options: {
        link: {
          isLinked: false
        },
        isEditable: false
      }
      // style: { head: { width: 50 } }
    },
    {
      column: "Version",
      title: "Version",
      property: "version",
      align: "center",
      sort: false,
      style: { head: { width: 100 } },
      options: {
        link: {
          isLinked: false
        },
        isEditable: false
      }
      // style: { head: { width: 50 } }
    }
    // {
    //   column: "Citation",
    //   title: "Citation",
    //   property: "citations",
    //   align: "center",
    //   sort: false,
    //   options: {
    //     link: {
    //       isLinked: false
    //     },
    //     isEditable: false
    //   },
    //   style: { head: { width: 170 } }
    // }
    // url
  ]
};

function AnnoCivic({bCurVersion}) {
  const {
    comm: { annotationType }
  } = useStore();

  return <AnnoRefTable columns={columns[annotationType]} type={"Civic"}  bCurVersion={bCurVersion} />;
}

export default observer(AnnoCivic);
