import React, { useEffect, useCallback, useState } from "react";
import { makeStyles } from "@material-ui/styles";
import CustomTab from "components/atoms/ui/CustomTab";
import useCommStore from "modules/hooks/useCommStore";
import useStore from "modules/hooks/useStore";
import VariantTabPanel from "./VariantTabPanel";
import { observer } from "mobx-react";


const useStyles = makeStyles({
  tabContainer: {
    width: "100%",
    height: "100%"
  },
  container: {
    position: "absolute",
    bottom: 0,
    width: "100%",
    top: "35px"
  },
  tabsRoot: {
    minHeight: 35
  },
  tabRoot: {
    fontSize: "1.1rem",
    borderBottom: "1px solid #c6c6c6",
    backgroundColor: "#ffffff",
    minWidth: 182,
    minHeight: 35,
    fontWeight: 400
  },
  panelRoot: {
    // overflowY: "auto",
    height: "100%",
    width: "100%",
    backgroundColor: "#f6f6f6",
    borderRight: "1px solid #c6c6c6",
    borderLeft: "1px solid #c6c6c6",
    borderTop: "none",
    borderBottom: "1px solid #c6c6c6"
  },
  selected: {
    borderRight: "1px solid #c6c6c6",
    borderLeft: "1px solid #c6c6c6",
    borderTop: "1px solid #c6c6c6",
    borderBottom: "none",
    backgroundColor: "#f6f6f6",
    fontWeight: 600
  },
  indicator: {
    display: "none"
  },
  empty: {
    borderBottom: "1px solid #c6c6c6",
    maxWidth: "100%",
    width: "100%",
    minHeight: 35
  },
  disabled: {
    opacity: "0.7!important",
    color: "darkgrey"
  },
  wrapper: {
    fontSize: "1rem"
  }
});

function VariantTab({ geneName, versionTab, history, match, location }) {
  const { gene } = useCommStore();
  const {
    study,
    study: { annoStats, tabUrl }
  } = useStore();

  // const [loading, setLoading] = useState(false);
  const [defaultValue, setDefaultValue] = useState('snv');

  const handleChange = useCallback(
    (e, newValue) => {
      setDefaultValue(newValue);
    },
    []
  );

  // useEffect(() => {
  //   setLoading(false);

  //   if (history.action === "POP" || location.state) {
  //     patient.loadPerson(match.params.id).then(value => {
  //       setLoading(true);
  //     });

  //   }
      
  // }, [match.params.id, history]);

  // useEffect(() => {
  //   if (gene) {
  //     setLoading(false);
  //     study.loadAnnoStats(gene, version).then(value => {
  //       setLoading(true);
  //     });
  //   }
  // }, [gene]);

  // const defaultValue = 'snv';

  return (
    <CustomTab
      columns={versionTab === 0 ? annoStats['V2'] : annoStats['V1'] }
      onChange={handleChange}
      useStyles={useStyles}
      defaultValue={defaultValue}
    >
      <VariantTabPanel value={defaultValue} />
    </CustomTab>
  );
}

export default observer(VariantTab);
