import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import SexFilterButtonList from "../SexFilterButtonList";
import AgeFilterButtonList from "../AgeFilterButtonList";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";

// 펼침 오무림을 위한 추가 by jdy, 2021-01

const styles = theme => ({
  padding: {
    padding: "0px!important",
    display: "inline"
  }
});

class GenderAge extends Component {
  
  render() {
    const { classes } = this.props;
    return (
      <ExpansionPanelDetails classes={{ root: classes.padding }}>
        <div>           
          <SexFilterButtonList />
          <AgeFilterButtonList />
        </div>
      </ExpansionPanelDetails>
    );
  }
}

export default withStyles(styles)(GenderAge);
