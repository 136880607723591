import { axios } from "dependencyLibrary";

class PaientReviewRepository {
  URL = "/clinical/reviews";
  version = "/v2";

  fetchReviews = ({ type, id }) => {
    return axios.request({
      method: "GET",
      url: `${this.URL}/${type}/${id}`,
      ver: this.version
    });
  };

  submitReviews = ({ type, payload }) => {
    return axios.request({
      method: "POST",
      url: `${this.URL}/${type}`,
      ver: this.version,
      data: payload
    });
  };

  // modifyReviews = ({ type, data }) => {
  //   return axios.request({
  //     method: "PUT",
  //     url: `${this.URL}/${type}`,
  //     ver: this.version,
  //     data
  //   });
  // };

  // removeReviews = ({ type, id }) => {
  //   return axios.request({
  //     method: "DELETE",
  //     url: `${this.URL}/${type}/${id}`,
  //     ver: this.version
  //   });
  // };
}

export default new PaientReviewRepository();
