import React from "react";
import { Route, Redirect, Switch } from "react-router-dom";
import {
  NotFound,
  OverviewOfAdmin,
  UserManager,
  AccessLog,
  InstitutionManager,
  RegisterUser,
  RegisterIns,
  QuestionAndAnswer,
  Sync
} from "pages";
import useStore from "modules/hooks/useStore";

function PrivateRoute({ component: Component, ...rest }) {
  const { account } = useStore();

  const remaining = Math.floor(
    ((Date.now() - sessionStorage.getItem("date")) / (1000 * 60 * 60)) % 24
  );

  const isSession = remaining <= 6;

  // console.log("remaining", remaining);

  if (!isSession) account.logout();

  return (
    <Route
      {...rest}
      render={props =>
        sessionStorage.getItem("token") ||
        localStorage.getItem("token") ||
        isSession ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: "/login",
              state: { from: props.location }
            }}
          />
        )
      }
    />
  );
}

function AdminRouter({ match: { url } }) {
  return (
    <Switch>
      <PrivateRoute path={`${url}/qna/:id`} component={QuestionAndAnswer} />
      <PrivateRoute path={`${url}/qna`} component={QuestionAndAnswer} />
      <PrivateRoute path={`${url}/sync/:tabs`} component={Sync} />
      <PrivateRoute path={`${url}/sync`} component={Sync} />
      <PrivateRoute
        path={`${url}/institutions/register`}
        component={RegisterIns}
      />
      <PrivateRoute
        path={`${url}/institutions`}
        component={InstitutionManager}
      />
      <PrivateRoute path={`${url}/logs`} component={AccessLog} />
      <PrivateRoute path={`${url}/users/register`} component={RegisterUser} />
      <PrivateRoute path={`${url}/users`} component={UserManager} />
      <PrivateRoute path={`${url}`} component={OverviewOfAdmin} />
      {/* <Route component={NotFound} /> */}
    </Switch>
  );
}

export default AdminRouter;
