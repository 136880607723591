import React from "react";
import useStore from "modules/hooks/useStore";
import TableTemplate from "components/organisms/TableTemplate";
import { observer } from "mobx-react";

function TheraphyInformation() {
  const {
    patient: { theraphyInformation }
  } = useStore();

  return (
    <TableTemplate title={"Therapy Information"}>
      {JSON.stringify(theraphyInformation)}
    </TableTemplate>
  );
}

export default observer(TheraphyInformation);
