import React, { useCallback, useMemo } from "react";
import Logo from "../../molecules/HeaderLogo";
import HeaderMenu from "../../molecules/HeaderMenu";
import HeaderPopover from "../../molecules/HeaderPopover";
import InstituteSearchKeyword from "../../molecules/InstituteSearchKeyword";
import CheckPermission from "components/lib/CheckPermission";
import { makeStyles } from "@material-ui/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import useStore from "modules/hooks/useStore";
import { Link } from "react-router-dom";
import { observer } from "mobx-react";

const useStyles = makeStyles({
  header: {
    position: "absolute",
    top: 0,
    right: 0,
    left: 0,
    width: "100%",
    height: "7%",
    justifyContent: "center",
    alignItems: "center",
    zIndex: 500
  },
  back: {
    backgroundColor: "#ffffff",
    display: "flex"
  },
  container_1500: {
    minWidth: 1500
  },
  container: {
    position: "absolute",
    top: 0,
    right: 0,
    left: 0,
    bottom: 0,
    width: 1250,
    margin: "1rem auto",
    display: "flex",
    alignItems: "center",
    zIndex: 500
  },
  menu: {
    position: "absolute",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    top: 0,
    right: 0,
    bottom: 0,
    left: "21.5rem"
  }
});

function Header({ isMain }) {
  const classes = useStyles();
  const {
    patient,
    patient:{isMatchView},
    account: { loggedIn }
  } = useStore();
  const matches = useMediaQuery("(min-width:1500px)");

  const onClick = useCallback(() => {
    patient.resetSearch({});
    // statistics.resetSelected();
    // statistics.resetSearch();
  }, []);

  return (
    <div className={`${classes.header} ${!isMain && classes.back}`} id="header">
      <div
        className={`${classes.container} ${matches && classes.container_1500}`}
      >
        {!isMatchView && (
          <Link to="/overview">
            <div onClick={onClick}>
              <Logo isMain={isMain} />
            </div>
          </Link>
        )}
        {isMatchView && (
          <div>
            <Logo isMain={isMain} />
          </div>
        )}

        {!isMain && loggedIn && (
          <div className={classes.menu}>
            <HeaderMenu isMatchView={isMatchView} />
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center"
              }}
            >
              <CheckPermission permission={"ROLE_DM_USER"}>
                <div style={{ marginRight: 5 }}>
                  <InstituteSearchKeyword />
                </div>
              </CheckPermission>
              {!isMatchView && (<HeaderPopover />)}
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default observer(Header);
