import React from "react";
import PersonSearchInput from "components/molecules/PersonSearchInput";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles({
  container: {
    padding: "10px 10px"
  }
});

function SearchKeywordInputOfPatient() {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <PersonSearchInput />
    </div>
  );
}

export default SearchKeywordInputOfPatient;
