import React, { useEffect, useCallback, useState, useMemo } from "react";
import useStore from "modules/hooks/useStore";
import { observer } from "mobx-react";
import MatchButton from "components/molecules/MatchButton";
import BookmarkIcon from "@material-ui/icons/Bookmark";
import BookmarkBorderIcon from "@material-ui/icons/BookmarkBorder";
import Loading from "components/molecules/LoadingComponent";

function FollowPatientButton() {
  const {
    patient,
    patient: { person, isFollow, followId }
  } = useStore();

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(false);
    if (person.id) {
      patient.isFollowPatient(person.id);
    }
    setLoading(true);
  }, [person, followId]);

  const onClick = useCallback(() => {
    patient.follow(isFollow.followed);
    patient.loadPersons();
    // patient.setFollowId();
  }, [isFollow]);

  const follow = useMemo(() => {
    return <Loading loading={loading}>Bookmark</Loading>;
  }, [loading, isFollow]);

  // console.log(isFollow, followId === person.id);
  return (
    <MatchButton
      onClick={onClick}
      style={{ width: 150 }}
      icon={
        isFollow.followed && isFollow.id === person.id ? (
          <div style={{ color: "#f9c012", paddingTop: 1 }}>
            <BookmarkIcon />
          </div>
        ) : (
          <BookmarkBorderIcon />
        )
      }
    >
      {follow}
    </MatchButton>
  );
}

export default observer(FollowPatientButton);
