import React, { Component } from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";

class PieChart extends Component {
  state = {
    options: {}
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps !== prevState)
      return {
        options: {
          chart: {
            plotBackgroundColor: null,
            plotBorderWidth: null,
            plotShadow: false,
            type: "pie",
            width: nextProps.width,
            height: nextProps.height || (9 / 16) * 100 + "%"
          },
          title: {
            text: null
          },
          credits: {
            enabled: false
          },
          exporting: {
            enabled: false
          },
          legend: {
            enabled: false
          },
          tooltip: {
            enabled: false
          },
          plotOptions: {
            pie: {
              size: "80%",
              allowPointSelect: false,
              cursor: "pointer",
              dataLabels: {
                crop: false,
                enabled: true,
                format: "{point.name} ({point.y})",
                style: {
                  color:
                    (Highcharts.theme && Highcharts.theme.contrastTextColor) ||
                    "black",
                  fontSize: "1rem",
                  fontWeight: "none",
                  fontFamily: `-apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif`,
                  width: "70px"
                },
                softConnector: true,
                distance: 5
              },
              showInLegend: true
            },
            series: {
              cursor: "pointer",
              point: {
                events: {
                  click: function() {
                    nextProps.onClick(this.name);
                  }
                }
              }
            }
          },
          series: [{ data: nextProps.series }]
        }
      };

    return null;
  }

  render() {
    const { options } = this.state;

    return <HighchartsReact highcharts={Highcharts} options={options} />;
  }
}

export default PieChart;
