import React, { useEffect } from "react";
import TabHeader from "./TabHeader";
import TabPanel from "./TabPanel";
import useMediaQuery from "@material-ui/core/useMediaQuery";

function CustomTab({
  children,
  variant,
  columns,
  onChange,
  useStyles,
  defaultValue
}) {
  const classes = useStyles();
  const matches = useMediaQuery("(min-width:1500px)");

  const [value, setValue] = React.useState(defaultValue || columns[0].value);

  const handleChange = (e, newValue) => {
    onChange(e, newValue);
    setValue(newValue);
  };

  useEffect(() => {
    setValue(defaultValue);
  }, [defaultValue]);

  return (
    <div
      className={`${classes.tabContainer} ${matches &&
        classes.tabContainer_1500}`}
    >
      <TabHeader
        columns={columns}
        variant={variant}
        value={value}
        handleChange={handleChange}
        useStyles={useStyles}
      />
      <TabPanel
        columns={columns}
        value={value}
        children={children}
        useStyles={useStyles}
      />
    </div>
  );
}

export default CustomTab;
