import React from "react";
import { Route, Redirect, Switch } from "react-router-dom";
import { CtsContent, NotFound } from "pages";

function CtsRoute() {
  // console.log("cts route");
  return (
    <Route
      render={({ history, location, match }) => {
        history.goBack();
        //         const pathArr = location.pathname.split("/");
        //         console.log(pathArr);
        //         if (pathArr[2] && !pathArr[3]) {
        //           // return (
        //           //   <Redirect
        //           //     to={{
        //           //       pathname: `${match.url}/${pathArr[2]}/summary`,
        //           //       state: { from: location }
        //           //     }}
        //           //   />
        //           // );
        //         }
      }}
    />
  );
}

function CtsTabRouter(props) {
  const { match } = props;

  return (
    <Switch>
      <Route path={`${match.url}/:nctId`} component={CtsContent} />
      <CtsRoute />
    </Switch>
  );
}

export default CtsTabRouter;
