import React from "react";
import Button from "@material-ui/core/Button";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles({
  label: {
    display: "inline-block"
  },
  buttonRoot: {
    borderRadius: 0,
    borderRight: "1px solid #dddddd"
  },
  buttonLast: {
    borderRight: "none"
  },
  selected: {
    backgroundColor: "#14a4e7",
    color: "#fff"
  },
  group: {
    borderRadius: 0,
    borderTop: "1px solid #dddddd",
    borderBottom: "1px solid #dddddd"
  }
});

function CustomButton({ onClick, data, last, selected }) {
  const classes = useStyles();

  const handleClick = e => {
    onClick(e, data);
  };

  return (
    <Button
      disableFocusRipple
      disableRipple
      fullWidth
      value={data.key}
      onClick={handleClick}
      classes={{
        root: `${classes.buttonRoot} ${last && classes.buttonLast} ${selected &&
          classes.selected}`,
        label: classes.label
      }}
    >
      {data.name}
    </Button>
  );
}

function CustomButtonList({ data, onClick, selected }) {
  const classes = useStyles();

  const handleClick = (e, name) => {
    onClick(name);
  };

  return (
    <ButtonGroup
      fullWidth
      disableFocusRipple
      disableRipple
      className={classes.group}
    >
      {data.map((d, i) => {
        return (
          <CustomButton
            key={i}
            data={d}
            selected={selected === d.selected}
            onClick={handleClick}
            last={data.length - 1 === i}
          />
        );
      })}
    </ButtonGroup>
  );
}

export default CustomButtonList;
