import React, { useMemo } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Paper from "@material-ui/core/Paper";
import Draggable from "react-draggable";
import CloseIcon from "@material-ui/icons/Close";
import { makeStyles } from "@material-ui/core/styles";

const useStyle = makeStyles(theme => ({
  title: {
    padding: 10,
    "& h2": {
      display: "flex",
      justifyContent: "space-between"
    }
  },
  dividers: {
    // borderTop: "none",
    borderBottom: "none",
    paddingBottom: 0,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center"
    // borderTop: "1px solid #dedede"
  },
  actions: {
    padding: 5
  },
  paperWidthSm: {
    maxWidth: 680,
    minHeight: 400
  },
  paperWidthMd: {
    height: 704
  }
}));

function PaperComponent(props) {
  return (
    <Draggable>
      <Paper {...props} />
    </Draggable>
  );
}

function Form({ onSubmit, children }) {
  return (
    <form
      onSubmit={onSubmit}
      style={{ display: "flex", flexDirection: "column" }}
    >
      {children}
    </form>
  );
}

function CustomDialog({
  open,
  onClose,
  title,
  children,
  actions,
  maxWidth,
  draggable,
  onSubmit
}) {
  const classes = useStyle();

  const close = useMemo(() => {
    return (
      <button
        onClick={onClose}
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          background: "none",
          border: "none",
          color: "#ababab",
          cursor: "pointer",
          padding: 0
        }}
      >
        <CloseIcon />
      </button>
    );
  }, []);

  const content = useMemo(() => {
    let tags = (
      <>
        {title && (
          <>
            <DialogTitle classes={{ root: classes.title }}>
              {title}
              {close}
            </DialogTitle>
          </>
        )}
        <DialogContent dividers={true} classes={{ dividers: classes.dividers }}>
          {children}
        </DialogContent>
        <DialogActions classes={{ root: classes.actions }}>
          {actions}
        </DialogActions>
      </>
    );

    if (onSubmit) {
      tags = <Form onSubmit={onSubmit}>{tags}</Form>;
    }

    return tags;
  }, [children]);

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth={maxWidth}
      PaperComponent={draggable && PaperComponent}
      fullWidth
      classes={{
        paperWidthSm: classes.paperWidthSm,
        paperWidthMd: classes.paperWidthMd
      }}
      // scroll={'paper'}
    >
      {content}
    </Dialog>
  );
}

export default CustomDialog;
