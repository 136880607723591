import React, { useMemo } from "react";
import useStore from "modules/hooks/useStore";
import { makeStyles } from "@material-ui/core/styles";
import TableTemplate from "components/organisms/TableTemplate";

const useStyle = makeStyles(theme => ({
  container: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderTop: "1px solid #dedede",
    borderLeft: "1px solid #dedede",
    borderRight: "1px solid #dedede"
  },
  lastContainer: {
    borderBottom: "1px solid #dedede"
  },
  div: {
    textAlign: "center",
    // display: "flex",
    // justifyItems: "center",
    // alignItems: "center",
    height: 15,
    padding: "5px 5px",
    borderRight: "1px solid #dedede",
    width: "50%"
  },
  title: {
    whiteSpace: "nowrap",
    backgroundColor: "#f3f3f3",
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  content: {
    display: "flex",
    alignItems: "center",
    justifyContent: "start"
  },
  lastDiv: {
    borderRight: "none"
  }
}));

function ClinicalSummary() {
  const {
    patient: { clinicalTrialSummaryData }
  } = useStore();
  const classes = useStyle();

  const content = useMemo(() => {
    return clinicalTrialSummaryData.content.map((ctsd, i) => {
      const conditions = ctsd.conditions.join("/");
      const drugs = ctsd.drugs.join("/");
      const sponsor = ctsd.sponsor;

      return (
        <div key={i}>
          <div className={`${classes.container}`}>
            <div className={`${classes.div} ${classes.title}`}>Sponsor</div>
            <div
              title={sponsor}
              className={`${classes.div} ${classes.lastDiv} ${classes.content}`}
            >
              {`${
                sponsor.length > 25 ? sponsor.slice(0, 25) + "..." : sponsor
              }`}
            </div>
          </div>
          <div className={`${classes.container}`}>
            <div className={`${classes.div} ${classes.title}`}>
              Condition or Disease
            </div>
            <div
              title={conditions}
              className={`${classes.div} ${classes.lastDiv} ${classes.content}`}
            >{`${
              conditions.length > 25
                ? conditions.slice(0, 25) + "..."
                : conditions
            }`}</div>
          </div>
          <div className={`${classes.container}`}>
            <div className={`${classes.div} ${classes.title}`}>
              Intervention/Treatment
            </div>
            <div
              title={drugs}
              className={`${classes.div} ${classes.lastDiv} ${classes.content}`}
            >{`${drugs.length > 25 ? drugs.slice(0, 25) + "..." : drugs}`}</div>
          </div>
          <div className={`${classes.container}`}>
            <div className={`${classes.div} ${classes.title}`}>Phase</div>
            <div
              className={`${classes.div} ${classes.lastDiv} ${classes.content}`}
            >
              {ctsd.phases}
            </div>
          </div>
          <div className={`${classes.container}`}>
            <div className={`${classes.div} ${classes.title}`}>
              Age Eligible for Study
            </div>
            <div
              className={`${classes.div} ${classes.lastDiv} ${classes.content}`}
            >
              {ctsd.ages}
            </div>
          </div>
          <div className={`${classes.container}`}>
            <div className={`${classes.div} ${classes.title}`}>
              Sexes Eligible for Study
            </div>
            <div
              className={`${classes.div} ${classes.lastDiv} ${classes.content}`}
            >
              {ctsd.sexs}
            </div>
          </div>
          <div className={`${classes.container} ${classes.lastContainer}`}>
            <div className={`${classes.div} ${classes.title}`}>Status</div>
            <div
              className={`${classes.div} ${classes.lastDiv} ${classes.content}`}
            >
              {ctsd.overallStatus}
            </div>
          </div>
        </div>
      );
    });
  }, [clinicalTrialSummaryData]);

  return (
    <div style={{ paddingBottom: 5 }}>
      <TableTemplate title={"Clinical trial summary"}>{content}</TableTemplate>
    </div>
  );
}

export default ClinicalSummary;
