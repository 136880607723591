import React, { useMemo } from "react";
import useStore from "modules/hooks/useStore";
import { observer } from "mobx-react";
import MuiTable from "components/atoms/MuiTable";
import TableTemplate from "components/organisms/TableTemplate";
import { withRouter } from "react-router-dom";

const columns = {
  snv: [
    {
      column: "Variant",
      title: "Variant",
      property: "variant",
      align: "left",
      sort: false,
      options: {
        link: {
          isLinked: false
        },
        isEditable: false
      }
      // style: { head: { width: 60 } }
    },
    {
      column: "Chr",
      title: "CHR",
      property: "chr",
      align: "right",
      sort: false,
      options: {
        link: {
          isLinked: false
        },
        isEditable: false
      }
      // style: { head: { width: 60 } }
    },
    {
      column: "Start",
      title: "Start",
      property: "startPosition",
      align: "right",
      sort: false,
      options: {
        link: {
          isLinked: false
        },
        isEditable: false
      }
      // style: { head: { width: 60 } }
    },
    {
      column: "End",
      title: "End",
      property: "endPosition",
      align: "right",
      sort: false,
      options: {
        link: {
          isLinked: false
        },
        isEditable: false
      }
      // style: { head: { width: 60 } }
    },
    {
      column: "Ref",
      title: "Reference",
      property: "reference",
      align: "left",
      sort: false,
      options: {
        link: {
          isLinked: false
        },
        isEditable: false
      }
      // style: { head: { width: 60 } }
    },
    {
      column: "Alt",
      title: "ALT",
      property: "alternative",
      align: "left",
      sort: false,
      options: {
        link: {
          isLinked: false
        },
        isEditable: false
      }
      // style: { head: { width: 60 } }
    }
  ],
  cnv: [
    {
      column: "Variant",
      title: "Variant",
      property: "variant",
      align: "left",
      sort: false,
      options: {
        link: {
          isLinked: false
        },
        isEditable: false
      }
      // style: { head: { width: 60 } }
    },
    {
      column: "Copy Number",
      title: "Copy Number",
      property: "copyNumber",
      align: "right",
      sort: false,
      options: {
        link: {
          isLinked: false
        },
        isEditable: false
      }
      // style: { head: { width: 60 } }
    },
    {
      column: "Locus",
      title: "Locus",
      property: "locus",
      align: "left",
      sort: false,
      options: {
        link: {
          isLinked: false
        },
        isEditable: false
      }
      // style: { head: { width: 60 } }
    }
  ],
  sv: [
    {
      column: "Variant",
      title: "Variant",
      property: "variant",
      align: "left",
      sort: false,
      options: {
        link: {
          isLinked: false
        },
        isEditable: false
      }
      // style: { head: { width: 60 } }
    },
    {
      column: "Break Point",
      title: "Break Point",
      property: "breakPoint",
      align: "left",
      sort: false,
      options: {
        link: {
          isLinked: false
        },
        isEditable: false
      }
      // style: { head: { width: 60 } }
    },
    {
      column: "Read Count",
      title: "Read Count",
      property: "readCount",
      align: "right",
      sort: false,
      options: {
        link: {
          isLinked: false
        },
        isEditable: false
      }
      // style: { head: { width: 60 } }
    }
  ]
};

const layoutStyle = {
  position: "absolute",
  // width: "100%",
  top: 0,
  // bottom: 0,
  right: 0,
  left: 0
};

// const headStyle = {
//   height: "1.2rem"
// };

function VariantProfile({ match, location }) {
  const {
    comm: { evidenceData },
    patient: { variantProfile }
  } = useStore();

  const column = useMemo(() => {
    const { mutType } = evidenceData;
    let type = "snv";
    if (mutType === "Amp." || mutType === "Del." || mutType === "CNV") {
      type = "cnv";
    } else if (mutType === "SV") {
      type = "sv";
    }
    return columns[type];
  }, [evidenceData]);

  const table = useMemo(() => {
    return (
      <TableTemplate
        title={"Variant Profile"}
        layoutStyle={layoutStyle}
        // headStyle={headStyle}
      >
        <MuiTable
          id={`variantProfile`}
          // parentEl={parentEl.current}
          columns={column || []}
          data={variantProfile}
          // stickyHeader
          defaultPagination={{
            mode: "scroll"
            // page: 0,
            // rowsPerPage: 10
          }}
        ></MuiTable>
      </TableTemplate>
    );
  }, [column]);

  return table;
}

export default withRouter(observer(VariantProfile));
