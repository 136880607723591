import React from "react";
import ActionableDialogButton from "components/molecules/ActionableDialogButton";
import { extendObservable, computed } from "mobx";

function transferNull(fn, obj) {
  const retObj = {};
  for (let [k, v] of Object.entries(obj)) {
    Object.assign(retObj, {
      [k]: fn(v)
    });
  }

  return retObj;
}

class SvListModel {
  constructor(data) {
    extendObservable(
      this,
      transferNull(v => v || "", data)
    );
  }

  @computed
  get pa() {
    return (
      this.pathogenicity && (
        <ActionableDialogButton
          gene={this.gene}
          variant={this.gene2}
          mutType={this.sequenceAlteration}
          type={"pa"}
        >
          {/* pa */}
        </ActionableDialogButton>
      )
    );
  }

  @computed
  get ac() {
    return (
      this.actionability && (
        <ActionableDialogButton
          gene={this.gene}
          variant={this.gene2}
          mutType={this.sequenceAlteration}
          type={"ac"}
        >
          {/* ac */}
        </ActionableDialogButton>
      )
    );
  }
}

export default SvListModel;
