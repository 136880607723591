import React, { useState, useMemo } from "react";
import CustomDialog from "../../components/atoms/ui/CustomDialog";
import useStore from "modules/hooks/useStore";
import MatchButton from "components/molecules/MatchButton";
import CloseIcon from "@material-ui/icons/Close";

function DeleteUser({ id }) {
  const { account } = useStore();

  const [open, setOpen] = useState(false);

  
  	/* 
	    변경일자:   2020-07-02
	    변경자 이름: 지신복 
	    변경 내용:  이벤트 null 체크로직 추가 
	    변경 사유: 삭제후 닫기시 에러발생                                
	 */   
  const onClose = e => {
    if(e != null){
      e.stopPropagation();
    }
    setOpen(false);
  };

  

  const handleDeleteClickOpen = e => {
    e.stopPropagation();
    setOpen(true);
  };

  const deleteUser = async e => {
    e.stopPropagation();
    try {
      await account.deleteUser(id);
    } catch (error) {
      alert("error");
    }
    onClose();
  };

  const actions = useMemo(() => {
    return (
      <>
        <MatchButton style={{ width: "50%" }} onClick={onClose}>
          Cancel
        </MatchButton>
        <MatchButton style={{ width: "50%" }} onClick={deleteUser}>
          Yes
        </MatchButton>
      </>
    );
  }, []);

  return (
    <>
      <button
        onClick={handleDeleteClickOpen}
        style={{ background: "none", border: "none", cursor: "pointer" }}
      >
        <CloseIcon />
      </button>
      <CustomDialog
        open={open}
        title={"Delete User"}
        onClose={onClose}
        actions={actions}
        maxWidth={"xs"}
      >
        <div>Finally, please check again.</div>
      </CustomDialog>
    </>
  );
}

export default DeleteUser;
