import React, { useEffect, useMemo } from "react";
import TableTemplate from "../TableTemplate";
import CustomMenuList from "../../atoms/ui/CustomMenuList";
import CustomButton from "../../atoms/ui/CustomButton";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import useStore from "modules/hooks/useStore";
import dateFormat from "dateformat";
import { useSpring, animated } from "react-spring";
import { observer } from "mobx-react";
import { makeStyles } from "@material-ui/styles";

const gapOfHeight = 55;
const overflow = {
  overflow: "auto"
};

const layoutStyle_1280 = {
  width: 190,
  height: 190
};

const layoutStyle_1500 = {
  width: 230,
  height: 230
};

const bodyStyle_1280 = {
  height: 190 - gapOfHeight,
  ...overflow
};

const bodyStyle_1500 = {
  height: 230 - gapOfHeight,
  ...overflow
};

const useCustomStyle = makeStyles({
  ul: {
    display: "flex",
    flexDirection: "column",
    alignItems: "baseline"
  },
  li: {
    marginBottom: 5
  }
});

const useStyle = makeStyles({
  number: {
    fontSize: "2rem",
    color: "#181818",
    fontWeight: 600,
    height: "50%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },
  listContainer: {
    display: "flex",
    justifyContent: "center"
  }
});

const useBtnStyle = makeStyles({
  button: {
    background: "none",
    border: "none",
    cursor: "pointer",
    textDecoration: "underline",
    color: "#7d7d7d",
    fontSize: "0.9rem",
    padding: 0
  }
});

function AdminCard({ title, list, type, history }) {
  const match = useMediaQuery("(min-width:1500px)");
  const classes = useStyle();

  const {
    account,
    account: { overviewStat }
  } = useStore();

  const spring =
    typeof overviewStat[type] === "number"
      ? useSpring({
          from: { val: 0 },
          to: {
            val: overviewStat[type]
          }
        })
      : overviewStat[type];

  const cardList = list.map(({ id, label, icon, action }) => {
    const onClick = () => {
      action && action(history);
    };

    return (
      <>
        <CustomButton onClick={onClick} key={id} useCustomStyle={useBtnStyle}>
          {label}
        </CustomButton>
        {icon}
      </>
    );
  });

  useEffect(() => {
    account.loadAdminStatus({ type });
  }, []);

  const animation = useMemo(() => {
    return (
      <animated.div className={classes.number}>
        {typeof overviewStat[type] === "number"
          ? spring.val.interpolate(val => Math.floor(val))
          : dateFormat(spring, "mediumDate")}
      </animated.div>
    );
  }, [overviewStat[type]]);

  return (
    <TableTemplate
      title={title}
      layoutStyle={match ? layoutStyle_1500 : layoutStyle_1280}
      bodyStyle={match ? bodyStyle_1500 : bodyStyle_1280}
    >
      {animation}
      <div className={classes.listContainer}>
        <CustomMenuList list={cardList} useCustomStyle={useCustomStyle} />
      </div>
    </TableTemplate>
  );
}

export default observer(AdminCard);
