import React, { useState, useEffect } from "react";
import useStore from "modules/hooks/useStore";
import { observer } from "mobx-react";

const permissionCoverage = [
  "ROLE_ADMIN",
  "ROLE_DM_USER",
  "ROLE_INS_USER",
  "ROLE_CRB_MEMBER"
];

function CheckPermission({ children, permission, only, reverse }) {
  const [isAllowed, setAllowed] = useState(false);

  const {
    account: { user }
  } = useStore();

  useEffect(() => {
    if (user && Object.keys(user).length > 0) {
      const permissionOfUser = permissionCoverage.indexOf(user.authorities[0]); // 유저의 권한
      const permissionOfComponent = permissionCoverage.indexOf(permission); // 컴포넌트의 접근권한

      if (!only) {
        if (!reverse) {
          if (permissionOfUser <= permissionOfComponent) setAllowed(true);
        } else {
          if (permissionOfUser >= permissionOfComponent) setAllowed(true);
        }
      } else {
        if (permissionOfUser === permissionOfComponent) setAllowed(true);
      }
    } else {
      if (permission === "ALL") setAllowed(true);
      else return setAllowed(false);
    }
  }, [user]);

  return <>{isAllowed ? children : null}</>;
}

export default observer(CheckPermission);
