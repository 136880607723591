import queryString from "query-string";

const qs = {
  stringify: query => {
    return queryString.stringify(query);
  },

  parse: query => {
    return queryString.parse(query);
  }
};

export default qs;
