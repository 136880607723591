import React, { useEffect, useState } from "react";
import useStore from "modules/hooks/useStore";
import { observer } from "mobx-react";
import { makeStyles } from "@material-ui/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import EditStudyDialog from "./EditStudyDialog";

const useStyles = makeStyles({
  root: {
    display: "flex"
  },
  container: {
    display: "flex",
    justifyItems: "center",
    alignItems: "center",
    borderTop: "1px solid #dedede",
    borderLeft: "1px solid #dedede",
    borderBottom: "1px solid #dedede"
  },
  lastContainer: {
    borderTop: "none"
  },
  div: {
    display: "flex",
    justifyItems: "center",
    alignItems: "center",
    height: 10,
    padding: "10px 5px",
    borderRight: "1px solid #dedede"
  },
  lastDiv: {
    borderRight: "none"
  },
  title: {
    whiteSpace: "nowrap",
    backgroundColor: "#f3f3f3",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontWeight: 500
  },
  content: {
    minWidth: 20,
    display: "flex",
    alignItems: "center"
    // justifyContent: "center"
  },
  number: {
    justifyContent: "flex-end"
  },
  width_40: {
    width: 20
  },
  width_50: {
    width: 30
  },
  width_55: {
    width: 35
  },
  width_60: {
    width: 40
  },
  width_70: {
    width: 50
  },
  width_80: {
    width: 60
  },
  width_85: {
    width: 65
  },
  width_diagnosis: {
    width: 232
  },
  width_diagnosis_1500: {
    width: 290
  },
  width_metastatis: {
    width: 112
  },
  width_metastatis_1500: {
    width: 152
  },
  width_stage: {
    width: 135
  },
  width_stage_1500: {
    width: 156
  },
  width_last: {
    width: 149
  },
  width_last_1500: {
    width: 170
  },
  width_initStudy: {
    width: 74
  },
  width_initStudy_1500: {
    width: 65
  },
  width_half1: {
    width: "41.9%"
  },
  width_half2: {
    width: "58.1%",
    borderRight: "1px solid #dedede"
  },
  width_100: {
    minWidth: 80
  }
});

function PersonInfo() {
  const classes = useStyles();
  const matches = useMediaQuery("(min-width:1500px)");

  const {
    patient: { person }
  } = useStore();

  const width70 = matches ? classes.width_100 : classes.width_70;
  const width40 = matches ? classes.width_50 : classes.width_40;
  const width50 = matches ? classes.width_60 : classes.width_50;
  const width60 = matches ? classes.width_70 : classes.width_60;
  const widthDiagnosis = matches
    ? classes.width_diagnosis_1500
    : classes.width_diagnosis;
  const widthMetastatis = matches
    ? classes.width_metastatis_1500
    : classes.width_metastatis;
  const widthStage = matches ? classes.width_stage_1500 : classes.width_stage;
  const widthInitStudy = matches
    ? classes.width_initStudy_1500
    : classes.width_initStudy;

  const withLast = matches ? classes.width_last_1500 : classes.width_last;

  return (
    <div className={classes.root}>
      <div className={classes.width_half1}>
        <div className={classes.container}>
          <div className={`${classes.div} ${classes.title} ${width70}`}>
            Subject
          </div>
          <div
            className={`${classes.div} ${classes.content} ${matches &&
              classes.width_85}`}
          >
            {person.subjectId}
          </div>
          <div className={`${classes.div} ${classes.title} ${width40}`}>
            Sex
          </div>
          <div className={`${classes.div} ${classes.content}`}>
            {person.sex}
          </div>
          <div className={`${classes.div} ${classes.title} ${width60}`}>
            Dx Age
          </div>
          <div
            className={`${classes.div} ${classes.content} ${classes.number}`}
          >
            {person.specimenList && person.specimenList[0].diagnosisAge}
          </div>
          <div className={`${classes.div} ${classes.title} ${width60}`}>
            Health
          </div>
          <div
            className={`${classes.div} ${classes.content} ${classes.lastDiv} ${classes.width_85}`}
          >
            {"Deceased"}
          </div>
        </div>
        <div className={`${classes.container} ${classes.lastContainer}`}>
          <div className={`${classes.div} ${classes.title} ${width70}`}>
            Diagnosis
          </div>
          <div
            className={`${classes.div} ${classes.content} ${widthDiagnosis}`}
          >
            {person.diagnosisInfo &&
              `${person.diagnosisInfo.primaryCancer}/${person.diagnosisInfo.initialDegreeDifferentiation}`}
          </div>
          <div
            className={`${classes.div} ${classes.title} ${classes.width_85} ${classes.lastDiv}`}
          >
            Metastasis
          </div>
        </div>
      </div>
      <div className={classes.width_half2}>
        <div className={classes.container}>
          <div className={`${classes.div} ${classes.title} ${width70}`}>
            Death Age
          </div>
          <div
            className={`${classes.div} ${classes.content} ${classes.number}`}
          >
            {"-"}
          </div>
          <div className={`${classes.div} ${classes.title} ${width40}`}>
            Inst
          </div>
          <div className={`${classes.div} ${classes.content} ${width50}`}>
            {person.institute}
          </div>
          <div className={`${classes.div} ${classes.title} ${width70}`}>
            Init Study
          </div>
          <div
            className={`${classes.div} ${classes.content} ${widthInitStudy}`}
          >
            {person.personStudy && person.personStudy.studyId}
          </div>
          <div className={`${classes.div} ${classes.title} ${width70}`}>
            Cur Study
          </div>
          <div
            className={`${classes.div} ${classes.content} ${withLast} ${classes.lastDiv}`}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                width: "100%",
                justifyContent: "space-between"
              }}
            >
              {person.personStudyCurrent && person.personStudyCurrent.studyId}
              <EditStudyDialog>Edit</EditStudyDialog>
            </div>
          </div>
        </div>
        <div className={`${classes.container} ${classes.lastContainer}`}>
          <div
            className={`${classes.div} ${classes.content} ${widthMetastatis}`}
          >
            {person.diagnosisInfo && `${person.diagnosisInfo.metastasis}`}
          </div>
          <div className={`${classes.div} ${classes.title} ${width50}`}>
            Initial
          </div>
          <div className={`${classes.div} ${classes.content} ${widthStage}`}>
            {person.diagnosisInfo &&
              `pT${person.diagnosisInfo.initialTumorStage}N${person.diagnosisInfo.initialRegionalLymphNodeStage}M${person.diagnosisInfo.initialDistantMetastasisStage}(stage${person.diagnosisInfo.initialCancerStaging})`}
          </div>
          <div className={`${classes.div} ${classes.title} ${width70}`}>
            Current
          </div>
          <div
            className={`${classes.div} ${classes.content} ${withLast} ${classes.lastDiv}`}
          >
            {person.diagnosisInfo &&
              `cT${person.diagnosisInfo.currentTumorStage}N${person.diagnosisInfo.currentRegionalLymphNodeStage}M${person.diagnosisInfo.currentDistantMetastasisStage}(stage${person.diagnosisInfo.currentCancerStaging})`}
          </div>
        </div>
      </div>
    </div>
  );
}

export default observer(PersonInfo);
