import { action, observable, configure } from "mobx";
import StudyRepository from "modules/repository/StudyRepository";
import { queryString } from "dependencyLibrary";
import AnnotationDataModel from "modules/model/AnnotationDataModel";
import AnnotationSummaryModel from "../model/AnnotationSummaryModel";
import TableFormatModel from "../model/TableFormatModel";
import RefSiteTabModel from "../model/RefSiteTabModel";
import AnnoRefListModel from "../model/AnnoRefListModel";
import { version } from "react";
import { RemoveCircleOutlineRounded } from "@material-ui/icons";
import GVGuideModel from "modules/model/GVGuideModel";

const summary = [{ label: "SNV", value: "snv" }];
const empty = [
  { label: "CNV", value: "cnv" },
  { label: "SV", value: "sv" }
];

configure({ enforceAction: "true" });

export default class Study {
  @observable tabUrl = {
    match: "",
    ngs: ""
  };
  @observable annotationData;
  @observable annoSnvSummary = {'V1': {}, 'V2': {}};
  @observable annoCnvSummary = {'V1': {}, 'V2': {}};
  @observable annoSvSummary = {'V1': {}, 'V2': {}};
  @observable annoStats = {'V1': [], 'V2': []};
  @observable annotationVersion = {curVersion : '2020-11', oldVersion : '2020-05'};

  // @observable annoGeneVariants = {};

  // annotation
  @observable variantProfile = {};
  // @observable alleleFrequency = {};
  // @observable theraphyInformation = {};
  // @observable pathogenicityInformation = {};
  @observable refSiteColumns = [];
  @observable refSiteAnnotations = {};

  @observable variantProfileRef = {};
  @observable refSiteColumnsRef = [];
  @observable refSiteAnnotationsRef = {};

  @observable refSiteAnnotationsGuide = [];
  @observable variantProfileGuide = {};

  constructor(root) {
    this.root = root;
  }

  @action
  async resetAnnotationData() {
    this.annotationData = null;
  } 

  @action
  setAnnotationVersion(newObj) {
    this.annotationVersion = {
      ...newObj
    };
  }

  @action
  async loadGetAnnotationVersions() {

    let version = this.annotationVersion.curVersion;

    let queryData = { version: `${version}`}; // version = ('2020-11', '2021-05')
    const query = queryString.stringify(queryData);

    const { data } = await StudyRepository.fetchAnnoVersions(query);

    const {
      count,
      curVersion,
      oldVersion
    } = data;

    // this.setAnnotationVersion({
    //   curVersion : `${curVersion}`,
    //   oldVersion: `${oldVersion}`
    // });
    this.annotationVersion = {
      curVersion : `${curVersion}`,
      oldVersion: `${oldVersion}`
    };

    
    // this.root.comm.setAnnotationQueryData({curVersion: `${curVersion}`});
    // this.root.comm.setAnnotationQueryData({oldVersion: `${oldVersion}`});

    console.log("loadGetAnnotationVersions : ", this.annotationVersion.curVersion, this.annotationVersion.oldVersion);
    if (2 != count) {
      // 
      console.log("study.loadGetAnnotationVersions count is not 2 but : ", count);
    }

  }

  @action
  async loadAnnoStatsCurrent(gene) {
    
    let version = this.annotationVersion.curVersion;

    let queryData = { gene : `${gene}`, version: `${version}`}; // version = ('2020-11', '2021-05')
    const query = queryString.stringify(queryData);

    const { data } = await StudyRepository.fetchAnnoStat(query);
    // this.annoStats = summary.concat(data).concat(empty);

    const {
      annoVariantTypes // id = (SNV, CNV, SV), count, acpaList
    } = data;

    this.annoStats['V2'] = [];

    for (let { id, count } of annoVariantTypes) {
      this.annoStats['V2'].push({ count: count, label: id.toUpperCase(), value: id.toLowerCase() });
    }

    // const ref = {};
   
    // for (let { id, acpaList } of annoVariantTypes) {
    //   Object.assign(ref, {
    //     [id]: new TableFormatModel(acpaList.map(m => new AnnotationSummaryModel(m)))
    //   });
    // }
    this.annoSnvSummary['V2'] = {};
    this.annoCnvSummary['V2'] = {};
    this.annoSvSummary['V2'] = {};

    for (let { id, annoAcPaList } of annoVariantTypes) {

      if ('SNV' == id) {
        this.annoSnvSummary['V2'] = new TableFormatModel(annoAcPaList.map(m => new AnnotationSummaryModel(m)));
      }
      else if ('CNV' == id) {
        this.annoCnvSummary['V2'] = new TableFormatModel(annoAcPaList.map(m => new AnnotationSummaryModel(m)));
      }
      else {
        this.annoSvSummary['V2'] = new TableFormatModel(annoAcPaList.map(m => new AnnotationSummaryModel(m)));
      }
    }

    return data;
  }

  @action
  async loadAnnoStatsPrior(gene) {
 

    let version = this.annotationVersion.oldVersion;

    let queryData = { gene : `${gene}`, version: `${version}`}; // version = ('2020-11', '2021-05')
    const query = queryString.stringify(queryData);

    const { data } = await StudyRepository.fetchAnnoStat(query);


    const {
      annoVariantTypes // id = (SNV, CNV, SV), count, acpaList
    } = data;

    this.annoStats['V1'] = [];

    for (let { id, count } of annoVariantTypes) {
      this.annoStats['V1'].push({ count: count, label: id.toUpperCase(), value: id.toLowerCase() });
    }

    // const ref = {};
   
    // for (let { id, acpaList } of annoVariantTypes) {
    //   Object.assign(ref, {
    //     [id]: new TableFormatModel(acpaList.map(m => new AnnotationSummaryModel(m)))
    //   });
    // }


    // const ref = {};
    
    // for (let { id, annoAcPaList } of annoVariantTypes) {
    //   Object.assign(ref, {
    //     [id]: new TableFormatModel(annoAcPaList.map(m => new AnnotationSummaryModel(m)));
    //   });
    // }

    // this.annoSummary['V1'] = ref;

    this.annoSnvSummary['V1'] = {};
    this.annoCnvSummary['V1'] = {};
    this.annoSvSummary['V1'] = {};

    for (let { id, annoAcPaList } of annoVariantTypes) {

      if ('SNV' == id) {
        this.annoSnvSummary['V1'] = new TableFormatModel(annoAcPaList.map(m => new AnnotationSummaryModel(m)));
      }
      else if ('CNV' == id) {
        this.annoCnvSummary['V1'] = new TableFormatModel(annoAcPaList.map(m => new AnnotationSummaryModel(m)));
      }
      else {
        this.annoSvSummary['V1'] = new TableFormatModel(annoAcPaList.map(m => new AnnotationSummaryModel(m)));
      }
    }

    return data;
  }


  @action
  setTabUrl(property, url) {
    this.tabUrl = Object.assign(this.tabUrl, {
      [property]: url
    });
  }
  

  @action
  async loadAnnotationSel() {

    let query = queryString.stringify(this.root.comm.annotationData);

    console.log("loadAnnotationSel query: ", query, this.root.comm.annotationData.version, this.annotationVersion.curVersion, this.annotationVersion.oldVersion);
  
    const { data } = await StudyRepository.fetchAnnotationData(
      this.root.comm.annotationType,
      `?${query}`
    );

    // console.log("loadAnnotationData : ", data);


    const {
      gene,
      variant,
      variantType,
      annoRefSites
    } = data;

    this.variantProfile = new TableFormatModel([
      {
        gene: `${gene}`,
        variant: `${variant}`,
        variantType: `${variantType}`
      }
    ]);


    this.refSiteColumns = annoRefSites.map(
      m => new RefSiteTabModel(m)
    );

    const ref = {};
    // evidences.map -> annotations.map
    for (let { id, annotations } of annoRefSites) {
      Object.assign(ref, {
        [id]: new TableFormatModel(annotations.map(m => new AnnoRefListModel(m)))
      });
    }


    this.refSiteAnnotations = ref;

    return version;

  }



  @action
  async loadAnnotationRef() {

    await this.loadGetAnnotationVersions();

    let queryTmp = queryString.stringify(this.root.comm.annotationData);
    //query = query + '&version=' + version;
    let query;
    let version = this.root.comm.annotationData.version;
    let curVersion = this.annotationVersion.curVersion;

    if (version == curVersion) {
      query = queryTmp.replace(version, this.annotationVersion.oldVersion);
    }
    else {
      query = queryTmp.replace(version, this.annotationVersion.curVersion);
    }
  
    console.log("loadAnnotationRef query: ", query, this.annotationVersion.curVersion, this.annotationVersion.oldVersion);

    const { data } = await StudyRepository.fetchAnnotationData(
      this.root.comm.annotationType,
      `?${query}`
    );

    // console.log("loadAnnotationData : ", data);


    const {
      gene,
      variant,
      variantType,
      annoRefSites
    } = data;

    this.variantProfileRef = new TableFormatModel([
      {
        gene: `${gene}`,
        variant: `${variant}`,
        variantType: `${variantType}`
      }
    ]);


    this.refSiteColumnsRef = annoRefSites.map(
      m => new RefSiteTabModel(m)
    );

    const ref = {};
    // evidences.map -> annotations.map
    for (let { id, annotations } of annoRefSites) {
      Object.assign(ref, {
        [id]: new TableFormatModel(annotations.map(m => new AnnoRefListModel(m)))
      });
    }


    this.refSiteAnnotationsRef = ref;

    return version;
  }

  @action
  async loadAnnotationGuideK() {

    let query = queryString.stringify(this.root.comm.annotationData);

    //query = query + '&version=' + version;
    let annotationType = this.root.comm.annotationType;
  
    const { data } = await StudyRepository.fetchAnnotationData(
      annotationType + '_guide',
      `?${query}`
    );
    
    // console.log("loadAnnotationData query: ", query);

    // console.log("loadAnnotationData : ", data);


    const {
      id,
      version,
      gene,
      variant,
      variantType,
      annoGuideList
    } = data;

    this.variantProfileGuide = new TableFormatModel([
      {
        gene: `${gene}`,
        variant: `${variant}`,
        variantType: `${variantType}`
      }
    ]);

    console.log("annoGuideList : ", annoGuideList);
    // sort annotation 
    // let iFrom, nFrom;
    // let sortGuideList = [];

    // if (annotationType == "pa") {
    //   for(iFrom=3; iFrom>-1; iFrom--) {        
    //     annoGuideList.map(m => {
    //       nFrom = 0;
    //       if (m.oncogenicClinvarK != null) nFrom ++;
    //       if (m.oncogenicOncokbK != null) nFrom ++;
    //       if (m.oncogenicCivicK != null) nFrom ++;

    //       if (nFrom == iFrom) {
    //         sortGuideList.push(m);
    //       }
    //     });
    //   }
    // }
    // else {
    //   for(iFrom=3; iFrom>-1; iFrom--) {        
    //     annoGuideList.map(m => {
    //       nFrom = 0;
    //       if (m.levelClinvarK != null) nFrom ++;
    //         if (m.levelOncokbK != null) nFrom ++;
    //           if (m.levelCivicK != null) nFrom ++;

    //       if (nFrom == iFrom) {
    //         sortGuideList.push(m);
    //       }
    //     });
    //   }
    // }

    // this.refSiteAnnotationsGuide = new TableFormatModel(sortGuideList.map(m => new GVGuideModel(m)))
    this.refSiteAnnotationsGuide = new TableFormatModel(annoGuideList.map(m => new GVGuideModel(m)))

    return version;
  }

}

