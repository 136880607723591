import React from "react";
import ClinicalTrialGovLogo from "assets/images/patient/match/clintrialsdotgov.png";
import ClinicalTrialGovDeleselctLogo from "assets/images/patient/match/clintrialsdotgov-gray.png";

class CtsTabModel {
  constructor(data) {
    this.nctId = data.nctId;
    this.icon = <img src={ClinicalTrialGovLogo} alt="" />;
    this.deSelectIcon = <img src={ClinicalTrialGovDeleselctLogo} alt="" />;
    this.label = data.nctId;
    // this.count=0
    this.value = data.nctId;
  }
}

export default CtsTabModel;
