import React, { useEffect, useMemo, useCallback } from "react";
import useStore from "modules/hooks/useStore";
import PieChart from "../../atoms/PieChart";
import TableTemplate from "../../organisms/TableTemplate";
import { withRouter } from "react-router-dom";
import { observer } from "mobx-react";

function EvidenceMatchPieChart({ history }) {
  const {
    statistics,
    statistics: { panels }
  } = useStore();

  useEffect(() => {
    // console.log("user", user);
    // statistics.loadPanel();
  }, []);

  const title = useMemo(() => {
    return (
      <>
        <span>Interpretation Criteria</span>
      </>
    );
  }, []);

  const onClick = useCallback(value => {
    //   comm.setSearch({
    //     panel: value
    //   });
    //   history.push("/patient");
  }, []);

  return (
    <TableTemplate title={title}>
      <PieChart series={[]} onClick={onClick} height={300} />
    </TableTemplate>
  );
}

export default withRouter(observer(EvidenceMatchPieChart));
