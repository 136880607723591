import React from "react";
import { makeStyles } from "@material-ui/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";

const useStyles = makeStyles({
  layout_1500: {
    minWidth: 336
  },
  layout: {
    width: 300,
    display: "inline-block",
    position: "absolute",
    bottom: 0,
    top: 0,
    background: "#ffffff"
  },
  title: {
    height: 38,
    backgroundColor: "#7b7b7b",
    color: "#ffffff",
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },
  p: {
    margin: 0,
    fontSize: "1.2rem",
    width: "100%"
  },
  left: {
    position: "absolute",
    top: "38px",
    left: 0,
    bottom: 0,
    right: 0
  }
});

function LeftNav(props) {
  const classes = useStyles();
  const { children, title } = props;
  const matches = useMediaQuery("(min-width:1500px)");

  return (
    <div className={`${classes.layout} ${matches && classes.layout_1500}`}>
      <div className={classes.title}>
        <h2 className={classes.p}>{title}</h2>
      </div>
      <div className={classes.left}>{children}</div>
    </div>
  );
}
export default LeftNav;
