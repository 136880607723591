import React, { Component } from "react";
import MatchButton from "./MatchButton";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";

const columns = [
  {
    column: "Subject",
    property: "Subject"
  },
  {
    column: "Study",
    property: "Study"
  },
  {
    column: "Sex",
    property: "Sex"
  },
  {
    column: "Dx Age",
    property: "DxAge"
  },
  {
    column: "Vital Status",
    property: "VitalStatus"
  },
  {
    column: "Cancer",
    property: "Cancer"
  },
  {
    column: "Cancer Detail",
    property: "CancerDetail"
  },
  {
    column: "Differentiation",
    property: "Differentiation"
  },
  {
    column: "TNM",
    property: "TNM"
  },
  {
    column: "Review",
    property: "Review"
  }
];

// TSV Download 구현부 add by jdy : 2020-12

class TsvParser extends Component {

  handleColumnList = (d) => {

    this.totalString = "";

    let length = columns.length - 1;

    columns.map((c, i) => {
      const { property } = c;
      
      let addString = "";

     if (i == length) {
      addString = d[property] + "\n";
     } else {
      addString = d[property] + "\t";
     }

      this.totalString += addString;
    });

    return this.totalString;
  }

  onUpdateData = (data) => {

    this.contentList = "";

    data.map((d, i) => {

      this.totalString = this.handleColumnList(d);

      this.contentList += this.totalString;


    });

    return this.contentList;
  } 

  downloadTxtFile = (textContent) => {
    const element = document.createElement("a");
    // const file = new Blob([document.getElementById('myInput').value], {type: 'text/plain'});
    const file = new Blob([textContent], {type: 'text/plain'});
    element.href = URL.createObjectURL(file);
    element.download = "tsvFile.txt";
    document.body.appendChild(element); // Required for this to work in FireFox
    element.click();
  }

  handleClick = () => {
    const {data} = this.props;

    let textContent = "";

    textContent = this.onUpdateData(data);

    this.downloadTxtFile(textContent);

  }

  render () {
    return (
      <MatchButton onClick={this.handleClick} icon={<ArrowDownwardIcon />}>
        TSV
      </MatchButton>
    );
  }
}

export default TsvParser;
