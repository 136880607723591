import React, { useRef, useCallback, useEffect, useMemo } from "react";
import TableTemplate from "components/organisms/TableTemplate";
import MuiTable from "components/atoms/MuiTable";
import SearchAdminForm from "../admin/SearchAdminForm";
import MatchButton from "components/molecules/MatchButton";
import useStore from "modules/hooks/useStore";
import { observer } from "mobx-react";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles({
  main: {
    position: "absolute",
    left: 15,
    right: 15,
    top: 15,
    bottom: 15
  },
  viewtitle: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    fontSize: "1rem",
    fontWeight: "600",
    color: "#3a3a3a"
  },
  titleDiv: {
    height: "35px",
    backgroundColor: "#f5f5f5",
    borderTop: "solid 1px #c8c8c8",
    borderBottom: "solid 1px #c8c8c8",
    marginTop: "10px",
    fontSize: "12px",
    color: "#636262"
  },
  mainView: {
    fontSize: "12px",
    color: "#636262",
    padding: "10px 7px 0px 0px"
  },
  openBtn: {
    width: "100px",
    height: "25px"
  },
  answerDiv: {
    height: 80,
    border: "solid 1px #c8c8c8",
    backgroundColor: "#f1f1f1",
    padding: "15px 10px",
    marginTop: "10px"
  },
  adminContent: {
    width: "10%",
    fontSize: "12px",
    color: "#636262",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#f5f5f5"
  },
  adminComment: {
    width: "100%",
    fontSize: "1rem",
    color: "#636262",
    display: "flex",
    alignItems: "center",
    padding: 10
  },
  openbuttonDiv: {
    textAlign: "right",
    paddingBottom: "0px",
    borderBottom: "solid 1px #c8c8c8",
    height: "30px"
  }
});

const types = [
  {
    value: "type",
    label: "Type"
  },
  {
    value: "title",
    label: "Title"
  },
  {
    value: "email",
    label: "E-mail"
  }
];

const columns = [
  {
    column: "No.",
    property: "id",
    align: "center",
    sort: true,
    options: {
      link: {
        isLinked: false
      },
      isEditable: false
    },
    style: { head: { width: 50 } }
  },
  {
    column: "Title",
    property: "link",
    align: "left",
    sort: false,
    options: {
      link: {
        isLinked: false
      },
      isEditable: false
    },
    style: { head: { width: 350 } }
  },
  {
    column: "E-Mail",
    property: "email",
    align: "left",
    sort: false,
    options: {
      link: {
        isLinked: false
      },
      isEditable: false
    }
  },
  {
    column: "Time",
    property: "createdDateTime",
    align: "right",
    sort: false,
    options: {
      link: {
        isLinked: false
      },
      isEditable: false
    }
  },
  {
    column: "Status",
    property: "commented",
    align: "center",
    sort: true,
    options: {
      link: {
        isLinked: false
      },
      isEditable: false
    },
    style: { head: { width: 100 } }
  }
];

const MyQuestionTable = observer(() => {
  const parentEl = useRef(null);

  const {
    account,
    account: { questions, questionSearch, user }
  } = useStore();

  useEffect(() => {
    if (Object.keys(user).length > 0) account.loadMyQuestionList();
  }, [questionSearch, user]);

  const getList = useCallback(value => {
    account.setQuestionSearch(value);
  }, []);

  const handlePagination = useCallback(({ page, rowsPerPage }) => {
    account.setQuestionSearch({ page, size: rowsPerPage });
  }, []);

  const handleSort = useCallback(({ orderBy, order }) => {
    account.setQuestionSearch({
      sort: `${orderBy},${order}`
    });
  }, []);

  return (
    <>
      <SearchAdminForm types={types} getList={getList} default={"type"} />
      <TableTemplate title={"Q&A Board"} borderHide={true}>
        <div ref={parentEl}>
          <MuiTable
            id={`questions`}
            parentEl={parentEl.current}
            columns={columns}
            data={questions}
            oddRow
            defaultSort={{
              order: questionSearch.sort.split(",")[1],
              orderBy: questionSearch.sort.split(",")[0],
              handleSort: handleSort
            }}
            defaultPagination={{
              page: questionSearch.page,
              rowsPerPage: questionSearch.size,
              handlePatination: handlePagination
            }}
          ></MuiTable>
        </div>
      </TableTemplate>
    </>
  );
});

const QuestionComments = observer(({ id }) => {
  const classes = useStyles();
  const {
    account,
    account: { comment }
  } = useStore();

  useEffect(() => {
    account.loadQuestionComment({ id });
  }, []);

  return (
    <div style={{ display: "flex", borderBottom: "solid 1px #c8c8c8" }}>
      <div className={classes.adminContent}>Answer</div>
      <div className={classes.adminComment}>
        <textarea
          placeholder="Enter a answer"
          value={comment.comment}
          style={{ width: "100%" }}
          required
          rows="8"
          disabled
        />
      </div>
    </div>
  );
}, []);

/* 
    변경일자:   2020-06-16
    변경자 이름: 지시복 
    변경 내용:  textarea 컴포넌트 추가 
    변경 사유:  답변에 enter키 표현이 가능한 textarea 컴포넌트 추가                               
*/
const QuestionDetail = observer(({ id, match, history }) => {
  const classes = useStyles();

  const {
    account,
    account: { question }
  } = useStore();

  useEffect(() => {
    account.loadQuestion({ id });
    return () => {
      account.resetQuestion();
      account.resetQuestionComment();
    };
  }, [id]);

  const reply = useMemo(() => {
    return question.commented && <QuestionComments id={id} />;
  }, [question.commented]);

  const onClick = useCallback(() => {
    history.goBack();
  }, []);

  return (
    <>
      <div className={classes.main}>
        <div className={classes.viewtitle}>
          {`Q&A BOARD`}
          <MatchButton
            onClick={onClick}
            style={{ width: "100px", float: "right" }}
          >
            Go to list
          </MatchButton>
        </div>
        <div className={classes.titleDiv}>
          <div style={{ float: "left", padding: "10px" }}>
            {question.type ? `[ ${question.type} ] ${question.title}` : ""}
          </div>
          <div style={{ float: "right", padding: "10px" }}>
            {question.createdDateTime
              ? question.createdDateTime.slice(0, 10)
              : ""}
          </div>
        </div>
        <div className={classes.mainView}>
          <textarea
            placeholder="Enter a answer"
            value={question.contents}
            style={{ width: "100%" }}
            required
            rows="8"
            disabled
          />
        </div>
        <div className={classes.openbuttonDiv}></div>
        {reply}
      </div>
    </>
  );
});

function MyQuestion(props) {
  const { account } = useStore();

  useEffect(() => {
    return () => {
      account.resetQuestionSearch({});
    };
  }, []);

  return (
    <>
      {Object.keys(props.match.params).length > 0 ? (
        <QuestionDetail id={props.match.params.id} {...props} />
      ) : (
        <MyQuestionTable />
      )}
    </>
  );
}

export default MyQuestion;
