import React from "react";
import Loader from "assets/images/patient/ajax-loader.gif";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles({
  container: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    height: "100%"
  }
});

function LoadingComponent({ children, loading, style }) {
  const classes = useStyles();
  return loading ? (
    children
  ) : (
    <div className={classes.container} style={style}>
      <img src={Loader} alt="" style={{ width: "1.5rem", height: "1.5rem" }} />
    </div>
  );
}

export default LoadingComponent;
