import React from "react";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Draggable from "react-draggable";

function CustomAlert({ title, popupWidth, popupHeight, children, actions }) {
  const matches = useMediaQuery("(min-width:1500px)");

  return (
    <Draggable bounds="body">
      <div
        style={{
          position: "fixed",
          top: 55,
          // bottom: 0,
          // right: 15,
          left: 55,
          display: "flex",
          flexDirection: "column",
          // justifyContent: "center",
          // alignItems: "center",
          zIndex: 1000,
          width: popupWidth,
          height: popupHeight,
          boxShadow: "0px 0px 10px 0px rgba(0, 0, 0, 0.5)"
        }}
      >
        <div
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: "#fff"
          }}
        >
          
            <div
              style={{
                position: "absolute",
                top: 20,
                // bottom: 50,
                left: 20,
                right: 20,
                color: "#444444",
                fontSize: "1.64rem",
                padding: 10,
                // fontWeight: "bold",
                fontFamily: "Roboto",
                fontStretch: "normal",
                fontStyle: "normal"
              }}
            >
              {title}

          </div>
          <div
              style={{
                position: "absolute",
                top: 80,
                // bottom: 50,
                left: 20,
                right: 20,
                color: "#4444e4",
                fontSize: "1.24rem",
                padding: 10,
                // fontWeight: "bold",
                fontFamily: "Roboto",
                fontStretch: "normal",
                fontStyle: "normal"
              }}
            >
          
            {children}
          </div>
          <div
            style={{
              position: "absolute",
              bottom: 0,
              left: 0,
              right: 0,
              display: "flex",
              justifyContent: "flex-end",
              marginBottom: 10
            }}
          >
            {actions}
          </div>
        </div>
      </div>
    </Draggable>
  );
}

export default CustomAlert;
