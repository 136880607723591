import React from "react";
import { Route, Redirect, Switch } from "react-router-dom";
import useStore from "modules/hooks/useStore";

import {
  Login,
  Overview,
  Patient,
  Explorer,
  NotFound,
  MyPage,
  Admin,
  ChangePassword,
  InternalServerError
} from "pages";
import Annotation from "pages/Annotation/annotation"; 
import AnnotationDialog from "components/organisms/Annotation/AnnotationDialog"; 
import PatientView from "pages/matchView/PatientView"; 
import MatchView from "pages/matchView/MatchView"; 
// import PostView from "pages/matchView/PostView"; 


function PrivateRoute({ component: Component, ...rest }) {
  const { account } = useStore();

  const remaining = Math.floor(
    ((Date.now() - sessionStorage.getItem("date")) / (1000 * 60 * 60)) % 24
  );

  const isSession = remaining <= 6;
  if (!isSession) account.logout();

  return (
    <Route
      {...rest}
      render={props =>
        sessionStorage.getItem("token") ||
        localStorage.getItem("token") ||
        (sessionStorage.getItem("date") && isSession) ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: "/login",
              state: { from: props.location }
            }}
          />
        )
      }
    />
  );
}

function LoginRoute({ component: Component, ...rest }) {
  return (
    <Route
      {...rest}
      render={props =>
        sessionStorage.getItem("token") || localStorage.getItem("token") ? (
          <Redirect
            to={{
              pathname: "/overview",
              state: { from: props.location }
            }}
          />
        ) : (
          <Component {...props} />
        )
      }
    />
  );
}

function Router() {

  return (
    <Switch>
      <LoginRoute exact path="/" component={Login} />
      <LoginRoute exact path="/login" component={Login} />
      <PrivateRoute path="/overview" component={Overview} />
      <PrivateRoute path="/patient" component={Patient} />
      <PrivateRoute path="/explorer" component={Explorer} />
      <PrivateRoute path="/my-page" component={MyPage} />
      <PrivateRoute path="/admin" component={Admin} />
      <Route path="/patient-view" component={PatientView} />

      {/* <Route path="/match-view/:type/summary" component={PatientView} /> */}
      <Route path="/match-view/:type" component={MatchView} />
      {/* <Route path="/post-view" component={PostView} /> */}

      <Route path="/annotation-show" component={Annotation} />
      {/* <Route path= "/annotation-geneVariant/:type" component={() => <AnnotationDialog /> }/> */}
      <Route path= "/annotation-geneVariant/:type" component={AnnotationDialog}/>
      <Route path="/change" component={ChangePassword} />
      <Route path="/500" component={InternalServerError} />
      <Route component={NotFound} />
    </Switch>
  );
}
export default Router;
