import React from "react";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";

function TabHeader({
  columns,
  value: currentValue,
  handleChange,
  variant,
  useStyles
}) {
  const classes = useStyles();

  // console.log(columns, value);

  return (
    <Tabs
      value={currentValue}
      variant={variant}
      onChange={handleChange}
      scrollButtons="desktop"
      classes={{
        root: classes.tabsRoot,
        indicator: classes.indicator,
        scrollButtons: classes.scrollButtons
      }}
    >
      {columns.map(({ label, value, count, icon, deSelectIcon }, i) => {
        const customLabel = count ? `${label} (${count})` : label;
        const choiceIcon = currentValue === value ? icon : deSelectIcon;

        return value === "empty" ? (
          //TODO: 리팩토링
          <Tab
            key={i}
            // value={value}
            // label={label}
            disabled
            // disableFocusRipple
            // disableRipple
            classes={{
              root: classes.empty,
              // textColorInherit: classes.disabled
              disabled: classes.disabled
            }}
          />
        ) : (
          <Tab
            key={i}
            value={value}
            label={customLabel}
            icon={choiceIcon}
            disabled={count <= 0}
            disableFocusRipple
            disableRipple
            wrapped
            classes={{
              root: classes.tabRoot,
              selected: classes.selected,
              disabled: classes.disabled,
              wrapper: classes.wrapper
              // labelIcon: { paddingTop: 0 }
            }}
          />
        );
      })}
    </Tabs>
  );
}

export default TabHeader;
