import React, { Component } from "react";
import { observer, inject } from "mobx-react";
import AnnotationShow from "components/organisms/Annotation/AnnotationShow"; 

@inject("study", "comm")
@observer
class Annotation extends Component {

  initAnnotation = () => {
    const {
      comm
    } = this.props;

    comm.setAnnotationOpen(false);

    comm.setAnnotationType("SNV");
    comm.setAnnotationQueryData({versionTab : 'V2'}); //'2020-05'
    comm.setAnnotationQueryData({version : '2021-05'});
    comm.setAnnotationQueryData({variantType : 'SNV'});
    comm.setAnnotationQueryData({gene : ''});
    comm.setAnnotationQueryData({subVariantType : 'amp'});
    comm.setAnnotationQueryData({gene2 : ''});
    comm.setAnnotationQueryData({hgvsp : ''});

  }

  render() {

    this.initAnnotation();
    // return (
    //   <div>
    //     <span> Annotation page </span>
    //   </div>
    // );
    const title = () => {
      return (
        <span style={{ fontWeight: 500, fontSize: "2.0rem", marginLeft: 0, textDecoration: "underline" }}>
          Annotation Search </span>
      );
    };

    return (
      <AnnotationShow title={title} />

    );
  }
}

export default Annotation;
