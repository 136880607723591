import SearchModel from "./SearchModel";

class SearchListModel {
  constructor({ label, data }) {
    this.label = label;
    this.options = data.map(m => new SearchModel(m));
  }
}

export default SearchListModel;
