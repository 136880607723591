import React, { Component } from "react";
import { inject, observer } from "mobx-react";
import { withStyles } from "@material-ui/core/styles";
import AccountTable from "pages/myPage/AccountTable";

const styles = theme => ({
  position: {
    display: "flex"
  },
  tableWidth: {
    width: "48%",
    padding: 10
  }
});

const profile = {
  title: "My Profile",
  content: [
    { name: "E-Mail", key: "email" },
    { name: "First Name", key: "firstName" },
    { name: "Last Name", key: "lastName" },
    { name: "Role", key: "authorities" }
  ]
};

const setting = {
  title: "My Institution",
  content: [
    { name: "Code", key: "institute" },
    { name: "Institution Name", key: "instituteName" }
  ]
};

const password = {
  title: "Change Password",
  content: [
    { name: "Current Password", key: "input", state: "currentPw" },
    // { name: "New Password", key: "input", state: "newPw" },
    { name: "Confirm New Password", key: "input", state: "confirmPw" }
  ]
};

@inject("account")
@observer
class Account extends Component {
  state = {
    currentPw: "",
    newPw: "",
    confirmPw: ""
  };

  change = (value, e) => {
    this.setState({
      [e]: value
    });
  };

  onSubmit = e => {
    e.preventDefault();
    const { account } = this.props;
    const { currentPw, newPw, confirmPw } = this.state;

    // if (newPw === confirmPw)
    account
      .changePassword({ currentPassword: currentPw, newPassword: confirmPw })
      .then(value => {
        if (value) alert(value.data);
      });
    // else {
    //   alert("");
    // }
  };

  onClose = () => {
    this.setState({
      open: false
    });
  };

  render() {
    const {
      classes,
      account: { user }
    } = this.props;

    return (
      <>
        <div className={classes.position}>
          <div className={classes.tableWidth}>
            <AccountTable user={user} tableList={profile} />
          </div>
          <div className={classes.tableWidth}>
            <AccountTable user={user} tableList={setting} />
          </div>
        </div>
        <div className={classes.tableWidth}>
          <AccountTable
            user={user}
            tableList={password}
            state={this.state}
            onChange={this.change}
            onSubmit={this.onSubmit}
          />
        </div>
      </>
    );
  }
}

export default withStyles(styles)(Account);
