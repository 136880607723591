import React, { useEffect, useCallback } from "react";
import useStore from "modules/hooks/useStore";
import { observer } from "mobx-react";
import { Link } from "react-router-dom";
import { useSpring, animated } from "react-spring";

function OverviewRequestCountButton() {
  const {
    account,
    account: { overviewStat }
  } = useStore();

  const spring = useSpring({
    from: { val: 0 },
    to: {
      val: overviewStat.activated
    }
  });

  useEffect(() => {
    account.loadAdminStatus({ type: "activated" });
  }, []);

  const onClick = useCallback(() => {
    account.setUserSearch({
      type: "activated",
      keyword: false
    });
  }, []);

  return (
    <>
      <Link
        to={"/admin/users"}
        onClick={onClick}
        style={{ textDecoration: "none", color: "white" }}
      >
        <span
          style={{
            marginRight: 5,
            color: "#7d7d7d",
            textDecoration: "underline"
          }}
          onClick={onClick}
        >
          {"Request to join"}
        </span>
        <animated.span
          style={{
            color: "rgb(69, 136, 252)",
            textDecoration: "underline"
          }}
        >
          {spring.val.interpolate(val => Math.floor(val))}
        </animated.span>
      </Link>
    </>
  );
}

export default observer(OverviewRequestCountButton);
