import React, { Component } from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { lightBlue } from "@material-ui/core/colors";

class LineChart extends Component {
  state = {
    options: {}
  };

  static getDerivedStateFromProps(props, state) {
    if (props !== state) {
      return {
        options: {
          chart: {
            type: "area",
            height: props.height || (9 / 16) * 100 + "%"
          },
          title: {
            text: null
          },
          credits: {
            enabled: false
          },
          tooltip: {
            crosshairs: true,
            shared: true
          },
          legend: {
            layout: "vertical",
            align: "right",
            verticalAlign: "top"
            // floating: true,
          },
          xAxis: {
            gridLineWidth: 2,
            categories: props.category,
            tickmarkPlacement: "on",
            crosshair: true,
            // gridLineWidth: 1,
            gridZIndex: 4,
            min: 0.45,
            max: props.category.length - 1.45
            // startOnTick: false,
            // endOnTick: false,
            // minPadding: 0,
            // maxPadding: 0,
            // align: "left"
          },
          yAxis: {
            min: 0,
            softMax: 20,
            gridLineWidth: 1,
            gridZIndex: 4,
            title: {
              text: null
            }
          },
          plotOptions: {
            line: {
              softThreshold: false
            },
            series: {
              marker: {
                enabled: false
              }
            },
            area: {
              fillColor: {
                linearGradient: {
                  x1: 0,
                  y1: 0,
                  x2: 0,
                  y2: 1
                },
                stops: [
                  [0, lightBlue[200]],
                  [1, lightBlue[50]]
                ]
              }
            }
          },
          series: props.series
        }
      };
    }

    return null;
  }

  render() {
    const { options } = this.state;

    return (
      <HighchartsReact
        highcharts={Highcharts}
        options={options}
        redraw={true}
        animation={true}
      />
    );
  }
}
export default LineChart;
