import { configure, action, observable, set, toJS } from "mobx";
import PatientRepository from "../repository/PatientRepository";
import TreatmentModel from "../model/TreatmentModel";
import NgsSummaryModel from "../model/NgsSummaryModel";
import TableFormatModel from "../model/TableFormatModel";
import CtsSummaryModel from "../model/CtsSummaryModel";
import CtsTabModel from "../model/CtsTabModel";
import NgsTabModel from "../model/NgsTabModel";
import ClinicalTrialSummaryTableModel from "../model/ClinicalTrialSummaryTableModel";
import NgsInfoModel from "../model/NgsInfoModel";
import SnvListModel from "../model/SnvListModel";
import CnvListModel from "../model/CnvListModel";
import SvListModel from "../model/SvListModel";
import PatientModel from "../model/PatientModel";
import { queryString } from "dependencyLibrary";
import SearchListModel from "../model/SearchListModel";
import ScrollChartModel from "../model/ScrollChartModel";
import SelectBoxModel from "../model/SelectBoxModel";
import VariantSelectBoxModel from "../model/VariantSelectBoxModel";
import InsSelectBoxModel from "../model/InsSelectBoxModel";
import NgsSelectBoxModel from "../model/NgsSelectBoxModel";
import ClinicalTrialMatchedDataModel from "../model/ClinicalTrialMatchedDataModel";
import FollowPatientTableModel from "../model/FollowPatientTableModel";
import PatientExcelModel from "../model/PatientExcelModel";
// import AlleleFrequencyModel from "../model/AlleleFrequencyModel";
import EvidenceModel from "../model/EvidenceModel";
import RefSiteTabModel from "../model/RefSiteTabModel";

const map = (f, obj) => {
  let rArr = [];
  for (let o in obj) {
    rArr.push(f(o, obj));
  }
  return rArr;
};

const summary = [{ label: "SUMMARY", value: "summary" }];
const empty = [
  { label: "CLINICAL REVIEW", value: "review" },
  { label: "empty", value: "empty" }
];
const all = [{ label: "ALL", value: "ALL" }];

const defaultSearchCriteria = {
  reported: true,
  size: 10000 // modify for DEBUG by jdy 10000
};

const defaultFollowPatientSearch = {
  size: 5,
  page: 0
};

// TODO: store 분리를 해야함....
configure({ enforceAction: "true" });
export default class Patient {
  @observable tabUrl = {
    match: "",
    ngs: ""
  };

  // 2021.10.14 add 1 line by jdy
  @observable isMatchView = false;

  @observable person = {};
  @observable personTableformat = [];
  @observable selected = {};

  @observable persons = {}; // {content, totalElements}
  @observable personsLength = 0;

  @observable personStats = [];

  @observable treatments = [];

  @observable ngsGeneName = "foundHgvsc";

  @observable ngsHeader = [];
  @observable ngsTabs = []; //tab
  @observable ngsSummary = [];
  @observable ngs = {};
  @observable ngsVariantTab = [];
  @observable ngsInfo = {};
  @observable snvList = {};
  @observable cnvList = {};
  @observable svList = {};
  @observable ngsSnvReported = {};
  @observable ngsCnvReported = {};
  @observable ngsSvReported = {};

  @observable ctsKoreaList = []; // korea cts list 2021.10.13 add by jdy
  @observable ctsList = []; // cts list
  @observable cts = []; // tab
  @observable clinicalTrial = {}; // current clinical trial
  @observable clinicalTrialSummaryData = {}; // clinical summary table
  @observable briefSummary = "";
  @observable briefTitle = "";
  // detail clinical match
  @observable clinicalTrialDesign = []; // study design
  @observable criteria = [];

  @observable search = { ...defaultSearchCriteria };
  @observable searchKeyword = {};
  @observable searchKeywordList = [];

  @observable variantsOfPatient = [];

  // statistic
  @observable patientStatistic = {};

  //charting
  @observable cancerChart = {};
  @observable variantChart = {};
  @observable insChart = {};

  @observable insChart0;
  @observable insChart1;
  @observable insChartName = [];

  //inslist
  @observable insList = [];

  //edit study
  @observable studies = [];

  //follow
  @observable isFollow = false;
  @observable followId = -1;

  @observable explorerReturn = {};

  @observable clinicalTrialMatchedData = [];

  @observable instituteSearch = {};

  // evidence
  @observable variantProfile = {};
  @observable alleleFrequency = {};
  @observable theraphyInformation = {};
  @observable pathogenicityInformation = {};
  @observable refSiteColumns = [];
  @observable refSiteEvidences = {};

  @observable ngsSelect = [];

  @observable followPatients = [];
  @observable followPatientSearch = { ...defaultFollowPatientSearch };

  constructor(root) {
    this.root = root;
  }

  @action
  setExplorerReturn(bool) {
    this.explorerReturn = { isReturn: bool };
  }

  @action
  setTabUrl(property, url) {
    this.tabUrl = Object.assign(this.tabUrl, {
      [property]: url
    });
  }

  @action
  resetInstituteSearch(obj) {
    this.instituteSearch = { ...obj };
  }

  @action
  resetSearchKeywordList() {
    this.searchKeywordList = [];
  }

  @action
  setSearchKeyword(obj) {
    this.searchKeyword = {
      ...defaultSearchCriteria,
      ...this.instituteSearch,
      ...obj
    };
  }

  @action
  resetSearch(obj) {
    this.search = { ...defaultSearchCriteria, ...this.instituteSearch, ...obj };
  }

  @action
  initSearch() {
    this.search = { ...defaultSearchCriteria };
  }

  @action
  setSearch(obj) {
    set(this.search, obj);
  }

  @action
  setSelected(person) {
    this.selected = Object.assign(
      {},
      {
        [person.id]: null
      }
    );
  }

  @action
  setPerson(person) {
    this.person = person;
    this.setPersonTableFormat(person);
    this.setSelected(person);
  }

  @action
  resetPerson() {
    this.person = {};
    this.personTableformat = {};
    this.selected = {};
  }

  @action
  setPersonTableFormat(person) {
    this.personTableformat = new TableFormatModel(
      new Array(new PatientModel(toJS(person)))
    );
  }

  @observable personsForExcel = [];

  @action
  async loadPersons() {

    console.log('patient.search : ', this.search);

    const search = queryString.stringify(this.search);
    const { data } = await PatientRepository.fetchPersons(`?${search}`);
    this.persons = new TableFormatModel(
      data.content.map(m => new PatientModel(m))
    );
    this.personsForExcel = data.content.map(m => new PatientExcelModel(m));
    return data.content[0];

    // TODO: 데이터 초기 patient 값?
    // if (Object.keys(this.persons).length <= 0) {
    //   this.setPerson(data.content[0]);
    // }
  }

  @action
  resetPersons() {
    this.persons = {};
  }

  @action
  async loadPerson(id) {

    console.log('patient.loadPerson : ', id);

    const { data } = await PatientRepository.fetchPerson(id);
    this.setPerson(data);
  }

  @action
  async loadStats(id) {
    console.log('DEBUG PatientStore loadStats id: ', id);
    const { data } = await PatientRepository.fetchStat(id);
    this.personStats = summary.concat(data).concat(empty);
  }

  @action
  async loadTreatements(id) {
    let dataObj = await PatientRepository.fetchTreatments(id);

    console.log('DEBUG PatientStore loadTreatements fetchTreatments : ', dataObj);

    if (dataObj) {

      console.log('DEBUG PatientStore loadTreatements fetchTreatments : not null ');

      this.treatments = new TableFormatModel(
        dataObj.data.map(el => new TreatmentModel(el))
      );
      return true;
    }
    else {
      console.log('DEBUG PatientStore loadTreatements fetchTreatments : null ');
      this.treatments = [];
      return false;
    }
    // const { data } = await PatientRepository.fetchTreatments(id);
    // this.treatments = new TableFormatModel(
    //   data.map(el => new TreatmentModel(el))
    // );
  }

  @action
  async loadNgsSummary(id) {

    console.log('DEBUG loadNgsSummary id: ', id);

    const { data } = await PatientRepository.fetchNgsSummary(id);

    // console.log('DEBUG ngsSummary data: ', data);
    // console.log('DEBUG ngsSummary data[0].gene : ', data[0].gene);

    let geneName = data[0].gene;

    console.log('DEBUG ngsSummary geneName : ', geneName);

    if (geneName == 'zero')
    {
      this.ngsGeneName = "nullHgvsc";

      this.ngsSummary = [];
      this.ngs={};
      // this.resetNgs();

    }
    else {
      this.ngsGeneName = "foundHgvsc";

      this.ngsSummary = new TableFormatModel(
        data.map(el => new NgsSummaryModel(el))
      );
    }

  }

  @action
  async loadNgsHeader(id) {
    const { data } = await PatientRepository.fetchNgsHeader(id);
    this.ngsHeader = data;
    this.ngsTabs = data.map(d => new NgsTabModel(d)); // tab
    return data[0];
  }

  @action
  async loadNgsSelect(id) {
    const { data } = await PatientRepository.fetchNgsHeader(id);
    this.ngsSelect = data.map(d => new NgsSelectBoxModel(d));
    this.loadRelevantVariants({ secId: data[0].secId, id });
  }

  @action
  resetNgsSelect() {
    this.ngsSelect = [];
  }

  @action
  resetNgsHeader() {
    this.ngsHeader = [];
    this.ngsTabs = [];
  }

  @action
  async loadCts(id) {
    // const { data } = await PatientRepository.fetchCtsSummary(id);
    // this.ctsList = new TableFormatModel(
    //   data.map(el => new CtsSummaryModel(el))
    // );

    const dataObj = await PatientRepository.fetchCtsSummary(id);

    console.log('DEBUG PatientStore loadCts fetchCtsSummary : ', dataObj);

    if (dataObj) {

      console.log('DEBUG PatientStore loadCts fetchCtsSummary : not null ');

      this.ctsList = new TableFormatModel(
        dataObj.data.map(el => new CtsSummaryModel(el))
      );

    }
    else {
      console.log('DEBUG PatientStore loadCts fetchCtsSummary : null ');
      this.ctsList = [];
    }
    // 2021.10.14 modify by jdy : from this to end fo function

    let bfound=false; 

    let dataKorea = [];
    let dataForeign = [];
    let dataReAlign = [];

    dataObj.data.map(el => {
      // console.log("data.map el : ", el);
      // console.log("data.map countries length : ", el.countryList.length);
      bfound=false; //

      el.countryList.map(mel => {
        // console.log("data.map countries content : ", mel);
        if (mel.includes("Korea") || mel.includes("korea")) bfound=true;
      });

      if (bfound) {
        dataKorea.push(el);
      }
      else {
        dataForeign.push(el);
      }
    });

    this.ctsKoreaList = new TableFormatModel(
      dataKorea.map(el => new CtsSummaryModel(el))
    );

    // this.cts = data.map(d => new CtsTabModel(d)); // tab
  
    dataKorea.map(d => dataReAlign.push(d));
    dataForeign.map(d => dataReAlign.push(d));

    this.cts = dataReAlign.map(d => new CtsTabModel(d)); // tab

    if (dataReAlign.length > 0) {
      console.log('DEBUG PatientStore loadCts fetchCtsSummary : end not null ');

      return dataReAlign[0];
    }
    else {
      console.log('DEBUG PatientStore loadCts fetchCtsSummary : end null');
      return null;
    }

    // return data[0];
  }

  @action
  async loadClinicalTrial(nctId) {
    const { data } = await PatientRepository.fetchClinicalTrial({
      id: this.person.id,
      nctId
    });
    this.setClinicalTrial(data);
  }

  @action
  async loadClinicalDetail(nctId) {
    const { data } = await PatientRepository.fetchClinicalDetail({
      id: this.person.id,
      nctId
    });
    this.clinicalTrialDesign = data.clinicalTrialDesign;
    this.criteria = data.criteria;
  }

  @action
  resetClinicalDetail() {
    this.clinicalTrialDesign = [];
    this.criteria = [];
  }

  @action
  setClinicalTrial(clinicalTrial = {}) {
    this.clinicalTrial = clinicalTrial;
    this.clinicalTrialSummaryData = new TableFormatModel([
      new ClinicalTrialSummaryTableModel(clinicalTrial)
    ]);
    this.clinicalTrialMatchedData = new TableFormatModel([
      new ClinicalTrialMatchedDataModel(clinicalTrial.matchedInformation)
    ]);
    this.briefSummary = clinicalTrial.briefSummaryList;
    this.briefTitle = clinicalTrial.briefTitle;
  }

  @action
  setNgs(ngs = {}) {
    this.ngs = ngs;
    this.ngsInfo = new NgsInfoModel(ngs);

    this.setNgsSelected(
      ngs.snvList
        .filter(f => {
          return f.reported;
        })
        .map(m => m.id),
      "ngsSnvReported"
    );

    this.setNgsSelected(
      ngs.cnvList
        .filter(f => {
          return f.reported;
        })
        .map(m => m.id),
      "ngsCnvReported"
    );
    this.setNgsSelected(
      ngs.svList
        .filter(f => {
          return f.reported;
        })
        .map(m => m.id),
      "ngsSvReported"
    );

    this.snvList = new TableFormatModel(
      ngs.snvList.map(m => new SnvListModel(m))
    );
    this.cnvList = new TableFormatModel(
      ngs.cnvList.map(m => new CnvListModel(m))
    );
    this.svList = new TableFormatModel(ngs.svList.map(m => new SvListModel(m)));
  }

  @action
  resetNgs() {
    this.ngsInfo = {};
    this.ngsSvReported = {};
    this.ngsCnvReported = {};
    this.ngsSnvReported = {};
    this.snvList = {};
    this.cnvList = {};
    this.svList = {};
  }

  @action
  setNgsSelected(arr = [], obj) {
    this[obj] = {};
    arr.forEach(el => {
      Object.assign(this[obj], {
        [el]: null
      });
    });
    // console.log(this[obj]);
  }

  @action
  setNgsVariantTab(ngs) {
    this.ngsVariantTab = [
      { value: "snv", label: "SNV/INDEL", count: ngs.snvList.length },
      { value: "cnv", label: "CNV", count: ngs.cnvList.length },
      { value: "sv", label: "SV", count: ngs.svList.length }
    ];
    // .concat(empty);
  }

  @action
  async loadNgs(param) {

    if (this.ngsGeneName == "nullHgvsc") {
      return;
    }

    const { data } = await PatientRepository.fetchNgs(param);
    this.setNgs(data);
  }

  @action
  setFirstElement(value) {
    const person = Object.keys(this.person).length > 0 ? this.person : value;
    this.setPerson(person);
    return person;
  }

  @action
  async loadSearchKeywordList() {
    const search = queryString.stringify(this.searchKeyword);
    const { data } = await PatientRepository.search(`?${search}`);
    this.searchKeywordList = map(
      (o, obj) => new SearchListModel({ label: o, data: obj[o] }),
      data
    );
  }

  @action
  setTableFormat(list) {
    return new TableFormatModel(list);
  }

  @action
  async loadStatistics() {
    const search = queryString.stringify(this.search);
    const { data } = await PatientRepository.fetchStatistics(`?${search}`);
    this.patientStatistic = data;
  }

  @action
  resetStatistics() {
    // 20210517 delete 1, add 1, by jdy
    // this.patientStatistic = [];
    this.patientStatistic = {};
  }

  @action
  async loadCancerChart() {
    const search = queryString.stringify(this.search);
    const { data } = await PatientRepository.fetchCancerChart(`?${search}`);

    this.cancerChart = {
      softMax:
        data.content.length > 0
          ? data.content
              .map(d => d.mutatedPatients)
              .reduce((a, b) => Math.max(a, b))
          : 0,
      name: data.content.map(d => d.cancerType.replace("cancer", "")),
      series: [
        new ScrollChartModel({
          data: data.content,
          name: "Mutated Patients",
          property: "mutatedPatients",
          index: 0
        }),
        new ScrollChartModel({
          data: data.content.map(d =>
            d.insCancerType ? d.insCancerType : { mutatedPatients: 0 }
            ),
          name: "Mutated Patients",
          property: "mutatedPatients",
          index: 1
        })
      ]
    };
    return data;
  }

  @action
  resetCancerChart() {
    this.cancerChart = {};
  }

  @action
  async loadVariantChart() {
    const search = queryString.stringify(this.search);
    const { data } = await PatientRepository.fetchVariantChart(`?${search}`);

    const list = data.content.slice(0, 20);

    this.variantChart = {
      softMax:
        list.length > 0
          ? list.map(d => d.value).reduce((a, b) => Math.max(a, b))
          : 0,
      name: list.map(d =>
        d.name.length > 10 ? `${d.name.slice(0, 10)}...` : d.name
      ),
      series: [
        new ScrollChartModel({
          data: list,
          name: "Total",
          property: "value",
          index: 0
        }),
        new ScrollChartModel({
          data: list.map(d => (d.variantDTO ? d.variantDTO : { value: 0 })),
          name: "Number of Patients",
          property: "value",
          index: 1
        })
      ]
    };
    // add by jdy 2020-12-07
    return list;
  }

  @action
  resetVariantChart() {
    this.variantChart = {};
  }

  @action
  async loadInsChart() {
    const search = queryString.stringify(this.search);
    const { data } = await PatientRepository.fetchInsChart(`?${search}`);
    const list = data.content;

    // if (
    //   this.search.institute ||
    //   this.root.account.user.authorities[0] === "ROLE_INS_USER") {

    //   const ins = list[0];

    //   this.insChart0 = new ScrollChartModel({
    //     data: list.map((m, i) => {
    //       if (i === 0) {
    //         return { value: 0 };
    //       }
    //       return m;
    //     }),
    //     name: "Total",
    //     property: "value",
    //     index: 0
    //   });

    //   this.insChart1 = new ScrollChartModel({
    //     data: list.map((m, i) => {
    //       if (i === 0) {
    //         return m;
    //       }
    //       return { value: 0 };
    //     }),
    //     name: "Number of Patients",
    //     property: "value",
    //     index: 1
    //   });

    // }
    // else {
    //   this.insChart0 = new ScrollChartModel({
    //     data: list,
    //     name: "Total",
    //     property: "value",
    //     index: 0
    //   });

    //   this.insChart1 = new ScrollChartModel({
    //     data: list.map(d => { return { value: 0 }}),
    //     name: "Number of Patients",
    //     property: "value",
    //     index: 1
    //   });
    // }

    // let myName = [];
    // let mySeries = [];

    // let mySize = list.length > 0
    // ? list.map(d => d.value).reduce((a, b) => Math.max(a, b))
    // : 0;
    
    // list.map((d, i) => {
    //   myName.push(d.key);
    // });

    // mySeries.push(this.insChart0);
    // mySeries.push(this.insChart1);

    // this.insChart = {
    //   softMax : mySize,
    //   name : myName,
    //   series : mySeries
    // };

    if (
      this.search.institute ||
      this.root.account.user.authorities[0] === "ROLE_INS_USER"
    ) {
      const ins = list[0];
      // console.log("g", list[0]);
      this.insChart = {
        softMax:
          list.length > 0
            ? list.map(d => d.value).reduce((a, b) => Math.max(a, b))
            : 0,
        name: list.map(d => d.key),
        series: [
          new ScrollChartModel({
            data: list.map((m, i) => {
              if (i === 0) {
                return { value: 0 };
              }
              return m;
            }),
            name: "Total",
            property: "value",
            index: 0
          }),
          new ScrollChartModel({
            data: ins ? [ins] : [],
            name: "Number of Patients",
            property: "value",
            index: 1
          })
        ]
      };
    } else {
      this.insChart = {
        softMax:
          list.length > 0
            ? list.map(d => d.value).reduce((a, b) => Math.max(a, b))
            : 0,
        name: list.map(d => d.key),
        series: [
          new ScrollChartModel({
            data: list,
            name: "Total",
            property: "value",
            index: 0
          }),
          new ScrollChartModel({
            data: [],
            name: "Number of Patients",
            property: "value",
            index: 1
          })
        ]
      };
    }

    return list;
  }

  @action
  resetInsChart() {
    this.insChart = {}
  }

  @action
  async loadStudied() {
    const search = queryString.stringify(this.search);
    const { data } = await PatientRepository.fetchStudies(`?${search}`);
    this.studies = data.content.map(d => new SelectBoxModel(d));
  }

  @action
  modifyStudy(studyId) {
    PatientRepository.changeStudy({
      personId: this.person.id,
      studyId
    });
  }

  @action
  async isFollowPatient(id) {
    const { data } = await PatientRepository.fetchFollowPatient({ id });
    this.isFollow = { id, followed: data };
  }

  @action
  async loadFollowPatients() {
    const search = queryString.stringify(this.followPatientSearch);
    const { data } = await PatientRepository.fetchFollowPatient({
      search: `?${search}`
    });
    this.followPatients = new TableFormatModel(
      data.content.map(m => new FollowPatientTableModel(m)),
      data.totalElements
    );
  }

  @action
  setFollowPatientSearch(query) {
    this.followPatientSearch = {
      ...this.followPatientSearch,
      ...query
    };
  }

  @action
  resetFollowPatientSearch() {
    this.followPatientSearch = {
      ...defaultFollowPatientSearch
    };
  }

  @action
  async follow(isFollow) {
    await PatientRepository.followPatient(isFollow, {
      personId: this.person.id
    });
    await this.isFollowPatient(this.person.id);
  }

  @action
  async isFollowPatientById(id) {
    const { data } = await PatientRepository.fetchFollowPatient({ id });
    return data;
  }

  @action
  async followById({ isFollow, id }) {
    await PatientRepository.followPatient(isFollow, {
      personId: id
    });
    await this.isFollowPatient(id);
  }

  @action
  setFollowId(id) {
    this.followId = id || this.person.id;
    // this.isFollow = id || this.person.id;
  }

  @action
  async loadRelevantVariants(param) {
    const { data } = await PatientRepository.fetchRelevantVariantList(param);
    this.variantsOfPatient = data.map(
      (map, i) => new VariantSelectBoxModel(map, i)
    );
  }

  @action
  async loadInsList() {
    const { data } = await PatientRepository.fetchInsList();
    this.insList = all.concat(
      data.content.map(map => new InsSelectBoxModel(map))
    );
  }

  @action
  resetInsList() {
    this.insList = [];
  }

  @action
  changeNotice(id) {
    PatientRepository.notice(id);
  }

  @action
  async loadEvidenceData() {
    const query = queryString.stringify(this.root.comm.evidenceData);
    const { data } = await PatientRepository.fetchEvidence(
      this.root.comm.evidenceType,
      `?${query}`
    );
    
    // console.log("loadEvidenceData : ", data);


    const {
      gene,
      variant,
      variantCoordinates,
      frequencies,
      kmasterAnnotations,
      referenceSiteAnnotations
    } = data;
    this.variantProfile = new TableFormatModel([
      {
        variant: `${gene}, ${variant}`,
        ...variantCoordinates
      }
    ]);

    const obj = {};

    for (let [k, v] of Object.entries(frequencies)) {
      Object.assign(obj, {
        [k]: (function({ frequency, count, totalCount }) {
          return `${frequency},${count},${totalCount}`;
        })(v)
      });
    }

    this.alleleFrequency = new TableFormatModel([obj]);

    this.theraphyInformation = kmasterAnnotations;

    this.refSiteColumns = referenceSiteAnnotations.map(
      m => new RefSiteTabModel(m)
    );

    const ref = {};
    // evidences.map
    for (let { id, evidences } of referenceSiteAnnotations) {
      Object.assign(ref, {
        [id]: new TableFormatModel(evidences.map(m => new EvidenceModel(m)))
      });
    }

    this.refSiteEvidences = ref;
    return data;
  }
}
