import React, { useRef, useEffect, useCallback } from "react";
import AutoSelect from "../../atoms/AutoSelect";
import useStore from "modules/hooks/useStore";
import { observer } from "mobx-react";

const customStyle = {
  control: base => ({
    ...base,
    borderRadius: 0,
    minHeight: 25,
    maxHeight: 25
  }),
  indicatorsContainer: base => ({
    ...base,
    maxHeight: 25
  }),
  menu: base => ({
    ...base,
    borderRadius: 0,
    zIndex: 50,
    minHeight: 25,
    color: "#3a3a3a"
  }),
  placeholder: base => ({
    ...base
  })
};

const components = {
  DropdownIndicator: null
};

function PersonSearchInput() {
  const {
    patient,
    patient: { searchKeywordList, search }
  } = useStore();

  const select = useRef();

  useEffect(() => {
    patient.resetSearchKeywordList();
  }, []);

  const handleChange = (newValue, { action }) => {
    if (action === "clear") {
      patient.resetSearch({});
    }

    if (newValue) {
      const { type, value } = newValue;
      patient.resetSearch({
        // ...search,
        [type]: value
      });
    }
  };

  const handleInputChange = (newValue, { action }) => {
    if (action === "menu-close") {
      patient.resetSearchKeywordList();
    }

    if (newValue.length > 2) {
      patient.setSearchKeyword({
        ...search,
        searchKeyword: newValue
      });
      patient.loadSearchKeywordList();
    }
  };

  // const onMenuOpen = () => {
  // console.log("on menu open");
  // };

  return (
    <AutoSelect
      select={select}
      autoCompleteList={searchKeywordList}
      handleChange={handleChange}
      handleInputChange={handleInputChange}
      // onMenuOpen={onMenuOpen}
      styles={customStyle}
      components={components}
      placeholder={"Search Keyword"}
    />
  );
}

export default observer(PersonSearchInput);
