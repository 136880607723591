import React, { useEffect, useCallback, useState } from "react";
import CustomTable from "components/atoms/CustomTable";
import CustomCell from "components/atoms/CustomTable/CustomCell";
import useStore from "modules/hooks/useStore";
import { observer } from "mobx-react";
import { withRouter } from "react-router-dom";

const columns = [
  { column: "Panel", key: "panel", link: false, align: "left" },
  { column: "Repeats", key: "repeats", link: false, align: "right" },
  { column: "Patients", key: "patients", link: true, align: "right" },
  { column: "Samples", key: "samples", link: false, align: "right" }
];

function PanelTable({ history }) {
  const {
    patient,
    statistics,
    statistics: { panelStatistics, panelTotals, search }
  } = useStore();
  const [loading, setLoading] = useState(false);

  useEffect(() => {

    if (!search.cancer) {
      // for DEBUG by jdy
      statistics.loadPanelStatistics()
      // console.log("statistics.loadPanelStatistics()");
    }

    // setLoading(false);

    // if (!search.cancer) {
    //   statistics.loadPanelStatistics().then(value => {
    //     setLoading(true);
    //   });
    // }
    // else {
    //   setLoading(true);
    // }
    
    return () => {
      statistics.resetPanelStatistics();
    };
  }, [search]);

  const onClick = useCallback(({ link, panel }) => {
    const [key, value] = link.split("=");

    let payload = { [key]: value };

    if (panel !== "Multiple Panel")
      Object.assign(payload, {
        panel
      });

    patient.setSearch(payload);
    history.push("/patient");
  });

  return (
    <div
      style={{
        overflowY: "auto",
        overflowX: "hidden",
        position: "absolute",
        top: 10,
        right: 10,
        left: 10,
        bottom: 10
      }}
    >


        <CustomTable
          columnList={columns}
          tableList={panelStatistics}
          panelTotals={panelTotals}
          Component={CustomCell}
          onClick={onClick}
        />

    </div>
  );
}

export default withRouter(observer(PanelTable));
