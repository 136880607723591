import React, { useEffect, useMemo, useState, useRef } from "react";
import usePersonStore from "modules/hooks/usePersonStore";
import PatientPane from "./PatientPane";
import PersonTable from "components/organisms/PersonTableOfPatient";
import PatientRouter from "shared/PatientRouter";
import SearchPatient from "components/organisms/SearchKeywordInputOfPatient";
import FollowPatient from "components/molecules/FollowPatientButton";
import ActionableDialog from "components/molecules/ActionableDialog";
import PatientStatistics from "components/molecules/PatientStatistics";
import WriteClinicalReviewButton from "components/molecules/WriteClinicalReviewButton";
// import { observer } from "mobx-react";
import { makeStyles } from "@material-ui/core/styles";
import CheckPermission from "components/lib/CheckPermission";
import PatientIcon from "assets/images/tab/patient.svg";
import useStore from "modules/hooks/useStore";
// import Loading from "components/molecules/LoadingComponent";

const useStyle = makeStyles(theme => ({
  title: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center"
  }
}));

function PatientView({ history, match, location }) {
  const classes = useStyle();
  const { id } = usePersonStore();
  const { patient, comm } = useStore();

  // const [loading, setLoading] = useState(false);

//   useEffect(() => {
//     // setLoading(false);

//     // if (personTable && patientRouter) {
//     //   setLoading(true);
//     // }
//     return () => {
//       patient.resetSearch({});
//       patient.resetPersons();
//       patient.resetPerson();
//       comm.resetOpen();
//     };
//   }, []);

  useEffect(() => {
    // patient.isMatchView = true;
    patient.loadPerson(id);
  }, [id]);

  if (history.action === "POP" && location.pathname === "/patient-view") {
    history.goBack();
  }

//   const personTable = useMemo(() => {
//     return (
//       <>
//         <SearchPatient />
//         <PersonTable />
//       </>
//     );
//   }, []);

  const patientRouter = useMemo(() => {
    return (
      <>
        <PatientRouter match={match} />
        <ActionableDialog />
      </>
    );
  }, []);

//   const ltitle = useMemo(() => {
//     return (
//       <div
//         style={{
//           // width: "100%",
//           display: "flex",
//           alignItems: "center",
//           justifyContent: "flex-end",
//           padding: 10
//         }}
//       >
//         {/* <div style={{ marginLeft: 10 }}>{"PATIENT LIST"}</div> */}
//         <PatientStatistics />
//       </div>
//     );
//   }, []);

  const rTitle = useMemo(() => {
    return (
      //TODO: follow patient
      <div className={classes.title}>
        <div style={{ display: "flex" }}>
          {/* <img src={PatientIcon} alt="" /> CHANGE */}
          <div style={{ marginLeft: 5 }}>PATIENT</div>
        </div>
        <div style={{ display: "flex" }}>
          <FollowPatient />
          <CheckPermission permission={"ROLE_INS_USER"}>
            <WriteClinicalReviewButton>
              Write Clinical Review
            </WriteClinicalReviewButton>
          </CheckPermission>
        </div>
      </div>
    );
  }, []);


  return (
    <PatientPane
      right={patientRouter}
      rtitle={rTitle}
    />
  );
}

// export default observer(Patient);
export default PatientView;
