import React, { useRef, useEffect, useMemo, useState } from "react";
import AutoSelect from "components/atoms/AutoSelect";
import useStore from "modules/hooks/useStore";
import { observer } from "mobx-react";
import ClearIcon from "@material-ui/icons/Clear";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import CheckRouter from "components/lib/CheckRouter";

const CustomClearText = () => (
  <div
    style={{
      color: "hsl(0,0%,80%)",
      display: "flex",
      justifyContents: "center",
      alignItems: "center"
    }}
  >
    <ClearIcon style={{ fontSize: "1.2rem" }} />
  </div>
);

const ClearIndicator = props => {
  const {
    children = <CustomClearText />,
    getStyles,
    innerProps: { ref, ...restInnerProps }
  } = props;
  return (
    <div
      {...restInnerProps}
      ref={ref}
      style={getStyles("clearIndicator", props)}
    >
      <div style={{ padding: 0 }}>{children}</div>
    </div>
  );
};

const CustomDropDownText = () => (
  <div
    style={{
      color: "hsl(0,0%,80%)",
      display: "flex",
      justifyContents: "center",
      alignItems: "center"
    }}
  >
    <ArrowDropDownIcon style={{ fontSize: "1.8rem" }} />
  </div>
);

const DropdownIndicator = props => {
  const {
    children = <CustomDropDownText />,
    getStyles,
    innerProps: { ref, ...restInnerProps }
  } = props;
  return (
    <div
      {...restInnerProps}
      ref={ref}
      style={getStyles("dropdownIndicator", props)}
    >
      <div style={{ padding: 0 }}>{children}</div>
    </div>
  );
};

const customStyle = {
  control: base => ({
    ...base,
    borderRadius: 0,
    minHeight: 30,
    maxHeight: 30
  }),
  menu: base => ({
    ...base,
    borderRadius: 0,
    zIndex: 50,
    minHeight: 30,
    color: "#3a3a3a"
  }),
  placeholder: base => ({
    ...base
  }),
  clearIndicator: base => ({
    ...base,
    padding: "0px 10px"
    // borderLeft: "1px solid hsl(0,0%,80%)"
  }),
  dropdownIndicator: base => ({
    ...base,
    padding: "0px 5px"
    // borderLeft: "1px solid hsl(0,0%,80%)"
  }),
  indicatorSeparator: base => ({
    ...base,
    backgroundColor: "none"
    // borderLeft: "1px solid hsl(0,0%,80%)"
  })
};

function InstituteSearchKeyword() {
  const select = useRef();

  const {
    patient,
    statistics,
    account: { user },
    patient: { insList, instituteSearch }
  } = useStore();

  const [selected, setSelected] = useState(false);
  const [value, setValue] = useState();
  // const [show] = useCheckRouter();

  // console.log(show);

  useEffect(() => {
    return () => {
      patient.resetInstituteSearch({});
    };
  }, []);

  useEffect(() => {
    if (user && Object.keys(user).length > 0) patient.loadInsList();
  }, [user]);

  useEffect(() => {
    let value = instituteSearch.institute;
    if (instituteSearch.institute) {
      value = { label: value, value };
    }
    setValue(value || "");
  }, [instituteSearch]);

  const components = useMemo(() => {
    // DropdownIndicator: null,
    return {
      DropdownIndicator: !selected ? DropdownIndicator : null,
      ClearIndicator
    };
  }, [selected]);

  const handleChange = (newValue, { action }) => {
    if (action === "clear" || newValue.value === "ALL") {
      patient.resetInstituteSearch({});
      patient.resetSearch({});
      statistics.resetSelected();
      statistics.resetSearch();
    }

    if (action === "clear") setSelected(false);

    statistics.resetSelected();
    statistics.resetSearch();


    if (newValue && newValue.value !== "ALL") {
      const { value } = newValue;
      patient.resetInstituteSearch({
        institute: value
      });
      patient.resetSearch({
        institute: value
      });
      statistics.setSearch({
        institute: value
      });
      //
      // statistics.resetSelected();
      // statistics.resetSearch();
  
      // let index = 1;
      // let cancerValue = statistics.cancers[index].key;
  
      // statistics.setOpen(false);
      // statistics.setSelectedCancer(cancerValue);
      // statistics.setSearch({
      //   cancer: cancerValue
      // });
      // statistics.setSelectedCancerIndex(index);
      //
      setSelected(true);
    }
  };

  return (
    <CheckRouter showList={["/admin", "/my-page"]}>
      <div style={{ width: 160, height: 30, marginRight: 5 }}>
        <AutoSelect
          select={select}
          autoCompleteList={insList}
          handleChange={handleChange}
          styles={customStyle}
          placeholder={"Select Institution"}
          components={components}
          value={value}
        />
      </div>
    </CheckRouter>
  );
}

export default observer(InstituteSearchKeyword);
