import React, { useMemo, useRef, useCallback, useEffect } from "react";
import CommTemplatePage from "../templates";
import OverviewContent from "components/organisms/Overview";
import OverviewDownLoadPopover from "components/molecules/OverviewDownLoadPopover";
import OverviewStatistic from "components/molecules/OverviewStatistic";
// import InstituteSelectBox from "components/molecules/InstituteSelectBox";
import { makeStyles } from "@material-ui/styles";
import useStore from "modules/hooks/useStore";
import CheckPermission from "components/lib/CheckPermission";
import OverviewIcon from "assets/images/tab/overview.svg";

const useStyles = makeStyles({
  container: {
    display: "flex",
    alignItems: "center",
    // width: "100%",
    justifyContent: "space-between",
    padding: 5
  }
});

function Overview(props) {
  const {
    patient,
    statistics,
    statistics: { search }
  } = useStore();

  const classes = useStyles();
  const targetRef = useRef();

  useEffect(() => {
    // statistics.resetSearch();
    return () => {
      // console.log("overview return");
      statistics.resetSearch();
      //FIXME: 로고클릭시 초기화 수정수정
      // patient.resetSearch({});
    };
  }, [search]);

  const right = useMemo(() => {
    return <OverviewContent targetRef={targetRef} />;
  }, []);

  // const onChange = useCallback(({ target: { value } }) => {
  //   statistics.resetSelected();
  //   statistics.resetSearch();
  //   patient.resetSearch({});

  //   if (value !== "ALL") {
  //     statistics.setSearch({
  //       institute: value
  //     });
  //     patient.setSearch({
  //       institute: value
  //     });
  //   }
  // }, []);

  // const selectBox = useMemo(() => {
  //   return (
  //     <CheckPermission permission={"ROLE_KM_USER"}>
  //       <InstituteSelectBox onChange={onChange} />
  //     </CheckPermission>
  //   );
  // }, []);

  const rtitle = useMemo(() => {
    return (
      <div className={classes.container}>
        <div>
          {/* <img src={OverviewIcon} alt="" />  CHANGE */}
          <span style={{ marginLeft: 5 }}>{"OVERVIEW"}</span>
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center"
          }}
        >
          <OverviewStatistic /> 
          {/* {selectBox} */}
        </div>
        {/* <OverviewDownLoadPopover targetRef={targetRef} /> */}
      </div>
    );
  }, []);

  return (
    <>
      <CommTemplatePage right={right} rtitle={rtitle} />
    </>
  );
}

export default Overview;
