import { extendObservable, computed } from "mobx";

class CtsSummaryModel {
  constructor(data) {
    extendObservable(this, data);
  }

  @computed
  get phases() {
    return this.phase ? this.phase : "";
  }

  @computed
  get drugs() {
    return this.drugList.join("/");
  }

  @computed
  get conditions() {
    return this.conditionList.join("/");
  }

  @computed
  get countries() {
    return this.countryList.join("/");
  }
}

export default CtsSummaryModel;
