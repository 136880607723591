const colors = ["#d8d8d8", "#14a4e7"];

class ScrollChartModel {
  constructor({ data = [], name, property, index }) {
    // console.log(data, name, property, index);
    this.name = name;
    this.data = data.map(d => {
      return d[property];
    }); // data 배열
    this.color = colors[index];
    this.totalPatients = data.map(d => d["totalPatients"] || 0); // total 배열
    this.mutatedPatients = data.map(d => d["mutatedPatients"] || 0); // mutated 배열
  }
}

export default ScrollChartModel;
