import React, { useMemo, useEffect } from "react";
import { makeStyles } from "@material-ui/styles";
import PersonTable from "components/organisms/PersonTableOfExplorer";
import CancerScrollChart from "components/molecules/CancerScrollChart";
import VariantScrollChart from "../../molecules/VariantScrollChart";
import InsScrollChart from "../../molecules/InsSrollChart";
import useStore from "modules/hooks/useStore";

const useStyles = makeStyles({
  layout: {
    position: "absolute",
    bottom: 0,
    top: 10,
    right: 10,
    left: 10,
    backgroundColor: "#f6f6f6",
    border: "1px solid #c6c6c6"
  },
  chartContainer: {
    position: "absolute",
    bottom: "52%",
    top: 10,
    right: 10,
    left: 10,
    display: "flex"
  },
  tableContainer: {
    position: "absolute",
    bottom: 10,
    top: "49%",
    right: 10,
    left: 10
  }
});

function Explorer(props) {
  const classes = useStyles();

  const {
    patient,
    statistics,
    statistics: { search }
  } = useStore();

  // useEffect(() => {
  //   console.log("effect 1");
  //   return () => {
  //     console.log("return effect 1");
  //   };
  // }, [search]);

  const charts = useMemo(() => {
    return (
      <>
        <CancerScrollChart />
        <VariantScrollChart />
        <InsScrollChart />
      </>
    );
  }, []);

  return (
    <div className={classes.layout}>
      <div className={classes.chartContainer}>{charts}</div>
      <div className={classes.tableContainer}>
        <PersonTable />
      </div>
    </div>
  );
}

export default Explorer;
