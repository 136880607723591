import React, { useMemo } from "react";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { makeStyles } from "@material-ui/styles";

const containerMargin = 10;
const line = "solid 1px #c6c6c6";

const defaultStyle = {
  position: "absolute"
};

const useCustomStyle = makeStyles({
  layout: {
    ...defaultStyle,
    backgroundColor: "#f6f6f6",
    left: "17%",
    right: "17%",
    bottom: 0,
    top: 0
  },
  container: {
    ...defaultStyle,
    left: containerMargin,
    right: containerMargin,
    bottom: 0,
    top: containerMargin,
    overflowY: "auto"
  },

  whiteBackground: {
    borderLeft: line,
    borderRight: line,
    borderTop: line,
    backgroundColor: "#ffffff"
  }
});

function PageContent(props) {
  const classes = useCustomStyle();

  return (
    <div className={classes.layout} style={props.rightStyle}>
      <div
        className={`${classes.container} ${props.back &&
          classes.whiteBackground}`}
      >
        {props.router}
      </div>
    </div>
  );
}

export default PageContent;
