import { observable, action } from "mobx";

export default class Comm {
  @observable selectedMenu = "OVERVIEW";
  @observable isReviewOpen = false;
  @observable isActionableOpen = false;
  @observable evidenceData = {};
  @observable evidenceType = "";

  @observable isAnnotationOpen = false;
  @observable isAnnotationAllOpen = false;
  @observable annotationQueryData = {};
  @observable annotationData = {};
  @observable annotationType = "";
  @observable subjectId = "";

  constructor(root) {
    this.root = root;
  }

  @action
  setEvidenceData(newObj) {
    this.evidenceData = {
      ...newObj,
      //FIXME: 요청 api 변경?
      // personId: this.root.patient.person.id
      disease: this.root.patient.person.diagnosisInfo.primaryCancer
    };
  }

  @action
  setEvidenceType(type) {
    this.evidenceType = type;
  }

  @action
  setSubject(subjectId) {
    this.subjectId = subjectId;
  }

  @action
  setSelectedMenu(menu) {
    this.selectedMenu = menu;
  }

  @action
  setActionableOpen(open) {
    this.isActionableOpen = open;
  }

  @action
  setReviewOpen() {
    this.isReviewOpen = !this.isReviewOpen;
  }

  @action
  setOpen(open) {
    this.isReviewOpen = open;
  }

  @action
  resetOpen() {
    this.setOpen(false);
    this.setActionableOpen(false);
  }

  @action
  setAnnotationAllOpen(open) {
    this.isAnnotationAllOpen = open;
  }
  @action
  setAnnotationOpen(open) {
    this.isAnnotationOpen = open;
  }

  @action
  setAnnotationType(type) {
    this.annotationType = type;
  }
  
  // @action
  // getAnnotationType() {
  //   return this.annotationType;
  // }

  @action
  setAnnotationQueryData(newObj) {
    this.annotationQueryData = {
      ...this.annotationQueryData,
      ...newObj
    };
  }

  @action
  setAnnotationData(newObj) {
    this.annotationData = {
      ...newObj
    };
  }


  @action
  setAnnotationDataVersion(newObj) {
    this.annotationData = {
      ...this.annotationData,
      ...newObj
    };
  }
}
