import React, { useEffect, useMemo, useCallback, useRef, useState } from "react";
import useStore from "modules/hooks/useStore";
import ColumnChart from "../../atoms/ColumnChart";
import ColVariantChart from "components/atoms/ColVariantChart";
import TableTemplate from "../../organisms/TableTemplate";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { withRouter } from "react-router-dom";
import { observer } from "mobx-react";
import Loading from "components/molecules/LoadingComponent";

const layoutStyle = {
  position: "absolute",
  width: "48%",
  top: 0,
  bottom: 0,
  right: 0,
};

function InterpretationCriteriaChart({ history }) {
  const {
    patient,
    statistics,
    statistics: { studies, search, variantChart }
  } = useStore();
  const mathes = useMediaQuery("(min-width:1500px)");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(false);

    if (!search.cancer) {
      statistics.loadVariantCategory().then(_ => {
        setLoading(true);
      });
    }
    else {
      setLoading(true);
    }

    return () => {
      statistics.resetStudy();
    };
  }, [search]);

  const divRef = useRef();

  const height =
    divRef.current &&
    divRef.current.offsetHeight -
      divRef.current.childNodes[0].childNodes[0].offsetHeight -
      30;

  const title = useMemo(() => {
    return (
      <>
        <span>Interpretation Criteria Chart</span>
      </>
    );
  }, []);

  const onClick = useCallback(value => {
    patient.setSearch({
      study: value.category
    });
    history.push("/patient");
  }, []);

  const chart = useMemo(() => {
    return (
      <TableTemplate title={title} layoutStyle={layoutStyle}>
      {/* <ColumnChart series={[]} onClick={onClick} height={300} /> */}
      {Object.keys(variantChart).length > 0 && (
          <ColVariantChart list={variantChart} height={height}/>
        )}
    </TableTemplate>
    );
  }, [variantChart, mathes, height]);

  return (
    <div ref={divRef}>
      <Loading loading={loading}>
        {chart}
      </Loading>
    </div>
  );
}

export default withRouter(observer(InterpretationCriteriaChart));
