import React, { useMemo } from "react";
import ClinicalMatch from "./ClinicalMatch";
import ClinicalReview from "./ClinicalReview";
import Ngs from "./Ngs";
import Summary from "./Summary";

//patient 탭 메뉴구성
const panelObj = {
  match: ClinicalMatch,
  review: ClinicalReview,
  ngs: Ngs,
  summary: Summary
};

function PatientTabPanel({ value }) {
  const panel = useMemo(() => {
    const Component = panelObj[value];

    return <Component />;
  });

  return panel;
}

export default PatientTabPanel;
