import React, { useMemo } from "react";
import Select from "@material-ui/core/Select";
import { makeStyles, withStyles } from "@material-ui/styles";
import InputBase from "@material-ui/core/InputBase";

const useStyles = makeStyles({
  selectIcon: {
    position: "absolute",
    // background: "linear-gradient(#f1f1f1, #dfdfdf)",
    // borderLeft: "1px solid #d4d4d5",
    top: 0,
    right: 3,
    bottom: 0,
    // width: "1.96rem",
    height: "100%"
    // height: "1.75rem"
  }
});

const Input = withStyles(theme => ({
  input: {
    height: "100%",
    position: "relative",
    border: "1px solid #d4d4d5",
    backgroundColor: "#ffffff",
    fontSize: "1rem",
    fontWeight: 400,
    // padding: 0,
    padding: "3px 3rem 3px 3px",
    "&:focus": {
      backgroundColor: "#ffffff"
    }
  }
}))(InputBase);

function CustomSelectBox({
  data = [],
  onChange,
  defaultValue,
  maxLength,
  inputStyle,
  name
}) {
  const classes = useStyles();

  const select = useMemo(() => {
    return (
      data.length > 0 && (
        <Select
          native
          name={name}
          onChange={onChange}
          value={defaultValue}
          input={<Input className={inputStyle} />}
          classes={{
            icon: classes.selectIcon,
            select: classes.select,
            root: classes.root
          }}
        >
          {data.map(({ value, label, url }, i) => {
            return (
              <option key={i} value={url || value} title={label}>
                {label.length > maxLength
                  ? `${label.slice(0, maxLength)}...`
                  : label}
              </option>
            );
          })}
        </Select>
      )
    );
  }, [defaultValue, data]);

  return select;
}

export default CustomSelectBox;
