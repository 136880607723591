import React, { useRef, useEffect, useState } from "react";
import PatientTab from "./PatientTab";
import { makeStyles } from "@material-ui/styles";
import PersonInfo from "./PersonInfo";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import useStore from "modules/hooks/useStore";
import Loading from "components/molecules/LoadingComponent";

const useStyles = makeStyles({
  layout_1500: {
    minWidth: 1120
  },
  layout: {
    position: "absolute",
    bottom: 0,
    top: 15,
    left: 15,
    right: 15,
    width: 906
  },
  container: {
    width: "100%",
    top: 10,
    bottom: 0,
    position: "absolute"
  },
  div: {
    width: "100%",
    position: "absolute",
    top: 70,
    bottom: 0
  }
});

function Patient(props) {
  const classes = useStyles();
  const matches = useMediaQuery("(min-width:1500px)");

  const [loading, setLoading] = useState(false);
  const {
    patient: { persons }
  } = useStore();

  useEffect(() => {
    setLoading(false);
    if (persons) {
      setLoading(true);
    }
  }, [persons]);

  return (
    <div className={`${classes.layout} ${matches && classes.layout_1500}`}>
      <Loading loading={loading}>
        <PersonInfo />
        <div className={classes.div}>
          <div className={classes.container}>
            <PatientTab {...props} />
          </div>
        </div>
      </Loading>
    </div>
  );
}

export default Patient;
