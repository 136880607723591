import React, { Component } from "react";
import Dialog from "@material-ui/core/Dialog";

class CustomObjDialog extends Component {
  render() {
    const { open, CustomDialogContent, onClickOpen, ...rest } = this.props;
    return (
      <div>
        <Dialog
          open={open}
          onClose={onClickOpen}
          aria-labelledby="form-dialog-title"
          maxWidth={false}
        >
          <CustomDialogContent close={onClickOpen} {...rest} />
        </Dialog>
      </div>
    );
  }
}

export default CustomObjDialog;
