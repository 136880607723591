import React from "react";

function CustomSvg(props) {
  const { children, useCustomStyle } = props;
  const customClasses = useCustomStyle && useCustomStyle();

  return (
    <img
      src={children}
      alt=""
      className={customClasses && customClasses.logo}
    />
  );
}

export default CustomSvg;
