import React, { useCallback } from "react";
import CustomButtonList from "../../atoms/ui/CustomButtonList";
import useStore from "modules/hooks/useStore";
import { observer } from "mobx-react";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles({
  container: {
    marginTop: 5
  },
  title: {
    color: "#434343",
    margin: 10,
    fontSize: "1rem"
  },
  buttonConteiner: {
    marginTop: 5
  }
});

const sexProperty = "gender";
const sexList = [
  { name: "All", key: {}, selected: undefined },
  { name: "Female", key: { [sexProperty]: "F" }, selected: "F" },
  { name: "Male", key: { [sexProperty]: "M" }, selected: "M" }
];

function SexFilterButtonList() {
  const {
    patient,
    patient: {
      search,
      search: { gender }
    }
  } = useStore();

  const classes = useStyles();

  const handleClick = useCallback(
    ({ key }) => {
      if (Object.keys(key).length <= 0) delete search.gender;
      patient.resetSearch({ ...search, ...key });
    },
    [search]
  );

  return (
    <div className={classes.container}>
      <span className={classes.title}>Sex</span>
      <div className={classes.buttonConteiner}>
        <CustomButtonList
          data={sexList}
          onClick={handleClick}
          selected={gender}
        />
      </div>
    </div>
  );
}

export default observer(SexFilterButtonList);
