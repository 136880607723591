import React, { useEffect, useRef } from "react";
import PanelPieChart from "../../molecules/PanelPieChart";
import CancerColumnChart from "../../molecules/CancerColumnChart";
import StudyColumnChart from "../../molecules/StudyColumnChart";
import EvidenceMatchPieChart from "../../molecules/EvidenceMatchPieChart";
import InterpretationCriteriaChart from "../../molecules/InterpretationCriteriaChart";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import useStore from "modules/hooks/useStore";
import { makeStyles } from "@material-ui/styles";

const useCustomStyle = makeStyles({
  container: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    position: "absolute",
    width: "100%",
    top: 0,
    bottom: 0
  },
  layout_1500: {
    minWidth: 1470
  },
  layout: {
    position: "absolute",
    right: 15,
    left: 15,
    top: 15,
    bottom: 0,
    border: "1px solid #c6c6c6",
    backgroundColor: "#f6f6f6",
    width: 1220
  },
  innerLayout: {
    display: "flex",
    justifyContent: "space-between",
    padding: 15
  },
  panelContainer_1500: {
    minWidth: 558
  },
  panelContainer: {
    width: 458,
    position: "absolute",
    bottom: 15,
    left: 15,
    top: 15
  },
  rightContainer_1500: {
    minWidth: 865
  },
  rightContainer: {
    width: 715,
    position: "absolute",
    right: 15,
    bottom: 15,
    top: 15
  },
  cancerContainer: {
    position: "absolute",
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    height: "49%"
  },
  studyContainer: {
    display: "flex",
    width: "50%"
  },
  evidenceContainer: {
    // marginTop: 10,
    display: "flex",
    width: "50%"
  },
  right_over_layout: {
    position: "absolute",
    left: 0,
    right: 0,
    top: "51%",
    bottom: 0,
    display: "flex",
    height: "49%"
  }
});

function Overview({ targetRef }) {
  const classes = useCustomStyle();
  const matches = useMediaQuery("(min-width:1500px)");

  // const { statistics } = useStore();

  //FIXME: 우선은 overview일때만 초기화 바꺼야함
  // useEffect(() => {
  // statistics.resetSelected();
  // statistics.resetSearch();
  // patient.resetSearch({});
  // }, []);

  return (
    <div className={`${classes.container}`}>
      <div className={`${classes.layout} ${matches && classes.layout_1500}`}>
        <div className={`${classes.innerLayout}`} ref={targetRef}>
          <div
            className={`${classes.panelContainer} ${matches &&
              classes.panelContainer_1500}`}
          >
            <PanelPieChart />
          </div>
          <div
            className={`${classes.rightContainer} ${matches &&
              classes.rightContainer_1500}`}
          >
            <div className={`${classes.cancerContainer}`}>
              <CancerColumnChart />
            </div>
            <div className={`${classes.right_over_layout}`}>
              <div className={`${classes.studyContainer}`}>
                {/* 기존 차트를 제거 후 variant 차트 들어감 - ksh 20201127  */}
                <StudyColumnChart />
              </div>
              <div className={`${classes.evidenceContainer}`}>
              {/* InterpretationCriteriaChart  - ksh 20201127 새로운 차트 들어갈 공간 새롭게 추가 */}
                <InterpretationCriteriaChart />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Overview;
