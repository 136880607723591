import React from "react";
import { makeStyles } from "@material-ui/styles";
import { Link } from "react-router-dom";

const useStyles = makeStyles({
  div: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    height: "100%"
  },
  h1: {
    fontSize: "5rem",
    margin: 0
  }
});

function NotFound() {
  //FIXME: /overview -> routing 전환으로 변경
  const classes = useStyles();
  return (
    <div className={classes.div}>
      <h1 className={classes.h1}>404</h1>
      <p>Page not found.</p>
      <Link to={"/overview"}>home</Link>
    </div>
  );
}

export default NotFound;
