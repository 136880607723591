import React, { useEffect, useCallback, useMemo } from "react";
import TableTemplate from "components/organisms/TableTemplate";
import useStore from "modules/hooks/useStore";
import CustomInput from "components/atoms/ui/CustomInput";
import CustomSelectBox from "components/atoms/ui/CustomSelectBox";
import useInputObjState from "components/lib/useInputObjState";
import { observer } from "mobx-react";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import useAssign from "./useAssign";

const layoutStyle = {
  backgroundColor: "#eef5ff"
};

const headStyle = {
  borderBottom: "1px solid #dedede"
};

const formStyle = {
  display: "flex",
  flexDirection: "column"
};

const titleStyle = {
  fontSize: "1rem",
  fontWeight: 400,
  padding: `10px 0px`,
  color: "#3a3a3a"
};

// function useInputMobx(property) {
//   const {
//     patient,
//     patient: { idx }
//   } = useStore();

//   const handleChangeState = useCallback(({ target: { value } }) => {
//     patient.setAddedTheryphy(idx, property, value);
//   }, []);

//   return useObserver(() => [patient.getAddedTheryphy(), handleChangeState]);
// }

// function useSelectBoxMobx() {
//   const {
//     patient,
//     patient: { idx }
//   } = useStore();

//   const handleChangeState = useCallback(({ target: { value } }) => {
//     patient.setAddedTheraphyObj(idx, JSON.parse(value));
//   }, []);

//   return useObserver(() => [handleChangeState]);
// }

// function TheraphyInput({ onChange }) {
//   // const [therapy, handleChangeTherapy] = useInputMobx("therapy");

//   return (
//     <>
//       <div style={titleStyle}>Therapy</div>
//       <CustomInput
//         // placeholder="therapy"
//         name="therapy"
//         type="text"
//         onChange={onChange}
//         // value={therapy}
//         // inputStyle={{ width: "100%" }}
//       />
//     </>
//   );
// }

// const SecInput = observer(() => {
//   const [handleSecId] = useSelectBoxMobx();

//   const {
//     patient,
//     patient: { ngsSelect }
//   } = useStore();

//   useEffect(() => {
//     if (ngsSelect.length > 0)
//       patient.setAddedTheraphyObj(0, JSON.parse(ngsSelect[0].value));
//   }, [ngsSelect]);

//   const selectBox = useMemo(() => {
//     return (
//       <>
//         <div style={titleStyle}>Test ID</div>
//         <CustomSelectBox
//           data={ngsSelect}
//           onChange={handleSecId}
//           // inputStyle={{ width: "100%" }}
//           maxLength={30}
//         />
//       </>
//     );
//   }, [ngsSelect]);

//   return selectBox;
// });

// const VariantInput = observer(() => {
//   const [handleChangeVariantName] = useSelectBoxMobx();

//   const {
//     patient,
//     patient: { variantsOfPatient }
//   } = useStore();

//   useEffect(() => {
//     if (variantsOfPatient.length > 0)
//       patient.setAddedTheraphyObj(0, JSON.parse(variantsOfPatient[0].value));
//   }, [variantsOfPatient]);

//   const selectBox = useMemo(() => {
//     return (
//       <>
//         <div style={titleStyle}>Relevant Variant</div>
//         <CustomSelectBox
//           data={variantsOfPatient}
//           onChange={handleChangeVariantName}
//           // inputStyle={{ width: "100%" }}
//           maxLength={30}
//         />
//       </>
//     );
//   }, [variantsOfPatient]);

//   return selectBox;
// });

// function ClinicalSignificanceInput() {
//   const [clinicalSignificance, handleChangeClinicalsignificance] = useInputMobx(
//     "clinicalSignificance"
//   );

//   return (
//     <>
//       <div style={titleStyle}>Clinical Significance</div>
//       <CustomInput
//         // placeholder="clinical significance"
//         type="text"
//         onChange={handleChangeClinicalsignificance}
//         value={clinicalSignificance}
//         // inputStyle={{ width: "100%" }}
//       />
//     </>
//   );
// }

// function CommentInput() {
//   const [comment, handleChangeComment] = useInputMobx("comment");
//   return (
//     <>
//       <div style={titleStyle}>Comment</div>
//       <textarea
//         // style={{ width: "100%" }}
//         // placeholder="comment"
//         type="text"
//         onChange={handleChangeComment}
//         value={comment}
//       />
//       {/* <CustomInput
//         // placeholder="comment"
//         type="text"
//         onChange={handleChangeComment}
//         value={comment}
//       /> */}
//     </>
//   );
// }

const type = "therapies";
function TherapiesInputs({ button }) {
  const {
    comm,
    patient,
    review,
    patient: { ngsSelect, variantsOfPatient }
  } = useStore();

  const [therapies, setTherapies, handleChangeTherapies] = useInputObjState({
    // therapy: "",
    // secId: "",
    // variantId: "",
    // variantType: "",
    // clinicalSignificance: "",
    // comment: ""
  });

  useAssign(ngsSelect, setTherapies, therapies);
  useAssign(variantsOfPatient, setTherapies, therapies);

  const onSubmit = e => {
    e.preventDefault();
    review
      .submitReviews({
        type,
        therapies
      })
      .then(value => {
        if (value) {
          alert(`${value.type} ${value.success}`);
          review.loadReviews(type);
          comm.setOpen(false);
        }
      });
    // comm.setOpen(false);
  };

  const therapyInput = useMemo(() => {
    return (
      <>
        <div style={titleStyle}>Therapy</div>
        <CustomInput
          name="therapy"
          type="text"
          onChange={handleChangeTherapies}
          value={therapies.therapy}
        />
      </>
    );
  }, []);

  const secInput = useMemo(() => {
    return (
      <>
        <div style={titleStyle}>Test ID</div>
        <CustomSelectBox
          data={ngsSelect}
          onChange={handleChangeTherapies}
          maxLength={30}
        />
      </>
    );
  }, []);

  const variantInput = useMemo(() => {
    return (
      <>
        <div style={titleStyle}>Relevant Variant</div>
        <CustomSelectBox
          data={variantsOfPatient}
          onChange={handleChangeTherapies}
          maxLength={30}
        />
      </>
    );
  }, []);

  const clinicalSignificanceInput = useMemo(() => {
    return (
      <>
        <div style={titleStyle}>Clinical Significance</div>
        <CustomInput
          type="text"
          name="clinicalSignificance"
          onChange={handleChangeTherapies}
          value={therapies.clinicalSignificance}
        />
      </>
    );
  }, []);

  const commentInput = useMemo(() => {
    return (
      <>
        <div style={titleStyle}>Comment</div>
        <textarea
          type="text"
          name="comment"
          onChange={handleChangeTherapies}
          value={therapies.comment}
          required
        />
        {/* <CustomInput
        // placeholder="comment"
        type="text"
        onChange={handleChangeComment}
        value={comment}
      /> */}
      </>
    );
  }, []);

  const title = useMemo(() => {
    return (
      <div style={{ color: "#14a4e7", fontSize: "1.14rem" }}>
        {"Therapy Recommendation"}
      </div>
    );
  }, []);

  return (
    <TableTemplate
      title={title}
      borderHide={true}
      layoutStyle={layoutStyle}
      headStyle={headStyle}
      // bodyStyle={bodyStyle}
    >
      <form onSubmit={onSubmit} style={formStyle}>
        {therapyInput}
        {secInput}
        {variantInput}
        {clinicalSignificanceInput}
        {commentInput}
        {button}
      </form>
    </TableTemplate>
  );
}

export default observer(TherapiesInputs);
