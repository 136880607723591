import React, { useMemo, useEffect, useCallback } from "react";
import CommTemplatePage from "../templates";
import SearchFilter from "components/organisms/SearchFilters";
import ExplorerContent from "components/organisms/Explorer";
import ExplorerStatistic from "components/molecules/ExplorerStatistic";
import { makeStyles } from "@material-ui/styles";
import useStore from "modules/hooks/useStore";
import ExplorerIcon from "assets/images/tab/explorer.svg";

const useStyles = makeStyles({
  container: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%"
  },
  opsContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between"
  }
});

function Explorer(props) {
  const {
    patient,
    statistics,
    statistics: { search }
  } = useStore();
  const classes = useStyles();

  useEffect(() => {
    // console.log("effect");
    patient.setExplorerReturn(false);
    // statistics.resetSearch();
    return () => {
      // console.log("explorer return");
      patient.setExplorerReturn(true);
      statistics.resetSearch();
      statistics.resetSelected();
      patient.resetPersons();
      if (props.history.action === "POP") patient.resetSearch({});
    };
  }, [search]);

  const right = useMemo(() => {
    return <ExplorerContent />;
  }, []);

  const left = useMemo(() => {
    return <SearchFilter />;
  }, []);

  const rtitle = useMemo(() => {
    return (
      <div className={classes.container}>
        <div style={{ display: "flex", alignItems: "center" }}>
          {/* <img src={ExplorerIcon} alt="" /> CHANGE */}
          <span style={{ marginLeft: 5 }}>{"EXPLORER"}</span>
        </div>
        <div className={classes.opsContainer}>
          <ExplorerStatistic />
        </div>
      </div>
    );
  }, []);

  const ltitle = useMemo(() => {
    return (
      <div
        style={{
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between"
        }}
      >
        <div style={{ margin: 10 }}>{"SEARCH FILTERS"}</div>
      </div>
    );
  }, []);

  return (
    <>
      <CommTemplatePage
        left={left}
        right={right}
        ltitle={ltitle}
        rtitle={rtitle}
      />
    </>
  );
}

export default Explorer;
