import React, { useMemo, useCallback, useState, useEffect } from "react";
import CustomTab from "components/atoms/ui/CustomTab";
import TableTemplate from "components/organisms/TableTemplate";
import useStore from "modules/hooks/useStore";
import { observer } from "mobx-react";
import { makeStyles } from "@material-ui/styles";
import Oncokb from "./Oncokb";
import Clinvar from "./Clinvar";
import Civic from "./Civic";

const useStyles = makeStyles({
  // tabContainer: {
  //   width: "100%",
  //   height: "100%"
  // },
  // container: {
  //   position: "absolute",
  //   bottom: 0,
  //   width: "100%",
  //   top: "35px"
  // },
  tabsRoot: {
    minHeight: 60,
    backgroundColor: "#ffffff"
  },
  tabRoot: {
    color: "#b4b4b4",
    minHeight: 60
  },
  // panelRoot: {
  //   // overflowY: "auto",
  //   height: "100%",
  //   width: "100%",
  //   backgroundColor: "#f6f6f6",
  //   borderRight: "1px solid #c6c6c6",
  //   borderLeft: "1px solid #c6c6c6",
  //   borderTop: "none",
  //   borderBottom: "1px solid #c6c6c6"
  // },
  selected: {
    color: "#434343",
    fontWeight: 600
  },
  indicator: {
    backgroundColor: "#14a4e7",
    height: 5
  }
  // empty: {
  //   borderBottom: "1px solid #c6c6c6",
  //   maxWidth: "100%",
  //   width: "100%",
  //   minHeight: 35
  // },
  // disabled: {
  //   opacity: "0.7!important",
  //   color: "darkgrey"
  // },
  // wrapper: {
  //   fontSize: "1rem"
  // }
});

const layoutStyle = {
  position: "absolute",
  // width: "100%",
  top: 264,
  bottom: 0,
  right: 0,
  left: 0
};

const bodyStyle = {
  position: "absolute",
  left: 0,
  right: 0,
  top: 39,
  bottom: 0,
  paddingTop: 0
};

const refSites = {
  Oncokb,
  Clinvar,
  Civic
};

function findCountIndex(refSiteColumns) {
  for (let i = 0, len = refSiteColumns.length; i < len; i++) {
    if (refSiteColumns[i].count > 0) return i;
  }
}

function RefSitePanel({ selected }) {
  const Component = refSites[selected];
  return (
    <div
      style={{
        position: "absolute",
        top: 60,
        bottom: 0,
        left: 0,
        right: 0,
        borderTop: "1px solid #c6c6c6"
      }}
    >
      <Component />
    </div>
  );
}

function RefSiteTab() {
  const {
    patient: { refSiteColumns }
  } = useStore();

  const [selected, setSelected] = useState("");

  const index = useMemo(() => {
    return (refSiteColumns.length > 0 && findCountIndex(refSiteColumns)) || 0;
  }, [refSiteColumns]);

  useEffect(() => {
    if (refSiteColumns.length > 0) {
      setSelected(refSiteColumns[index].id);
    }
  }, [refSiteColumns]);

  const handleChange = useCallback((e, newValue) => {
    setSelected(newValue);
  }, []);

  const panel = useMemo(() => {
    return selected && <RefSitePanel selected={selected} />;
  }, [selected]);

  const tab = useMemo(() => {
    return (
      refSiteColumns.length > 0 && (
        // index > -1 && (
        <CustomTab
          columns={refSiteColumns}
          onChange={handleChange}
          useStyles={useStyles}
          defaultValue={refSiteColumns[index].value}
        >
          {/* TODO: 리스트 테이블로 */}
          {panel}
        </CustomTab>
      )
    );
    // );
  }, [refSiteColumns, panel]);

  return (
    <TableTemplate
      title={"Reference Sites"}
      layoutStyle={layoutStyle}
      bodyStyle={bodyStyle}
    >
      {tab}
    </TableTemplate>
  );
}

export default observer(RefSiteTab);
