import React, { useEffect, useMemo } from "react";
import TableTemplate from "components/organisms/TableTemplate";
import useStore from "modules/hooks/useStore";
import { observer } from "mobx-react";
import { makeStyles } from "@material-ui/core/styles";

const useStyle = makeStyles(theme => ({
  container: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderTop: "1px solid #dedede",
    borderLeft: "1px solid #dedede",
    borderRight: "1px solid #dedede"
  },
  lastContainer: {
    borderBottom: "1px solid #dedede"
  },
  div: {
    textAlign: "center",
    // display: "flex",
    // justifyItems: "center",
    // alignItems: "center",
    height: 15,
    padding: "5px 5px",
    borderRight: "1px solid #dedede",
    width: "50%"
  },
  title: {
    whiteSpace: "nowrap",
    backgroundColor: "#f3f3f3",
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  content: {
    display: "flex",
    alignItems: "center"
  },
  left: {
    justifyContent: "start"
  },
  right: {
    justifyContent: "flex-end"
  },
  lastDiv: {
    borderRight: "none"
  }
}));

function StudyDesign() {
  const {
    patient: { clinicalTrialDesign }
  } = useStore();
  const classes = useStyle();

  const content = useMemo(() => {
    return clinicalTrialDesign.map((ctd, i) => {
      return (
        <div key={i}>
          <div className={`${classes.container}`}>
            <div className={`${classes.div} ${classes.title}`}>Study Type</div>
            <div
              className={`${classes.div} ${classes.lastDiv} ${classes.content}`}
            >
              {ctd.studyType || "-"}
            </div>
          </div>
          <div className={`${classes.container}`}>
            <div className={`${classes.div} ${classes.title}`}>
              Estimated Enrollment
            </div>
            <div
              className={`${classes.div} ${classes.lastDiv} ${classes.content} ${classes.right}`}
            >
              {ctd.estimatedEnrollment || "-"}
            </div>
          </div>
          <div className={`${classes.container}`}>
            <div className={`${classes.div} ${classes.title}`}>Allocation</div>
            <div
              className={`${classes.div} ${classes.lastDiv} ${classes.content}`}
            >
              {ctd.allocation || "-"}
            </div>
          </div>
          <div className={`${classes.container}`}>
            <div className={`${classes.div} ${classes.title}`}>
              Intervention Model
            </div>
            <div
              className={`${classes.div} ${classes.lastDiv} ${classes.content}`}
            >
              {ctd.interventionModel || "-"}
            </div>
          </div>
          <div className={`${classes.container}`}>
            <div className={`${classes.div} ${classes.title}`}>Masking</div>
            <div
              className={`${classes.div} ${classes.lastDiv} ${classes.content}`}
            >
              {ctd.masking || "-"}
            </div>
          </div>
          <div className={`${classes.container}`}>
            <div className={`${classes.div} ${classes.title}`}>
              Primary Purpose
            </div>
            <div
              className={`${classes.div} ${classes.lastDiv} ${classes.content}`}
            >
              {ctd.primaryPurpose || "-"}
            </div>
          </div>
          <div className={`${classes.container}`}>
            <div className={`${classes.div} ${classes.title}`}>
              Recruitment State
            </div>
            <div
              className={`${classes.div} ${classes.lastDiv} ${classes.content}`}
            >
              {ctd.recruitmentStatus || "-"}
            </div>
          </div>
          <div className={`${classes.container}`}>
            <div className={`${classes.div} ${classes.title}`}>
              Study Start Date
            </div>
            <div
              className={`${classes.div} ${classes.lastDiv} ${classes.content}`}
            >
              {ctd.studyStartDate || "-"}
            </div>
          </div>
          <div className={`${classes.container}`}>
            <div className={`${classes.div} ${classes.title}`}>
              Estimated Primary Completion Date
            </div>
            <div
              className={`${classes.div} ${classes.lastDiv} ${classes.content}`}
            >
              {ctd.estimatedPrimaryCompletionDate || "-"}
            </div>
          </div>
          <div className={`${classes.container}  ${classes.lastContainer}`}>
            <div className={`${classes.div} ${classes.title}`}>
              Estimated Study Completion Date
            </div>
            <div
              className={`${classes.div} ${classes.lastDiv} ${classes.content}`}
            >
              {ctd.estimatedStudyCompletionDate || "-"}
            </div>
          </div>
        </div>
      );
    });
  }, [clinicalTrialDesign]);

  return (
    <div>
      <TableTemplate title={"Study design"}>{content}</TableTemplate>
    </div>
  );
}

export default observer(StudyDesign);
