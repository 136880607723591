import React, { useState, useEffect, useMemo } from "react";
import CustomDialog from "../../atoms/ui/CustomDialog";
import InfoTable from "./InfoTable";
// import TherapiesTable from "./TherapiesTable";
import TherapiesInputs from "./TherapiesInputs";
import PotentialClinicalTrialsInputs from "./PotentialClinicalTrialsInputs";
// import PotentialClinicalTrialsTable from "./PotentialClinicalTrialsTable";
import useStore from "modules/hooks/useStore";
import { observer } from "mobx-react";
import AdditionalComment from "./AdditionalComment";
import MatchButton from "components/molecules/MatchButton";
import PostAddIcon from "@material-ui/icons/PostAdd";
import SubContent from "components/organisms/SubContent";
import Loading from "components/molecules/LoadingComponent";
import CloseIcon from "@material-ui/icons/Close";

const buttonStyle = {
  marginTop: 15,
  width: "100%",
  height: 35,
  // background: "none",
  // border: "1px solid #979797",
  // color: "#434343",
  cursor: "pointer",
  backgroundColor: "#e6e6e6",
  color: "#969696",
  border: "0px",
  "&:hover": {
    backgroundColor: "#14a4e7",
    color: "#ffffff"
  }
};

const sumbitButton = (
  <button type="submit" style={buttonStyle}>
    Submit
  </button>
);

function WriteClinicalReviewDialogPanel() {
  return (
    <>
      {/* <InfoTable />
      <br /> */}
      {/* <TherapiesTable />
      
      <br />
      <PotentialClinicalTrialsTable />
      <br /> */}
      <TherapiesInputs button={sumbitButton} />
      <br />
      <PotentialClinicalTrialsInputs button={sumbitButton} />
      <br />
      <AdditionalComment button={sumbitButton} />
    </>
  );
}

function WriteClinicalReviewButton({ children }) {
  const {
    comm,
    comm: { isReviewOpen },
    patient,
    patient: { person }
  } = useStore();
  const [loading, setLoading] = useState(false);
  // const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    // if (!isReviewOpen) {
    //   patient.addTheraphy();
    //   patient.addPotential();
    //   patient.addComment();
    // }
    comm.setOpen(!isReviewOpen);
  };

  const onClose = () => {
    comm.setOpen(false);
  };

  // const onSubmit = () => {
  //   patient.submitClinicalReview();
  //   onClose();
  // };

  // const actions = useMemo(() => {
  //   return (
  //     <>
  //       <MatchButton onClick={onClose}>Cancel</MatchButton>
  //       <MatchButton onClick={onSubmit}>Submit Review</MatchButton>
  //     </>
  //   );
  // }, []);

  const close = useMemo(() => {
    return (
      <button
        onClick={onClose}
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          background: "none",
          border: "none",
          color: "#ababab",
          cursor: "pointer"
        }}
      >
        <CloseIcon />
      </button>
    );
  }, []);

  useEffect(() => {
    setLoading(false);
    if (Object.keys(person).length > 0 && isReviewOpen) {
      patient.loadNgsSelect(person.id);
      setTimeout(() => {
        setLoading(true);
      }, 500);
    }
  }, [person, isReviewOpen]);

  return (
    <>
      <MatchButton
        style={{ width: 150 }}
        onClick={handleClickOpen}
        icon={<PostAddIcon />}
      >
        {children}
      </MatchButton>
      {isReviewOpen && (
        <SubContent title={"WRITE CLINICAL REVIEW"} closeComponent={close}>
          <Loading loading={loading}>
            <WriteClinicalReviewDialogPanel />
          </Loading>
        </SubContent>
      )}
      {/* <CustomDialog
        open={open}
        onClose={onClose}
        title={"Write Clinical Review"}
        actions={actions}
        //FIXME: width 수정
        maxWidth={"sm"}
      >
        <WriteClinicalReviewDialogPanel />
      </CustomDialog> */}
    </>
  );
}

export default observer(WriteClinicalReviewButton);
