import React, { useRef, useMemo } from "react";
// import MuiTable from "components/atoms/MuiTable";
import useStore from "modules/hooks/useStore";
import { observer } from "mobx-react";
// import TableTemplate from "../../../../TableTemplate";
import { makeStyles } from "@material-ui/styles";
// import DataFormat from "components/atoms/DataFormat";
import RefTable from "./RefTable";
import civic from "assets/images/siteicon/civic.png";
import CustomImageButton from "components/atoms/ui/CustomImageButton";

const useStyles = makeStyles({
  container: {
    overflow: "auto",
    position: "absolute",
    top: "3rem",
    bottom: 0,
    left: 20,
    right: 20
  },
  title: {
    margin: "0px 10px",
    fontSize: "1.1rem",
    color: "#454444"
  }
});

const citations = {
  column: "Citation",
  title: "Citation",
  property: "citations",
  align: "center",
  sort: false,
  options: {
    link: {
      isLinked: false
    },
    isEditable: false
  },
  style: { head: { width: 100 } }
};

const url = {
  column: "URL",
  title: "Url",
  property: "url",
  align: "center",
  sort: false,
  options: {
    link: {
      isLinked: false
    },
    isEditable: false,
    withCellExcute: ({ value }) => WrapperComponent => {
      return (
        <WrapperComponent>
          <CustomImageButton
            src={civic}
            style={{ width: 20, height: 20, cursor: "pointer" }}
            onClick={() => {
              window.open(value);
            }}
            title={value}
          />
        </WrapperComponent>
      );
    }
  },
  style: { head: { width: 50 } }
};

const columns = {
  pa: [
    {
      column: "Diseases",
      title: "Diseases",
      property: "disease",
      align: "left",
      sort: false,
      options: {
        link: {
          isLinked: false
        },
        isEditable: false
      }
      // style: { head: { width: 50 } }
    },
    {
      column: "Interpretation",
      title: "Interpretation",
      property: "interpretation",
      align: "left",
      sort: false,
      options: {
        link: {
          isLinked: false
        },
        isEditable: false
      }
      // style: { head: { width: 50 } }
    },
    {
      column: "Level",
      title: "Evidence Level",
      property: "evidenceLevel",
      align: "left",
      sort: false,
      options: {
        link: {
          isLinked: false
        },
        isEditable: false
      },
      style: { head: { width: 50 } }
    },

    {
      column: "Type",
      title: "Evidence Type",
      property: "evidenceType",
      align: "left",
      sort: false,
      options: {
        link: {
          isLinked: false
        },
        isEditable: false
      }
      // style: { head: { width: 50 } }
    },
    {
      column: "Variant Origin",
      title: "Variant Origin",
      property: "variantOrigin",
      align: "left",
      sort: false,
      options: {
        link: {
          isLinked: false
        },
        isEditable: false
      }
      // style: { head: { width: 50 } }
    },
    {
      column: "Rating",
      title: "Rating",
      property: "rating",
      align: "right",
      sort: false,
      options: {
        link: {
          isLinked: false
        },
        isEditable: false
      },
      style: { head: { width: 50 } }
    }
    // citations,
    // url
  ],
  ac: [
    {
      column: "Diseases",
      title: "Diseases",
      property: "disease",
      align: "left",
      sort: false,
      options: {
        link: {
          isLinked: false
        },
        isEditable: false
      },
      style: { head: { width: 130 } }
    },
    {
      column: "Drug",
      title: "Drug",
      property: "drug",
      align: "left",
      sort: false,
      options: {
        link: {
          isLinked: false
        },
        isEditable: false,
        withCellExcute: ({ value }) => WrapperComponent => {
          return (
            <WrapperComponent>
              <div title={value}>
                {value.length > 10 ? `${value.slice(0, 7)}...` : value}
              </div>
            </WrapperComponent>
          );
        }
      },
      style: { head: { width: 60 } }
    },
    {
      column: "Interpretation",
      title: "Interpretation",
      property: "interpretation",
      align: "left",
      sort: false,
      options: {
        link: {
          isLinked: false
        },
        isEditable: false
      },
      style: { head: { width: 100 } }
    },
    {
      column: "Level",
      title: "Evidence Level",
      property: "evidenceLevel",
      align: "left",
      sort: false,
      options: {
        link: {
          isLinked: false
        },
        isEditable: false
      },
      style: { head: { width: 50 } }
    },
    {
      column: "Type",
      title: "Evidence Type",
      property: "evidenceType",
      align: "left",
      sort: false,
      options: {
        link: {
          isLinked: false
        },
        isEditable: false
      },
      style: { head: { width: 70 } }
    },
    {
      column: "Variant Origin",
      title: "Variant Origin",
      property: "variantOrigin",
      align: "left",
      sort: false,
      options: {
        link: {
          isLinked: false
        },
        isEditable: false
      },
      style: { head: { width: 130 } }
    },
    {
      column: "Rating",
      title: "Rating",
      property: "rating",
      align: "right",
      sort: false,
      options: {
        link: {
          isLinked: false
        },
        isEditable: false
      },
      style: { head: { width: 50 } }
    }
    // citations,
    // url
  ]
};

function Civic() {
  const {
    comm: { evidenceType }
  } = useStore();

  return <RefTable columns={columns[evidenceType]} type={"Civic"} />;
}

export default observer(Civic);
