import React, { useMemo, useState, useEffect } from "react";
import { makeStyles } from "@material-ui/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import CommTemplatePage from "../templates";
import PersonIcon from "@material-ui/icons/Person";
import PageContent from "components/organisms/PageContent";
import LeftMenu from "components/organisms/PageContent/LeftMenu";
import Account from "pages/myPage/Account";
import MyPage from "./MyPage";
import MyPageRouter from "shared/MyPageRouter";
import useStore from "modules/hooks/useStore";

const useStyles = makeStyles({
  title: {
    display: "flex"
  },
  titleIcon: {
    fontSize: "1.4rem"
  }
});

const list = [
  {
    label: "MY PATIENT",
    url: "/my-page",
    permission: "ROLE_ADMIN"
    // page: <MyPage />,
    // icon: <PersonIcon style={{ paddingRight: "5px" }} />
  },
  {
    label: "MY ACCOUNT",
    url: "/my-page/account",
    permission: "ROLE_ADMIN"

    // page: <Account />,
    // icon: <PersonIcon style={{ paddingRight: "5px" }} />
  },
  {
    label: "MY QUESTION",
    url: "/my-page/qna",
    permission: "ROLE_DM_USER"
    // page: <Account />,
    // icon: <PersonIcon style={{ paddingRight: "5px" }} />
  }
];

function myPage(props) {
  const classes = useStyles();
  const { patient, statistics } = useStore();
  // const matches = useMediaQuery("(min-width:1500px)");
  const [selected, setSelected] = useState(
    list.findIndex(f => {
      return f.url.includes(
        props.location.pathname.split("/")[2] || "/my-page"
      );
    })
  );

  useEffect(() => {
    patient.resetInstituteSearch({});
    patient.resetSearch({});
    statistics.resetSelected();
    statistics.resetSearch();
  }, []);

  useEffect(() => {
    setSelected(
      list.findIndex(f => {
        return f.url.includes(
          props.location.pathname.split("/")[2] || "/my-page"
        );
      })
    );
  }, [props.location.pathname]);

  const handleChange = page => {
    setSelected(page);
  };

  const right = useMemo(() => {
    return (
      <div
        style={{
          display: "flex",
          position: "absolute",
          top: 10,
          bottom: 0,
          right: 10,
          left: 10,
          borderLeft: "1px solid #c6c6c6",
          borderRight: "1px solid #c6c6c6",
          borderTop: "1px solid #c6c6c6"
          // padding: "0px 10px"
        }}
      >
        <LeftMenu list={list} handleChange={handleChange} selected={selected} />
        <PageContent
          rightStyle={{ right: 0 }}
          back={true}
          router={<MyPageRouter {...props} />}
        />
      </div>
    );
  }, [selected]);

  const rtitle = useMemo(() => {
    return (
      <div className={classes.container}>
        <div className={classes.title}>
          {/* <PersonIcon className={classes.titleIcon} /> CHANGE  */}
          <span style={{ marginLeft: 5 }}>{"MY PAGE"}</span>
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center"
          }}
        ></div>
      </div>
    );
  }, []);

  return (
    <>
      <CommTemplatePage right={right} rtitle={rtitle} />
    </>
  );
}

export default myPage;
