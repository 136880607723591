import React, { useEffect } from "react";
import Input from "../../atoms/ui/CustomInput";
import LoginButton from "../../molecules/LoginButton";
import LoginCheckbox from "../../molecules/LoginCheckbox";
// import FindEmailDialog from "./FindEmailDialog";
import FindPasswordDialog from "./FindPasswordDialog";
import RequestJoinDialog from "./RequestJoinDialog";
import useInputState from "../../lib/useInputState";
import useCheckState from "../../lib/useCheckState";
import useStore from "modules/hooks/useStore";
import { makeStyles } from "@material-ui/styles";
import { observer } from "mobx-react";

const useStyles = makeStyles({
  login: {
    display: "flex",
    marginTop: "2rem"
  },
  loginInput: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-around",
    marginRight: 10
  }
});

const useCustomStyle = makeStyles({
  input_1500: {
    minWidth: 330,
    minHeight: 20
  },
  input: {
    width: 250,
    height: 15,
    // marginBottom: 10,
    border: "solid 1px #c6c6c6",
    backgroundColor: "transparent",
    color: "#c6c6c6",
    padding: 10
  }
});

function LoginForm(props) {
  const [email, setEmail, handleChangeEmail] = useInputState(""); //admin
  const [password, setPassword, handleChangePassword] = useInputState(""); //Kmaster1004!!
  const [checked, setChecked, handleChangeCheckbox] = useCheckState(false);


  const {
    account,
    account: { loggedIn }
  } = useStore();
  const classes = useStyles();

  const handleSubmit = e => {
    e.preventDefault();
    account.login({ login: email, password, rememberMe: checked });
    // props.history.push("/overview");
  };

  useEffect(() => {
    // console.log("loggedIn", loggedIn);
    if (loggedIn) {
      // props.history.push("/overview");
      // 2021.10.07 modify by jdy for test
      props.history.push("/overview");
    }
  }, [loggedIn]);

  return (
    <>
      <form onSubmit={handleSubmit}>
        <div className={classes.login}>
          <div className={classes.loginInput}>
            <Input
              type="text"
              placeholder="Enter your email"
              onChange={handleChangeEmail}
              useCustomStyle={useCustomStyle}
              value={email}
            />
            <Input
              type="password"
              placeholder="Enter your password"
              onChange={handleChangePassword}
              useCustomStyle={useCustomStyle}
              value={password}
            />
          </div>
          <LoginButton />
        </div>
        <LoginCheckbox
          checked={checked}
          onChange={handleChangeCheckbox}
          label="Stay logged in"
        />
      </form>
      <div style={{ margin: "15px 0px" }}>
        <FindPasswordDialog />
        <RequestJoinDialog />
      </div>
    </>
  );
}

export default observer(LoginForm);
