import React, { useCallback, useEffect } from "react";
import MatchButton from "../MatchButton";
import XLSX from "xlsx";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";

function ExcelParser({ data }) {
  //FIXME: data 부분 state로 바꾸기

  // useEffect(() => {
  //   effect
  //   return () => {

  //   };
  // }, [input])

  const handleClick = useCallback(() => {
    let ws = XLSX.utils.json_to_sheet(data);
    let wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Patient");
    XLSX.writeFile(wb, "PERSON_list.xlsx");
  }, [data]);

  return (
    <MatchButton onClick={handleClick} icon={<ArrowDownwardIcon />}>
      Download
    </MatchButton>
  );
}

export default ExcelParser;
