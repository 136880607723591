import React from "react";
import { extendObservable, computed } from "mobx";
import FollowPatientOfPatientTable from "components/molecules/FollowPatientOfPatientTable";
import NoticedOfPersonTable from "components/molecules/NoticedOfPersonTable";

class PatientModel {
  constructor(data) {
    extendObservable(this, data);
    this.reviewed = "YES";
    this.vitalStatus = data.vitalStatus || "";
    this.followed = (
      <div title={"Bookmark"}>
        <FollowPatientOfPatientTable id={data.id} value={data.followed} />
      </div>
    );
    this.notiValue = data.noticed;
    this.noticed = (
      <div title={"Noticed"}>
        <NoticedOfPersonTable id={data.id} value={data.noticed} />
      </div>
    );
  }

  @computed
  get studyId() {
    return this.personStudyCurrent.studyId;
  }

  @computed
  get review() {
    return "YES";
  }

  @computed
  get diagnosisAge() {
    return this.specimenList[0].diagnosisAge;
  }

  @computed
  get differentiation() {
    return this.diagnosisInfo.currentDegreeDifferentiation || "";
  }

  @computed
  get tnm() {
    const {
      currentTumorStage,
      currentRegionalLymphNodeStage,
      currentDistantMetastasisStage,
      currentCancerStaging
    } = this.diagnosisInfo;
    const na = "n/a";
    return (
      `T${currentTumorStage || na}N${currentRegionalLymphNodeStage ||
        na}M${currentDistantMetastasisStage || na}(${currentCancerStaging ||
        na})` || ""
    );
  }

  @computed
  get primaryCancer() {
    return this.diagnosisInfo.primaryCancer || "";
  }

  @computed
  get cancerDetail() {
    return this.diagnosisInfo.detailCancer || "";
  }

  // get mark() {
  //   return (
  //     <div title={"Bookmark"}>
  //       <FollowPatientOfPatientTable id={this.id} value={this.followed} />
  //     </div>
  //   );
  // }

  // get noti() {
  //   return (
  //     <div title={"Noticed"}>
  //       <NoticedOfPersonTable id={this.id} value={this.noticed} />
  //     </div>
  //   );
  // }
}

export default PatientModel;
