import React from "react";
import { extendObservable, computed } from "mobx";
import EditRegisterButton from "pages/admin/EditRegisterUser";
import DeleteUser from "pages/admin/DeleteUser";

class AccountUserTableModel {
  constructor(data) {
    extendObservable(this, data);
    this.activated = data.activated ? (
      "Activate"
    ) : (
      <span style={{ color: "red" }}>Deactivate</span>
    );
  }

  // @computed
  get name() {
    return `${this.lastName} ${this.firstName}`;
  }

  // @computed
  get control() {
    return (
      <>
        <EditRegisterButton {...this}>Edit</EditRegisterButton>
        <DeleteUser {...this}>Delete</DeleteUser>
      </>
    );
  }

  // @computed
  get auth() {
    return this.authorities.toString().replace("ROLE_", "");
  }

  // @computed
  // get act() {
  //   return
  // }
}

export default AccountUserTableModel;
