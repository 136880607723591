import React, { useEffect } from "react";
import AdminCard from "components/organisms/AdminCard";
import AdminLineChart from "components/organisms/AdminLineChart";
import CustomMenuList from "components/atoms/ui/CustomMenuList";
import TableTemplate from "components/organisms/TableTemplate";
import OverviewRequestCountButton from "./OverviewRequestCountButton";
import OverviewExcelUploadBtn from "./OverviewExcelUploadBtn";
import useStore from "modules/hooks/useStore";
import { makeStyles } from "@material-ui/styles";

const data = [
  {
    title: "User Account",
    list: [
      {
        id: 1,
        label: <OverviewRequestCountButton />
      },
      {
        id: 2,
        label: <OverviewExcelUploadBtn type="user" />
      },
      {
        id: 3,
        label: "Register user",
        // icon: <a href={"https://www.naver.com"}>4</a>,
        action: function(h) {
          h.push("/admin/users/register");
        }
      },
      {
        id: 4,
        label: "Go to list",
        // icon: <a href={"https://www.naver.com"}>4</a>,
        action: function(h) {
          h.push("/admin/users");
        }
      }
    ],
    type: "user"
  },
  {
    title: "Institution Account",
    list: [
      {
        id: 1,
        label: <OverviewExcelUploadBtn type="institution" />
      },
      {
        id: 2,
        label: "Register institution",
        // icon: <a href={"https://www.naver.com"}>4</a>,
        action: function(h) {
          h.push("/admin/institutions/register");
        }
      },
      {
        id: 3,
        label: "Go to list",
        // icon: <a href={"https://www.naver.com"}>4</a>,
        action: function(h) {
          h.push("/admin/institutions");
        }
      }
    ],
    type: "institution"
  },
  {
    title: "Data Release",
    list: [
      // {
      //   id: 1,
      //   label: "Go to list",
      //   // icon: <a href={"https://www.naver.com"}>4</a>,
      //   action: function(h) {
      //     h.push("/admin/release");
      //   }
      // }
    ],
    type: "lastUpdateDate"
  },
  {
    title: "Q&A Board",
    list: [
      {
        id: 1,
        label: "Go to list",
        // icon: <a href={"https://www.naver.com"}>4</a>,
        action: function(h) {
          h.push("/admin/qna");
        }
      }
    ],
    type: "qaBoard"
  }
];

const useStyle = makeStyles({
  ul: {
    display: "flex",
    justifyContent: "space-between"
  },
  li: {}
});

function AdminCardList(props) {
  const list = data.map(m => <AdminCard {...m} {...props} />);
  return (
    <div style={{ marginBottom: 10 }}>
      <CustomMenuList list={list} useCustomStyle={useStyle} />
    </div>
  );
}

function Overview(props) {
  return (
    <>
      <AdminCardList {...props} />
      <TableTemplate title={"Number of visitors"}>
        <AdminLineChart />
      </TableTemplate>
    </>
  );
}

export default Overview;
