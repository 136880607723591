import React, { useEffect, useMemo, useCallback, useRef, useState } from "react";
import useStore from "modules/hooks/useStore";
import ColumnChart from "../../atoms/ColumnChart";
import ColChart from "components/atoms/ColChart";
import TableTemplate from "../../organisms/TableTemplate";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { withRouter } from "react-router-dom";
import { observer } from "mobx-react";
import Loading from "components/molecules/LoadingComponent";

const layoutStyle = {
  position: "absolute",
  width: "50%",
  top: 0,
  bottom: 0,
  right: 0,
  left: 0
};

function StudyColumnChart({ history }) {
  const {
    patient,
    statistics,
    statistics: { studies, search, studyChart , reported, reportedChart }
  } = useStore();
  const mathes = useMediaQuery("(min-width:1500px)");
  const [loading, setLoading] = useState(false);

  //임시 데이터 생성 - ksh 20201127
  // let reportedChart = {"category":["TGFBR2, E125fs","TBRD7, 277_277del","EGF, E1134fs","MSH3, A60delinsAAAP","XPC, 34_35del","NCOA3, 1243_1244del","HDAC2, K11fs","NTRK1, P695S","RUNX1, E422A","PTEN, splicing"],
  // "series":[{"key":"TGFBR2, E125fs","name":"TGFBR2, E125fs","y":1737,"data":[1737,0,0,0,0,0,0,0,0,0],"color":"#14a4e7"},
  // {"key":"TBRD7, 277_277del","name":"TBRD7, 277_277del","y":1653,"data":[0,1653,0,0,0,0,0,0,0,0],"color":"#14a4e7"},
  // {"key":"EGF, E1134fs","name":"EGF, E1134fs","y":952,"data":[0,0,952,0,0,0,0,0,0,0],"color":"#14a4e7"},
  // {"key":"MSH3, A60delinsAAAP","name":"MSH3, A60delinsAAAP","y":868,"data":[0,0,0,868,0,0,0,0,0,0],"color":"#14a4e7"},
  // {"key":"XPC, 34_35del","name":"XPC, 34_35del","y":843,"data":[0,0,0,0,843,0,0,0,0,0],"_colorIndex":3},
  // {"key":"NCOA3, 1243_1244del","name":"NCOA3, 1243_1244del","y":636,"data":[0,0,0,0,0,636,0,0,0,0],"_colorIndex":3},
  // {"key":"HDAC2, K11fs","name":"HDAC2, K11fs","y":541,"data":[0,0,0,0,0,0,541,0,0,0],"_colorIndex":3},
  // {"key":"NTRK1, P695S","name":"NTRK1, P695S","y":415,"data":[0,0,0,0,0,0,0,415,0,0],"_colorIndex":3},
  // {"key":"RUNX1, E422A","name":"RUNX1, E422A","y":374,"data":[0,0,0,0,0,0,0,0,374,0],"_colorIndex":3},
  // {"key":"PTEN, splicing","name":"PTEN, splicing","y":331,"data":[0,0,0,0,0,0,0,0,0,331],"_colorIndex":3}]};

  useEffect(() => {
    setLoading(false);

    if (!search.cancer) {
      let array = [];

      array.push(statistics.loadStudy());
      array.push(statistics.loadReported());
  
      const promises = array.map(item => (item));
  
      Promise.all(promises).then(res => {
        setLoading(true);
      });
    }
    else {
      setLoading(true);
    }

    return () => {
      statistics.resetStudy();
    };
  }, [search]);

  const divRef = useRef();

  const height =
    divRef.current &&
    divRef.current.offsetHeight -
      divRef.current.childNodes[0].childNodes[0].offsetHeight -
      30;

  const title = useMemo(() => {
    return (
      <>
        <span>Reported Variant Chart</span>
      </>
    );
  }, []);

  const onClick = useCallback(value => {
    patient.setSearch({
      study: value.category
    });
    history.push("/patient");
  }, []);

  const chart = useMemo(() => {
    return (
      <TableTemplate title={title} layoutStyle={layoutStyle}>
        {Object.keys(reportedChart).length > 0 && (
          <ColChart list={reportedChart} height={height}/>
         // { <ColumnChart
          //   series={reportedChart.series}
          //   onClick={onClick}
          //   height={height}
          //   category={reportedChart.category}
          //   legend={true}
          // /> }
        )}
      </TableTemplate>
    );
  }, [reportedChart, mathes, height]);

  return (
    <div ref={divRef}>
      <Loading loading={loading}>
        {chart}
      </Loading>
    </div>
  );

}

export default withRouter(observer(StudyColumnChart));
