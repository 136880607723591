import React, { useEffect, useState, useMemo } from "react";
import { withRouter } from "react-router-dom";
import Header from "components/organisms/Header";
import Loading from "components/molecules/LoadingComponent";
import Router from "./Router";
import useStore from "modules/hooks/useStore";
import { makeStyles } from "@material-ui/styles";
import background from "../assets/images/login/background.jpg";

const useStyles = makeStyles({
  back: {
    position: "fixed",
    width: "100%",
    height: "100%"
  },
  main: {
    backgroundImage: `url(${background})`,
    backgroundSize: "cover"
  },
  comm: {
    backgroundColor: "#cecbcb"
  }
});

function App(props) {
  const {
    location: { pathname }
  } = props;

  const { account } = useStore();
  const classes = useStyles();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    // storage token 확인 후 user를 load한다.
    async function loadUser(id_token) {
      await account.setAuthInHeader(id_token);
      account.setLoggedIn(!!id_token);
      try {
        account.loadUser();
      } catch (e) {
        console.log("app", e);
      }
    }

    const id_token =
      sessionStorage.getItem("token") || localStorage.getItem("token");

    const remaining = Math.floor(
      ((Date.now() - sessionStorage.getItem("date")) / (1000 * 60 * 60)) % 24
    );

    const isSession = remaining > 6;

    // console.log("remaining", remaining);

    if (!!id_token) {
      if (!isSession) loadUser(id_token);
      else account.logout();
    }
    setLoading(true);
  }, []);

  const router = useMemo(() => {
    return (
      <Loading loading={loading}>
        <Router />
      </Loading>
    );
  }, [loading]);

  let strPath = pathname;
  let  bFound = strPath.includes("patient-view");

  console.log("bFound patient-view : ", bFound);

  const isMain = pathname === "/" || pathname === "/login";
  const isAnnotation = (pathname === "/annotation-show") || (pathname.includes('annotation-geneVariant'));
  const isMatchView = pathname === "/match-view" || pathname === "/ins-user" || pathname === "/post-view" || bFound;

  let switchClass = `${classes.back} ${classes[isMain ? "main" : "comm"]}`;


  const header = useMemo(() => {
    if (isAnnotation) {
      return(
        <span></span>
      );
    }
    // else if (isMatchView) {
    //   return (
    //     <span></span>
    //   );
    // }
    else
    {

      return (
        <Header isMain={isMain} isMatchView={isMatchView} />
      );
    }
  }, [isAnnotation, isMain]);

  return (
    <div className={switchClass}>
      {header}      
      {router}
    </div>
  );
}

export default withRouter(App);
