import React from "react";
import useStore from "modules/hooks/useStore";
import { observer } from "mobx-react";
import { makeStyles } from "@material-ui/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";

const useStyles = makeStyles({
  root: {
    display: "flex",
    border: "1px solid #dedede",
    marginLeft: 20,
    marginRight: 20
  },
  div: {
    display: "flex",
    justifyItems: "center",
    alignItems: "center",
    height: 15,
    padding: "10px 5px",
    borderRight: "1px solid #dedede"
  },
  title_1500: {
    minWidth: 80
  },
  title: {
    whiteSpace: "nowrap",
    backgroundColor: "#f3f3f3",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: 50
  },
  content: {
    padding: "10px 20px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  lastDiv: {
    borderRight: "none"
  }
});

function NgsInfo() {
  const classes = useStyles();
  const matches = useMediaQuery("(min-width:1500px)");
  const {
    patient: {
      ngsInfo: { testId, panel, specimen, vcfFilter }
    }
  } = useStore();

  return (
    <div className={classes.root}>
      <div
        className={`${classes.div} ${classes.title} ${matches &&
          classes.title_1500}`}
      >
        Test ID
      </div>
      <div className={`${classes.div} ${classes.content}`}>{testId}</div>
      <div
        className={`${classes.div} ${classes.title} ${matches &&
          classes.title_1500}`}
      >
        Panel
      </div>
      <div className={`${classes.div} ${classes.content}`}>{panel}</div>
      <div
        className={`${classes.div} ${classes.title} ${matches &&
          classes.title_1500}`}
      >
        Specimen
      </div>
      <div className={`${classes.div} ${classes.content}`}>{specimen}</div>
      <div
        className={`${classes.div} ${classes.title} ${matches &&
          classes.title_1500}`}
      >
        VCF Filter
      </div>
      <div className={`${classes.div} ${classes.content} ${classes.lastDiv}`}>
        {vcfFilter}
      </div>
    </div>
  );
}

export default observer(NgsInfo);
