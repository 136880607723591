import React, { useState, useMemo, useEffect } from "react";
import CustomDialog from "../../atoms/ui/CustomDialog";
import MatchButton from "components/molecules/MatchButton";
import ClinicalSummary from "./ClinicalSummary";
import StudyDesign from "./StudyDesign";
import Criteria from "./Criteria";
import VisibilityIcon from "@material-ui/icons/Visibility";
import useStore from "modules/hooks/useStore";
import { withRouter } from "react-router-dom";
import { observer } from "mobx-react";

const ViewDetailsDialogPanel = () => {
  return (
    <>
      <div style={{ display: "flex" }}>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            width: "50%",
            paddingRight: 10
          }}
        >
          <ClinicalSummary />
          <StudyDesign />
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            width: "50%",
            paddingLeft: 10
          }}
        >
          <div
            style={{
              position: "absolute",
              right: 24,
              bottom: 66,
              top: 70,
              left: "50%"
            }}
          >
            <Criteria />
          </div>
        </div>
      </div>
    </>
  );
};

function ViewDetailsDialogButton({ history, match, children }) {
  const [open, setOpen] = useState(false);
  const {
    patient,
    patient: { clinicalTrial }
  } = useStore();

  const handleClickOpen = () => {
    patient.loadClinicalDetail(match.params.nctId);
    setOpen(true);
  };

  const onClose = () => {
    patient.resetClinicalDetail();
    setOpen(false);
  };

  const actions = useMemo(() => {
    return (
      <>
        <MatchButton onClick={onClose}>Cancel</MatchButton>
        {/* <MatchButton onClick={onSubmit}>Submit Review</MatchButton> */}
      </>
    );
  }, []);

  const title = useMemo(() => {
    return (
      clinicalTrial.briefTitle && (
        <span style={{ fontSize: "1rem" }} title={clinicalTrial.briefTitle}>
          <a
            href={clinicalTrial.ctgovLink}
            target="_blank"
            rel="noopener noreferrer"
          >
            {clinicalTrial.nctId}
          </a>{" "}
          {clinicalTrial.briefTitle.length > 130
            ? clinicalTrial.briefTitle.slice(0, 130) + "..."
            : clinicalTrial.briefTitle}
        </span>
      )
    );
  }, [clinicalTrial]);

  return (
    <>
      <MatchButton onClick={handleClickOpen} icon={<VisibilityIcon />}>
        {children}
      </MatchButton>
      <CustomDialog
        open={open}
        title={title}
        onClose={onClose}
        actions={actions}
        maxWidth={"md"}
      >
        <ViewDetailsDialogPanel />
      </CustomDialog>
    </>
  );
}

export default withRouter(observer(ViewDetailsDialogButton));
