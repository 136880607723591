import React, { Component } from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import drilldow from "highcharts/modules/drilldown";
drilldow(Highcharts);
class ColChart extends Component {
  state = {
    fooKey: 1
  };

  shouldComponentUpdate(nextProps) {
    // return false 하면 업데이트를 안함
    return this.props.list !== nextProps.list
    // || this.props.list !== undefined
    // return true;
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.list && nextProps.list.length) {
      return {
        fooKey: prevState.fooKey + 1
      };
    }

    return null;
  }


  render() {
    const { list , height} = this.props;

    let options;
    
    options = {
      chart: {
        marginTop: 35,
        backgroundColor: "#ffff0000",
        type: "column",
        height: height || (15 / 16) * 100 + "%"
      },
      title: {
        text: null
      },
      subtitle: {
        text: "",
        y: 15
      },
      credits: {
        enabled: false
      },
      xAxis: {
        categories: list.categories,
        // crosshair: true,
        labels: {
          align: 'right',
          //padding : 0,
          rotation: -45,
          x: 25
        }
      },
      yAxis: {
        gridLineDashStyle: "longdash",
        gridLineWidth: 0.5,
        // softMax: 1000,
        tickAmount: 6,
        labels: {
          formatter: function () {
            return this.value.toLocaleString();
          }
        },
        title: {
          text: "(Subjects)",
          align: "high",
          rotation: 0,
          offset: 0,
          y: -20,
          x: -5,
          style: {
            fontSize: "10px",
            fontFamily: '"Roboto", "Arial", "Helvetica", sans-serif',
            color: "#434343"
          }
        }
      },
      legend: {
        enable: true,
        itemDistance: 5,
        itemStyle: { "fontSize": "10px" },
        layout: 'vertical',
    
        align: 'right',
        verticalAlign: 'top',
        floating: true,

        labelFormatter: function () {
          if (this.name.length > 15) {
            return `${this.name.slice(0, 15)}...`
          }
          else {
            return this.name
          }
        }
      },    
      tooltip: {
        //pointFormat: 'Subjects : {this.series.data.name} <b>{point.y}</b><br/>',
        formatter: function () {
          let additionalString0 = '';
          let additionalString1 = '';
          let index = this.point.index;
          let data = this.series.userOptions.data;
          // console.log('index :', index);
          additionalString0 = `<br>${this.x}`;
          additionalString1 = '<br>' + data[index].name;
          
          return `Subjects : ${this.y} ${additionalString0} ${additionalString1}`;
        }
      },
      plotOptions: {
        // series: {
        //   stacking: "normal"
        // },
        column: {
          pointPlacement: "between",
          stacking: "normal",
          grouping: false,
        }
      },
      series: list.series
    }


    return <HighchartsReact
      highcharts={Highcharts}
      options={options}
      oneToOne={true}
      key={this.state.fooKey}
    />;
  }
}

export default ColChart;
