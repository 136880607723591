import React, { useMemo } from "react";
import AnnoRefSiteTabCurrent from "./AnnoRefSiteTab/AnnoRefSiteTabCurrent";
import AnnoRefSiteTabRef from "./AnnoRefSiteTab/AnnoRefSiteTabRef";
import AnnoVariantGuide from "./AnnoVariantGuide";

//panel 탭 메뉴구성
const panelObj = {
0: AnnoVariantGuide,
1: AnnoRefSiteTabCurrent,
2: AnnoRefSiteTabRef
};

function AnnoVariantTabPanel({ value }) {
  const panel = useMemo(() => {
    const Component = panelObj[value];

    return <Component />;
  });

  return panel;
}

export default AnnoVariantTabPanel;
