import React, { useCallback } from "react";
import CustomButtonList from "../../atoms/ui/CustomButtonList";
import useStore from "modules/hooks/useStore";
import { observer } from "mobx-react";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles({
  container: {
    marginTop: 5
  },
  title: {
    color: "#434343",
    margin: 10,
    fontSize: "1rem"
  },
  buttonContainer: {
    marginTop: 5
  }
});

const sampleTypeProperty = "sampleType";
const sampleTypeList = [
  { name: "All", key: {}, selected: undefined },
  { name: "Tissue", key: { [sampleTypeProperty]: "T" }, selected: "T" },
  { name: "Blood", key: { [sampleTypeProperty]: "B" }, selected: "B" }
];

function SampleTypeFilterList() {
  const {
    patient,
    patient: {
      search,
      search: { sampleType }
    }
  } = useStore();

  const classes = useStyles();

  const handleClick = useCallback(
    ({ key }) => {
      if (Object.keys(key).length <= 0) delete search.sampleType;
      patient.resetSearch({ ...search, ...key });
    },
    [search]
  );

  return (
    <div className={classes.container}>
      <span className={classes.title}>SampleType</span>
      <div className={classes.buttonContainer}>
        <CustomButtonList
          data={sampleTypeList}
          onClick={handleClick}
          selected={sampleType}
        />
      </div>
    </div>
  );
}

export default observer(SampleTypeFilterList);
