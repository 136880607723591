import React, { useMemo, useEffect, useState } from "react";
import CustomModal from "components/atoms/ui/CustomModal";
import MatchButton from "components/molecules/MatchButton";
import RefSiteTab from "./RefSiteTab";
import VariantProfile from "./VariantProfile";
import AlleleFrequecy from "./AlleleFrequency";
import TheraphyInformation from "./TheraphyInformation";
import Loading from "components/molecules/LoadingComponent";
import useStore from "modules/hooks/useStore";
import { observer } from "mobx-react";

const ActionablePanel = () => {
  return (
    <>
      <VariantProfile />
      <br />
      <AlleleFrequecy />
      <br />
      {/* <TheraphyInformation /> */}
      {/* <br /> */}
      <RefSiteTab />
    </>
  );
};

function ActionableDialog() {
  const {
    patient,
    comm,
    comm: { isActionableOpen, evidenceData, evidenceType }
  } = useStore();

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(false);
    if (isActionableOpen) {
      patient.loadEvidenceData().then(value => {
        setLoading(true);
      });
    }
    // else
    // {
    //   setLoading(true);
    // }
  }, [isActionableOpen, evidenceData]);

  const onClose = () => {
    comm.setActionableOpen(false);
  };

  const actions = useMemo(() => {
    return (
      <MatchButton onClick={onClose} style={{ width: 120, marginRight: 20 }}>
        Close
      </MatchButton>
    );
  }, []);

  const title = useMemo(() => {
    return (
      <span style={{ fontWeight: 500, fontSize: "1.3rem", marginLeft: 10 }}>{`${
        evidenceType === "ac" ? "Actionability" : "Pathogenicity"
      } Variant`}</span>
    );
  }, [evidenceType]);

  return (
    <>
      {isActionableOpen && (
        
        <CustomModal
          // open={open}
          title={title}
          // onClose={onClose}
          actions={actions}
          // maxWidth={"sm"}
        >
          <Loading loading={loading}>
            <ActionablePanel />
          </Loading>  
            
        </CustomModal>

      )}
    </> 
  );
}

export default observer(ActionableDialog);
