import { useEffect } from "react";

function useAssign(list, f, v) {
  useEffect(() => {
    if (list.length > 0)
      f(
        Object.assign(v, {
          ...JSON.parse(list[0].value)
        })
      );
  }, [list]);
}

export default useAssign;
