import React, { useMemo } from "react";
import useStore from "modules/hooks/useStore";
import { observer } from "mobx-react";

function ClinicalBriefTitle() {
  const {
    patient: { briefTitle }
  } = useStore();

  const content = useMemo(() => {
    return briefTitle;
  });

  return (
    <div style={{ padding: 10 }}>
      <h3>Title</h3>
      {content}
    </div>
  );
}

export default observer(ClinicalBriefTitle);
