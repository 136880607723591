import React from "react";
import { Route, Redirect, Switch } from "react-router-dom";
import { MyPageOfAccount, MyAccount, MyQuestion } from "pages";
import useStore from "modules/hooks/useStore";

function PrivateRoute({ component: Component, ...rest }) {
  const { account } = useStore();

  const remaining = Math.floor(
    ((Date.now() - sessionStorage.getItem("date")) / (1000 * 60 * 60)) % 24
  );

  const isSession = remaining <= 6;

  // console.log("remaining", remaining);

  if (!isSession) account.logout();

  return (
    <Route
      {...rest}
      render={props =>
        sessionStorage.getItem("token") ||
        localStorage.getItem("token") ||
        isSession ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: "/login",
              state: { from: props.location }
            }}
          />
        )
      }
    />
  );
}

function MyPageRouter({ match: { url } }) {
  return (
    <Switch>
      <PrivateRoute path={`${url}/qna/:id`} component={MyQuestion} />
      <PrivateRoute path={`${url}/qna`} component={MyQuestion} />
      <PrivateRoute path={`${url}/account`} component={MyAccount} />
      <PrivateRoute path={`${url}`} component={MyPageOfAccount} />
    </Switch>
  );
}

export default MyPageRouter;
