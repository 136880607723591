import React, { useState, useRef, useMemo, useEffect } from "react";
import Button from "@material-ui/core/Button";
import Popover from "@material-ui/core/Popover";
import { makeStyles } from "@material-ui/core/styles";

function Menu({ children: { label, item: Item }, handleClose }) {
  return <Item handleClose={handleClose}>{label}</Item>;
}

function CustomPopover({ useCustomStyle, children, menus }) {
  const button = useRef();
  const classes = useCustomStyle && useCustomStyle();
  const [anchorEl, setAnchorEl] = useState(null);
  const [width, setWidth] = useState(0);
  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const buttonWidth = button.current ? button.current.clientWidth : 0;

  useEffect(() => {
    // console.log(width);
    setWidth(buttonWidth);
  }, [buttonWidth]);

  const useStyle = makeStyles(theme => ({
    paper: {
      width
    }
  }));

  const customClasses = useStyle();

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <>
      <Button
        aria-describedby={id}
        variant="contained"
        onClick={handleClick}
        className={classes && classes.button}
        ref={button}
      >
        {children || ""}
      </Button>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center"
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center"
        }}
        classes={{
          paper: customClasses.paper
        }}
      >
        {menus.map((menu, i) => (
          <Menu key={i} handleClose={handleClose}>
            {menu}
          </Menu>
        ))}
      </Popover>
    </>
  );
}

export default CustomPopover;
