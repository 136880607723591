import React, { Component } from "react";
import { observer, inject } from "mobx-react";
import { withStyles } from "@material-ui/core/styles";
import axios from "axios";
import AnnotationShow from "components/organisms/Annotation/AnnotationShow"; 
import PatientRouter from "shared/PatientRouter";
import FollowPatient from "components/molecules/FollowPatientButton";

import WriteClinicalReviewButton from "components/molecules/WriteClinicalReviewButton";
import CheckPermission from "components/lib/CheckPermission";
import { queryString } from "dependencyLibrary";
import PatientView from "./PatientView";
import Loading from "components/molecules/LoadingComponent";

import CustomObjDialog from "components/atoms/CustomObjDialog";
import SampleIdContent from "components/atoms/CustomObjDialog/SampleIdContent";

const styles = theme => ({

  title: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center"
  }
})



@inject("account", "comm", "patient")
@observer
class MatchView extends Component {
    
  state = {
    loading: false,
    messageData: "",
    messageResponse: "Please Wait ...",
    isNotFound: false,
    bInsInit : false,
    person_id : 0
  };

  componentDidMount() {

    // window.addEventListener("message", this.handleMessage, true);

    const { history, match, location, account, patient, comm } = this.props;

    const query = queryString.parse(location.search);

    // console.log("match-view : match ", match);
    // console.log("match-view : location ", location);
    // console.log("match-view : query ", query);

    patient.isMatchView = true;

    let id = 110319;
    let ipAddress = "127.0.0.1";

    // ok start
    // if (match.params.type != "ins") return;

    // let institution = query.i;
    // let sampleID = query.s;
    // ok end

    //try start
    let institution = "KAA";
    let sampleID = match.params.type;

    // let paramType = match.params.type;

    // if (paramType == "ins") {
    //   institution = query.i;
    //   sampleID = query.s;
    // } 
    // else {
    //   let subParam = paramType.substr(0,2);
      
    //   if (subParam == "AA") {
    //     institution = "KAA";
    //     sampleID = paramType.substr(2);
    //   }
    //   else if (subParam == "GR") {
    //     institution = "KGR";
    //     sampleID = paramType.substr(2);
    //   }
    //   else if (subParam == "AS") {
    //     institution = "KAS";
    //     sampleID = paramType.substr(2);
    //   }
    //   else {
    //     return;
    //   }
      
    //   console.log("Sample ID : ", sampleID);
    // }
    //try end

    let annamNum = sampleID;

    // this.getIpClient().then(responseIp => {     
    //   ipAddress = responseIp;
   
    //   account.insUserLogin({ login: institution, sampleID: annamNum, ipAddress, rememberMe: false})
    //   .then(success => {

    //     if (success) {

    //       id = comm.subjectId;

    //       if (id == "NotFound") {
    //         this.setState({
    //           loading: true,
    //           bInsInit: false,
    //           isNotFound: true,
    //           messageResponse: annamNum
    //         });
    //       }
    //       else {

    //         account.getTestPortal()
    //         .then(bSuccess => {
      
    //           if (bSuccess) {
    //             // console.log("getTestPortal: ", success);
      
    //             this.setState({
    //               loading: true,
    //               bInsInit: false,
    //               isNotFound: true,
    //               messageResponse: "getTestPortal success"
    //             });
    //           }
    //           else {
    //             this.setState({
    //               loading: true,
    //               bInsInit: false,
    //               isNotFound: true,
    //               messageResponse: "getTestPortal failed"
    //             });                
    //           }
    //         } );
    //       }
    //     }
    //     else {
    //       this.setState({
    //         loading: true,
    //         bInsInit: false,
    //         messageResponse: "Invalid Local Site"
    //       });
    //     }

    //   } );
    // });

    this.getIpClient().then(responseIp => {     
      ipAddress = responseIp;
   
      account.insUserLogin({ login: institution, sampleID: annamNum, ipAddress, rememberMe: false})
      .then(success => {

        if (success) {

          id = comm.subjectId;

          if (id == "NotFound") {
            this.setState({
              loading: true,
              bInsInit: false,
              isNotFound: true,
              messageResponse: annamNum
            });
          }
          else {

            patient.person.id = id;
        
            this.setState({
              loading: true,
              bInsInit: true,
              person_id:id
            });
          }
        }
        else {
          this.setState({
            loading: true,
            bInsInit: false,
            messageResponse: "Invalid Local Site"
          });
        }

      } );
    });

    
  }

  componentWillUnmount() {
    // window.removeEventListener("message", this.handleMessage);
  }

  // componentWillUpdate(nextProps, nextState) {
  // }

  componentDidUpdate(prevProps, prevState, snapshot) {
      // if (this.props.localizedSite.loading) this.props.localizedSite.setLoading(false);
      const { history } = this.props;
      const {bInsInit, person_id, messageResponse} = this.state;
  
      if (bInsInit) {
        history.push(`/patient-view/${person_id}/summary`);
      }
  }
  
  onClose = () => {
    this.setState({
      isNotFound: false
    });
  };

  // handleMessage = event => {
  //   const { history, match, account } = this.props;

    
  //   if (event.origin !== "http://localhost:3000/match-view/") return;
    

  //   if (event.institution) {

  //     console.log("message Data : ", event.institution);

  //     let id = 110315;

  //     // let sampleID = "22";
  //     // let institution="KAA";      
      
  //     let sampleID = event.sampleID;
  //     let institution=event.institution;

  //     account.insUserLogin({ login: institution, sampleID, rememberMe: false })
  //     .then(() => {
  //       history.push(`${match.url}/${id}/summary`);
  //       this.setState({
  //         messageData: event.data
  //       });
  //     } );

      
  //     // this.setState({
  //     //   messageData: event.data
  //     // });
  //   }
  // };

    async getIpClient() {

    // const { history, comm } = this.props

    // comm.setAnnotationOpen(false);

    // comm.setAnnotationType("SNV");
    // comm.setAnnotationQueryData({versionTab : 'V2'}); //'2020-05'
    // comm.setAnnotationQueryData({version : '2021-05'});
    // comm.setAnnotationQueryData({variantType : 'SNV'});
    // comm.setAnnotationQueryData({gene : ''});
    // comm.setAnnotationQueryData({subVariantType : 'amp'});
    // comm.setAnnotationQueryData({gene2 : ''});
    // comm.setAnnotationQueryData({hgvsp : ''});

    // props.history.push(`${props.match.url}/${value.id}/summary`);    
    // history.push(`${props.match.url}/11038/summary`);
    // history.push("/match-view");
    // history.push(`${match.url.replace(match.params.tab, newValue)}${url}`);

    try {
      const response = await axios.get('https://api.ipify.org?format=json');
      // console.log("Ipaddress", response);
      return response.data.ip;
      // const response = await axios.get('https://ipinfo.io/json');
      // // console.log("Ipaddress", response);
      // return response.ip;
    } catch (error) {
      console.error(error);
      return "0.0.0.1";
    }


    // // 브라우저마다 prefix가 달라 아래와 같이 처리한다.
    // var RTCPeerConnection = window.RTCPeerConnection || window.webkitRTCPeerConnection || window.mozRTCPeerConnection || window.msRTCPeerConnection;
    // // RTCPeerConnection 객체 생성 
    // var rtc = new RTCPeerConnection();
    // // 임의의 이름으로 채널 생성
    // rtc.createDataChannel("TEMP");
    // // // 이벤트 핸들러 설정, ice 후보가 감지 되었을때 호출됩니다.
    // // // 원래는 이곳에서 해당 candidate를 현재 커넥션에 연결해야 하나, ip를 알아내는 것이 목적이기 때문에 별다른 행동을 하지 않습니다.
    // rtc.onicecandidate = function(iceevent) {
    //   if( iceevent && iceevent.candidate && iceevent.candidate.candidate ) {
    //     var r = /\b\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}\b/;
    //     var t = iceevent.candidate.candidate.match(r);
    //     console.log("Ipaddress : ", t[0]); //IP
    //   }
    // }
    // //createOffer를 호출하면 연결하고자 하는 SDP가 생성됩니다. createOffer()의 결과로 offer가 생성되는데 이를 다시 setLocalDescription를 통해 설정하면
    // //로컬의 엔드포인트가 생성이 완료됩니다. 이렇게 생성된 Description을 연결하고자 하는 원격지에 전달해야 하나 이역시 해당 예제에서는 필요가 없습니다.
    // setLocalDescription후에 onicecandidate가 호출됩니다.
    // rtc.createOffer().then(offer=>rtc.setLocalDescription(offer));

  }

  render() {

    const {match, classes} = this.props;
    const {loading, messageResponse, isNotFound} = this.state;

    // this.initAnnotation();

    return (
      <div>
        <Loading loading={loading}>
          <CustomObjDialog
            open={this.state.isNotFound}
            onClickOpen={this.onClose}
            messageResponse={this.state.messageResponse}
            CustomDialogContent={SampleIdContent}
          />

          {/* <span> {messageResponse} </span> */}
        </Loading>  
      </div>
    );

    // if (match.params.type === "ins") {
    //   return (
    //     <div>
    //       <span> MatchView page </span>
    //     </div>
    //   );
    // }
    // else {
    //   return (
    //     <div>
    //       <PatientView />
    //     </div>
    //   );
    // }

    // const title = () => {
    //   return (
    //     <span style={{ fontWeight: 500, fontSize: "2.0rem", marginLeft: 0, textDecoration: "underline" }}>
    //       MatchView Search </span>
    //   );
    // };

    // return (
    //    <div>
    //     <div className={classes.title}>
    //       <div style={{ display: "flex" }}>
    //         {/* <img src={PatientIcon} alt="" /> CHANGE */}
    //         <div style={{ marginLeft: 5 }}>PATIENT</div>
    //       </div>
    //       <div style={{ display: "flex" }}>
    //         <FollowPatient />
    //         <CheckPermission permission={"ROLE_INS_USER"}>
    //           <WriteClinicalReviewButton>
    //             Write Clinical Review
    //           </WriteClinicalReviewButton>
    //         </CheckPermission>
    //       </div>
    //     </div>
    //     <>
    //       <PatientRouter match={match} />
    //     </>

    //    </div> 
      

    // );
  }
}

export default withStyles(styles)(MatchView);