import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import MatchButton from "components/molecules/MatchButton";

const styles = theme => ({
  row: {
    display: "flex",
    height: "30px",
    borderLeft: "solid 1px #dedede",
    borderRight: "solid 1px #dedede",
    borderTop: "solid 1px #dedede"
  },
  lastRow: {
    display: "flex",
    height: "30px",
    border: "solid 1px #dedede"
  },
  name: {
    width: "40%",
    backgroundColor: " #f3f3f3",
    lineHeight: 2.5,
    paddingLeft: "5px"
  },
  key: {
    width: "60%",
    borderLeft: " solid 1px #dedede",
    backgroundColor: "#ffffff",
    lineHeight: 2.5,
    paddingLeft: "5px"
  },
  input: {
    width: "60%",
    border: 0
  },
  button: {
    float: "right"
  },
  buttonTitle: {
    display: "flex",
    justifyContent: "space-between",
    // alignItems: "center",
    height: 30
  }
});

class AccountTable extends Component {
  change = e => {
    const { onChange } = this.props;
    onChange(e.target.value, e.target.name);
  };

  render() {
    const { classes, user, tableList, state, onSubmit } = this.props;
    let list = [];

    if (user) {
      const len = tableList.content.length - 1;
      tableList.content.forEach((c, i) => {
        if (c.key === "input") {
          list.push(
            <div
              key={c.name}
              className={len > i ? classes.row : classes.lastRow}
            >
              <div className={classes.name}>{c.name}</div>
              <input
                className={classes.input}
                type={"password"}
                placeholder={"........"}
                value={state[c.state]}
                name={[c.state]}
                onChange={this.change}
                required
              ></input>
            </div>
          );
        } else {
          list.push(
            <div
              key={c.name}
              className={len > i ? classes.row : classes.lastRow}
            >
              <div className={classes.name}>{c.name}</div>
              <div className={classes.key}>{user[c.key]}</div>
            </div>
          );
        }
      });
    }

    return (
      <div>
        <form onSubmit={onSubmit}>
          <div className={classes.buttonTitle}>
            <span style={{ fontWeight: 500 }}>{tableList.title}</span>
            <div className={classes.button}>
              {onSubmit ? (
                <MatchButton style={{ width: 150, height: 20 }} type="submit">
                  Change
                </MatchButton>
              ) : null}
            </div>
          </div>
          <div>{list}</div>
        </form>
      </div>
    );
  }
}

export default withStyles(styles)(AccountTable);
