import React, { useRef } from "react";

import SamplePrimary from "./SamplePrimary";
import GenderAge from "./GenderAge";
import TableTemplate from "components/organisms/TableTemplate";
import LeftTabPanel from "components/organisms/LeftTabPanel/LeftTabPanel";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles({
  layout: {
    height: "100%",
    position: "absolute",
    top: 370,
    right: 0,
    left: 0,
    width: "100%"
  },
  panelmargin: {
    margin: 0,
    fontSize: "1.2rem",
    width: "100%"
    // margin: "3px 0.7vw 0.5vw 0.7vw"
  },
});

// 펼침 오무림을 위한 변경 by jdy, 2021-01

function FilterButtonList() {
  const classes = useStyles();

  return (
    <div className={classes.layout} >
      <div className={classes.panelmargin}>
        <LeftTabPanel
          title={"Sample / Primary"}
          Detail={SamplePrimary}
          expandedInit={true}
        />
      </div>
      <div className={classes.panelmargin}>
        <LeftTabPanel
          title={"Demography"}
          Detail={GenderAge}
          expandedInit={true}
        />
      </div>
    </div>
  );
}

export default FilterButtonList;
// return (
//   <div className={classes.container}>
//     <div className={classes.layout1}>
//       {/*SampleType, PrimarySite 버튼 추가 - 박남준 */}
//       <TableTemplate
//         title={"SampleType / PrimarySite"}
//         bodyPadding={"none"}
//         bodyStyle={bodyStyle1}
//         layoutStyle={layoutStyle1}
//         borderHide = {true}
//         headStyle={{ backgroundColor: "rgb(237, 238, 241)", border: "1px solid #dedede" }}
//       >
//         <div className={classes.bodyContent}>
//           <SampleTypeFilterList />
//           <PrimarySiteTypeFilterList />
//           {/* <SexFilterButtonList />
//           <AgeFilterButtonList /> */}
//         </div>
//       </TableTemplate>
//     </div>
//     <div className={classes.layout2}>
//       <TableTemplate
//         title={"Demography"}
//         bodyPadding={"none"}
//         bodyStyle={bodyStyle2}
//         layoutStyle={layoutStyle2}
//         borderHide = {true}
//         headStyle={{ backgroundColor: "rgb(237, 238, 241)", border: "1px solid #dedede" }}
//       >
//         <div className={classes.bodyContent}>
//           {/* <SampleTypeFilterList />
//           <PrimarySiteTypeFilterList /> */}
//           <SexFilterButtonList />
//           <AgeFilterButtonList />
//         </div>
//       </TableTemplate>
//     </div>
//   </div>
// );
