// import { extendObservable, computed } from "mobx";

class CancerSelectModel {
  constructor(data, i) {
    this.index = i;
    this.value = this;
    this.label = data.valueAsConceptName;
  }
}

export default CancerSelectModel;
