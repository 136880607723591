import { extendObservable, computed } from "mobx";

class PanelTableModel {
  constructor(data) {
    this.id = Date.now();
    extendObservable(this, data);
  }

  @computed
  get repeat() {
    return this.repeatSpecimenNumber === null ? 1 : this.repeatSpecimenNumber;
  }

  @computed
  get patient() {
    return this.personCount; // this.repeatPersonCount
  }

  @computed
  get specimen() {
    return this.specimenCount;
  }
}

export default PanelTableModel;
