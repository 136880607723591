import React from "react";
import PersonTableOfFollow from "components/organisms/PersonTableOfFollow";

function MyPage() {
  return (
    <>
      <PersonTableOfFollow />
    </>
  );
}

export default MyPage;
