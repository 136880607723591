import React, { useEffect, useMemo, useState } from "react";
import { makeStyles } from "@material-ui/styles";
import CustomTab from "components/atoms/ui/CustomTab";
import usePersonStore from "modules/hooks/usePersonStore";
import useStore from "modules/hooks/useStore";
import { observer } from "mobx-react";
import NgsTabPanel from "./NgsTabPanel";
import CustomAlert from "components/atoms/ui/CustomAlert";
import MatchButton from "components/molecules/MatchButton";

const useStyles = makeStyles({
  tabContainer: {
    width: "100%",
    height: "100%"
  },
  container: {
    width: "100%",
    backgroundColor: "#fff",
    position: "absolute",
    top: 60,
    bottom: 0,
    borderTop: "1px solid #c6c6c6"
  },
  tabsRoot: {
    minHeight: 60,
    backgroundColor: "#ffffff"
  },
  tabRoot: {
    color: "#b4b4b4",
    minHeight: 60
  },
  panelRoot: {
    position: "absolute",
    top: 20,
    bottom: 20,
    right: 0,
    left: 0
  },
  selected: {
    color: "#434343",
    fontWeight: 600
  },
  indicator: {
    backgroundColor: "#14a4e7",
    height: 5
  },
  scrollButtons: {
    width: 20
  },
  wrapper: {
    fontSize: "0.9rem"
  }
});

function NgsTab({ history, match, location }) {
  const {
    patient,
    patient: { ngsTabs,  ngsGeneName}
  } = useStore();

  const [isNgsGeneName, setIsNgsGeneName] = useState(false);

  const handleChange = (e, newValue) => {
    history.push(`${match.url.replace(match.params.secId, newValue)}/snv`);
  };

  useEffect(() => {
    if (ngsTabs.length > 0) {
      const [{ secId }] = ngsTabs.filter(f => {
        return f.value === match.params.secId;
      });

      patient.loadNgs({
        id: match.url.split("/")[2],
        secId,
        variantType: location.pathname.split("/").pop()
      });

      setIsNgsGeneName(ngsGeneName == "nullHgvsc");
    }

    // return () => {
    //   patient.setNgsMounted(true);
    // };
  }, [ngsTabs, match.params.secId]);

  const defaultValue = match.params.secId;

  const onClose = () => {
    setIsNgsGeneName(false);
  };

  const actions = useMemo(() => {
    return (
      <MatchButton onClick={onClose} style={{ width: 120, marginRight: 20 }}>
        Close
      </MatchButton>
    );
  }, []);

  return (
    <>
      {isNgsGeneName && (

        <CustomAlert
          popupWidth= "600px"
          popupHeight= "300px"
          title="Case : hgvsc is null"
          // onClose={onClose}
          actions={actions}
          // maxWidth={"sm"}
        >
          <div>
            This data can not show because Hgvsc is null.
          </div>  
          
        </CustomAlert>
  
      )}

      <CustomTab
        columns={ngsTabs}
        variant="scrollable"
        onChange={handleChange}
        useStyles={useStyles}
        defaultValue={defaultValue}
      >
        <NgsTabPanel value={defaultValue} match={match} />
      </CustomTab>
    </>
  );
}

export default observer(NgsTab);
