import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/styles";
import CustomTab from "components/atoms/ui/CustomTab";
import usePersonStore from "modules/hooks/usePersonStore";
import useStore from "modules/hooks/useStore";
import ClinicalTabPanel from "./ClinicalTabPanel";
import { observer } from "mobx-react";

const useStyles = makeStyles({
  tabContainer: {
    width: "100%",
    height: "100%"
  },
  container: {
    width: "100%",
    backgroundColor: "#fff",
    position: "absolute",
    top: 60,
    bottom: 0,
    borderTop: "1px solid #c6c6c6",
    overflow: "auto"
  },
  tabsRoot: {
    minHeight: 60,
    backgroundColor: "#ffffff"
  },
  tabRoot: {
    color: "#b4b4b4",
    minHeight: 60
  },
  panelRoot: {
    position: "absolute",
    top: 20,
    bottom: 20,
    right: 20,
    left: 20
  },
  selected: {
    color: "#434343",
    fontWeight: 500
  },
  indicator: {
    backgroundColor: "#14a4e7",
    height: 5
  },
  scrollButtons: {
    width: 20
  }
});

function ClinicalMatchTab({ history, match }) {
  const { patient } = usePersonStore();
  const {
    patient: { cts }
  } = useStore();

  const handleChange = (e, newValue) => {
    history.push(`${match.url.replace(match.params.nctId, newValue)}`);
  };

  useEffect(() => {
    patient.loadClinicalTrial(match.params.nctId);
  }, [match.params.nctId]);

  const defaultValue = match.params.nctId;

  return (
    <>
      <CustomTab
        columns={cts}
        variant="scrollable"
        onChange={handleChange}
        useStyles={useStyles}
        defaultValue={defaultValue}
      >
        <ClinicalTabPanel value={defaultValue} />
      </CustomTab>
    </>
  );
}

export default observer(ClinicalMatchTab);
