import { axios } from "dependencyLibrary";

class AccountRepository {
  version = "/v2";

  getTestPortal = () => {
    return axios.request({ method: "GET", url: "/ins_user/test_portal"});
  };

  insUserLogin = data => {
    return axios.request({ method: "POST", url: "/ins_user", data });
  };

  requestLogin = data => {
    return axios.request({ method: "POST", url: "/authenticate", data });
  };

  loadUser = () => {
    return axios.request({ method: "GET", url: "/accounts" });
  };

  loadUserList = data => {
    return axios.request({ method: "GET", url: `/users${data}` });
  };

  activate = data => {
    return axios.request({ method: "PUT", url: `/users/activate`, data });
  };

  loadAuthList = () => {
    return axios.request({ method: "GET", url: `/users/authorities` });
  };

  fetchInsList = data => {
    return axios.request({ method: "GET", url: `/user/institutes${data}` });
  };

  loadInsList = data => {
    return axios.request({ method: "GET", url: `/institutes${data}` });
  };

  loadLogList = data => {
    return axios.request({ method: "GET", url: `/managements/audits${data}` });
  };

  changePassword = data => {
    return axios.request({
      method: "POST",
      url: "/accounts/me/change_password",
      data
    });
  };

  changeUser = data => {
    return axios.request({ method: "PUT", url: "/users", data });
  };

  changeIns = (id, data) => {
    return axios.request({ method: "PUT", url: `/institutes/${id}`, data });
  };

  creatUser = data => {
    return axios.request({ method: "POST", url: `/users`, data });
  };

  registerUser = data => {
    return axios.request({ method: "POST", url: `/accounts/register`, data });
  };

  creatIns = data => {
    return axios.request({ method: "POST", url: `/institutes`, data });
  };

  deleteUser = data => {
    return axios.request({ method: "DELETE", url: `/users/${data}` });
  };

  deleteIns = id => {
    return axios.request({ method: "DELETE", url: `/institutes/${id}` });
  };

  resrtPassword = data => {
    return axios.request({
      method: "POST",
      url: `/accounts/reset_password/init`,
      data
    });
  };

  resrtPasswordFinish = data => {
    return axios.request({
      method: "POST",
      url: `/accounts/reset_password/finish`,
      data
    });
  };

  fetchQuestionList = query => {
    return axios.request({
      method: "GET",
      url: `/qaBoard${query}`,
      ver: this.version
    });
  };

  fetchMyQuestionList = query => {
    return axios.request({
      method: "GET",
      url: `/qaBoard/user${query}`,
      ver: this.version
    });
  };

  insertQuestion = data => {
    return axios.request({
      method: "POST",
      url: `/qaBoard`,
      ver: this.version,
      data
    });
  };

  fetchQuestion = query => {
    return axios.request({
      method: "GET",
      url: `/qaBoard/detail${query}`,
      ver: this.version
    });
  };

  fetchQuestionComment = query => {
    return axios.request({
      method: "GET",
      url: `/qaBoard/comment${query}`,
      ver: this.version
    });
  };

  comment = ({ method, data }) => {
    return axios.request({
      method,
      url: "/qaBoard/comment",
      ver: this.version,
      data
    });
  };

  fetchNotifications = query => {
    return axios.request({
      method: "GET",
      url: `/notifications${query}`,
      ver: this.version
    });
  };

  modifyNotifications = ({ payload, all }) => {
    return axios.request({
      method: "PUT",
      url: `/notifications${all || ""}`,
      ver: this.version,
      data: payload
    });
  };

  fetchAdminStatus = ({ type }) => {
    return axios.request({
      method: "GET",
      url: `/admin/${type}/stat`,
      ver: this.version
    });
  };

  fetchVisitorCount = () => {
    return axios.request({
      method: "GET",
      url: `/visitor`,
      ver: this.version
    });
  };

  setAuthInHeader = token => {
    axios.setAuthInHeader(token);
  };

  uploadExcelTemplate = ({ type, data }) => {
    return axios.request({
      method: "POST",
      url: `/excel/${type}`,
      ver: this.version,
      data
    });
  };

  downloadExcelTemplate = ({ type }) => {
    return axios.request({
      method: "GET",
      url: `/excel/${type}/download`,
      responseType: "blob",
      headers: {
        Accept: "application/vnd.ms-excel"
      },
      ver: this.version
    });
  };

  fetchSyncData = ({ search, type, system }) => {
    const queryType = type ? `/${type}` : "";
    const querySystem = system ? `/${system}` : "";

    return axios.request({
      method: "GET",
      url: `/updateHistory${queryType}${querySystem}${search}`
    });
  };

  updateCdm = data => {
    return axios.request({
      method: "PUT",
      url: "/cdm/update",
      // ver: this.version,
      data
    });
  };

  fetchApiKey = type => {
    return axios.request({
      method: "GET",
      url: `/key-store/${type}`
    });
  };

  modifyApiKey = ({ type, id, payload }) => {
    return axios.request({
      method: "PUT",
      url: `/key-store/${type}/${id}`,
      data: payload
    });
  };
}

export default new AccountRepository();
