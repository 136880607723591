import { axios } from "dependencyLibrary";

class PatientRepository {
  URL = "/persons";
  version = "/v2";

  fetchPersons = filter => {
    return axios.request({
      method: "GET",
      url: `${this.URL}${filter}`,
      ver: this.version
    });
  };

  fetchPerson = id => {
    return axios.request({
      method: "GET",
      url: `${this.URL}/${id}`,
      ver: this.version
    });
  };

  fetchStat = id => {
    return axios.request({
      method: "GET",
      url: `${this.URL}/${id}/stats`,
      ver: this.version
    });
  };

  fetchTreatments = id => {
    return axios.request({
      method: "GET",
      url: `${this.URL}/${id}/treatments`,
      ver: this.version
    });
  };

  fetchNgsHeader = id => {
    return axios.request({
      method: "GET",
      url: `${this.URL}/${id}/ngs/header`,
      ver: this.version
    });
  };

  fetchNgsSummary = id => {
    return axios.request({
      method: "GET",
      url: `${this.URL}/${id}/ngs/summary`,
      ver: this.version
    });
  };

  fetchNgs = ({ id, secId, variantType }) => {
    return axios.request({
      method: "GET",
      url: `${this.URL}/${id}/ngs/${secId}/all`,
      ver: this.version
    });
  };

  fetchCtsSummary = id => {
    return axios.request({
      method: "GET",
      url: `${this.URL}/${id}/cts/summary`,
      ver: this.version
    });
  };

  fetchCts = id => {
    return axios.request({
      method: "GET",
      url: `${this.URL}/${id}/cts`,
      ver: this.version
    });
  };

  fetchClinicalTrial = ({ id, nctId }) => {
    return axios.request({
      method: "GET",
      url: `${this.URL}/${id}/cts/${nctId}`,
      ver: this.version
    });
  };

  fetchClinicalDetail = ({ id, nctId }) => {
    return axios.request({
      method: "GET",
      url: `${this.URL}/${id}/cts/${nctId}/detail`,
      ver: this.version
    });
  };

  search = filter => {
    return axios.request({
      method: "GET",
      url: `/search${filter}`,
      ver: this.version
    });
  };

  fetchReview = id => {
    return axios.request({
      method: "GET",
      url: `/clinical/reviews/${id}`,
      ver: this.version
    });
  };

  review = data => {
    return axios.request({
      method: "POST",
      url: `/clinical/reviews`,
      ver: this.version,
      data
    });
  };

  notice = id => {
    return axios.request({
      method: "DELETE",
      url: `/clinical/reviews/notices/${id}`,
      ver: this.version
    });
  };

  fetchStatistics = filter => {
    return axios.request({
      method: "GET",
      url: `${this.URL}/statistics${filter}`,
      ver: this.version
    });
  };

  fetchCancerChart = filter => {
    return axios.request({
      method: "GET",
      url: `/cancer${this.URL}/cancertype${filter}`,
      ver: this.version
    });
  };

  fetchVariantChart = filter => {
    return axios.request({
      method: "GET",
      url: `/variant${this.URL}/byvariant${filter}`,
      ver: this.version
    });
  };

  fetchInsChart = filter => {
    return axios.request({
      method: "GET",
      url: `/institute${this.URL}/cases${filter}`,
      ver: this.version
    });
  };

  fetchStudies = filter => {
    return axios.request({
      method: "GET",
      url: `/study${this.URL}/statistics${filter}`,
      ver: this.version
    });
  };

  changeStudy = data => {
    return axios.request({
      method: "PUT",
      url: `/study${this.URL}`,
      ver: this.version,
      data
    });
  };

  fetchRelevantVariantList = ({ secId, id }) => {
    return axios.request({
      method: "GET",
      url: `${this.URL}/${id}/ngs/${secId}/variants`,
      ver: this.version
    });
  };

  fetchInsList = () => {
    return axios.request({
      method: "GET",
      url: `/institutes/all`,
      ver: this.version
    });
  };

  fetchFollowPatient = ({ id, search }) => {
    const url = id ? `/${id}` : `${search}`;
    return axios.request({
      method: "GET",
      url: `/follow/patients${url}`
    });
  };

  followPatient = (isFollow, data) => {
    return axios.request({
      method: isFollow ? "DELETE" : "PUT",
      url: `/follow/patients`,
      data
    });
  };

  fetchEvidence = (type, query) => {
    return axios.request({
      method: "GET",
      url: `/annotations/${type}${query}`,
      ver: this.version
    });
  };
}

export default new PatientRepository();
