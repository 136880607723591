import React, { Component } from "react";
import { Router } from "react-router-dom";
import { history } from "dependencyLibrary";
import { Provider } from "mobx-react";
import Store from "modules/store/Store";
import App from "shared/App";

const store = new Store();

class Root extends Component {
  render() {
    return (
      <Provider {...store}>
        <Router history={history}>
          <App />
        </Router>
      </Provider>
    );
  }
}

export default Root;
