import React from "react";
import useStore from "modules/hooks/useStore";
import ac from "assets/images/button/ac.svg";
import pa from "assets/images/button/pa.svg";

const icon = {
  ac,
  pa
};

function AnnotationDialogButton({ children, type, ...rest }) {
  const { comm } = useStore();

  const handleClickOpen = () => {
    comm.setAnnotationType(type);
    comm.setAnnotationData(rest);
    comm.setAnnotationOpen(true);
    let queryVersion = `${comm.annotationData.version}`;
    let query1 = `${comm.annotationData.gene}`;
    let query2 = `${comm.annotationData.variant}`;
    let query3 = `${comm.annotationData.variantType}`;
    let query4 = `${comm.annotationType}`;
    // let query = `${query1}/${query2}/${query3}/${query4}`;
    let query = `${query4}?` + '&version=' + queryVersion
              + '&gene=' + query1 + '&variant=' + query2 + '&variantType=' + query3;

    console.log('anno type : ', `${query}`);

    window.open(`/annotation-geneVariant/${query}`, '_blank');
  };
  
  return (
    <button
      onClick={handleClickOpen}
      style={{ border: "hidden", background: "none", cursor: "pointer" }}
    >
      {children}
      <img
        src={icon[type]}
        alt=""
        title={type === "ac" ? "Actionability" : "Pathogenicity"}
      />
    </button>
  );
}

export default AnnotationDialogButton;
