import React, { useEffect, useMemo, useState, useCallback } from "react";
import TableTemplate from "../../organisms/TableTemplate";
import CustomMenuList from "../../atoms/ui/CustomMenuList";
import useStore from "modules/hooks/useStore";
import { makeStyles } from "@material-ui/styles";
import { observer } from "mobx-react";

const bodyStyle = {
  position: "absolute",
  top: 59,
  bottom: 0,
  left: 0,
  right: 0,
  // borderTop: "1px solid #dedede",
  borderLeft: "1px solid #dedede",
  borderBottom: "1px solid #dedede",
  borderRight: "1px solid #dedede",
  backgroundColor: "#fff",
  overflow: "auto"
};

const readToggle = {
  height: 14,
  border: "none",
  background: "none",
  textDecoration: "underline",
  cursor: "pointer",
  color: "rgb(125, 125, 125)",
  fontSize: "1rem"
};

const useStyle = makeStyles({
  card: {
    height: 55,
    color: "#7d7d7d",
    backgroundColor: "#f6f6f6",
    boxShadow: "0 1px 1px 0 rgba(163, 163, 163, 0.5)",
    padding: 5
  },
  head: {
    display: "flex",
    justifyContent: "space-between"
  },
  type: {
    width: 60,
    color: "#fff",
    padding: 2,
    textAlign: "center"
  },
  date: {
    textAlign: "right",
    fontSize: "0.8rem"
  },
  body: {
    display: "flex",
    alignItems: "center",
    wordBreak: "keep-all",
    height: 28
  }
});

function ReadButton({ id, read }) {
  const { account } = useStore();

  const handleReadClick = useCallback(() => {
    account.modifyNotifications({ id, read: !read });
  }, [id, read]);

  return (
    <button
      style={{ ...readToggle, fontSize: "0.8rem" }}
      onClick={handleReadClick}
    >
      {read ? "Unread" : "Read"}
    </button>
  );
}

const NotiContents = observer(() => {
  const classes = useStyle();
  const {
    account,
    account: { notifications, notiiSearch }
  } = useStore();

  useEffect(() => {
    account.loadNotificationList();
  }, [notiiSearch]);

  const list = useMemo(() => {
    const notiList = notifications.map(
      ({ id, type, title, createdDateTime, read }) => {
        const buttonProps = { id, read };
        return (
          <div className={classes.card}>
            <div className={classes.head}>
              <div
                className={classes.type}
                style={{ backgroundColor: "#14a4e7" }}
              >
                {type}
              </div>
              <ReadButton {...buttonProps} />
            </div>
            <div className={classes.body}>{title}</div>
            <div className={classes.date}>
              {new Date(createdDateTime).toLocaleDateString()}
            </div>
          </div>
        );
      }
    );

    return notiList.length > 0 ? (
      <CustomMenuList list={notiList} />
    ) : (
      <div
        style={{
          position: "absolute",
          top: 0,
          bottom: 0,
          right: 0,
          left: 0,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          color: "rgb(125, 125, 125)"
        }}
      >
        {"No Unread Notifications"}
      </div>
    );
  }, [notifications]);

  return list;
});

function RightMenu() {
  const {
    account,
    account: { notifications }
  } = useStore();

  const [read, setRead] = useState(false);

  const handleAllClick = useCallback(() => {
    setRead(!read);
  }, [read]);

  useEffect(() => {
    let obj = {};
    if (!read) {
      obj = { read };
    }
    account.setNotiSearch(obj);
  }, [read]);

  const handleAllReadClick = useCallback(() => {
    account.modifyNotifications({ read: true }, "/read");
  }, []);

  const size = useMemo(() => {
    return (
      <button style={{ ...readToggle }} onClick={handleAllClick}>
        {read ? "Filter by Unread" : "View all"}
      </button>
    );
  }, [notifications, read]);

  const top = useMemo(() => {
    return (
      <div style={{ textAlign: "right", height: 24 }}>
        {notifications.length > 0 && (
          <button
            style={{ ...readToggle, margin: 5 }}
            onClick={handleAllReadClick}
          >
            {!read && `Mark All as Read`}
          </button>
        )}
      </div>
    );
  }, [notifications]);

  return (
    <div
      style={{
        position: "absolute",
        right: 0,
        top: 0,
        bottom: 0,
        width: "17%"
      }}
    >
      <TableTemplate
        title={"Notifications"}
        bodyStyle={bodyStyle}
        size={size}
        top={top}
      >
        <NotiContents />
      </TableTemplate>
    </div>
  );
}

export default observer(RightMenu);
