import React, { useState, useCallback, useEffect, useMemo } from "react";
import CustomDialog from "../../atoms/ui/CustomDialog";
import CustomSelectBox from "components/atoms/ui/CustomSelectBox";
import CustomInput from "../../atoms/ui/CustomInput";
import MatchButton from "components/molecules/MatchButton";
import useStore from "modules/hooks/useStore";
import { observer } from "mobx-react";
import { makeStyles } from "@material-ui/core/styles";
import CommentIcon from "@material-ui/icons/Comment";
import { Link } from "react-router-dom";

const useStyle = makeStyles(theme => ({
  width: {
    width: "100%",
    height: "3rem"
  },
  mainPaddingTop: {
    paddingTop: 20,
    paddingBottom: 10,
    // color: "#707070",
    fontSize: "1.2rem",
    fontWeight: 500
  },
  warming: {
    paddingTop: "10px",
    fontSize: "10px",
    color: "#ff4e5f"
  },
  mainTextarea: {
    minHeight: "250px",
    padding: "10px"
  },
  alert: {
    fontSize: "16px"
  },
  alertAherf: {
    fontSize: "16px",
    paddingLeft: "5px"
  }
}));

const typesOfQuestion = [
  {
    value: "Bug Report",
    label: "Bug Report"
  },
  {
    value: "Suggestion",
    label: "Suggestion"
  },
  {
    value: "Data error",
    label: "Data error"
  },
  {
    value: "Etc",
    label: "Etc"
  }
];

const QuestionTypeSelect = () => {
  const classes = useStyle();
  const { account } = useStore();

  const onChange = e => {
    account.setInputQuestion({
      type: e.target.value
    });
  };

  return (
    <>
      {account.user.authorities[0] !== "ROLE_ADMIN" && (
        <div style={{ display: "flex" }}>
          <div className={classes.alert}>
            The registered questions can be found in{" "}
          </div>{" "}
          <Link to="/my-page/qna" className={classes.alertAherf}>
            {" My Question Page."}
          </Link>
        </div>
      )}
      <div className={classes.mainPaddingTop}>Type</div>
      <CustomSelectBox
        data={typesOfQuestion}
        onChange={onChange}
        inputStyle={classes.width}
      />
    </>
  );
};

const QuestionSubjectInput = observer(() => {
  const classes = useStyle();
  const {
    account,
    account: { inputQuestion }
  } = useStore();

  const onChange = e => {
    account.setInputQuestion({
      title: e.target.value
    });
  };

  return (
    <>
      <div className={classes.mainPaddingTop}>Subject</div>
      <CustomInput
        placeholder="Enter a subject"
        type="text"
        onChange={onChange}
        value={inputQuestion.title}
        inputStyle={{
          height: "50px",
          borderRadius: "1px",
          border: "solid 1px #cac9c9",
          fontSize: "13px",
          padding: "0px 10px"
        }}
        required
      />
    </>
  );
});

const QuestionContentTextarea = observer(() => {
  const classes = useStyle();
  const {
    account,
    account: { inputQuestion }
  } = useStore();

  const onChange = e => {
    account.setInputQuestion({
      contents: e.target.value
    });
  };

  return (
    <>
      <div className={classes.mainPaddingTop}>Content</div>
      <textarea
        placeholder="Enter a content"
        type="text"
        onChange={onChange}
        value={inputQuestion.contents}
        className={classes.mainTextarea}
        required
      />
    </>
  );
});

function ContactUsPanel() {
  const { account } = useStore();

  useEffect(() => {
    return () => {
      account.resetInputQuestion();
    };
  }, []);

  return (
    <>
      <QuestionTypeSelect />
      <QuestionSubjectInput />
      <QuestionContentTextarea />
    </>
  );
}

function ContactUs() {
  const [open, setOpen] = useState(false);
  const { account } = useStore();
  // const classes = useStyle();
  const onClick = useCallback(() => {
    setOpen(true);
  }, []);

  const onClose = () => {
    setOpen(false);
  };

  const onSubmit = useCallback(e => {
    e.preventDefault();
    account.submitInputQuestion().then(value => {
      if (value) {
        alert(value.data);
        onClose();
      }
    });
  }, []);

  const actions = useMemo(() => {
    return (
      <div style={{ padding: "0px 14px", display: "flex" }}>
        <MatchButton
          style={{ width: "130px", height: "30px" }}
          onClick={onClose}
          type="button"
        >
          Cancel
        </MatchButton>
        <MatchButton
          style={{
            width: "130px",
            height: "30px"
            // backgroundColor: "#14a4e7"
            // color: "#ffffff"
          }}
          type="sumbit"
        >
          Submit
        </MatchButton>
      </div>
    );
  }, []);

  return (
    <>
      <button
        onClick={onClick}
        style={{
          color: "rgb(209, 209, 209)",
          background: "none",
          border: "none",
          textDecoration: "underline",
          cursor: "pointer",
          padding: 0
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center"
          }}
        >
          <CommentIcon style={{ fontSize: "14px", marginRight: 3 }} />
          {`Q&A`}
        </div>
      </button>
      <CustomDialog
        open={open}
        onClose={onClose}
        title={"Q&A"}
        actions={actions}
        onSubmit={onSubmit}
      >
        <ContactUsPanel />
      </CustomDialog>
    </>
  );
}

export default ContactUs;
