import React from "react";
import Radio from "@material-ui/core/Radio";
import { withStyles } from "@material-ui/core/styles";

const MatchRadio = withStyles({
  root: {
    color: "#14a4e7",
    "&$checked": {
      color: "#14a4e7"
    }
  },
  checked: {}
})(props => <Radio color="default" {...props} />);

function CustomRadioButton({ selectedValue, handleChange, value, name }) {
  return (
    <MatchRadio
      checked={selectedValue === value}
      onChange={handleChange}
      value={value}
      name={name}
    />
  );
}

export default CustomRadioButton;
