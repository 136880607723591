import React, { useState } from "react";
import CustomDialog from "../../atoms/ui/CustomDialog";
import MatchButton from "components/molecules/MatchButton";
import CustomInput from "../../atoms/ui/CustomInput";
import useInputState from "../../lib/useInputState";
import useStore from "modules/hooks/useStore";
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";

const title = <h1 style={{ color: "#575757", padding: 5 }}>Forgot password</h1>;
const desc = (
  <p
    style={{
      fontSize: "1.3rem",
      color: "#6c6c6c",
      padding: 5
    }}
  >
    <span>If you forgot your password, here you can request a new one. </span>
    <span>
      You will receive an email containing a link to set a new password.
    </span>
  </p>
);

function FindPasswordDialog() {
  const [open, setOpen] = useState(false);
  const [email, setEmail, handleChangeEmail] = useInputState("");
  const { account } = useStore();

  const onClose = () => {
    setOpen(false);
  };

  const onClick = () => {
    setEmail("");
    setOpen(true);
  };

  const onSubmit = e => {
    e.preventDefault();
    e.stopPropagation();
    account.resrtPassword({ email }).then(value => {
      if (value) alert(value);
    });
  };

  return (
    <>
      <button
        onClick={onClick}
        style={{
          display: "flex",
          // justifyContent: "center",
          alignItems: "center",
          color: "#acacac",
          textDecoration: "underline",
          cursor: "pointer",
          background: "none",
          border: "none"
        }}
      >
        <HelpOutlineIcon style={{ marginRight: 5 }} />
        Forgot password
      </button>
      <CustomDialog open={open} onClose={onClose} maxWidth={"sm"}>
        {title}
        {desc}
        <form onSubmit={onSubmit}>
          <div
            style={{
              padding: 5,
              display: "flex",
              flexDirection: "column"
            }}
          >
            <CustomInput
              placeholder="Enter your email"
              type="email"
              onChange={handleChangeEmail}
              value={email}
              inputStyle={{ height: 25, padding: 10 }}
            />
          </div>
          <div style={{ display: "flex", marginTop: 20 }}>
            <MatchButton
              type={"button"}
              onClick={onClose}
              style={{ width: "50%", height: 35, fontWeight: 100 }}
            >
              Cancel
            </MatchButton>
            <MatchButton
              type={"submit"}
              style={{
                width: "50%",
                height: 35,
                backgroundColor: "#14a4e7",
                color: "#fff"
              }}
            >
              Submit
            </MatchButton>
          </div>
        </form>
      </CustomDialog>
    </>
  );
}

export default FindPasswordDialog;
