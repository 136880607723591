import React from "react";
import { extendObservable } from "mobx";
import { Link } from "react-router-dom";

// const colors = {
//   "Bug Report": "#e79314",
//   Suggestions: "#14a4e7",
//   "Data error": "#505050",
//   Etc: "#505050"
// };

function getFormatDate(date) {
  var year = date.getFullYear(); //yyyy
  var month = 1 + date.getMonth(); //M
  month = month >= 10 ? month : "0" + month; //month 두자리로 저장
  var day = date.getDate(); //d
  day = day >= 10 ? day : "0" + day; //day 두자리로 저장
  var hours = date.getHours();
  hours = hours >= 10 ? hours : "0" + hours; //day 두자리로 저장
  var min = date.getMinutes();
  min = min >= 10 ? min : "0" + min; //min 두자리로 저장
  return `${year}-${month}-${day} ${hours}:${min}`;
}

class QuestionModel {
  constructor(data) {
    extendObservable(this, data);
    this.createdDateTime = getFormatDate(new Date(data.createdDateTime));
    this.commented = data.commented ? (
      <div
        style={{
          color: "#fff",
          height: 15,
          backgroundColor: "#14a4e7",
          padding: "3px 8px"
        }}
      >
        Complete
      </div>
    ) : (
      <div>Waiting</div>
    );
  }

  get link() {
    return (
      <>
        <span
          style={{
            marginRight: 2
          }}
        >
          [ {this.type} ]
        </span>
        <Link to={`/admin/qna/${this.id}`}>{this.title}</Link>
      </>
    );
  }
}

export default QuestionModel;
