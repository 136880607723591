import React, { useEffect, useState, useCallback } from "react";
import useStore from "modules/hooks/useStore";
import NotificationsActiveIcon from "@material-ui/icons/NotificationsActive";
import NotificationsIcon from "@material-ui/icons/Notifications";

function NoticedOfPatientTable({ id, value }) {
  // const { patient } = useStore();
  const [noticed, setNoticed] = useState(value);

  useEffect(() => {
    setNoticed(value);
  }, [value]);

  // const onClick = useCallback(() => {
  //   patient.followById({ isFollow: follow, id });
  //   patient.setFollowId(id);
  //   setFollow(!follow);
  // }, [follow]);

  return (
    <div
      // onClick={onClick}
      style={{
        // cursor: "pointer",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        color: "#979797"
      }}
    >
      {!noticed ? (
        <div style={{ opacity: 0.3 }}>
          <NotificationsIcon />
        </div>
      ) : (
        <div
          style={{
            color: "#14a4e7",
            display: "flex",
            alignItems: "center",
            justifyContent: "center"
          }}
        >
          <NotificationsActiveIcon />
        </div>
      )}
    </div>
  );
}

export default NoticedOfPatientTable;
