// import { extendObservable, computed } from "mobx";

class VariantSelectBoxModel {
  constructor(data, i) {
    this.index = i;
    this.value = `{"variantId": "${data.key}", "variantType":"${data.variantClass}"}`;
    this.label = data.name;
    this.key = data.key;
    this.type = data.variantClass;
  }
}

export default VariantSelectBoxModel;
