import React from "react";
import NgsLogo from "assets/images/patient/ngs/NGSTESTS.svg";
import NgsDeleselctLogo from "assets/images/patient/ngs/NGSTESTS_ DISABLE.svg";

class NgsTabModel {
  constructor(data) {
    this.icon = <img src={NgsLogo} alt="" />;
    this.deSelectIcon = <img src={NgsDeleselctLogo} alt="" />;
    this.label = data.genomicDate;
    // this.count=0
    this.value = data.secId;
    this.secId = data.secId;
    this.count = data.personCount;
  }
}

export default NgsTabModel;
