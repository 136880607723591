import React, { useMemo, useEffect } from "react";
import usePersonStore from "modules/hooks/usePersonStore";
import useStore from "modules/hooks/useStore";
import TableHead from "@material-ui/core/TableHead";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import { Link } from "react-router-dom";
import { makeStyles } from "@material-ui/styles";
import { observer } from "mobx-react";
import { withRouter } from "react-router-dom";
import accr from "assets/images/siteicon/accr.svg";
import kova from "assets/images/siteicon/kova.svg";
import krg from "assets/images/siteicon/krg.svg";
import kmaster from "assets/images/siteicon/kmaster.svg";

const useStyles = makeStyles({
  cell: {
    height: "35px",
    color: "#3a3a3a",
    backgroundColor: "#f3f3f3",
    padding: 0,
    fontSize: "1rem",
    border: "1px solid #dedede",
    fontWeight: 600
    // display: "flex",
    // justifyContent: "center",
    // alignItems: "center"
  },
  p: {
    margin: 0
  }
});

// function ngsColumns({ match }) {
//   const { patient, id } = usePersonStore();
//   const {
//     patient: { ngsHeader }
//   } = useStore();

//   const classes = useStyles();
//   useEffect(() => {
//     if (id) patient.loadNgsHeader(id);
//   }, [id]);

//   const customHeaderList = useMemo(() => {
//     let headerList = ngsHeader
//       .slice(0, 2)
//       .map(({ genomicDate, secId, panel }) => {
//         return {
//           name: (
//             <Link to={`/patient/${match.params.id}/ngs/${secId}/snv`}>
//               <p className={classes.p}>{genomicDate || "-"}</p>
//               <p className={classes.p}>({panel})</p>
//             </Link>
//           ),
//           style: { width: 100, height: 30 }
//         };
//       });

//     if (ngsHeader.length <= 1) {
//       const list = new Array(2 - ngsHeader.length);
//       list.fill({ name: "-", style: { width: 100, heigth: 30 } });
//       headerList = headerList.concat(list);
//     }

//     return headerList;
//   }, [ngsHeader]);

//   return customHeaderList;
// }

function NgsHeader(props) {
  const classes = useStyles();
  let columns = [
    {
      name: "AACR",
      title: "AACR",
      style: { width: "25%", height: 30 },
      icon: <img src={accr} alt="" />
    },
    {
      name: "KOVA",
      title: "KOVA",
      style: { width: "25%", height: 30 },
      icon: <img src={kova} alt="" />
    },
    {
      name: "KRGDB",
      title: "KRGDB",
      style: { width: "25%", height: 30 },
      icon: <img src={krg} alt="" />
    },
    {
      name: "K-MASTER",
      title: "K-MASTER",
      style: { width: "25%", height: 30 },
      icon: <img src={kmaster} alt="" />
    }
  ];

  // columns = columns.concat(ngsColumns(props));

  const headerCellList = useMemo(() => {
    return columns.map((c, i) => {
      return (
        <TableCell
          key={i}
          align="center"
          padding="none"
          classes={{
            root: classes.cell
          }}
          style={c.style}
          title={c.title}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center"
            }}
          >
            <span
              style={{
                marginRight: 5,
                display: "flex",
                justifyContent: "center",
                alignItems: "center"
              }}
            >
              {c.icon}
            </span>
            {c.name}
          </div>
        </TableCell>
      );
    });
  }, [columns]);

  const headRow = useMemo(() => {
    return (
      <TableRow
        classes={{
          root: classes.cell
        }}
      >
        {headerCellList}
      </TableRow>
    );
  }, [headerCellList]);

  return <TableHead>{headRow}</TableHead>;
}

export default withRouter(observer(NgsHeader));
