import React, { useRef, useCallback, useEffect, useMemo } from "react";
import TableTemplate from "components/organisms/TableTemplate";
import MuiTable from "components/atoms/MuiTable";
import CustomTab from "components/atoms/ui/CustomTab";
import SyncUpdateButton from "components/molecules/SyncUpdateButton";
import useStore from "modules/hooks/useStore";
import { makeStyles } from "@material-ui/styles";
import { observer } from "mobx-react";
import ApiBtn from "./SyncApiButton";

const useStyles = makeStyles({
  tabContainer: {
    position: "absolute",
    top: 0,
    left: 2,
    right: 2,
    bottom: 0
  },
  container: {
    position: "absolute",
    bottom: 0,
    width: "100%",
    top: 35
  },
  tabsRoot: {
    minHeight: 35
  },
  tabRoot: {
    fontSize: "1.1rem",
    borderBottom: "1px solid #c6c6c6",
    // backgroundColor: "#ffffff",
    backgroundColor: "#f6f6f6",
    minWidth: 182,
    minHeight: 35,
    fontWeight: 400
  },
  panelRoot: {
    // overflowY: "auto",
    height: "100%",
    width: "100%",
    // backgroundColor: "#f6f6f6",
    backgroundColor: "#ffffff",
    borderRight: "1px solid #c6c6c6",
    borderLeft: "1px solid #c6c6c6",
    borderTop: "none"
    // borderBottom: "1px solid #c6c6c6"
  },
  selected: {
    borderRight: "1px solid #c6c6c6",
    borderLeft: "1px solid #c6c6c6",
    borderTop: "1px solid #c6c6c6",
    borderBottom: "none",
    // backgroundColor: "#f6f6f6",
    backgroundColor: "#ffffff",
    fontWeight: 600
  },
  indicator: {
    display: "none"
  },
  empty: {
    borderBottom: "1px solid #c6c6c6",
    maxWidth: "100%",
    width: "100%",
    minHeight: 35
  },
  disabled: {
    opacity: "0.7!important",
    color: "darkgrey"
  },
  wrapper: {
    fontSize: "1rem"
  }
});

const matchColumns = [
  {
    column: "Event Data",
    property: "updateDatetime",
    align: "right",
    sort: false,
    options: {
      link: {
        isLinked: false
      },
      isEditable: false
    }
    // style: { head: { width: 200 } }
  },
  {
    column: "Person",
    property: "updatePersonCount",
    align: "right",
    sort: false,
    options: {
      link: {
        isLinked: false
      },
      isEditable: false
    }
    // style: { head: { width: 200 } }
  },
  {
    column: "Specimen",
    property: "updateSpecimenCount",
    align: "right",
    sort: false,
    options: {
      link: {
        isLinked: false
      },
      isEditable: false
    }
    // style: { head: { width: 200 } }
  },
  {
    column: "SNV",
    property: "updateSnvCount",
    align: "right",
    sort: false,
    options: {
      link: {
        isLinked: false
      },
      isEditable: false
    }
    // style: { head: { width: 200 } }
  },
  {
    column: "CNV",
    property: "updateCnvCount",
    align: "right",
    sort: false,
    options: {
      link: {
        isLinked: false
      },
      isEditable: false
    }
    // style: { head: { width: 200 } }
  },
  {
    column: "SV",
    property: "updateSvCount",
    align: "right",
    sort: false,
    options: {
      link: {
        isLinked: false
      },
      isEditable: false
    },
    style: { head: { width: 40 } }
  }
];

const portalColumns = [
  ...matchColumns,
  {
    column: "MD5",
    property: "updateMd5",
    align: "left",
    sort: false,
    options: {
      link: {
        isLinked: false
      },
      isEditable: false
    },
    style: { head: { width: 210 } }
  },
  {
    column: "Code",
    property: "updateResultCode",
    align: "right",
    sort: false,
    options: {
      link: {
        isLinked: false
      },
      isEditable: false
    }
    // style: { head: { width: 200 } }
  },
  {
    column: "Msg",
    property: "updateResultMessage",
    align: "left",
    sort: false,
    options: {
      link: {
        isLinked: false
      },
      isEditable: false
    }
    // style: { head: { width: 200 } }
  }
];

const columns = {
  cdm: {
    syncColumns: [
      ...portalColumns,
      {
        column: "Match",
        property: "match",
        align: "center",
        sort: false,
        options: {
          link: {
            isLinked: false
          },
          isEditable: false
        }
        // style: { head: { width: 200 } }
      },
      {
        column: "Portal",
        property: "portal",
        align: "center",
        sort: false,
        options: {
          link: {
            isLinked: false
          },
          isEditable: false
        }
        // style: { head: { width: 200 } }
      }
      // {
      //   column: "Data",
      //   property: "update",
      //   align: "center",
      //   sort: false,
      //   options: {
      //     link: {
      //       isLinked: false
      //     },
      //     isEditable: false,
      //     withCellExcute: ({ value }) => WrapperComponent => {
      //       return (
      //         <WrapperComponent>
      //           <div style={{ display: "flex", justifyContent: "center" }}>
      //             {value}
      //           </div>
      //         </WrapperComponent>
      //       );
      //     }
      //   },
      //   style: { head: { width: 100 } }
      // }
    ],
    portalColumns,
    matchColumns
  },
  vcf: {
    syncColumns: [
      ...portalColumns,
      {
        column: "Match",
        property: "match",
        align: "center",
        sort: false,
        options: {
          link: {
            isLinked: false
          },
          isEditable: false
        }
        // style: { head: { width: 200 } }
      },
      {
        column: "Portal",
        property: "portal",
        align: "center",
        sort: false,
        options: {
          link: {
            isLinked: false
          },
          isEditable: false
        }
        // style: { head: { width: 200 } }
      }
      // {
      //   column: "Data",
      //   property: "update",
      //   align: "center",
      //   sort: false,
      //   options: {
      //     link: {
      //       isLinked: false
      //     },
      //     isEditable: false,
      //     withCellExcute: ({ value }) => WrapperComponent => {
      //       return (
      //         <WrapperComponent>
      //           <div style={{ display: "flex", justifyContent: "center" }}>
      //             {value}
      //           </div>
      //         </WrapperComponent>
      //       );
      //     }
      //   },
      //   style: { head: { width: 100 } }
      // }
    ],
    portalColumns,
    matchColumns
  }
};

const tabColumns = [
  { label: "CDM", value: "cdm" },
  // { label: "VCF", value: "vcf" },
  { label: "empty", value: "empty" }
];

// TODO: 업데이트 로그 관련 store, repository 작업 같이 해주세요.
function Sync({ history, match }) {
  const parentEl = useRef(null);
  const {
    account,
    account: {
      syncData,
      syncSearch,
      syncUpdateSearch,
      syncUpdateData
      /* TODO: 업데이트 로그 데이터 추가 */
    }
  } = useStore();

  const defaultValue = match.params.tabs || "cdm";

  useEffect(() => {
    return () => {
      account.resetSyncSearch();
      account.resetSyncUpdateSearch({ system: "portal" });
      account.resetSyncUpdateSearch({ system: "match" });
      // TODO: 업데이트 로그 리셋 작업
    };
  }, []);

  useEffect(() => {
    account.loadSyncData({ type: defaultValue });
  }, [syncSearch]);

  useEffect(() => {
    // TODO: 업데이트 로그 로드
    // 위에 객체에 type 프로퍼티와 값은 탭을 의미합니다.
    // 현재는 사용 안하지만 추후 추가 될 가능성있으니 적용해주세요.
    account.loadSyncUpdateData({ type: defaultValue, system: "match" });
  }, [syncUpdateSearch.match]);

  useEffect(() => {
    // TODO: 업데이트 로그 로드
    // 위에 객체에 type 프로퍼티와 값은 탭을 의미합니다.
    // 현재는 사용 안하지만 추후 추가 될 가능성있으니 적용해주세요.
    account.loadSyncUpdateData({ type: defaultValue, system: "portal" });
  }, [syncUpdateSearch.portal]);

  const handleChange = useCallback(
    (e, newValue) => {
      let url = `${match.url}/${newValue}`;
      if (match.params.tabs) {
        url = match.path.replace(":tabs", newValue);
      }
      history.push(`${url}`);
    },
    [match]
  );

  const syncHandlePatination = useCallback(({ page, rowsPerPage }) => {
    account.setSyncSearch({ page, size: rowsPerPage });
  }, []);

  const updatePortalHandlePatination = useCallback(({ page, rowsPerPage }) => {
    // TODO: 업데이트 로그 서치 변경 작업과 로드 작업
    account.setSyncUpdateSearch({
      obj: { page, size: rowsPerPage },
      system: "portal"
    });
  }, []);

  const updateMatchHandlePatination = useCallback(({ page, rowsPerPage }) => {
    // TODO: 업데이트 로그 서치 변경 작업과 로드 작업
    account.setSyncUpdateSearch({
      obj: { page, size: rowsPerPage },
      system: "match"
    });
  }, []);

  const size = useMemo(() => {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center"
        }}
      >
        <SyncUpdateButton system={"match"} type={defaultValue} />
        <SyncUpdateButton system={"portal"} type={defaultValue} />
      </div>
    );
  }, [defaultValue]);

  const title = useMemo(() => {
    return (
      <div style={{ display: "flex" }}>
        <div style={{ marginRight: 5 }}>{"Sync Log"}</div>
        <ApiBtn />
      </div>
    );
  }, []);

  return (
    <CustomTab
      columns={tabColumns}
      onChange={handleChange}
      useStyles={useStyles}
      defaultValue={defaultValue}
    >
      <div
        style={{
          top: 10,
          left: 2,
          right: 2,
          // border: "0px solid #c6c6c6",
          bottom: 0,
          // padding: 10,
          position: "absolute",
          backgroundColor: "#fff"
        }}
      >
        <TableTemplate borderHide={true} title={title} size={size}>
          <div ref={parentEl}>
            <MuiTable
              id={`sync_log`}
              parentEl={parentEl.current}
              columns={columns[defaultValue].syncColumns}
              data={syncData[defaultValue]}
              // stickyHeader
              oddRow
              defaultPagination={{
                page: syncSearch.page,
                rowsPerPage: syncSearch.size,
                handlePatination: syncHandlePatination
              }}
            />
          </div>
        </TableTemplate>
        <TableTemplate borderHide={true} title={"Match Updated Log"}>
          <div ref={parentEl}>
            <MuiTable
              id={`Match_updated_log`}
              parentEl={parentEl.current}
              columns={columns[defaultValue].matchColumns}
              data={syncUpdateData.match /* TODO: 업데이트 로그 데이터 할당 */}
              // stickyHeader
              oddRow
              defaultPagination={{
                page: syncUpdateSearch.match.page,
                rowsPerPage: syncUpdateSearch.match.size,
                handlePatination: updateMatchHandlePatination
              }}
            />
          </div>
        </TableTemplate>
        <TableTemplate borderHide={true} title={"Portal Updated Log"}>
          <div ref={parentEl}>
            <MuiTable
              id={`portal_updated_log`}
              parentEl={parentEl.current}
              columns={columns[defaultValue].portalColumns}
              data={syncUpdateData.portal /* TODO: 업데이트 로그 데이터 할당 */}
              // stickyHeader
              oddRow
              defaultPagination={{
                page: syncUpdateSearch.portal.page,
                rowsPerPage: syncUpdateSearch.portal.size,
                handlePatination: updatePortalHandlePatination
              }}
            />
          </div>
        </TableTemplate>
      </div>
    </CustomTab>
  );
}

export default observer(Sync);
