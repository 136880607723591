import React, { useMemo, useEffect } from "react";
import CustomPopover from "../../atoms/ui/CustomPopover";
import Pdf from "dependencyLibrary/reactToPdf";
import MenuItem from "@material-ui/core/MenuItem";

function OverviewDownLoadPopover({ targetRef }) {
  useEffect(() => {
    // console.log("targetRef", targetRef);
  }, []);
  const menus = useMemo(() => {
    return [
      {
        label: "Pdf",
        item: function({ children, handleClose }) {
          return (
            <Pdf
              targetRef={targetRef}
              filename={"overview"}
              orientation="landscape"
            >
              {function PdfDownloadMemu({ toPdf }) {
                const handleClick = () => {
                  toPdf();
                  handleClose();
                };

                return <MenuItem onClick={handleClick}>{children}</MenuItem>;
              }}
            </Pdf>
          );
        }
      }
    ];
  }, []);

  return <CustomPopover menus={menus}>{"Download"}</CustomPopover>;
}

export default OverviewDownLoadPopover;
