import React, { Component } from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
// require("highcharts/modules/exporting")(Highcharts);

class ColumnChart extends Component {
  state = {
    options: {}
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps !== prevState) {
      return {
        options: {
          chart: {
            type: "column",
            height: nextProps.height || (9 / 16) * 100 + "%"
            // height: "100%"
            // height: (9 / 16) * 100 + "%" // 16:9 ratio
            // marginRight: 80,
            // marginTop: 60
            // width: "100%"
            // height: "100%"
          },
          title: { text: null },
          subtitle: { text: null },
          credits: {
            enabled: false
          },
          xAxis: {
            type: "category",
            categories: nextProps.category,
            labels: {
              rotation: -45,
              formatter: function() {
                return this.value.replace(" cancer", "");
              }
            }
          },
          yAxis: {
            tickPixelInterval: 50,
            labels: {
              formatter: function() {
                return this.value.toLocaleString();
              }
            },
            title: null
          },
          legend: {
            itemDistance: 5,
            itemStyle: { fontSize: "0.8rem" },
            labelFormatter: function() {
              return this.name;
            },
            enabled: nextProps.legend
          },
          exporting: {
            enabled: false
          },
          tooltip: {
            shared: true,
            formatter: function() {
              return this.points.reduce(function(s, point) {
                if (point.y > 0) {
                  return (
                    s +
                    '<br/><span style="color:' +
                    point.color +
                    '">\u2B24</span>' +
                    point.series.name +
                    ": " +
                    point.y.toLocaleString()
                  );
                } else {
                  return s;
                }
              }, " <b>" + this.x + "<b/>");
            }
          },
          plotOptions: {
            column: {
              stacking: "normal"
              // grouping: false
            },
            series: {
              cursor: "pointer",
              point: {
                events: {
                  click: function() {
                    nextProps.onClick(this);
                  }
                }
              }
            }
          },
          series: nextProps.series
        }
      };
    }

    return null;
  }

  render() {
    const { options } = this.state;

    return <HighchartsReact highcharts={Highcharts} options={options} />;
  }
}
export default ColumnChart;
