import React from "react";
import { makeStyles } from "@material-ui/styles";

const useStyle = makeStyles({
  ul: {
    display: "flex",
    flexDirection: "column"
  },
  li: {
    marginBottom: 5
  }
});

const useDefaultStyle = makeStyles({
  ul: {
    listStyle: "none",
    padding: 0,
    margin: 0
  }
});

function CustomMenuList({ list, useCustomStyle = useStyle }) {
  const classes = useCustomStyle();
  const defaultClasses = useDefaultStyle();

  return (
    <ul className={`${defaultClasses.ul} ${classes.ul}`}>
      {list.map((m, i) => {
        return (
          <li key={i} className={classes.li}>
            {m}
          </li>
        );
      })}
    </ul>
  );
}

export default CustomMenuList;
