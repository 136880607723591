import React, { useMemo } from "react";
import useStore from "modules/hooks/useStore";
import { observer } from "mobx-react";

function ClinicalBriefSummary() {
  const {
    patient: { briefSummary }
  } = useStore();

  const content = useMemo(() => {
    return (
      briefSummary.length > 0 &&
      Array.from(briefSummary)
        .toString()
        .replace(" ", "")
        .split("\n\n")
        // .slice(1)
        .map(m => (
          <span key={m}>
            {m}
            <br />
            <br />
          </span>
        ))
    );
  });

  return (
    <div style={{ padding: 10 }}>
      <h3>Abstraction</h3>
      {content}
    </div>
  );
}

export default observer(ClinicalBriefSummary);
