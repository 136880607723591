import React, { useState, useEffect, useMemo } from "react";
import { observer } from "mobx-react";
import CustomDialog from "../../components/atoms/ui/CustomDialog";
import CustomSelectBox from "../../components/atoms/ui/CustomSelectBox";
import useStore from "modules/hooks/useStore";
import MatchButton from "components/molecules/MatchButton";
import Switch from "@material-ui/core/Switch";
import EditIcon from "@material-ui/icons/Edit";
import { makeStyles } from "@material-ui/core/styles";

const useStyle = makeStyles(theme => ({
  table: {
    margin: "5px 0px"
    // border: "solid 0.5px #dedede"
  },
  tableDiv: {
    display: "flex",
    fontSize: "11px",
    lineHeight: "3",
    borderTop: "solid 1px #dedede",
    borderLeft: "solid 1px #dedede",
    borderRight: "solid 1px #dedede"
  },
  lastDiv: {
    display: "flex",
    fontSize: "11px",
    lineHeight: "3",
    borderTop: "solid 1px #dedede",
    borderLeft: "solid 1px #dedede",
    borderRight: "solid 1px #dedede",
    borderBottom: "solid 1px #dedede"
  },
  name: {
    width: "30%",
    height: "30px",
    borderRight: "solid 1px #dedede",
    backgroundColor: "#f3f3f3",
    fontWeight: "500",
    paddingLeft: "5px"
  },
  value: {
    width: "70%",
    height: "30px",
    // border: "solid 1px #dedede",
    backgroundColor: "#ffffff",
    color: "#ababab",
    padding: "0px 5px"
  },
  buttonDiv: {
    display: "flex"
  },
  buttontitle: {
    width: "50%",
    lineHeight: "3.5",
    fontSize: "11px",
    fontWeight: "500"
  },
  switch: {
    lineHeight: "3.5",
    fontSize: "11px",
    fontWeight: "500"
  },
  width: {
    width: "-webkit-fill-available"
  },
  finally: {
    width: "50%"
  },
  description: {
    width: "-webkit-fill-available",
    border: " solid 1px #dedede"
  }
}));

const EditUserPanel = observer(
  ({ id, email, onClose, handleSubmit, handleDeleteClickOpen }) => {
    const {
      account,
      account: { userDetail, authList, allInsList }
    } = useStore();
    const [active, setActive] = useState(false);
    const [locked, setLocked] = useState(false);
    const [auth, setAuth] = useState("");
    const [ins, setIns] = useState("");
    const [password, setPassword] = useState("");
    const [first, setFirst] = useState("");
    const [last, setLast] = useState("");
    const classes = useStyle();

    const confirm = e => {
      e.stopPropagation();
      handleSubmit(
        active,
        auth.toString(),
        ins,
        password,
        first,
        last,
        userDetail,
        locked
      );
    };

    useEffect(() => {
      account.getUser(`/${id}`);
      account.getAuthList();
      account.getAllInsList("?size=1000");
    }, [id]);

    useEffect(() => {
      if (userDetail.id === id) {
        setActive(userDetail.activated);
        setLocked(userDetail.locked);
        setAuth(userDetail.authorities);
        setIns(userDetail.institute);
        setFirst(userDetail.firstName);
        setLast(userDetail.lastName);
      }
    }, [userDetail]);

    const authChange = e => {
      setAuth(e.target.value);
    };
    const passwordChange = e => {
      setPassword(e.target.value);
    };
    const insChange = e => {
      setIns(e.target.value);
    };
    const firstChange = e => {
      setFirst(e.target.value);
    };
    const lastChange = e => {
      setLast(e.target.value);
    };

    const authSelectBox = useMemo(() => {
      return (
        <CustomSelectBox
          data={authList}
          onChange={authChange}
          defaultValue={auth}
          inputStyle={classes.width}
        // maxLength={10}
        />
      );
    }, [userDetail, authList, auth]);

    const firstBox = useMemo(() => {
      return (
        <input
          className={classes.description}
          onChange={firstChange}
          value={first}
        />
      );
    }, [first]);

    const passwordBox = useMemo(() => {
      return (
        <input
          type="password"
          className={classes.description}
          onChange={passwordChange}
          value={password}
        />
      );
    }, [password]);

    const lastBox = useMemo(() => {
      return (
        <input
          className={classes.description}
          onChange={lastChange}
          value={last}
        />
      );
    }, [last]);

    const insSelectBox = useMemo(() => {
      return (
        <CustomSelectBox
          data={allInsList}
          onChange={insChange}
          defaultValue={ins}
          inputStyle={classes.width}
        // maxLength={10}
        />
      );
    }, [userDetail, allInsList, ins]);

    const handleChange = e => {
      setActive(e.target.checked);
    };

    const handleLockedChange = e => {
      setLocked(e.target.checked);
    };
    // const deleteUser = async () => {
    //   handleDeleteClickOpen();
    //   // try {
    //   //   await account.deleteUser(name)
    //   // } catch (error) {
    //   //   alert('error')
    //   // }
    //   // onClose()
    //   // account.getUserList('?size=10');
    // };

    // const resetUser = async e => {
    //   e.stopPropagation();
    //   let query = {};
    //   query.email = email;
    //   try {
    //     await account.resrtPassword(query).then(value => {
    //       if (value) alert(value);
    //     });
    //   } catch (error) {
    //     alert("error");
    //   }
    //   onClose();
    //   account.getUserList({ size: 10 });
    // };

    // console.log(userDetail)
    return (
      <>
        <div style={{ marginBottom: 10 }}>
          <div className={classes.table}>
            <div className={classes.lastDiv}>
              <div className={classes.name}>E-Mail</div>
              <div className={classes.value}>{userDetail.email}</div>
            </div>
          </div>
          <div className={classes.buttonDiv}>
            <div className={classes.buttontitle}>Activate :</div>
            <div className={classes.switch}>Deactive</div>
            <Switch checked={active} onChange={handleChange} color="primary" />
            <div className={classes.switch}>Active</div>
          </div>
          <div className={classes.buttonDiv}>
            <div className={classes.buttontitle}>Locked :</div>
            <div className={classes.switch}>Unlocked</div>
            <Switch
              checked={locked}
              onChange={handleLockedChange}
              color="primary"
            />
            <div className={classes.switch}>Locked</div>
          </div>
          <div className={classes.buttonDiv}>
            <div className={classes.buttontitle}>Reset Password : </div>
            <div style={{ width: 430, display: "flex", alignItems: 'center' }}>{passwordBox}</div>
            {/* <MatchButton style={{ width: 210 }} onClick={resetUser}>
              RESET
            </MatchButton> */}
          </div>
          {/* <div className={classes.buttonDiv}>
          <div className={classes.buttontitle}>Delete Account : </div>
          <MatchButton style={{ width: 210 }} onClick={deleteUser}>
            DELETE
          </MatchButton>
        </div> */}
          <div className={classes.table}>
            <div className={classes.tableDiv}>
              <div className={classes.name}>First Name</div>
              <div className={classes.value}>{firstBox}</div>
            </div>
            <div className={classes.lastDiv}>
              <div className={classes.name}>Last Name</div>
              <div className={classes.value}>{lastBox}</div>
            </div>
          </div>
          <div className={classes.table}>
            <div className={classes.tableDiv}>
              <div className={classes.name}>Currently Role</div>
              <div className={classes.value}>{userDetail.authorities}</div>
            </div>
            <div className={classes.lastDiv}>
              <div className={classes.name}>Change Role</div>
              <div className={classes.value}>{authSelectBox}</div>
            </div>
          </div>
          <div className={classes.table}>
            <div className={classes.tableDiv}>
              <div className={classes.name}>Currently Institution</div>
              <div className={classes.value}>
                {userDetail.instituteName}({userDetail.institute})
              </div>
            </div>
            <div className={classes.lastDiv}>
              <div className={classes.name}>Change Institution</div>
              <div className={classes.value}>{insSelectBox}</div>
            </div>
          </div>
        </div>
        <div className={classes.buttonDiv}>
          <MatchButton style={{ width: 210 }} onClick={onClose}>
            Cancel
          </MatchButton>
          <MatchButton style={{ width: 210 }} onClick={confirm}>
            Confirm
          </MatchButton>
        </div>
      </>
    );
  }
);

function EditRegisterButton({ children, id, login, email }) {
  const [open, setOpen] = useState(false);
  const { account } = useStore();

  const handleClickOpen = e => {
    e.stopPropagation();
    setOpen(true);
  };

  const onClose = e => {
    e.stopPropagation();
    setOpen(false);
  };

  const handleSubmit = async (
    active,
    auth,
    ins,
    password,
    first,
    last,
    userDetail,
    locked
  ) => {
    let query = {};
    query.activate = active;
    query.authority = auth;
    query.password = password
    query.firstName = first;
    query.instituteCode = ins;
    query.lastName = last;
    query.login = userDetail.login;
    query.locked = locked;
    //  수정필요
    // console.log(query);
    try {
      await account.changeUser(query);
    } catch (error) {
      alert("error");
    }
    setOpen(false);
  };

  return (
    <>
      <button
        onClick={handleClickOpen}
        style={{ background: "none", border: "none", cursor: "pointer" }}
      >
        <EditIcon />
        {/* {children} */}
      </button>
      <CustomDialog
        open={open}
        onClose={onClose}
        title={"Edit User"}
        // actions={actions}
        maxWidth={"xs"}
      >
        <EditUserPanel
          email={email}
          id={id}
          onClose={onClose}
          handleSubmit={handleSubmit}
        // handleDeleteClickOpen={handleDeleteClickOpen}
        />
      </CustomDialog>
      {/* <CustomDialog
        open={deleteopen}
        title={"Delete User"}
        onClose={onDeleteClose}
        actions={actions}
        maxWidth={"xs"}
      >
        <div>Finally, please check again.</div>
      </CustomDialog> */}
    </>
  );
}

export default observer(EditRegisterButton);
