import React, { useMemo } from "react";
import CustomSvg from "components/atoms/ui/CustomSvg";
import DefaultLogo from "assets/images/header/mm-logo.svg";
import GrayLogo from "assets/images/header/mm-logo-gray.svg";
import { makeStyles } from "@material-ui/styles";

const useCustomStyle = makeStyles({
  logo: {
    width: "21.5rem"
  }
});

function Logo({ isMain }) {
  const logo = useMemo(() => {
    return isMain ? GrayLogo : DefaultLogo;
  }, [isMain]);

  return <CustomSvg useCustomStyle={useCustomStyle}>{logo}</CustomSvg>;
}

export default Logo;
