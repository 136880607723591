import React, { useEffect, useState, useMemo } from "react";
import { makeStyles } from "@material-ui/styles";
import NgsRouter from "shared/NgsTabRouter";
import { withRouter } from "react-router-dom";
import useStore from "modules/hooks/useStore";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Loading from "components/molecules/LoadingComponent";

const useStyles = makeStyles({
  layout: {
    width: "100%",
    height: "100%"
  },
  container_1500: {
    minWidth: 1090
  },
  container: {
    border: "1px solid #c6c6c6",
    position: "absolute",
    width: 876,
    top: 15,
    bottom: 0,
    left: 15,
    right: 15
  }
});

function Ngs({ history, match, location }) {
  const classes = useStyles();
  const { patient } = useStore();
  const [loading, setLoading] = useState(false);
  const matches = useMediaQuery("(min-width:1500px)");

  // if (
  //   history.action === "POP" &&
  //   location.pathname.split("/").pop() === "ngs"
  // ) {
  //   history.goBack();
  // }

  useEffect(() => {
    setLoading(false);
    const firstNgs = patient.loadNgsHeader(match.params.id);
    firstNgs.then(value => {
      if (!location.pathname.split("/")[4]) {
        // patient.loadNgs({
        //   id: match.params.id,
        //   secId: value.secId,
        //   variantType: "snv"
        // });
        patient.setTabUrl("ngs", `/${value.secId}/snv`);
        history.push(
          `${match.path
            .replace(":id", match.params.id)
            .replace(":tab", "ngs")}/${value.secId}/snv`
        );
      }
      setLoading(true);
    });
    return () => {
      patient.setTabUrl("ngs", ``);
    };
  }, []);

  const router = useMemo(() => {
    return (
      <Loading loading={loading}>
        <NgsRouter match={match} />
      </Loading>
    );
  }, [loading]);

  return (
    <div className={classes.layout}>
      <div
        className={`${classes.container} ${matches && classes.container_1500}`}
      >
        {router}
      </div>
    </div>
  );
}

export default withRouter(Ngs);
