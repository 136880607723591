import { lightBlue } from "@material-ui/core/colors";

class LineChartModel {
  constructor({ data, name, property }) {
    this.name = name;
    this.data = data.map(d => d[property]); // data 배열
    this.color = lightBlue[200];
  }
}

export default LineChartModel;
