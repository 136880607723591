import React, { useEffect, useRef, useState } from "react";
import AutoSelect from "../../atoms/AutoSelect";
import useStore from "modules/hooks/useStore";
import { observer } from "mobx-react";

const customStyle = {
  control: base => ({
    ...base,
    borderRadius: 0,
    minHeight: 30,
    maxHeight: 30,
    border: "none"
  }),
  menu: base => ({
    ...base,
    borderRadius: 0,
    zIndex: 50,
    minHeight: 30,
    color: "#3a3a3a"
  }),
  placeholder: base => ({
    ...base
  })
};

const components = {
  DropdownIndicator: null
};

function VariantAutoSelect() {
  const {
    patient,
    patient: { search },
    statistics,
    statistics: { selectedCancer, variantSelect, open }
  } = useStore();

  const select = useRef();
  const [autoCompleteList, setAutoCompleteList] = useState([]);

  const handleChange = (newValue, { action }) => {
    if (newValue) {
      const {
        value: { index, key, type }
      } = newValue;
      statistics.setSelectedVariant(key);
      statistics.setSelectedVariantIndex(index);
      delete search.snv;
      delete search.cnv;
      delete search.sv;
      patient.resetSearch({
        ...search,
        cancer: selectedCancer,
        [type.toLowerCase()]: key
      });
    }
  };

  const handleInputChange = (newValue, { action }) => {
    if (action === "set-value") statistics.setOpen(true);
    if (newValue.length > 1) {
      setAutoCompleteList(variantSelect);
    } else {
      setAutoCompleteList([]);
    }
  };

  useEffect(() => {
    if (!open) select.current.select.clearValue();
  }, [open]);

  return (
    <div
      style={{
        boxShadow: "0px 3px 4px -1px rgba(121, 121, 121, 0.8)"
      }}
    >
      <AutoSelect
        select={select}
        open={open}
        autoCompleteList={autoCompleteList}
        handleChange={handleChange}
        handleInputChange={handleInputChange}
        styles={customStyle}
        components={components}
        placeholder={"Search Tumor Type"}
      />
    </div>
  );
}

export default observer(VariantAutoSelect);
