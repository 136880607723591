/* const colors = [
  "#14a4e7",
  "#7bd2fb",
  "#b2e6ff",
  "#d0d0d0",
  "#b8b8b8",
  "#6c6c6c"
];

class ReportedChartModel {
  constructor({ targetGene1Id, variant, reported, cnt,  i, size }) {
    //TODO: id 삽입 그에따른 onclick 이벤트 변화
    this.key = targetGene1Id + " " + variant; 
    this.name = reported > 0 ? "reported" : "not"; //name은 레전드 색깔 구분
    this.y = cnt; //y축
    this.data = new Array(size).fill(0).fill(cnt, i, i + 1);
    this.color = reported > 0 ? "#14a4e7" : "#f7a35c";
  }
}

export default ReportedChartModel;
 */




const basicColorList = [
  "#14a4e7",  
  "#F361A6",
];

const labelGV = [
  "UnReported",  
  "Reported",
];
// let variants = ['snvs', 'cnvs', 'svs']



class ReportedChartModel {
  constructor(data) {
    this.id = []
    this.name = []
    this.totalSubject = []
    this.selectSubject = []
    this.selectedTotalSubject = []
    this.totalSubjectCnt = []
    this.selected = {}
    this.selectedgene = {}
    this.selectedvariant = {}

    this.series = []
    this.seriesData = []
    this.categories = []
    this.positionCategory = []
    this.choiceText = []
    this.tickPixelInterval = 20
    this.marginLeft = 70

    this.hgvsp1Digit = 0;
    this.total = 0;
    this.length = 0;
    this.nTypes = 0;
    this.nItems = 0;
    this.numPages = 1;

    this.geneName = 'TP53';

    let category = '';

    // let nShowItems = 2;
    //  console.log(data); vus, pathogenic,
    this.name = "unReported";

    if (data.length > 0)
    {
      this.hgvsp1Digit = data[0].variant;
      this.geneName = data[0].targetGene1Id;
    }
    else
    {
      return;
    }

    for (let i of data) 
    {
      category = i.targetGene1Id + "," + i.variant;

      this.categories.push(category);

      
      if (i.reported == 0)
      {
        this.seriesData.push(
          {
            // hgvsp1Digit: this.hgvsp1Digit,
            y: i.cnt,
            name: labelGV[i.reported],
            color: basicColorList[i.reported]
          }
        );
      }
      else
      {
        this.seriesData.push(
          {
            // hgvsp1Digit: this.hgvsp1Digit,
            y: i.cnt,
            name: labelGV[i.reported],
            color: basicColorList[i.reported]
          }
        );
      }
      
    }
    // end for data
    // 
    // eval last group series



    // end last group series

    // set all series
    this.series = [
      {
        name: 'UnReported',
        data: this.seriesData,
        color: basicColorList[0],
      },
      {
        name: 'Reported',
        color: basicColorList[1],
      },
    ];

  } // end constructor
}

export default ReportedChartModel;
