import React from "react";
import Button from "../../atoms/ui/CustomButton";
import { makeStyles } from "@material-ui/styles";

const useCustomStyle = makeStyles({
  button: {
    width: "100%",
    height: "11.5vh",
    backgroundColor: "#14a4e7",
    border: "none",
    color: "#ffffff",
    cursor: "pointer",
    maxHeight: 95
  }
});

function LoginButton() {
  return (
    <Button type="submit" useCustomStyle={useCustomStyle}>
      Login
    </Button>
  );
}

export default LoginButton;
