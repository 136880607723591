import React, { useEffect, useMemo, useState, useRef } from "react";
import VariantHighChart from "../../atoms/ScrollChart/VariantHighChart";
import NoScrollVariantChart from "../../atoms/NoScrollChart/NoScrollVariantChart";
import useStore from "modules/hooks/useStore";
import TableTemplate from "components/organisms/TableTemplate";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Loading from "components/molecules/LoadingComponent";
import { observer } from "mobx-react";

// ScrollChart component를 CancerHighChart, VariantHighChart, InsHighChart의 3개로 나눔
// HighchartsReact의 key 값을 서로 다르게하기 위해서임. 
// by jdy , 2020-12

const layoutStyle = {
  position: "absolute",
  width: "33.3%",
  top: 0,
  bottom: 0,
  right: 0,
  left: "33.3%"
};

function VariantScrollChart() {
  const {
    account: { user },
    patient,
    patient: { search, variantChart },
    statistics: { selectedVariant }
  } = useStore();
  const [loading, setLoading] = useState(false);
  const mathes = useMediaQuery("(min-width:1500px)");
  const divRef = useRef();

  useEffect(() => {
    setLoading(false);
    if (selectedVariant) {
      patient.loadVariantChart().then(_ => {
        setLoading(true);
      });
      // jdy 2020-12-07
      // patient.loadVariantChart();
      // setTimeout(() => {
      //   setLoading(true);
      // }, 500);
    }

    return () => {
      patient.resetVariantChart();
    };
  }, [search]);

  const bodyStyle = {
    position: "absolute",
    top: 39,
    bottom: 0,
    left: 0,
    right: 0
  };

  const height =
    divRef.current &&
    divRef.current.offsetHeight -
      divRef.current.childNodes[0].childNodes[0].offsetHeight -
      30;

  // const height = useMemo(() => {
  //   // return matches ? 335 : 235;
  //   return 315;
  // }, [matches]);

  const chart = useMemo(() => {
    return (
      Object.keys(variantChart).length > 0 &&
      (variantChart.series[0].data.length > 6.5 ? (
        <VariantHighChart data={variantChart} height={height} />
      ) : (
        <NoScrollVariantChart data={variantChart} height={height} />
      ))
    );
  }, [variantChart, height]);

  const title = useMemo(() => {
    const strSelectedVariants = mathes ? "Selected variant" : "";
    return (
      Object.keys(variantChart).length > 0 && (
        <>
          <div
            style={{ marginBottom: 5 }}
          >{`Top ${variantChart.series[0].data.length} by Variant`}</div>

          <div style={{ display: "flex", justifyContent: "center" }}>
            {variantChart.series[1].data.length > 0 &&
              variantChart.series[1].data.reduce((a, b) => Math.max(a, b)) >
                0 && (
                <div style={{ display: "flex", marginRight: 10 }}>
                  <div
                    style={{
                      width: "1rem",
                      height: "1rem",
                      backgroundColor: "#14a4e7",
                      marginRight: 5
                    }}
                  />
                  {`${strSelectedVariants} in ${search.institute ||
                    user.institute}`}
                </div>
              )}
            <div style={{ display: "flex" }}>
              <div
                style={{
                  width: "1rem",
                  height: "1rem",
                  backgroundColor: "#d8d8d8",
                  marginRight: 5
                }}
              />
              {`${strSelectedVariants} in K-MASTER`}
            </div>
          </div>
        </>
      )
    );
  }, [variantChart, search, mathes, user]);

  return (
    <div ref={divRef}>
      <TableTemplate
        title={title}
        layoutStyle={layoutStyle}
        bodyStyle={bodyStyle}
      >
        <Loading loading={loading}>
          {chart}
          <div style={{ width: "100%", textAlign: "center" }}>
            {"Patients in Population"}
          </div>
        </Loading>
      </TableTemplate>
    </div>
  );
}

export default observer(VariantScrollChart);
