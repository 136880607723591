import React, { useCallback } from "react";
import CustomButtonList from "../../atoms/ui/CustomButtonList";
import useStore from "modules/hooks/useStore";
import { observer } from "mobx-react";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles({
  container: {
    marginTop: 5
  },
  title: {
    color: "#434343",
    margin: 10,
    fontSize: "1rem"
  },
  buttonConteiner: {
    marginTop: 5
  }
});

const primarySiteProperty = "primarySite";
const primarySiteList = [
  { name: "All", key: {}, selected: undefined },
  { name: "Primary", key: { [primarySiteProperty]: "P" }, selected: "P" },
  { name: "Metastasis", key: { [primarySiteProperty]: "M" }, selected: "M" }
];

function PrimarySiteTypeFilterList() {
  const {
    patient,
    patient: {
      search,
      search: { primarySite }
    }
  } = useStore();

  const classes = useStyles();

  const handleClick = useCallback(
    ({ key }) => {
      if (Object.keys(key).length <= 0) delete search.primarySite;
      patient.resetSearch({ ...search, ...key });
    },
    [search]
  );

  return (
    <div className={classes.container}>
      <span className={classes.title}>PrimarySite</span>
      <div className={classes.buttonConteiner}>
        <CustomButtonList
          data={primarySiteList}
          onClick={handleClick}
          selected={primarySite}
        />
      </div>
    </div>
  );
}

export default observer(PrimarySiteTypeFilterList);
