import React, { useEffect, useMemo, useCallback, useState } from "react";
import useStore from "modules/hooks/useStore";
import PieChart from "../../atoms/PieChart";
import PanelTable from "./PanelTable";
import TableTemplate from "../../organisms/TableTemplate";
import { withRouter } from "react-router-dom";
import { observer } from "mobx-react";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Loading from "components/molecules/LoadingComponent";

const bodyStyle = {
  borderTop: "1px solid #dedede",
  borderLeft: "1px solid #dedede",
  borderBottom: "1px solid #dedede",
  borderRight: "1px solid #dedede",
  backgroundColor: "#fff",
  position: "absolute",
  top: 35,
  bottom: 0,
  left: 0,
  right: 0
};

function PanelPieChart({ history }) {
  const {
    patient,
    patient: { instituteSearch },
    statistics,
    statistics: { panels, search }
  } = useStore();

  const [loading, setLoading] = useState(false);

  const matches = useMediaQuery("(min-width:1500px)");

  useEffect(() => {
    //FIXME: 우선 이렇게...
    // console.log(search);
    // 시간 소요 작업 modified by jdy 2020-01

    
    //async를 사용하는 함수 따로 선언
    const fetchData = async () => {
      setLoading(false);

      if (!search.cancer) {
        try {
          // for DEBUG by jdy
          const response = await statistics.loadPanel();
          // console.log("statistics.loadPanel()");
        } catch (e) {
          console.log(e);
        }
        setLoading(true);
      }
      else {
        setLoading(true);
      }
    };
    
    fetchData();

    return () => {
      statistics.resetPanel();
    };
  }, [search]);

  const title = useMemo(() => {
    return (
      <>
        <span>Subject Distribution by Panel</span>
      </>
    );
  }, []);

  const onClick = useCallback(value => {
    patient.setSearch({
      panel: value
    });
    history.push("/patient");
  }, []);

  const table = useMemo(() => {
    return (
      <div
        style={{
          position: "absolute",
          height: "60%",
          top: "40%",
          left: 0,
          right: 0,
          bottom: 0
        }}
      >
        <PanelTable />
      </div>
    );
  }, [search]);

  const width = matches ? 538 : 438;

  const chart = useMemo(() => {
    return (
      panels.length > 0 && (
        <div
          style={{
            position: "absolute",
            height: "40%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center"
          }}
        >
          <PieChart
            series={panels}
            onClick={onClick}
            height={"50%"}
            width={width}
          />
        </div>
      )
    );
  }, [panels, search, matches]);

  return (
    <TableTemplate title={title} bodyStyle={bodyStyle}>
      <Loading loading={loading}>
        {chart}
        {table}
      </Loading>
    </TableTemplate>
  );
}

export default withRouter(observer(PanelPieChart));
