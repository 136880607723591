import React, { useEffect, useState, useCallback } from "react";
import useStore from "modules/hooks/useStore";
import BookmarkIcon from "@material-ui/icons/Bookmark";
import BookmarkBorderIcon from "@material-ui/icons/BookmarkBorder";

function FollowPatientOfPatientTable({ id, value }) {
  const { patient } = useStore();
  const [follow, setFollow] = useState(value);

  useEffect(() => {
    setFollow(value);
  }, [value]);

  const onClick = useCallback(
    e => {
      e.stopPropagation();
      patient.followById({ isFollow: follow, id });
      // patient.setFollowId(id);
      setFollow(!follow);
    },
    [follow]
  );

  return (
    <div
      onClick={onClick}
      style={{
        cursor: "pointer",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        color: "#979797"
      }}
    >
      {!follow ? (
        <div style={{ opacity: 0.5 }}>
          <BookmarkBorderIcon />
        </div>
      ) : (
        <div
          style={{
            color: "#f9c012",
            display: "flex",
            alignItems: "center",
            justifyContent: "center"
          }}
        >
          <BookmarkIcon />
        </div>
      )}
    </div>
  );
}

export default FollowPatientOfPatientTable;
