import React, { useEffect, useRef, useState, useMemo, useCallback } from "react";
import { FixedSizeList as List } from "react-window";
import useStore from "modules/hooks/useStore";
import { observer } from "mobx-react";
import CancerFilterRadioButton from "../../molecules/CancerFilterRadioButton";
import CancerTypeAutoSelect from "../../molecules/CancerTypeAutoSelect";
import TableTemplate from "components/organisms/TableTemplate";
import { makeStyles } from "@material-ui/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Loading from "components/molecules/LoadingComponent";
import Checkbox from "@material-ui/core/Checkbox";
import CheckBoxOutlineBlankIcon from "assets/images/checkbox.svg"
import CheckBoxIcon from "assets/images/checkbox_select.svg"

const useStyles = makeStyles({
  list: {
    borderBottom: "1px solid #dddddd",
    display: "flex",
    alignItems: "center"
  },
  listBody: {
    height: "100%"
  },
  listContent: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between"
  },
  count: {
    margin: "0px 10px",
    backgroundColor: "#EBECF0",
    borderRadius: "2em",
    color: "#172B4D",
    display: "inline-block",
    fontSize: "1rem",
    fontWeight: "normal",
    lineHeight: "1",
    minWidth: 1,
    padding: "0.16666666666667em 0.5em",
    textAlign: "center"
  },
  icon: {
    marginLeft: "5px",
    width: '15px',
    height: "15px",
    marginTop: "6px",
    color: "#9d9d9d"
  },
  root: {
    // padding: "1px",
    // width: "100%",
    // height: "250",
    margin: "0px 0px",
    // border: "solid 1px #dddddd"
  },
});

function CancerListFilter(props) {
  const {
    patient: { search: s, explorerReturn, instituteSearch },
    statistics,
    statistics: { cancers, selectedCancerIndex, search }
  } = useStore();

  const mathes = useMediaQuery("(min-width:1500px)");
  // const mathesheight = useMediaQuery("(max-height:800px)");

  const listRef = useRef();
  const divRef = useRef();
  const classes = useStyles();
  const [loading, setLoading] = useState(false);

  const height = 180;
  // let height =
  //   divRef.current &&
  //   divRef.current.offsetHeight -
  //     divRef.current.childNodes[0].childNodes[0].offsetHeight -
  //     30 -
  //     5;

  useEffect(() => {
    setLoading(false);
    if (Object.keys(explorerReturn).length > 0 && !explorerReturn.isReturn) {
      search.searchType = ""
      const firstCancer = statistics.loadCancer();   // Default Cancer Set 하는 부분
      firstCancer.then(value => {
        statistics.setSelectedCancer(value.valueAsConceptName);  // Default Cancer Set 하여 가져온 Cancer를 Set해주고
        statistics.setSearch({                                   // Set한 데이터로 Search한다.
          cancer: value.valueAsConceptName
        });
        setLoading(true);
      });
    }
  }, [explorerReturn, instituteSearch]);


  useEffect(() => {
    if (listRef.current)
      listRef.current.scrollToItem(selectedCancerIndex, "top");
  }, [selectedCancerIndex]);

  const Row = ({ index, style }) => {
    const value = cancers[index].key;
    const count = cancers[index].y;
    const icon = <img src={CheckBoxOutlineBlankIcon} alt="" />
    const checkedIcon = <img src={CheckBoxIcon} alt="" />
    return (
      cancers && (
        <div style={style} className={classes.list}>
          {/* CancerFilter 라디오 버튼 -> 체크박스로 변경하는 부분 - 박남준 */}
          <CancerFilterRadioButton value={value} />
          {/* <Checkbox
            // disabled={disable}
            // checked={isCheck}
            className={classes.smallCheckboxs}
            icon={icon}
            checkedIcon={checkedIcon}
            classes={{ checked: classes.selectCancerreencolor, disabled: classes.disablecheckbox }}
            value={value}
            // onChange={this.cancerCheck}
          /> */}
          
          <div className={classes.listContent} title={value}>
            <span>
              {value.length > 30 ? `${value.slice(0, 30)}...` : value}
            </span>
            <span className={classes.count}>{count}</span>
          </div>
        </div>
      )
    );
  };

  const example = useMemo(
    () =>
      height && (
        <Loading loading={loading} style={{ height }}>
          <div style={{ paddingTop: 3 }}>
            <List
              height={height}
              itemCount={cancers.length}
              itemSize={30}
              width={"100%"}
              ref={listRef}
            >
              {Row}
            </List>
            <div style={{ border: "1px solid #c1c1c1" }}></div>
          </div>
        </Loading>
      ),
    [loading, mathes, cancers, height]
  );

  return (
    <div >
        <CancerTypeAutoSelect />
        {example}
    </div>
  );
}

export default observer(CancerListFilter);

// return (
//   <div className={classes.root} ref={divRef}>
//     <TableTemplate
//       title={`Cancer Type (${cancers.length - 1})`}
//       bodyPadding={"none"}
//       layoutStyle={layoutStyle}
//       bodyStyle={bodyStyle}
//       headStyle={{ backgroundColor: "rgb(237, 238, 241)" }}
//     >
//       <CancerTypeAutoSelect />
//       {example}
//     </TableTemplate>
//   </div>
// );

// return (
//   <div className={classes.layout} ref={divRef}>
//     <TableTemplate
//       title={`Cancer Type (${cancers.length - 1})`}
//       bodyPadding={"none"}
//       headStyle={{ backgroundColor: "rgb(237, 238, 241)" }}
//     >
//       <CancerTypeAutoSelect />
//       {example}
//     </TableTemplate>
//   </div>
// );