import React, { useRef, useEffect, useMemo, useState } from "react";
import MuiTable from "components/atoms/MuiTable";
import CheckCircle from "@material-ui/icons/CheckCircle";
import usePersonStore from "modules/hooks/usePersonStore";
import NgsHeader from "./NgsHeader";
import useStore from "modules/hooks/useStore";
import { observer } from "mobx-react";
import TableTemplate from "../../../TableTemplate";
import TableHeightContainer from "../../../../lib/TableHeightContainer";
import CustomAlert from "components/atoms/ui/CustomAlert";
import MatchButton from "components/molecules/MatchButton";
import DataFormat from "components/atoms/DataFormat";
import useMediaQuery from "@material-ui/core/useMediaQuery";

const columns = [
  {
    column: "PA",
    title: "Pathogenicity",
    property: "pa",
    align: "center",
    sort: false,
    options: {
      link: {
        isLinked: false
      },
      isEditable: false
    }
  },
  {
    column: "AC",
    property: "ac",
    title: "Actionability",
    align: "center",
    sort: false,
    options: {
      link: {
        isLinked: false
      },
      isEditable: false
    }
  },
  {
    column: "Variant",
    title: "Variant",
    property: "name",
    align: "left",
    sort: false,
    options: {
      link: {
        isLinked: false
      },
      isEditable: false,
      withCellExcute: props => WrapperComponent => {
        const values = props.value.split(", ");
        const variant = values[values.length - 1].split(": ");
        return (
          <WrapperComponent>
            <div title={props.value}>
              <DataFormat maxLength={10}>{values[0]}</DataFormat>
              {variant[0] && (
                <span style={{ marginLeft: 5, fontWeight: 600 }}>
                  <DataFormat maxLength={20}>{variant[0]}</DataFormat>
                </span>
              )}
              {/* {variant[1] && `: ${variant[1].slice(0, 5)}...`} */}
            </div>
          </WrapperComponent>
        );
      }
    }
  },
  {
    column: "Frequency in K-MASTER",
    title: "Frequency in K-MASTER",
    property: "freqInKmaster",
    align: "right",
    sort: false,
    options: {
      link: {
        isLinked: false
      },
      isEditable: false
    }
  },

  {
    column: "Current",
    property: "currentVariant",
    align: "center",
    sort: false,
    options: {
      link: {
        isLinked: false
      },
      isEditable: false,
      withCellExcute: props => WrapperComponent => {
        return (
          <WrapperComponent>
            {props.value ? <CheckCircle style={{ color: "#12ce26" }} /> : "-"}
          </WrapperComponent>
        );
      }
    }
  },
  {
    column: "Before",
    property: "beforeVariant",
    align: "center",
    sort: false,
    options: {
      link: {
        isLinked: false
      },
      isEditable: false,
      withCellExcute: props => WrapperComponent => {
        return (
          <WrapperComponent>
            {props.value ? <CheckCircle style={{ color: "#12ce26" }} /> : "-"}
          </WrapperComponent>
        );
      }
    }
  }
];

function NgsSummaryTable() {
  const parentEl = useRef(null);
  const { patient, id } = usePersonStore();
  const matches = useMediaQuery("(min-height:940px)");
  const [loading, setLoading] = useState(false);
  const [isNgsGeneName, setIsNgsGeneName] = useState(false);
  const {
    patient: { ngsSummary, ngsGeneName }
  } = useStore();

  useEffect(() => {
    setLoading(false);
    if (id) {
      patient.loadNgsSummary(id).then(value => {
        setLoading(true);
        setIsNgsGeneName(ngsGeneName == "nullHgvsc");
      });
    }
  }, [id]);

  const header = useMemo(() => {
    return <NgsHeader />;
  }, []);

  const size = useMemo(() => {
    return (
      ngsSummary.content && `(${ngsSummary.content.length} variants found)`
    );
  }, [ngsSummary.content]);

  const onClose = () => {
    setIsNgsGeneName(false);
  };

  const actions = useMemo(() => {
    return (
      <MatchButton onClick={onClose} style={{ width: 120, marginRight: 20 }}>
        Close
      </MatchButton>
    );
  }, []);

  return (
    // Reported Variants 이름 수정 - 박남준

    <>
      {isNgsGeneName && (
          
        <CustomAlert
          popupWidth= "600px"
          popupHeight= "300px"
          title="Variants Data can not be showed"
          // onClose={onClose}
          actions={actions}
          // maxWidth={"sm"}
        >
          <div>
            Variants Data can not be showed because Hgvsc is null.
          </div>  

        </CustomAlert>

      )}
      <TableTemplate title="Variants" size={size}>
        <div
          ref={parentEl}
          style={{ maxHeight: matches ? 180 : 150, overflowY: "auto" }}
        >
          {/* <TableHeightContainer maxHeight={180}> */}
          <MuiTable
            id={`NgsSummarys`}
            parentEl={parentEl.current}
            columns={columns}
            data={ngsSummary}
            stickyHeader
            header={header}
            oddRow
            loading={loading}
            defaultPagination={{
              mode: "scroll",
              page: 0,
              rowsPerPage: 10
            }}
          />
          {/* </TableHeightContainer> */}
        </div>
      </TableTemplate>
    </>
  );
}

export default observer(NgsSummaryTable);
