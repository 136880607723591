import { action, observable, configure, remove } from "mobx";
import StatisticsRepository from "../repository/StatisticsRepository";
import PieChartModel from "../model/PanelChartModel";
import ReportedChartModel from "../model/ReportedChartModel";
import VariantChartModel from "../model/VariantChartModel";
import CancerSelectModel from "../model/CancerSelectModel";
import PanelTableModel from "../model/PanelTableModel";
import VariantSelectModel from "../model/VariantSelectModel";
import VariantsModel from "../model/VariantsModel";
import CancerColumnModel from "../model/CancerColumnModel";
import CancerPanelModel from "../model/CancerPanelModel";
import { queryString } from "dependencyLibrary";

configure({ enforceAction: "true" });
export default class Statistics {
  defaultSearchCriteria = {
    reported: true,
    size: 30000 // for DEBUG by jdy 30000
  };

  panelColomnList = [
    "CancerSCAN",
    "AXEN",
    "FIRST",
    "K-MASTER 1.1",
    "K-MASTER",
    "Multiple Panel"
  ];

  @observable panels = [];
  @observable panelStatistics = [];
  @observable panelTotals = [];
  @observable cancers = [];
  @observable cancerChart = {};
  @observable cancerSelects = [];
  @observable selectedCancer = "";
  @observable selectedCancerIndex = 0;
  @observable studies = [];
  @observable studyChart = {};
  @observable reported = [];
  @observable reportedChart = {};
  @observable variantChart = {};
  @observable variants = [];
  @observable variantSelect = [];
  @observable selectedVariant = "";
  @observable selectedVariantIndex = 0;
  @observable open = false;

  @observable search = {
    ...this.defaultSearchCriteria
  };

  @observable query = queryString.stringify(this.search);

  constructor(root) {
    this.root = root;
  }

  @action
  getQuery(search) {
    this.query = queryString.stringify(search || this.search);
  }

  @action
  setOpen(open) {
    this.open = open;
  }

  @action
  setSelectedCancerIndex(num) {
    this.selectedCancerIndex = num;
  }

  @action
  setSelectedVariantIndex(num) {
    this.selectedVariantIndex = num;
  }

  @action
  resetSelected() {
    this.selectedCancer = "";
    this.selectedVariant = "";
  }

  @action
  resetSearch() {
    this.search = {
      ...this.defaultSearchCriteria,
      ...this.root.patient.instituteSearch
    };
  }

  @action
  setSearch(obj) {
    this.search = { ...this.search, ...obj };
  }

  @action
  setSelectedVariant(variant) {
    this.selectedVariant = variant;
  }

  @action
  setSelectedCancer(cancer) {
    this.selectedCancer = cancer;
  }

  @action
  async loadPanel() {
    const search = queryString.stringify(this.search);
    const { data } = await StatisticsRepository.fetchPanel(`?${search}`);
    this.panels = data.content.map((m, i) => new PieChartModel({ ...m, i }));
  }

  @action
  resetPanel() {
    this.panels = [];
  }

  @action
  async loadPanelStatistics() {
    const search = queryString.stringify(this.search);
    const { data } = await StatisticsRepository.fetchPanelStatistics(
      `?${search}`
    );

    let newArr = [];
    this.panelColomnList.map(el => {
      data.map(d => {
        if (d.panel === el) {
          if (d.repeats === 0) return false;
          // d.repeats = "Total";
          newArr.push(d);
        }
      });
    });

    this.panelTotals = data.filter(d => {
      return d.repeats <= 0;
    });

    this.panelStatistics = this.makeTableData({
      data: newArr,
      model: PanelTableModel,
      propertyOfKey: "panel"
    });
  }

  @action
  resetPanelStatistics() {
    this.panelStatistics = [];
  }

  @action
  makeTableData({ data, model, propertyOfKey }) {
    const retMap = new Map();
    for (let i = 0; i < data.length; i++) {
      let m = [new model(data[i])];
      if (retMap.has(data[i][propertyOfKey])) {
        !Array.isArray(retMap.get(data[i][propertyOfKey]))
          ? (m = [retMap.get(data[i][propertyOfKey])])
          : (m = retMap.get(data[i][propertyOfKey]));
        m.push(new model(data[i]));
      }
      retMap.set(data[i][propertyOfKey], m);
    }
    return retMap;
  }

  @action
  async loadCancer() {
    const search = queryString.stringify(this.search);
    const { data } = await StatisticsRepository.fetchCancer(`?${search}`);

    // console.log("cancerChart 값" + JSON.stringify(data));
    this.cancers = data.content.map((m, i) => new CancerColumnModel({ ...m }));
    this.cancerChart = {
      category: data.content.map(
        ({ valueAsConceptName }) => valueAsConceptName
      ),
      series: this.panels
        .map(m => m.key)
        .map((panel, i) => {
          return new CancerPanelModel({
            panel,
            data: data.content
              .map(m => {
                return m.panelDTOList.filter((m, i) => {
                  return panel === m.panel;
                })[0];
              })
              .map(m => {
                return m ? m.totalPatients : 0;
              }),
            i
          });
        })
    };
    this.cancerSelects = data.content.map(
      (m, i) => new CancerSelectModel(m, i)
    );
    
    return data.content.length > 0 && data.content[0];
  }

  @action
  resetCancer() {
    this.cancers = [];
    this.cancerChart = {};
  }

  @action
  async loadStudy() {
    const search = queryString.stringify(this.search);
    // const { data } = await StatisticsRepository.fetchStudy(`?${search}`);
    const dataObj = await StatisticsRepository.fetchStudy(`?${search}`);

    const { data } = dataObj;
    
    // console.log("data.content 값" + JSON.stringify(data.content));
    this.studies = data.content.map((m, i) => new PieChartModel({ ...m, i }));
    this.studyChart = {
      category: data.content.map(({ key }, i) => key),
      series: data.content.map(
        (m, i) => new PieChartModel({ ...m, i, size: data.totalElements })
      )
    };
  }

  //OVERVIEW reported variant 차트 - ksh 20201201
  @action
  async loadReported() {
    const search = queryString.stringify(this.search);
    const { data } = await StatisticsRepository.fetchReported(`?${search}`);
    // console.log("리포트 data.content 값" + JSON.stringify(data.content));
    this.reportedChart = new ReportedChartModel(data.content);
    // console.log("reportedChart 값" + JSON.stringify(this.reportedChart));
  }

  //OVERVIEW variant category 차트 - ksh 20201201
  @action
  async loadVariantCategory() {
    const search = queryString.stringify(this.search);
    const { data } = await StatisticsRepository.fetchVariantCategory(`?${search}`);
    // console.log("리포트 data.content 값" + JSON.stringify(data.content));
    // console.log("loadVariantCategory 값" + JSON.stringify(data.content));
    
    this.variantChart = new VariantChartModel(data.content);
    // console.log("variantChart 값" + JSON.stringify(this.variantChart));
  }

  @action
  resetStudy() {
    this.studies = [];
    this.studyChart = {};
  }

  @action
  async loadVariants() {
    this.getQuery();
    const { data } = await StatisticsRepository.fetchVariants(`?${this.query}`);
    this.variants = data.content.map(m => new VariantsModel(m));
    this.variantSelect = data.content.map(
      (m, i) => new VariantSelectModel(m, i)
    );
    return (
      data.content.length > 0 && new VariantSelectModel(data.content[0], 0)
    );
  }
}
