import { useState } from "react";

function useInputObjState(defaultState) {
  const [state, setState] = useState(defaultState);

  const handleChangeState = ({ target: { name, value } }) => {
    const data = name ? { [name]: value } : JSON.parse(value);
    setState(
      Object.assign(state, {
        ...data
      })
    );
  };

  return [state, setState, handleChangeState];
}

export default useInputObjState;
