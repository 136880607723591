import React, { useMemo } from "react";
import Select from "react-select";

const groupStyles = {
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  color: "#3a3a3a",
  fontSize: "1.1rem"
};
const groupBadgeStyles = {
  backgroundColor: "#EBECF0",
  borderRadius: "2em",
  color: "#172B4D",
  display: "inline-block",
  fontSize: "1rem",
  fontWeight: "normal",
  lineHeight: "1",
  minWidth: 1,
  padding: "0.16666666666667em 0.5em",
  textAlign: "center"
};

const formatGroupLabel = data => (
  <div style={groupStyles}>
    <span>{data.label}</span>
    <span style={groupBadgeStyles}>{data.options.length}</span>
  </div>
);

const formatOptionLabel = ({ label }) => (
  <>
    <span title={label}>
      {label.length > 30 ? `${label.slice(0, 30)}...` : label}
    </span>
  </>
);

function AutoSelect({
  autoCompleteList,
  placeholder,
  handleChange,
  handleInputChange,
  value,
  onMenuOpen,
  components,
  styles,
  select
}) {
  return (
    <Select
      ref={select}
      components={components}
      onChange={handleChange}
      onInputChange={handleInputChange}
      value={value}
      onMenuOpen={onMenuOpen}
      options={autoCompleteList}
      placeholder={placeholder}
      isClearable
      styles={styles}
      formatGroupLabel={formatGroupLabel}
      formatOptionLabel={formatOptionLabel}
    />
  );
}

export default AutoSelect;
