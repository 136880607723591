const colors = [
  "#14a4e7",
  "#7bd2fb",
  "#b2e6ff",
  "#d0d0d0",
  "#b8b8b8",
  "#6c6c6c"
];

class PanelChartModel {
  constructor({ key, value, i, size }) {
    //TODO: id 삽입 그에따른 onclick 이벤트 변화
    this.key = key;
    this.name = key.replace(" cancer", "");
    this.y = value;
    this.data = new Array(size).fill(0).fill(value, i, i + 1);
    this.color = i >= 0 ? colors[i] : "#d8d8d8";
  }
}

export default PanelChartModel;
