import React from "react";
import { extendObservable, computed } from "mobx";
import AnnotationDialogButton from "components/organisms/Annotation/AnnotationDialogButton";

class AnnotationSummaryModel {
  constructor(data) {
    extendObservable(this, data);
  }

  @computed
  get name() {
    const vv = `${this.gene}, ${this.variant}`;
    return vv;
  }

  get ac() {
    return this.actionability ? (
      <AnnotationDialogButton
        version={this.version}
        gene={this.gene}
        variant={this.variant}
        variantType={this.variantType}
        type={"ac"}
      />
    ) : (
      "-"
    );
  }

  get pa() {
    return this.pathogenicity ? (
      <AnnotationDialogButton
        version={this.version}
        gene={this.gene}
        variant={this.variant}
        variantType={this.variantType}
        type={"pa"}
      />
    ) : (
      "-"
    );
  }
}

export default AnnotationSummaryModel;
