import React, { useMemo, useState, useEffect } from "react";
import CustomPopover from "../../atoms/ui/CustomPopover";
import useStore from "modules/hooks/useStore";
import MenuItem from "@material-ui/core/MenuItem";
import { Link } from "react-router-dom";
import { observer } from "mobx-react";
import { makeStyles } from "@material-ui/core/styles";
import PersonIcon from "@material-ui/icons/Person";
import Loading from "components/molecules/LoadingComponent";
import CheckPermission from "components/lib/CheckPermission";

const useCustomStyle = makeStyles(theme => ({
  button: {
    // width: 109,
    height: 29,
    borderRadius: 1,
    boxShadow: "1px 1px 4px 0 rgba(121, 121, 121, 0.8)",
    backgroundColor: "#fcfcfc",
    cursor: "pointer",
    fontSize: "1rem",
    fontWeight: 400,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    color: "#343434"
  },
  li: {
    "& a": {
      textDecoration: "none",
      fontSize: "1rem",
      color: "#343434",
      minHeight: 29
    }
  }
}));

function MyPage({ children, handleClose }) {
  const classes = useCustomStyle();

  const handleClick = () => {
    handleClose();
  };

  return (
    <div className={classes.li}>
      <Link to={"/my-page"}>
        <MenuItem onClick={handleClick}>{children}</MenuItem>
      </Link>
    </div>
  );
}

function Admin({ children, handleClose }) {
  const classes = useCustomStyle();

  const handleClick = () => {
    handleClose();
  };

  return (
    <CheckPermission permission={"ROLE_ADMIN"}>
      <div className={classes.li}>
        <Link to={"/admin"}>
          <MenuItem onClick={handleClick}>{children}</MenuItem>
        </Link>
      </div>
    </CheckPermission>
  );
}

function Logout({ children, handleClose }) {
  const { account } = useStore();
  const classes = useCustomStyle();

  const handleClick = () => {
    handleClose();
    account.logout();
  };

  return (
    <div className={classes.li}>
      <Link to={"/"}>
        <MenuItem onClick={handleClick}>{children}</MenuItem>
      </Link>
    </div>
  );
}

function HeaderPopover({ history }) {
  const {
    patient:{isMatchView},
    account: { user }
  } = useStore();

  const [loading, setLoading] = useState(false);

  const menus = useMemo(() => {

    if (isMatchView) {
      return [
        // {
        //   label: "Account Manager"
        // },
        // {
        //   label: "Access Log"
        // },
        // {
        //   label: "Data Manager",
        //   onClick: () => {
        //     console.log("data manager");
        //   }
        // },
        // {
        //   label: "My Page",
        //   item: MyPage
        // },
        // {
        //   label: "Admin",
        //   item: Admin
        // },
        {
          label: "Logout",
          item: Logout
        }
      ];
    }
    else {
      return [
        // {
        //   label: "Account Manager"
        // },
        // {
        //   label: "Access Log"
        // },
        // {
        //   label: "Data Manager",
        //   onClick: () => {
        //     console.log("data manager");
        //   }
        // },
        {
          label: "My Page",
          item: MyPage
        },
        // {
        //   label: "Admin",
        //   item: Admin
        // },
        {
          label: "Logout",
          item: Logout
        }
      ];
    }

  }, []);

  useEffect(() => {
    setLoading(false);
    if (user && Object.keys(user).length > 0) setLoading(true);
  }, [user]);

  const name = useMemo(() => {
    return (
      <Loading loading={loading}>
        {user && (
          <div style={{ display: "flex" }}>
            <span style={{ marginRight: 2, color: "#979797" }}>
              <PersonIcon />
            </span>
            <div>{user.institute || ""}</div>/
            <div>{`${user.firstName || ""}(${user.roleName || ""})`}</div>
          </div>
        )}
      </Loading>
    );
  }, [loading, user]);

  return (
    <CustomPopover menus={menus} useCustomStyle={useCustomStyle}>
      {name}
    </CustomPopover>
  );
}

export default observer(HeaderPopover);
