import React, { useRef, useCallback, useEffect } from "react";
import TableTemplate from "components/organisms/TableTemplate";
import MuiTable from "components/atoms/MuiTable";
import SearchAdminForm from "./SearchAdminForm";
import useStore from "modules/hooks/useStore";
import { observer } from "mobx-react";

const types = [
  {
    value: "type",
    label: "Type"
  },
  {
    value: "title",
    label: "Title"
  },
  {
    value: "email",
    label: "E-mail"
  }
];

const columns = [
  {
    column: "No.",
    property: "id",
    align: "center",
    sort: true,
    options: {
      link: {
        isLinked: false
      },
      isEditable: false
    },
    style: { head: { width: 50 } }
  },
  {
    column: "Title",
    property: "link",
    align: "left",
    sort: false,
    options: {
      link: {
        isLinked: false
      },
      isEditable: false
    },
    style: { head: { width: 350 } }
  },
  {
    column: "E-Mail",
    property: "email",
    align: "left",
    sort: false,
    options: {
      link: {
        isLinked: false
      },
      isEditable: false
    }
  },
  {
    column: "Date",
    property: "createdDateTime",
    align: "right",
    sort: false,
    options: {
      link: {
        isLinked: false
      },
      isEditable: false
    }
  },
  {
    column: "Status",
    property: "commented",
    align: "center",
    sort: true,
    options: {
      link: {
        isLinked: false
      },
      isEditable: false
    },
    style: { head: { width: 100 } }
  }
];

function QuestionAndAnswerTable() {
  const parentEl = useRef(null);

  const {
    account,
    account: { questions, questionSearch }
  } = useStore();

  useEffect(() => {
    account.loadQuestionList();
  }, [questionSearch]);

  const getList = useCallback(value => {
    account.setQuestionSearch(value);
  }, []);

  const handlePagination = useCallback(({ page, rowsPerPage }) => {
    account.setQuestionSearch({ page, size: rowsPerPage });
  }, []);

  const handleSort = useCallback(({ orderBy, order }) => {
    account.setQuestionSearch({
      sort: `${orderBy},${order}`
    });
  }, []);

  return (
    <>
      <SearchAdminForm types={types} getList={getList} default={"type"} />
      <TableTemplate title={"Q&A Board"} borderHide={true}>
        <div ref={parentEl}>
          <MuiTable
            id={`questions`}
            parentEl={parentEl.current}
            columns={columns}
            data={questions}
            oddRow
            defaultSort={{
              order: questionSearch.sort.split(",")[1],
              orderBy: questionSearch.sort.split(",")[0],
              handleSort: handleSort
            }}
            defaultPagination={{
              page: questionSearch.page,
              rowsPerPage: questionSearch.size,
              handlePatination: handlePagination
            }}
          ></MuiTable>
        </div>
      </TableTemplate>
    </>
  );
}

export default observer(QuestionAndAnswerTable);
