import React, { useRef, useMemo } from "react";
import MuiTable from "components/atoms/MuiTable";
import useStore from "modules/hooks/useStore";
import { observer } from "mobx-react";
// import TableTemplate from "../../../../TableTemplate";
import { makeStyles } from "@material-ui/styles";
// import DataFormat from "components/atoms/DataFormat";

const useStyles = makeStyles({
  container: {
    // width: "100%"
    overflow: "auto",
    position: "absolute",
    top: 10,
    bottom: 10,
    left: 10,
    right: 10
  }
  // title: {
  //   margin: "0px 10px",
  //   fontSize: "1.1rem",
  //   color: "#454444"
  // }
});

function RefTable({ columns, type }) {
  const parentEl = useRef(null);
  const classes = useStyles();
  const {
    patient: { refSiteEvidences }
  } = useStore();

  // const title = useMemo(() => {
  //   return (
  //     snvList.totalElements && (
  //       <span
  //         className={classes.title}
  //       >{`${snvList.totalElements} SNV/INDEL detected`}</span>
  //     )
  //   );
  // }, [snvList.totalElements]);

  // console.log(type, refSiteEvidences);
  return (
    <div ref={parentEl} className={classes.container}>
      <MuiTable
        id={`${type}Data`}
        parentEl={parentEl.current}
        columns={columns}
        data={refSiteEvidences[type]}
        stickyHeader
        oddRow
        selectRow={{
          mode: "none",
          // defaultSelected: ngsSnvReported,
          hide: true
        }}
        defaultPagination={{
          mode: "scroll",
          page: 0,
          rowsPerPage: 10
        }}
      ></MuiTable>
    </div>
  );
}

export default observer(RefTable);
