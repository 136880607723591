import React, { useMemo, useCallback, useState, useEffect } from "react";
import CustomTab from "components/atoms/ui/CustomTab";
import TableTemplate from "components/organisms/TableTemplate";
import useStore from "modules/hooks/useStore";
import { observer } from "mobx-react";
import { makeStyles } from "@material-ui/styles";
import AnnoOncokb from "./annoOncokb";
import AnnoClinvar from "./annoClinvar";
import AnnoCivic from "./annoCivic";

const useStyles = makeStyles({
  // tabContainer: {
  //   width: "100%",
  //   height: "100%"
  // },
  // container: {
  //   position: "absolute",
  //   bottom: 0,
  //   width: "100%",
  //   top: "35px"
  // },
  tabsRoot: {
    minHeight: 60,
    backgroundColor: "#ffffff"
  },
  tabRoot: {
    color: "#b4b4b4",
    minHeight: 60
  },
  // panelRoot: {
  //   // overflowY: "auto",
  //   height: "100%",
  //   width: "100%",
  //   backgroundColor: "#f6f6f6",
  //   borderRight: "1px solid #c6c6c6",
  //   borderLeft: "1px solid #c6c6c6",
  //   borderTop: "none",
  //   borderBottom: "1px solid #c6c6c6"
  // },
  selected: {
    color: "#434343",
    fontWeight: 600
  },
  indicator: {
    backgroundColor: "#14a4e7",
    height: 5
  }
  // empty: {
  //   borderBottom: "1px solid #c6c6c6",
  //   maxWidth: "100%",
  //   width: "100%",
  //   minHeight: 35
  // },
  // disabled: {
  //   opacity: "0.7!important",
  //   color: "darkgrey"
  // },
  // wrapper: {
  //   fontSize: "1rem"
  // }
});

const layoutStyle = {
  position: "absolute",
  // width: "100%",
  top: 1,
  // bottom: 0,
  right: 0,
  left: 5
};

const bodyStyle = {
  position: "absolute",
  left: 0,
  right: 0,
  top: 30,
  // bottom: 0,
  height: 320,
  paddingTop: 0
};

// const layoutStyle = {
//   width: "100%",
//   height: 200
// };

// const bodyStyle = {
//   width: "100%",
//   height: 200,
//   paddingTop: 0
// };

const refSites = {
  AnnoOncokb,
  AnnoClinvar,
  AnnoCivic
};

function findCountIndex(refSiteColumnsRef) {
  for (let i = 0, len = refSiteColumnsRef.length; i < len; i++) {
    if (refSiteColumnsRef[i].count > 0) return i;
  }
}

function RefSitePanel({ selected }) {
  let annoSelected = 'Anno' + selected;

  const Component = refSites[annoSelected];
  return (
    <div
      style={{
        position: "absolute",
        top: 65,
        // bottom: 0,
        left: 0,
        right: 0,
        borderTop: "1px solid #c6c6c6"
      }}
    >
      <Component  bCurVersion={false} />
    </div>
  );
}

function AnnoRefSiteTabRef() {
  const {
    study: { refSiteColumnsRef }
  } = useStore();

  const [selected, setSelected] = useState("");

  const index = useMemo(() => {
    return (refSiteColumnsRef.length > 0 && findCountIndex(refSiteColumnsRef)) || 0;
  }, [refSiteColumnsRef]);

  useEffect(() => {
    if (refSiteColumnsRef.length > 0) {
      setSelected(refSiteColumnsRef[index].id);
    }
  }, [refSiteColumnsRef]);

  const handleChange = useCallback((e, newValue) => {
    setSelected(newValue);
  }, []);

  const panel = useMemo(() => {
    return selected && <RefSitePanel selected={selected} />;
  }, [selected]);

  const tab = useMemo(() => {
    return (
      refSiteColumnsRef.length > 0 && (
        // index > -1 && (
        <CustomTab
          columns={refSiteColumnsRef}
          onChange={handleChange}
          useStyles={useStyles}
          defaultValue={refSiteColumnsRef[index].value}
        >
          {/* TODO: 리스트 테이블로 */}
          {panel}
        </CustomTab>
      )
    );
    // );
  }, [refSiteColumnsRef, panel]);

  return (
    <TableTemplate
      title={"Reference Sites of Reference Version"}
      layoutStyle={layoutStyle}
      bodyStyle={bodyStyle}
    >
      {tab}
    </TableTemplate>
  );
}

export default observer(AnnoRefSiteTabRef);
