import React, {
  useRef,
  useEffect,
  useMemo,
  useCallback,
  useState
} from "react";
import { observer } from "mobx-react";
import MuiTable from "components/atoms/MuiTable";
import TableTemplate from "components/organisms/TableTemplate";
import useStore from "modules/hooks/useStore";
import SearchAdminForm from "./SearchAdminForm";
import MatchButton from "components/molecules/MatchButton";
import OverviewExcelUploadBtn from "./OverviewExcelUploadBtn";

const types = [
  {
    value: "code",
    label: "Code"
  },
  {
    value: "institution",
    label: "Institution"
  }
  // {
  //   value: "",
  //   label: "Manager Name"
  // },
  // {
  //   value: "",
  //   label: "Manager E-mail"
  // },
  // {
  //   value: "",
  //   label: "Manager Number"
  // }
];

const columns = [
  {
    column: "Institution",
    property: "name",
    align: "left",
    sort: true,
    options: {
      link: {
        isLinked: false
      },
      isEditable: false
    }
    // style: { head: { width: 200 } }
  },
  {
    column: "Code",
    property: "code",
    align: "center",
    sort: true,
    options: {
      link: {
        isLinked: false
      },
      isEditable: false
    }
    // style: { head: { width: 100 } }
  },
  // {
  //   column: "Name",
  //   property: "",
  //   align: "left",
  //   sort: false,
  //   options: {
  //     link: {
  //       isLinked: false
  //     },
  //     isEditable: false
  //   }
  //   // style: { head: { width: 150 } }
  // },
  // {
  //   column: "E-Mail",
  //   property: "managerEmail",
  //   align: "left",
  //   sort: false,
  //   options: {
  //     link: {
  //       isLinked: false
  //     },
  //     isEditable: false
  //   }
  //   // style: { head: { width: 150 } }
  // },
  // {
  //   column: "Number",
  //   property: "telephone",
  //   align: "left",
  //   sort: false,
  //   options: {
  //     link: {
  //       isLinked: false
  //     },
  //     isEditable: false
  //   }
  //   // style: { head: { width: 150 } }
  // },
  // {
  //   column: "Adderss",
  //   property: "",
  //   align: "left",
  //   sort: false,
  //   options: {
  //     link: {
  //       isLinked: false
  //     },
  //     isEditable: false
  //   }
  //   // style: { head: { width: 200 } }
  // },
  {
    column: "Users",
    property: "instituteUserCount",
    align: "right",
    sort: true,
    options: {
      link: {
        isLinked: false
      },
      isEditable: false
    }
    // style: { head: { width: 100 } }
  },
  {
    column: "Control",
    property: "control",
    align: "center",
    sort: false,
    options: {
      link: {
        isLinked: false
      },
      isEditable: false
      // withCellExcute: ({ d, value }) => WrapperComponent => {
      //   return (
      //     <WrapperComponent>
      //       <div style={{ display: "flex", justifyContent: "center" }}>
      //         <EditInsButton id={d.code} value={value}>
      //           Edit
      //         </EditInsButton>
      //       </div>
      //     </WrapperComponent>
      //   );
      // }
    },
    style: { head: { width: 100 } }
  }
];

function InstitutionManager(props) {
  const parentEl = useRef(null);
  const [loading, setLoading] = useState(false);
  const {
    account,
    account: { insList, insSearch }
  } = useStore();

  useEffect(() => {
    return () => {
      account.resetInsSearch();
    };
  }, []);

  useEffect(() => {
    setLoading(false);
    account.getInsList();
    setTimeout(() => {
      setLoading(true);
    }, 500);
  }, [insSearch]);

  const handlePagination = useCallback(({ page, rowsPerPage }) => {
    account.setInsSearch({ page, size: rowsPerPage });
  }, []);

  const getList = useCallback(value => {
    account.setInsSearch({ ...value, page: 0 });
  }, []);

  const onClickBtn = useCallback(() => {
    props.history.push(`${props.match.url}/register`);
  }, []);

  const InsBtn = useMemo(() => {
    return (
      <div style={{ display: "flex" }}>
        <OverviewExcelUploadBtn type="institution" button={true} />
        <MatchButton onClick={onClickBtn} style={{ width: 100 }}>
          Register
        </MatchButton>
      </div>
    );
  }, []);

  const insTitle = useMemo(() => {
    return (
      <>
        <span>Institution Management</span>
        {insList.totalElements > 0 && (
          <span
            style={{
              marginLeft: 5,
              fontSize: "0.8rem",
              color: "rgb(58, 58, 58)",
              fontWeight: 400
            }}
          >
            ({`${insList.totalElements} institutions registered`})
          </span>
        )}
      </>
    );
  }, [insList]);

  const handleSort = useCallback(({ orderBy, order }) => {
    account.setInsSearch({
      sort: `${orderBy},${order}`
    });
  }, []);

  return (
    <>
      <SearchAdminForm types={types} getList={getList} default={"code"} />
      <TableTemplate borderHide={true} title={insTitle} size={InsBtn}>
        <div ref={parentEl}>
          <MuiTable
            id={`institution_manager`}
            parentEl={parentEl.current}
            columns={columns}
            addColumNo
            data={insList}
            oddRow
            defaultSort={{
              order: insSearch.sort.split(",")[1],
              orderBy: insSearch.sort.split(",")[0],
              handleSort: handleSort
            }}
            defaultPagination={{
              page: insSearch.page,
              rowsPerPage: insSearch.size,
              handlePatination: handlePagination
            }}
            loading={loading}
          />
        </div>
      </TableTemplate>
    </>
  );
}

export default observer(InstitutionManager);
