import React, { useMemo, useEffect, useState } from "react";
import useStore from "modules/hooks/useStore";
import StatisticPresentationer from "components/atoms/StatisticPresentationer";
import DataFormat from "components/atoms/DataFormat";
import { observer } from "mobx-react";
import Loading from "components/molecules/LoadingComponent";

function PatientStatistics() {
  const {
    patient,
    statistics: { search: s },
    patient: {
      search,
      patientStatistic: { instituteTotal, kmasterTotal, searchTotal }
    }
  } = useStore();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    //async를 사용하는 함수 따로 선언
    const fetchData = async () => {
      setLoading(false);

      try {
        // for DEBUG by jdy
        const response = await patient.loadStatistics();
        // console.log("patient.loadStatistics()");
        
      } catch (e) {
        console.log(e);
      }
      setLoading(true);

    };
    
    fetchData();

    return () => {
      patient.resetStatistics();
    };
  }, [search]);

  const selectContent = useMemo(() => {
    return (
      <>
        {searchTotal !== instituteTotal && (
          <span>
            <DataFormat>{searchTotal}</DataFormat>
            {" / "}
          </span>
        )}
        <DataFormat>{instituteTotal}</DataFormat>
        {" / "}
        <DataFormat>{kmasterTotal}</DataFormat>
      </>
    );
  }, [searchTotal, instituteTotal, kmasterTotal]);

  const title = useMemo(() => {
    return (
      <>
        {searchTotal !== instituteTotal && <span>SELECT / </span>}
        <span>{s.institute || "INST"} / KM</span>
      </>
    );
  }, [searchTotal, instituteTotal]);

  return (
    <Loading loading={loading}>
      <StatisticPresentationer
        title={title}
        content={selectContent}
        containerStyle={{ width: "100%" }}
        titleStyle={{ minWidth: "45%" }}
      />
    </Loading>
  );
}

export default observer(PatientStatistics);
