import { useObserver } from "mobx-react";
import useStores from "./useStore";

function usePersonStore() {
  const { patient } = useStores();

  //FIXME: 분리하기!!
  return useObserver(() => ({
    patient,
    id: patient.person.id
    // stat: patient.personStats,
    // person: patient.person
    // persons: patient.persons,
    // selected: patient.selected
    // ngsSummary: patient.ngsSummary,
    // ngsHeader: patient.ngsHeader,
    // treatments: patient.treatments,
    // ctsList: patient.ctsList,
    // cts: patient.cts,
    // nctId: patient.clinicalTrial.nctId
    // clinicalTrial: patient.clinicalTrial
  }));
}

export default usePersonStore;
