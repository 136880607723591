import { axios } from "dependencyLibrary";

class StatisticsRepository {
  URL = "/persons/statistics";
  version = "/v2";

  fetchPanel = filter => {
    return axios.request({
      method: "GET",
      url: `/panel${this.URL}${filter}`,
      ver: this.version
    });
  };

  fetchPanelStatistics = filter => {
    return axios.request({
      method: "GET",
      url: `/panel${this.URL}/link${filter}`,
      ver: this.version
    });
  };

  fetchStudy = filter => {
    return axios.request({
      method: "GET",
      url: `/study${this.URL}${filter}`,
      ver: this.version
    });
  };

  //overview 차트 API - ksh 20201201
  fetchReported = filter => {
    return axios.request({
      method: "GET",
      url: `/study/persons/reported${filter}`,
      ver: this.version
    });
  };

  //variantCategory 차트 API - ksh 20201201
  fetchVariantCategory = filter => {
    return axios.request({
      method: "GET",
      url: `/study/persons/variant${filter}`,
      ver: this.version
    });
  };

  fetchCancer = filter => {
    return axios.request({
      method: "GET",
      url: `/cancer${this.URL}${filter}`,
      ver: this.version
    });
  };

  fetchVariants = (filter = "") => {
    return axios.request({
      method: "GET",
      url: `/cancer/persons/variants${filter}`,
      ver: this.version
    });
  };
}

export default new StatisticsRepository();
