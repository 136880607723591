import React, { useMemo } from "react";
import useStore from "modules/hooks/useStore";
import { observer } from "mobx-react";
import MuiTable from "components/atoms/MuiTable";
import TableTemplate from "components/organisms/TableTemplate";
import AlleleHeader from "./AlleleHeader";
const layoutStyle = {
  position: "absolute",
  // width: "100%",
  top: 132,
  // bottom: 0,
  right: 0,
  left: 0
};

function AlleleFreq({ value }) {
  const [freq, cnt, totCnt] = value.split(",");
  return (
    <div title={freq}>
      <p style={{ margin: 0 }}>{`${freq}% (${cnt}/${totCnt})`}</p>
    </div>
  );
}

const columns = [
  {
    column: "AACR",
    title: "AACR",
    property: "aacr",
    align: "right",
    sort: false,
    options: {
      link: {
        isLinked: false
      },
      isEditable: false,
      withCellExcute: ({ value }) => WrapperComponent => {
        return (
          <WrapperComponent>
            <AlleleFreq value={value} />
          </WrapperComponent>
        );
      }
    },
    style: { head: { width: "25%" } }
  },
  {
    column: "KOVA",
    title: "KOVA",
    property: "kova",
    align: "right",
    sort: false,
    options: {
      link: {
        isLinked: false
      },
      isEditable: false,
      withCellExcute: ({ value }) => WrapperComponent => {
        return <WrapperComponent>{`${value.split(",")[0]}%`}</WrapperComponent>;
      }
    },
    style: { head: { width: "25%" } }
  },
  {
    column: "KRGDB",
    title: "KRGDB",
    property: "krgdb",
    align: "right",
    sort: false,
    options: {
      link: {
        isLinked: false
      },
      isEditable: false,
      withCellExcute: ({ value }) => WrapperComponent => {
        return (
          <WrapperComponent>
            <AlleleFreq value={value} />
          </WrapperComponent>
        );
      }
    },
    style: { head: { width: "25%" } }
  },
  {
    column: "K-MASTER",
    title: "K-MASTER",
    property: "kmaster",
    align: "right",
    sort: false,
    options: {
      link: {
        isLinked: false
      },
      isEditable: false,
      withCellExcute: ({ value }) => WrapperComponent => {
        return (
          <WrapperComponent>
            <AlleleFreq value={value} />
          </WrapperComponent>
        );
      }
    },
    style: { head: { width: "25%" } }
  }
];

function AlleleFrequency() {
  const {
    patient: { alleleFrequency }
  } = useStore();

  const header = useMemo(() => {
    return <AlleleHeader />;
  }, []);

  return (
    <TableTemplate title={"Allele Frequency"} layoutStyle={layoutStyle}>
      <MuiTable
        id={`variantProfile`}
        // parentEl={parentEl.current}
        header={header}
        columns={columns}
        data={alleleFrequency}
        // stickyHeader
        defaultPagination={{
          mode: "scroll"
          // page: 0,
          // rowsPerPage: 10
        }}
      ></MuiTable>
    </TableTemplate>
  );
}

export default observer(AlleleFrequency);
