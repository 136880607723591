import React, { Component } from "react";
// import Highcharts from "highcharts";
import * as Highcharts from "highcharts/highstock";
import HighchartsReact from "highcharts-react-official";

// ScrollChart component를 CancerHighChart, VariantHighChart, InsHighChart의 3개로 나눔
// HighchartsReact의 key 값을 서로 다르게하기 위해서임. 
// add by jdy , 2020-12

let e;
(function(H) {
  //internal functions
  function stopEvent(e) {
    if (e) {
      if (e.preventDefault) {
        e.preventDefault();
      }
      if (e.stopPropagation) {
        e.stopPropagation();
      }
      e.cancelBubble = true;
    }
  }

  //the wrap
  H.wrap(H.Chart.prototype, "render", function(proceed) {
    var chart = this,
      mapNavigation = chart.options.mapNavigation;
    proceed.call(chart);
    // Add the mousewheel event
    H.addEvent(
      chart.container,
      document.onmousewheel === undefined ? "DOMMouseScroll" : "mousewheel",
      function(event) {
        var delta,
          extr,
          step,
          newMin,
          newMax,
          axis = chart.xAxis[0];
        e = chart.pointer.normalize(event);
        // Firefox uses e.detail, WebKit and IE uses wheelDelta
        delta = e.detail || -(e.wheelDelta / 120);
        delta = delta > 0 ? 1 : -1;
        if (
          chart.isInsidePlot(
            e.chartX - chart.plotLeft,
            e.chartY - chart.plotTop
          )
        ) {
          extr = axis.getExtremes();
          step = ((extr.max - extr.min) / 5) * delta;
          axis.setExtremes(extr.min + step, extr.max + step, true, false);
        }
        stopEvent(event); // Issue #5011, returning false from non-jQuery event does not prevent default
        return false;
      }
    );
  });
})(Highcharts);

class VariantHighChart extends Component {
  state = {
    fooKey: 1
  };

  shouldComponentUpdate(nextProps) {
    // return false 하면 업데이트를 안함
    return this.props.data !== nextProps.data || this.props.height !== nextProps.height
    // return this.props.TESTDATA !== nextProps.TESTDATA
    // return true;
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.data && nextProps.data.length) {
      return {
        fooKey: prevState.fooKey + 1
      };
    }

    return null;
  }

  render() {
    const {data, height} = this.props;
    let options = {};
    
    options= {
      chart: {
        type: "bar",
        height: height,
        marginLeft: 85
        // marginTop: 60
      },
      title: { text: null },
      subtitle: { text: null },
      credits: {
        enabled: false
      },
      xAxis: {
        scrollbar: {
          enabled: true,
          liveRedraw: true,
          buttonBackgroundColor: "#f2f2f2",
          buttonBorderColor: "#f2f2f2",
          barBorderRadius: 0,
          barBorderWidth: 0,
          barBorderColor: "#f2f2f2",
          rifleColor: "#cccccc",
          trackBorderWidth: 0,
          trackBorderRadius: 0,
          height: 10
        },
        tickLength: 0,
        min: 0,
        max: 6.5,
        categories: data.name,
        labels: {
          autoRotationLimit: 10,
          formatter: function() {
            // console.log(this.value);
            return typeof this.value !== "number" ? this.value : "";
          },
          style: {
            fontSize: "0.9rem"
          }
        },
        events: {
          afterSetExtremes: function() {
            var xAxis = this,
              numberOfPoints = xAxis.series[0].xData.length - 1, // jdy 2020-12-10 numberOfPoints = xAxis.series[0].points.length - 1,
              minRangeValue = xAxis.getExtremes().min,
              maxRangeValue = xAxis.getExtremes().max;

            if (minRangeValue < 0) {
              xAxis.setExtremes(0.2, xAxis.options.max);
            } else if (maxRangeValue > numberOfPoints) {
              xAxis.setExtremes(
                numberOfPoints - xAxis.options.max + 1,
                numberOfPoints
              );
            }
          }
        }
      },
      yAxis: {
        tickPixelInterval: 50,
        //최대값
        max: data.softMax + 30,
        // softMax: data.softMax,
        labels: {
          formatter: function() {
            return this.value.toLocaleString();
          }
        },
        title: null
      },
      legend: {
        enabled: false
        // align: 'left','center','right'
        // align: "left",
        // verticalAlign: "top"
      },
      tooltip: {
        shared: true,
        formatter: function() {
          return this.points.reduce(function(s, point) {
            if (point.y > 0) {
              return (
                s +
                '<br/><span style="color:' +
                point.color +
                '">\u2B24</span>' +
                point.series.name +
                ": " +
                point.y.toLocaleString()
              );
            } else {
              return s;
            }
          }, "");
        }
      },

      plotOptions: {
        bar: {
          cropThreshold: 30,
          grouping: true,
          shadow: false,
          borderWidth: 0
        },
        series: {
          // borderWidth: 0,
          dataLabels: {
            enabled: true,
            formatter: function() {
              if (this.point.y) {
                const totalPatients =
                  data.series[this.series.index].totalPatients[
                    this.point.x
                  ];

                const y = this.point.y.toLocaleString();

                return totalPatients ? `(${y}/${totalPatients})` : y;
              } else {
                return null;
              }
            },
            // format: "{point.y:.1f}%"
            // padding: 1,
            style: {
              fontSize: "0.8rem",
              fontWeight: "none"
            }
          }
        }
      },
      series: data.series
    };

    return (
      <HighchartsReact 
        highcharts={Highcharts} 
        options={options} 
        oneToOne={true}
        key={this.state.fooKey}
      />
    );
  }
}
export default VariantHighChart;
