import React, { useMemo, useEffect, useCallback, useState } from "react";
import { makeStyles } from "@material-ui/styles";
import CustomTab from "components/atoms/ui/CustomTab";

// import CustomModal from "components/atoms/ui/CustomModal";
// import MatchButton from "components/molecules/MatchButton";
import AnnoVariantShow from "./AnnoVariantShow";

// import TabHeader from "components/atoms/TabHeader";

import Loading from "components/molecules/LoadingComponent";
import useStore from "modules/hooks/useStore";
import { observer } from "mobx-react";
import { queryString } from "dependencyLibrary";

// const useStyles = makeStyles({
//   container: {
//     display: "flex"
//   },
//   root: {
//     // display: 'flex',
//     flexWrap: 'wrap',
//   },
//   row: {
//     display: 'flex',
//     flexDirection: 'row',
//   },
//   column: {
//     display: 'flex',
//     flexDirection: 'col',
//   },

//   titleFont: {
//     fontSize: '16px',
//   },

//   textField: {
//     width: '20ch',
//   },
//   spaceField : {
//     paddingLeft: 200,
//   },
//   table: {
//     minWidth: 650,
//   },
//   tableTitle: {
//     fontSize: '20px',
//     fontStyle: 'italic'
//   },
//   nav: {
//     padding: "15px 15px 0px 100px",
//     width: '290px',
//     // verticalAlign: 'top',
//     // width: 'auto',
//     // bottom: '10px',
//     // left: '11vw',
//     // overflowY: 'auto'
//     "@media (max-width: 1500px)": {
//       padding: "15px 15px 0px 0px",
//       //   margin: 'auto'
//     },
//   },
//   tabhead: { // tabsize 에 의해 default 설정되는 것 같음.
//     width: "291px",
//     height: "30px",
//     padding: "0px"
//   },
//   tabsize: {
//     width: "200px",
//     height: "30px",
//     padding: "0px"
//   },
//   // tabcontainer: {
//   //   width: '288px',
//   //   minHeight: "800px",
//   //   height: 'calc(100% - 130px)',
//   //   background: "#f6f6f6",
//   //   // height: "auto",
//   //   border: "solid 1px #c6c6c6",
//   //   borderTop: "0px",
//   // },
//   tabContainer1: {
//     width: "100%",
//     top: 10,
//     bottom: 0,
//     position: "absolute"
//   },
//   tabContainer2: {
//     width: "100%",
//     top: 50,
//     bottom: 0,
//     position: "absolute"
//   },
//   div: {
//     width: "100%",
//     position: "absolute",
//     top: 100,
//     bottom: 0
//   }
// });


function AnnotationDialog({ history, match, location }) {
  const {
    study,
    comm
  } = useStore();

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(false);
      
  
    const query = queryString.parse(location.search);

    console.log('match : ', match.params);

    comm.setAnnotationData({
      version : query.version,
      gene : query.gene,
      variant : query.variant,
      variantType : query.variantType
    });
    comm.setAnnotationType(match.params.type);

    let array = [];

    
    array.push(study.loadAnnotationGuideK());
    array.push(study.loadAnnotationSel());
    array.push(study.loadAnnotationRef());

    const promises = array.map(item => (item));

    Promise.all(promises).then(res => {
      setLoading(true);
    });

    // study.loadAnnotationData().then(value => {
    //   setLoading(true);
    // });

    // else
    // {
    //   setLoading(true);
    // }
  }, []);

  const title = useMemo(() => {
    return (
      <span style={{ fontWeight: 500, fontSize: "1.3rem", marginLeft: 10 }}>{`${
        match.params.type === "ac" ? "Actionability" : "Pathogenicity"
      } Variant`}</span>
    );
  }, []);

  // const customTabList = useMemo(() => {
  //   return ([
  //     {count:study.refSiteAnnotationsGuide.totalElements, label:'guideK', value:'guideK'}, 
  //     {count:12, label:'currentVersion', value:'currentVersion'}, 
  //     {count:12, label:'RefVersion', value:'RefVersion'}]
  //   );
  // });
  

  return (
    <>
      <div> {title} </div> <br />
      <Loading loading={loading}>
        
        <AnnoVariantShow />

      </Loading>  
    </> 
  );
}

export default observer(AnnotationDialog);
