import React, { Component, useCallback } from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import { observer } from "mobx-react";
import { withStyles } from "@material-ui/core/styles";
import DataFormat from "components/atoms/DataFormat";

const styles = theme => ({
  th: {
    background: "#f3f3f3",
    textAlign: "center",
    borderTop: "1px solid #dedede",
    borderRight: "1px solid #dedede",
    padding: "5px 5px",
    fontSize: "1rem",
    fontWeight: 500,
    color: "#3a3a3a",
    height: 20,
    "&:last-child": {
      paddingRight: "5px"
      // borderRight: "none"
    }
  },
  totalTh: {
    background: "#f3f3f3",
    borderRight: "1px solid #dedede",
    textAlign: "center",
    padding: "5px 5px",
    height: 15,
    fontSize: "1rem",
    color: "#3a3a3a",
    fontWeight: 500
  },
  totalTd: {
    padding: "5px 5px",
    fontSize: "1rem",
    borderRight: "1px solid #dedede",
    height: 15,
    "&:last-child": {
      paddingRight: "5px"
    }
  },
  td: {
    fontSize: "1rem",
    borderRight: "1px solid #dedede",
    padding: "5px 5px",
    "&:last-child": {
      paddingRight: "5px"
    }
  },
  tr: {
    // borderLeft: "1px solid #dddddd",
    // background: "#fff"
  },
  button: {
    fontSize: "1rem",
    padding: 0,
    border: "none",
    backgroundColor: "transparent",
    cursor: "pointer",
    color: "#4588fc",
    textDecoration: "underline"
  },
  table: {
    borderLeft: "1px solid #dedede"
  }
});

const assign = (obj, newObj) => {
  let retObj = {};
  if (Object.keys(obj).length <= 0) {
    return newObj;
  }

  for (let o in obj) {
    if (o === "repeats" || o === "samples" || o === "patients") {
      !Array.isArray(obj[o])
        ? (retObj[o] = [obj[o], newObj[o]])
        : (retObj[o] = obj[o].concat(newObj[o]));
    } else {
      if (obj[o] === newObj[o]) {
        retObj[o] = obj[o];
      } else {
        !Array.isArray(obj[o])
          ? (retObj[o] = [obj[o], newObj[o]])
          : (retObj[o] = obj[o].concat(newObj[o]));
      }
    }
  }
  return retObj;
};

const RBodyRow = observer(props => {
  const { v, Component, columnList, onClick, classes, panelTotals } = props;

  const vPanel = Array.isArray(Array.from(v)) ? v[0].panel : v.panel;
  const total = panelTotals.filter(f => f.panel === vPanel);

  const handleClick = useCallback(() => {
    onClick({ link: total[0]["link"], panel: total[0]["panel"] });
  });

  let obj = {};
  let arr = [];
  if (v.length) {
    arr = v.map((data, index) => (obj = assign(obj, data)));
    return (
      <>
        <TableRow classes={{ root: classes.tr }}>
          {columnList.map((c, i) => (
            <Component
              data={arr[v.length - 1]}
              column={c}
              key={i}
              onClick={onClick}
            />
          ))}
        </TableRow>

        {total[0] && (
          <TableRow classes={{ root: classes.tr }}>
            <TableCell colSpan={2} className={classes.totalTh}>
              Total
            </TableCell>
            <TableCell className={classes.totalTd} align="right">
              <button className={classes.button} onClick={handleClick}>
                <DataFormat>{total[0].patients}</DataFormat>
              </button>
            </TableCell>
            <TableCell className={classes.totalTd} align="right">
              <DataFormat>{total[0].samples}</DataFormat>
            </TableCell>
          </TableRow>
        )}
      </>
    );
  }
  // else {
  //   return (
  //     <TableRow classes={{ root: classes.tr }}>
  //       {columnList.map((c, i) => (
  //         <Component data={v} column={c} key={i} onClick={onClick} />
  //       ))}
  //     </TableRow>
  //   );
  // }
});

const RTbody = observer(props => {
  const { tableList } = props;
  let returnArr = [];
  tableList.forEach((v, k) => {
    returnArr = returnArr.concat(<RBodyRow key={k} v={v} {...props} />);
  });
  return <TableBody>{returnArr}</TableBody>;
});

const Thead = ({ columnList, classes }) => (
  <TableHead>
    <TableRow className={classes.tr}>
      {columnList.map(({ column }, i) => (
        <TableCell key={i} className={classes.th}>
          {column}
        </TableCell>
      ))}
    </TableRow>
  </TableHead>
);

class CustomTable extends Component {
  render() {
    const { id, classes } = this.props;
    return (
      <Table id={id} className={classes.table} stickyHeader>
        <Thead {...this.props} />
        <RTbody {...this.props} />
      </Table>
    );
  }
}
export default withStyles(styles)(CustomTable);
