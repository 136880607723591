import React, { Component } from "react";
import { observer, inject } from "mobx-react";
import { withStyles } from "@material-ui/core/styles";

import MatchButton from "components/molecules/MatchButton";
import GenePanel from "components/organisms/Annotation/GenePanel";
import VariantTab from "components/organisms/Annotation/VariantTab";
import TabHeader from "components/atoms/TabHeader";
import Loading from "components/molecules/LoadingComponent";

// src/components/organisms/Annotation folder 아래 전체는 Annotation 을 보여주기 위한 추가 file 들
// add this file by jdy 2021-01-14 , Annotation 을 보여주기 위한 Component
const styles = theme => ({
  container: {
    display: "flex"
  },
  root: {
    // display: 'flex',
    flexWrap: 'wrap',
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
  },
  column: {
    display: 'flex',
    flexDirection: 'col',
  },
  margin: {
    margin: theme.spacing(1),
  },
  titleFont: {
    fontSize: '16px',
  },
  withoutLabel: {
    marginTop: theme.spacing(3),
  },
  textField: {
    width: '20ch',
  },
  spaceField : {
    paddingLeft: 200,
  },
  table: {
    minWidth: 650,
  },
  tableTitle: {
    fontSize: '20px',
    fontStyle: 'italic'
  },
  nav: {
    padding: "15px 15px 0px 100px",
    width: '290px',
    // verticalAlign: 'top',
    // width: 'auto',
    // bottom: '10px',
    // left: '11vw',
    // overflowY: 'auto'
    "@media (max-width: 1500px)": {
      padding: "15px 15px 0px 0px",
      //   margin: 'auto'
    },
  },
  tabhead: { // tabsize 에 의해 default 설정되는 것 같음.
    width: "291px",
    height: "30px",
    padding: "0px"
  },
  tabsize: {
    width: "200px",
    height: "30px",
    padding: "0px"
  },
  // tabcontainer: {
  //   width: '288px',
  //   minHeight: "800px",
  //   height: 'calc(100% - 130px)',
  //   background: "#f6f6f6",
  //   // height: "auto",
  //   border: "solid 1px #c6c6c6",
  //   borderTop: "0px",
  // },
  tabContainer1: {
    width: "100%",
    top: 10,
    bottom: 0,
    position: "absolute"
  },
  tabContainer2: {
    width: "100%",
    top: 50,
    bottom: 0,
    position: "absolute"
  },
  div: {
    width: "100%",
    position: "absolute",
    top: 100,
    bottom: 0
  }
});

let tabList = ["Current Version (2021-05)", "Prior Version (2020-11)"];

@inject("comm", "study")
@observer
class AnnotationShow extends Component {

  state = {
    open: false,
    loading: false,
    geneName: 'KRAS',
    versionTab: 0
  };

  async componentDidMount() {
    const { comm, study } = this.props;
    await study.loadGetAnnotationVersions();
    
    tabList[0] = "Current Version : " + study.annotationVersion.curVersion;
    tabList[1] = "Prior Version : " + study.annotationVersion.oldVersion;
  }

  handleApply = () => {
    const { comm, study } = this.props;

    const { geneName } = this.state;

    // let geneName = comm.annotationQueryData.gene;
    
    comm.setAnnotationQueryData({gene: geneName});

    comm.setAnnotationQueryData({versionTab : 'V2'}); //'V1' // Version 초기화
    

    if (geneName) {

      this.setState({
        geneName: geneName,
        versionTab : 0,
        open: true,
        loading : false
      });
      
      let array = [];

      array.push(study.loadAnnoStatsCurrent(geneName));
      array.push(study.loadAnnoStatsPrior(geneName));
  
      const promises = array.map(item => (item));
  
      Promise.all(promises).then(res => {
        this.setState({
          loading: true
        });
      });
      // study.loadAnnoStats(geneName).then(value => {
      //   setLoading(true);
      // });
    }
    // console.log('gene : ', geneName);
  };

  onChangeVersionTab = (valueTab) => {

    const { comm } = this.props;

    if (0 == valueTab) {
      comm.setAnnotationQueryData({versionTab : 'V2'}); //'V1' // Version 초기화
    }
    else {
      comm.setAnnotationQueryData({versionTab : 'V1'}); //'V1' // Version 초기화
    }

    this.setState({
      versionTab: valueTab
    });

    console.log('versionTab : ', this.state.versionTab);
  };

  onGeneChange = (geneName) => {
      this.setState({
        geneName: geneName,
        open: false
      });
  };

  render() {

    const {open, geneName, versionTab, loading} = this.state;

    const {
      classes,
      title,
      history,
      match,
      location
    } = this.props;

    let annotation = [];

    return (
      <div>
        <div> {title()} </div> <br />
          
        <div className={classes.container} >   
          <GenePanel onGeneChange={this.onGeneChange}/>   
          <MatchButton onClick={this.handleApply} style={{ width: 120, marginRight: 20 }}>
              Apply
          </MatchButton>
        </div>

        {open && (
          <Loading loading={loading}>
            <div className={classes.div}>
              <div className={classes.tabContainer1}>
                <TabHeader
                  tabList={tabList}
                  value={versionTab}
                  onChangeTab={this.onChangeVersionTab}
                  tabstyle={classes.tabsize}
                />
              </div>
              <div className={classes.tabContainer2}>
                <VariantTab geneName={geneName} versionTab={versionTab} />
              </div>
            </div>
          </Loading>
        )}


      </div>
    );
  }
}

export default withStyles(styles)(AnnotationShow);