import React from "react";
import LoginFrom from "components/organisms/LoginForm";
import { makeStyles } from "@material-ui/styles";
import Footer from "components/organisms/Footer";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { SettingsPowerRounded } from "@material-ui/icons";


const useStyles = makeStyles({
  rectangle_1500: {
    minWidth: 950,
    minHeight: 500
  },
  rectangle: {
    width: 750,
    height: 450,
    boxShadow: "0 2px 4px 0 rgba(8, 8, 8, 0.5)",
    backgroundColor: "rgba(50, 50, 50, 0.85)",
    color: "#ffffff",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    margin: "auto"
  },
  form_1500: {
    minWidth: 500,
    minHeight: 300
  },
  form: {
    width: 390,
    height: 200,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center"
  },
  margin: {
    margin: 0
  },
  page: {
    position: "fixed",
    display: "flex",
    alignItems: "center",
    width: "100%",
    height: "100%"
  },
  h1: {
    fontSize: "2.5rem"
  }
});

const TITLE = "LOGIN";
const DESCRIPTION = "Please use it after joining the membership";

function login(props) {



  const classes = useStyles();
  const matches = useMediaQuery("(min-width:1500px)");



  return (
    <>
      <div className={classes.page}>
        <div
          className={`${classes.rectangle} ${matches &&
            classes.rectangle_1500}`}
        >
          <div className={`${classes.form} ${matches && classes.form_1500}`}>
            <h1 className={`${classes.margin} ${classes.h1}`}>{TITLE}</h1>

            <p className={classes.margin}>{DESCRIPTION}</p>
            <LoginFrom {...props} />
          </div>
        </div>
      </div>
      {/* <Footer /> */}
    </>
  );
}

export default login;
