import React, { useEffect, useMemo, useState } from "react";
import StatisticPresentationer from "../../atoms/StatisticPresentationer";
import useStore from "modules/hooks/useStore";
import { observer } from "mobx-react";
import { makeStyles } from "@material-ui/styles";
import DataFormat from "../../atoms/DataFormat";
import Loading from "components/molecules/LoadingComponent";

const useStyles = makeStyles({
  container: {
    display: "flex",
    alignItems: "center"
  }
});

function ExplorerStatistic() {
  const {
    patient,
    patient: {
      search,
      patientStatistic: { instituteTotal, kmasterTotal, searchTotal }
    },
    statistics: { selectedCancer, search: s }
  } = useStore();

  const classes = useStyles();
  const [loading, setLoading] = useState(false);

  useEffect(() => {

    //async를 사용하는 함수 따로 선언
    const fetchData = async () => {
      setLoading(false);

      if (selectedCancer) {
        try {
          // for DEBUG by jdy
          const response = await patient.loadStatistics();
          // console.log("patient.loadStatistics()");
          
        } catch (e) {
          console.log(e);
        }
        setLoading(true);
      }
      else {
        setLoading(true);
      }
    };
    
    fetchData();

    return () => {
      patient.resetStatistics();
    };
  }, [search]);

  const selectContent = useMemo(() => {
    return (
      <>
        <DataFormat>{searchTotal}</DataFormat>
        {" / "}
        <DataFormat>{instituteTotal}</DataFormat>
        {" / "}
        <DataFormat>{kmasterTotal}</DataFormat>
      </>
    );
  }, [searchTotal, instituteTotal, kmasterTotal]);

  // const kContent = useMemo(() => {
  //   return <DataFormat>{kmasterTotal}</DataFormat>;
  // }, [kmasterTotal]);

  return (
    <div className={classes.container}>
      <Loading loading={loading}>
        <StatisticPresentationer
            title={`SELECT / ${s.institute || "INST"} / KM`}
            content={selectContent}
        />
          {/* <StatisticPresentationer title={"K-MASTER"} content={kContent} /> */}
      </Loading>
    </div>
  );
}

export default observer(ExplorerStatistic);
