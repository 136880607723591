import React, {
  useEffect,
  useRef,
  useCallback,
  useState,
  useMemo
} from "react";
import MuiTable from "components/atoms/MuiTable";
import { makeStyles } from "@material-ui/styles";
import useStore from "modules/hooks/useStore";
import { observer } from "mobx-react";
import TableTemplate from "components/organisms/TableTemplate";
import SearchKeywordInputOfExplorer from "../SearchKeywordInputOfExplorer";
import ViewDetailButton from "./ViewDetailButton";
import { withRouter } from "react-router-dom";
import DataFormat from "components/atoms/DataFormat";
// import TSVDownLoad from "./TSVDownLoad";
import MatchButton from "components/molecules/MatchButton";
import ExcelParser from "components/molecules/ExcelParser";
import XLSX from "xlsx";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";
import TsvParser from "components/molecules/TsvParser";

const useStyles = makeStyles({
  container: {
    position: "absolute",
    overflowY: "scroll",
    bottom: 10,
    top: 10,
    left: 10,
    right: 10
  },
  tableTitle: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  }
});

// const handleClick = useCallback(() => {
//   let ws = XLSX.utils.json_to_sheet(data);
//   let wb = XLSX.utils.book_new();
//   XLSX.utils.book_append_sheet(wb, ws, "Patient");
//   XLSX.writeFile(wb, "PERSON_list.xlsx");
// }, [data]);

const bodyStyle = {
  borderTop: "1px solid #dedede",
  borderLeft: "1px solid #dedede",
  borderBottom: "1px solid #dedede",
  borderRight: "1px solid #dedede",
  backgroundColor: "#fff",
  position: "absolute",
  bottom: 0,
  top: 35,
  left: 0,
  right: 0
};

const columns = [
  {
    column: "Subject",
    property: "subjectId",
    align: "left",
    sort: true,
    options: {
      link: {
        isLinked: false
      },
      isEditable: false
    }
  },
  {
    column: "Study",
    property: "studyId",
    align: "left",
    sort: true,
    options: {
      link: {
        isLinked: false
      },
      isEditable: false
    }
  },
  {
    column: "Sex",
    property: "sex",
    align: "left",
    sort: true,
    options: {
      link: {
        isLinked: false
      },
      isEditable: false
    }
  },
  {
    column: "Dx Age",
    property: "diagnosisAge",
    align: "right",
    sort: false,
    options: {
      link: {
        isLinked: false
      },
      isEditable: false
    }
  },
  {
    column: "Vital Status",
    property: "vitalstatus",
    align: "center",
    sort: false,
    options: {
      link: {
        isLinked: false
      },
      isEditable: false
    }
  },
  {
    column: "Cancer",
    property: "primaryCancer",
    align: "left",
    sort: false,
    options: {
      link: {
        isLinked: false
      },
      isEditable: false
    }
  },
  {
    column: "Cancer Detail",
    property: "cancerDetail",
    align: "left",
    sort: false,
    options: {
      link: {
        isLinked: false
      },
      isEditable: false
    }
  },
  {
    column: "Differentiation",
    property: "differentiation",
    align: "left",
    sort: false,
    options: {
      link: {
        isLinked: false
      },
      isEditable: false
    }
  },
  {
    column: "TNM",
    property: "tnm",
    align: "left",
    sort: false,
    options: {
      link: {
        isLinked: false
      },
      isEditable: false
    }
  },
  {
    column: "Review",
    property: "reviewed",
    align: "left",
    sort: true,
    options: {
      link: {
        isLinked: false
      },
      isEditable: false
    }
  }
];

function PersonTableOfExplorer(props) {
  const {
    patient,
    patient: { persons, search, personsForExcel },
    statistics: { selectedCancer }
  } = useStore();
  const [loading, setLoading] = useState(false);
  const classes = useStyles();
  const parentEl = useRef(null);
  const node = useRef(null);

  const [selected, setSelect] = useState({});

  const handleSort = useCallback(
    ({ orderBy, order }) => {
      patient.resetSearch({
        ...search,
        sort: `${orderBy},${order}`
      });
    },
    [search]
  );

  useEffect(() => {
    setLoading(false);
    if (selectedCancer) {
      const firstPerson = patient.loadPersons();
      firstPerson.then(value => {
        setLoading(true);
        setSelect({});
      });
    }
  }, [search]);

  const table = useMemo(() => {
    return (
      <MuiTable
        id={`persons`}
        ref={node}
        parentEl={parentEl.current}
        columns={columns}
        data={persons}
        stickyHeader
        oddRow
        selectRow={{
          mode: "checkbox",
          selected,
          setSelect
        }}
        defaultSort={{
          order: "desc",
          orderBy: "personId",
          handleSort: handleSort
        }}
        defaultPagination={{
          mode: "scroll",
          page: 0,
          rowsPerPage: 20
        }}
        loading={loading}
      />
    );
  }, [loading, persons, selected]);

  const onClick = useCallback(() => {
    // if (Object.keys(node.current.selectionContext.state.selected).length > 0) {
    if (Object.keys(selected).length > 0) {
      patient.resetSearch({
        ...search,
        persons: Object.keys(selected).toString()
      });
      props.history.push("/patient");
    }
  }, [search, selected]);

  const tsvParser = useMemo(() => {
    return personsForExcel.length > 0 && <TsvParser data={personsForExcel} />;
  }, [personsForExcel]);

  const excelParser = useMemo(() => {
    return personsForExcel.length > 0 && <ExcelParser data={personsForExcel} />;
  }, [personsForExcel]);

  const left = useMemo(() => {
    return (
      <div className={classes.tableTitle}>
        {/* <SearchKeywordInputOfExplorer />  */}
        {/* TSV 다운로드버튼 추가 : add by 박남준 */}
        {tsvParser}
        <ViewDetailButton onClick={onClick} />
        {excelParser}
      </div>
    );
  }, [excelParser, selected]);

  const title = useMemo(() => {
    return (
      <>
        <span style={{ marginRight: 5 }}>{"Patient List"}</span>(
        <DataFormat>{persons.totalElements}</DataFormat>)
      </>
    );
  }, [persons]);

  return (
    <TableTemplate title={title} size={left} bodyStyle={bodyStyle}>
      <div ref={parentEl} className={classes.container}>
        {table}
      </div>
    </TableTemplate>
  );
}

export default withRouter(observer(PersonTableOfExplorer));
