import React from "react";
import { extendObservable } from "mobx";
import { Link } from "react-router-dom";

class MyQuestionModel {
  constructor(data) {
    extendObservable(this, data);
    this.commented = data.commented ? (
      <div
        style={{
          color: "#fff",
          height: 15,
          backgroundColor: "#14a4e7",
          padding: "3px 8px"
        }}
      >
        Complete
      </div>
    ) : (
      <div>Waiting</div>
    );
  }

  get link() {
    return (
      <Link
        to={`/my-page/qna/${this.id}`}
      >{`[${this.type}] ${this.title}`}</Link>
    );
  }
}

export default MyQuestionModel;
