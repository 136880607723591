import React, { useRef, useMemo } from "react";
import MuiTable from "components/atoms/MuiTable";
import useStore from "modules/hooks/useStore";
import { observer } from "mobx-react";
import TableTemplate from "../../../../TableTemplate";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles({
  container: {
    overflow: "auto",
    position: "absolute",
    top: "3rem",
    bottom: 20,
    left: 20,
    right: 20
  },
  title: {
    margin: "0px 10px",
    fontSize: "1.1rem",
    color: "#454444"
  }
});

const columns = [
  {
    column: "PA",
    title: "Pathogenicity",
    property: "pa",
    align: "center",
    sort: false,
    options: {
      link: {
        isLinked: false
      },
      isEditable: false
    },
    style: { head: { width: 50 } }
  },
  {
    column: "AC",
    title: "Actionability",
    property: "ac",
    align: "center",
    sort: false,
    options: {
      link: {
        isLinked: false
      },
      isEditable: false
    },
    style: { head: { width: 50 } }
  },
  {
    column: "Gene1",
    title: "Gene1",
    property: "gene",
    align: "left",
    sort: false,
    options: {
      link: {
        isLinked: false
      },
      isEditable: false
    }
  },
  {
    column: "Gene2",
    title: "Gene2",
    property: "gene2",
    align: "left",
    sort: false,
    options: {
      link: {
        isLinked: false
      },
      isEditable: false
    }
  },

  {
    column: "Drug",
    title: "Drug",
    property: "drug",
    align: "center",
    sort: false,
    options: {
      link: {
        isLinked: false
      },
      isEditable: false
    }
  },
  // {
  //   column: "CS",
  //   title: "Clinical Significance",
  //   property: "clinicalSignificance",
  //   align: "center",
  //   sort: false,
  //   options: {
  //     link: {
  //       isLinked: false
  //     },
  //     isEditable: false
  //   }
  // },
  {
    column: "Breakpoint",
    title: "Breakpoint",
    property: "breakPoint",
    align: "left",
    sort: false,
    options: {
      link: {
        isLinked: false
      },
      isEditable: false
    },
    style: { head: { width: 300 } }
  },
  {
    column: "Read Count",
    title: "Read Count",
    property: "readCount",
    align: "right",
    sort: false,
    options: {
      link: {
        isLinked: false
      },
      isEditable: false
    },
    style: { head: { width: 100 } }
  },
  {
    column: "KF(%)",
    title: "Frequency in K-MASTER",
    property: "percent",
    align: "right",
    sort: false,
    options: {
      link: {
        isLinked: false
      },
      isEditable: false
    },
    style: { head: { width: 50 } }
  }
];

function SvTable() {
  const parentEl = useRef(null);
  const classes = useStyles();
  const {
    patient: { svList, ngsSvReported }
  } = useStore();

  const title = useMemo(() => {
    return (
      svList.totalElements && (
        <span
          className={classes.title}
        >{`${svList.totalElements} SV detected`}</span>
      )
    );
  }, [svList.totalElements]);

  const reported = useMemo(() => {
    return (
      <div style={{ display: "flex", margin: "0px 10px" }}>
        <div
          style={{
            height: "1.5rem",
            width: "1.5rem",
            backgroundColor: "#cff2ff"
          }}
        ></div>
        <div
          style={{
            marginLeft: 5,
            fontSize: "0.8rem",
            fontWeight: 400,
            display: "flex",
            alignItems: "center",
            color: "#454444"
          }}
        >
          Reported
        </div>
      </div>
    );
  }, []);

  return (
    <TableTemplate title={title} borderHide={true} size={reported}>
      <div ref={parentEl} className={classes.container}>
        <MuiTable
          id={`sv`}
          parentEl={parentEl.current}
          columns={columns}
          data={svList}
          stickyHeader
          oddRow
          selectRow={{
            mode: "none",
            defaultSelected: ngsSvReported,
            hide: true
          }}
          defaultPagination={{
            mode: "scroll",
            page: 0,
            rowsPerPage: 10
          }}
        ></MuiTable>
      </div>
    </TableTemplate>
  );
}

export default observer(SvTable);
