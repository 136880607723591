import React from "react";
import { makeStyles } from "@material-ui/styles";
import TreatementsTable from "./TreatmentsTable";
import NgsSummaryTable from "./NgsSummaryTable";
import CtsSummaryTable from "./CtsSummaryTable";
import ActionableDialog from "components/molecules/ActionableDialog";

const useStyles = makeStyles({
  layout: {
    width: "100%",
    height: "100%"
  },
  container: {
    // margin: 15,
    backgroundColor: "#fff",
    padding: 15,
    border: "1px solid #c6c6c6",
    overflowY: "auto",
    position: "absolute",
    top: 15,
    left: 15,
    right: 15,
    bottom: 0
  }
});

function Summary() {
  const classes = useStyles();

  return (
    <div className={classes.layout}>
      <div className={classes.container}>
        {/* <ActionableDialog /> */}
        <TreatementsTable />
        <br />
        <NgsSummaryTable />
        <br />
        <CtsSummaryTable />
      </div>
    </div>
  );
}

export default Summary;
