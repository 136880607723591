import React, { useMemo } from "react";
import SnvSummary from "./SnvSummary";
import CnvSummary from "./CnvSummary";
import SvSummary from "./SvSummary";

//patient 탭 메뉴구성
const panelObj = {
  snv: SnvSummary,
  cnv: CnvSummary,
  sv: SvSummary
};

function VariantTabPanel({ value }) {
  const panel = useMemo(() => {
    const Component = panelObj[value];

    return <Component />;
  });

  return panel;
}

export default VariantTabPanel;
