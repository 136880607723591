const basicColorList = [
  "#14a4e7",  
  "#F361A6",
  "#7F005F",
  "#5FB04F",
];

const labelGV = [
  "Actionable",  
  "Pathogenic",
  "VUS",
  "Complex"
];
// let variants = ['snvs', 'cnvs', 'svs']



class VariantChartModel {
  constructor(data) {
    this.id = []
    this.name = []
    this.totalSubject = []
    this.selectSubject = []
    this.selectedTotalSubject = []
    this.totalSubjectCnt = []
    this.selected = {}
    this.selectedgene = {}
    this.selectedvariant = {}

    this.series = []
    this.seriesData = []
    this.categories = []
    this.positionCategory = []
    this.choiceText = []
    this.tickPixelInterval = 20
    this.marginLeft = 70

    this.hgvsp1Digit = 0;
    this.total = 0;
    this.length = 0;
    this.nTypes = 0;
    this.nItems = 0;
    this.numPages = 1;

    this.geneName = 'TP53';

    let category = '';

    // let nShowItems = 2;
    //  console.log(data); vus, pathogenic,
    this.name = "Actionable";

    if (data.length > 0)
    {
      this.hgvsp1Digit = data[0].variant;
      this.geneName = data[0].targetGene1Id;
    }
    else
    {
      return;
    }

    for (let i of data) 
    {
      category = i.targetGene1Id + "," + i.variant;

      this.categories.push(category);

      
      if (i.type == 0)
      {
        this.seriesData.push(
          {
            // hgvsp1Digit: this.hgvsp1Digit,
            y: i.cnt,
            name: labelGV[i.type],
            color: basicColorList[i.type]
          }
        );
      }
      else
      {
        this.seriesData.push(
          {
            // hgvsp1Digit: this.hgvsp1Digit,
            y: i.cnt,
            name: labelGV[i.type],
            color: basicColorList[i.type]
          }
        );
      }
      
    }
    // end for data
    // 
    // eval last group series



    // end last group series

    // set all series
    this.series = [
      {
        name: 'Actionable',
        data: this.seriesData,
        color: basicColorList[0],
      },
      {
        name: 'Pathogenic',
        color: basicColorList[1],
      },
      {
        name: 'VUS',
        color: basicColorList[2]
      },
      {
        name: 'Complex',
        color: basicColorList[3]
      },
    ];

  } // end constructor
}

export default VariantChartModel;
