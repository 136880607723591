import React, {
  useState,
  useEffect,
  useMemo,
  useCallback,
  useRef
} from "react";
import CustomDialog from "../../atoms/ui/CustomDialog";
import CustomSelectBox from "../../atoms/ui/CustomSelectBox";
import useStore from "modules/hooks/useStore";
import { observer } from "mobx-react";
import MatchButton from "components/molecules/MatchButton";
import EditIcon from "@material-ui/icons/Edit";
import { makeStyles } from "@material-ui/core/styles";

const useStyle = makeStyles(theme => ({
  container: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderTop: "1px solid #dedede",
    borderLeft: "1px solid #dedede",
    borderRight: "1px solid #dedede"
  },
  lastContainer: {
    borderBottom: "1px solid #dedede"
  },
  div: {
    textAlign: "center",
    // display: "flex",
    // justifyItems: "center",
    // alignItems: "center",
    height: 15,
    padding: "10px 5px",
    borderRight: "1px solid #dedede",
    width: "50%"
  },
  title: {
    whiteSpace: "nowrap",
    backgroundColor: "#f3f3f3",
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  content: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  lastDiv: {
    borderRight: "none"
  }
}));

const EditStudyButtonPanel = observer(({ submit, setSubmit }) => {
  const {
    patient,
    patient: { person, studies }
  } = useStore();
  const classes = useStyle();

  useEffect(() => {

    if (person) {
      setSubmit(person.personStudyCurrent.studyId);
      // for DEBUG by jdy
      patient.loadStudied();
    }

    // useEffec 의 return은 unMoundt에서 발생하므로 async func 사용 불가
    // modified by jdy 2021-01
    // return () => {
    //   patient.loadPerson(person.id);
    //   patient.loadPersons();
    // };
  }, [person]);

  const onChange = useCallback(e => {
    setSubmit(e.target.value);
  }, []);

  const selectBox = useMemo(() => {
      // for DEBUG by jdy
    patient.loadStudied();
    // return (<br/>);
    return (
      <CustomSelectBox
        data={studies}
        onChange={onChange}
        defaultValue={submit}
        maxLength={10}
      />
    );
  }, [studies, submit]);

  return (
    <div style={{ marginBottom: 5 }}>

      <div className={`${classes.container}`}>
        <div className={`${classes.div} ${classes.title}`}>
          Initial Assigned Study
        </div>
        <div className={`${classes.div} ${classes.lastDiv} ${classes.content}`}>
          {person.personStudy && person.personStudy.studyId}
        </div>
      </div>
      <div className={`${classes.container}`}>
        <div className={`${classes.div} ${classes.title}`}>
          Currently Assigned Study
        </div>
        <div className={`${classes.div} ${classes.lastDiv} ${classes.content}`}>
          {person.personStudyCurrent && person.personStudyCurrent.studyId}
        </div>
      </div>
      <div className={`${classes.container} ${classes.lastContainer}`}>
        <div className={`${classes.div} ${classes.title}`}>
          New Designated Study
        </div>
        <div className={`${classes.div} ${classes.lastDiv} ${classes.content}`}>
          {selectBox}
        </div>
      </div>

      
    </div>
  );
});

function EditStudyButton({ children }) {
  const [open, setOpen] = useState(false);
  const [selectedStudyId, setselectedStudyId] = useState("");

  const { 
    patient,
    patient: { person }
  } = useStore();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  const onClosePanel = () => {
    // EditStudyButtonPanel 변경으로 보상.
    // useEffec 의 return은 unMoundt에서 발생하므로 async func 사용 불가
    // modified by jdy 2021-01
    
    let array = [];

    array.push(patient.loadPerson(person.id));
    array.push(patient.loadPersons());

    const promises = array.map(item => (item));

    Promise.all(promises).then(res => {
      setOpen(false);
    });
  };
  

  const handleSubmit = () => {
    patient.modifyStudy(selectedStudyId);
    onClose();
  };

  const actions = useMemo(() => {
    return (
      <>
        <MatchButton onClick={onClose} style={{ width: 100 }}>
          Cancel
        </MatchButton>
        <MatchButton onClick={handleSubmit} style={{ width: 100 }}>
          Confirm
        </MatchButton>
      </>
    );
  }, [selectedStudyId]);

  const panel = useMemo(() => {
    return (
      <EditStudyButtonPanel
        submit={selectedStudyId}
        setSubmit={setselectedStudyId}
      />
    );
  }, [selectedStudyId]);

  return (
    <>
      <MatchButton
        onClick={handleClickOpen}
        style={{ height: 20 }}
        icon={<EditIcon />}
      >
        {children}
      </MatchButton>
      <CustomDialog
        open={open}
        onClose={onClosePanel}
        title={"Change Study Assignment"}
        actions={actions}
        maxWidth={"xs"}
      >
        {panel}
      </CustomDialog>
    </>
  );
}

export default observer(EditStudyButton);
