import axios from "axios";
import history from "./history";

axios.interceptors.response.use(null, function (err) {
  if (err.response.status === 500) {
    history.push("/500");
  }
  return Promise.reject(err);
});


const DOMAIN = process.env.NODE_ENV === "development" ? "/api/match" : "/match/api";
const api = {
  request: ({ method, url, data, ver = "", ...rest }) => {
    return axios({
      method,
      url: `${DOMAIN}${ver}${url}`,
      data,
      ...rest
    }).catch(
      ({
        response,
        response: {
          status,
          data,
          data: { code, message, errors }
        }
      }) => {
        // FIXME: 백엔드 문의
        console.log(response);
        const err =
          errors && errors.length > 0
            ? errors.map(
              f => `${message}: "${f.value}" \n${f.field} => ${f.reason}\n`
            )
            : `${message}`;
        if (data)
          alert(
            code || err !== "undefined"
              ? `${code || status} ${err}`
              : JSON.stringify(data)
          );
        return;
      }
    );
  },

  setAuthInHeader: token => {
    axios.defaults.headers.common["Authorization"] = token
      ? `Bearer ${token}`
      : null;
  }
};

export default api;
