import React from "react";
import { Route, Redirect, Switch } from "react-router-dom";
import { NgsVariantContent, NotFound } from "pages";

function NgsVariantTabRoute() {
  return (
    <Route
      render={({ history, location, match }) => {
        const pathArr = location.pathname.split("/");
        if (pathArr[4] && !pathArr[5]) {
          return (
            <Redirect
              to={{
                pathname: `${match.url}/snv`,
                state: { from: location }
              }}
            />
          );
        }
      }}
    />
  );
}

function NgsVariantTabRouter(props) {
  const { match } = props;

  return (
    <Switch>
      <Route path={`${match.url}/:vtype`} component={NgsVariantContent} />
      <NgsVariantTabRoute />
    </Switch>
  );
}

export default NgsVariantTabRouter;
