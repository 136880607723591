import React, {
  useRef,
  useEffect,
  useMemo,
  useCallback,
  useState
} from "react";
import { observer } from "mobx-react";
import MuiTable from "components/atoms/MuiTable";
import TableTemplate from "components/organisms/TableTemplate";
import useStore from "modules/hooks/useStore";
import MatchButton from "components/molecules/MatchButton";
import SearchUserForm from "./SearchUserForm";
import OverviewExcelUploadBtn from "./OverviewExcelUploadBtn";

const types = [
  {
    value: "name",
    label: "Name"
  },
  {
    value: "email",
    label: "E-Mail"
  },
  {
    value: "institution",
    label: "Institution"
  },
  {
    value: "code",
    label: "Code"
  }
  // {
  //   value: "role",
  //   label: "Role"
  // },
  // {
  //   value: "status",
  //   label: "Status"
  // }
];

/* 
    변경일자:   2020-06-29
    변경자 이름: 지신복 
    변경 내용:  테이블 정렬 항목 추가
    변경 사유:  사용자 편의성을 위해 테이블 정렬 항목 추가                           
*/

const columns = [
  // {
  //   column: "ID",
  //   property: "login",
  //   align: "left",
  //   sort: false,
  //   options: {
  //     link: {
  //       isLinked: false
  //     },
  //     isEditable: false
  //   },
  //   style: { head: { width: 300 } }
  // },
  {
    column: "Name",
    property: "name",
    align: "left",
    sort: true,
    options: {
      link: {
        isLinked: false
      },
      isEditable: false
    },
    style: { head: { width: 250 } }
  },
  {
    column: "E-Mail",
    property: "email",
    align: "left",
    sort: true,
    options: {
      link: {
        isLinked: false
      },
      isEditable: false
    },
    style: { head: { width: 250 } }
  },
  {
    column: "Institution",
    property: "instituteName",
    align: "left",
    sort: true,
    options: {
      link: {
        isLinked: false
      },
      isEditable: false
    },
    style: { head: { width: 300 } }
  },
  {
    column: "Code",
    property: "institute",
    align: "left",
    sort: true,
    options: {
      link: {
        isLinked: false
      },
      isEditable: false
    },
    style: { head: { width: 100 } }
  },
  {
    column: "Role",
    property: "auth",
    align: "left",
    sort: true,
    options: {
      link: {
        isLinked: false
      },
      isEditable: false
    },
    style: { head: { width: 150 } }
  },
  {
    column: "Status",
    property: "activated",
    align: "left",
    sort: true,
    options: {
      link: {
        isLinked: false
      },
      isEditable: false
    },
    style: { head: { width: 100 } }
  },
  {
    column: "Control",
    property: "control",
    align: "center",
    sort: false,
    options: {
      link: {
        isLinked: false
      },
      isEditable: false
    },
    style: { head: { width: 100 }, body: { textAlign: "center" } }
  }
];

function UserManager(props) {
  const parentEl = useRef(null);
  const node = useRef(null);
  const {
    account,
    account: { userList, useSearch }
  } = useStore();
  const [loading, setLoading] = useState(false);
  const [selected, setSelect] = useState({});

  useEffect(() => {
    return () => {
      account.resetUserSearch();
    };
  }, []);

  useEffect(() => {
    setLoading(false);
    account.getUserList();
    setTimeout(() => {
      setLoading(true);
    }, 500);
  }, [useSearch]);

  const handlePagination = useCallback(({ page, rowsPerPage }) => {
    account.setUserSearch({ page, size: rowsPerPage });
  }, []);

  const getList = useCallback(value => {
    account.setUserSearch({ ...value, page: 0 });
  }, []);

  // const handleClickCheckbox = useCallback(({ data }) => {
  //   // console.log(data);
  //   // account.setUserSelected(data);
  // }, []);

  const onClickBtn = useCallback(() => {
    props.history.push(`${props.match.url}/register`);
  }, []);

  const onClickApprove = useCallback(() => {
    const keys = Object.keys(selected);

    	/* 
          변경일자:   2020-06-29
          변경자 이름: 지신복 
          변경 내용:  체크로직 추가
          변경 사유:  체크로직이 없슴                             
      */  
    
    for(var i=0; i<keys.length; i++){
      for(var j=0; j<userList.content.length; j++){
        if(keys[i] == userList.content[j].id){
            if(userList.content[j].activated == "Activate"){
                alert("승인된 user가 선택되었습니다.\n승인된 user => "+userList.content[j].name);
                return;
            }
        }
      }
    }

    if(keys.length == 0){
      alert('승인할 user를 선택해 주세요.');
      return;
    }
    
    if (keys.length > 0)
      account
        .activateUser({
          ids: keys
        })
        .then(value => {
          console.log(value.data);
          alert("선택된 user가 승인 되었습니다.");
          account.getUserList();
          setSelect({});
        });
  }, [selected]);

  const UserBtn = useMemo(() => {
    return (
      <div style={{ display: "flex" }}>
        <OverviewExcelUploadBtn type="user" button={true} />
        <MatchButton onClick={onClickApprove} style={{ width: 100 }}>
          Approve
        </MatchButton>
        <MatchButton onClick={onClickBtn} style={{ width: 100 }}>
          Register
        </MatchButton>
      </div>
    );
  }, [selected]);

  const userTitle = useMemo(() => {
    return (
      <>
        <span>User Management</span>
        {userList.totalElements > 0 && (
          <span
            style={{
              marginLeft: 5,
              fontSize: "0.8rem",
              color: "rgb(58, 58, 58)",
              fontWeight: 400
            }}
          >
            ({`registered ${userList.totalElements} users`})
          </span>
        )}
      </>
    );
  }, [userList]);

  const handleSort = useCallback(({ orderBy, order }) => {
    const newOrderBy = orderBy === "act" ? `${orderBy}ivated` : orderBy;

    account.setUserSearch({
      sort: `${newOrderBy},${order}`
    });
  }, []);

  return (
    <>
      <SearchUserForm types={types} getList={getList} default={"name"} />
      <TableTemplate borderHide={true} title={userTitle} size={UserBtn}>
        <div ref={parentEl}>
          <MuiTable
            id={`user_management`}
            parentEl={parentEl.current}
            ref={node}
            columns={columns}
            data={userList}
            oddRow
            headerIcon={<></>}
            selectRow={{
              mode: "checkbox",
              // handleClickCheckbox: handleClickCheckbox,
              selected,
              setSelect
            }}
            defaultSort={{
              order: useSearch.sort.split(",")[1],
              orderBy: useSearch.sort.split(",")[0],
              handleSort: handleSort
            }}
            defaultPagination={{
              page: useSearch.page,
              rowsPerPage: useSearch.size,
              handlePatination: handlePagination
            }}
            loading={loading}
          />
        </div>
      </TableTemplate>
    </>
  );
}

export default observer(UserManager);
