import React, { Component } from "react";
import Highcharts from "highcharts";
// import * as Highcharts from "highcharts/highstock";
import HighchartsReact from "highcharts-react-official";

class NoScrollVariantChart extends Component {

  render() {
    const {data, height} = this.props;
    let options = {};

    options = {
      chart: {
        type: "bar",
        height: height,
        marginLeft: 85
        // marginTop: 60
      },
      title: { text: null },
      subtitle: { text: null },
      credits: {
        enabled: false
      },
      xAxis: {
        scrollbar: {
          enabled: true,
          liveRedraw: true,
          buttonBackgroundColor: "#f2f2f2",
          buttonBorderColor: "#f2f2f2",
          barBorderRadius: 0,
          barBorderWidth: 0,
          barBorderColor: "#f2f2f2",
          rifleColor: "#cccccc",
          trackBorderWidth: 0,
          trackBorderRadius: 0,
          height: 10
        },
        tickLength: 0,
        categories: data.name,
        labels: {
          autoRotationLimit: 10,
          formatter: function() {
            return typeof this.value !== "number" ? this.value : "";
          },
          style: {
            fontSize: "0.9rem"
          }
        }
      },
      yAxis: {
        tickPixelInterval: 50,
        //최대값
        max: data.softMax + 30,
        labels: {
          formatter: function() {
            return this.value.toLocaleString();
          }
        },
        title: null
      },
      legend: {
        enabled: false
        // align: 'left','center','right'
        // align: "left",
        // verticalAlign: "top"
      },
      tooltip: {
        shared: true,
        formatter: function() {
          return this.points.reduce(function(s, point) {
            if (point.y > 0) {
              return (
                s +
                '<br/><span style="color:' +
                point.color +
                '">\u2B24</span>' +
                point.series.name +
                ": " +
                point.y.toLocaleString()
              );
            } 
            else {
              return s;
            }
          }, "");
        }
      },

      plotOptions: {
        bar: {
          cropThreshold: 30,
          grouping: true,
          shadow: false,
          borderWidth: 0
        },
        series: {
          // borderWidth: 0,
          dataLabels: {
            enabled: true,
            formatter: function() {
              if (this.point.y) {
                const totalPatients =
                  data.series[this.series.index].totalPatients[
                    this.point.x
                  ];

                const y = this.point.y.toLocaleString();

                return totalPatients ? `(${y}/${totalPatients})` : y;
              } else {
                return null;
              }
            },
            // format: "{point.y:.1f}%"
            // padding: 1,
            style: {
              fontSize: "0.8rem",
              fontWeight: "none"
            }
          }
        }
      },
      series: data.series
    };

    return <HighchartsReact highcharts={Highcharts} options={options} />;
  }
}
export default NoScrollVariantChart;
