import React, { useState, useEffect, useMemo, useCallback } from "react";
import CustomDialog from "../../components/atoms/ui/CustomDialog";
import useStore from "modules/hooks/useStore";
import { observer } from "mobx-react";
import MatchButton from "components/molecules/MatchButton";
import { makeStyles } from "@material-ui/core/styles";
import EditIcon from "@material-ui/icons/Edit";

const useStyle = makeStyles(theme => ({
  ttable: {
    margin: "5px 0px"
    // border: "solid 0.5px #dedede"
  },
  tableDiv: {
    display: "flex",
    fontSize: "11px",
    lineHeight: "3",
    borderTop: "solid 1px #dedede",
    borderLeft: "solid 1px #dedede",
    borderRight: "solid 1px #dedede"
  },
  lastDiv: {
    display: "flex",
    fontSize: "11px",
    lineHeight: "3",
    borderTop: "solid 1px #dedede",
    borderLeft: "solid 1px #dedede",
    borderRight: "solid 1px #dedede",
    borderBottom: "solid 1px #dedede"
  },
  name: {
    width: "30%",
    height: "30px",
    borderRight: "solid 1px #dedede",
    backgroundColor: "#f3f3f3",
    fontWeight: "500",
    paddingLeft: "5px"
  },
  value: {
    width: "70%",
    height: "30px",
    // border: "solid 1px #dedede",
    backgroundColor: "#ffffff",
    color: "#ababab",
    padding: "0px 5px"
  },
  buttonDiv: {
    display: "flex"
  },
  buttontitle: {
    paddingTop: "10px",
    fontSize: "11px",
    fontWeight: "500"
  },
  description: {
    width: "-webkit-fill-available",
    border: "solid 1px #dedede"
  },
  deleteButtontitle: {
    width: "50%",
    lineHeight: "2.5"
  }
}));

const EditUserPanel = observer(({ onClose, joinInfo }) => {
  const classes = useStyle();

  const { account } = useStore();

  const onChange = ({ target: { name, value } }) => {
    account.setInsDetail(
      Object.assign(joinInfo, {
        [name]: value
      })
    );
  };

  const insBox = useMemo(() => {
    return (
      <input
        name="name"
        type="text"
        className={classes.description}
        onChange={onChange}
        value={joinInfo.name || ""}
        required
      />
    );
  }, [joinInfo.name]);

  const nameBox = useMemo(() => {
    return (
      <input
        name="description"
        type="text"
        className={classes.description}
        onChange={onChange}
        value={joinInfo.description || ""}
      />
    );
  }, [joinInfo.description]);

  const emailBox = useMemo(() => {
    return (
      <input
        name="managerEmail"
        type="text"
        className={classes.description}
        onChange={onChange}
        value={joinInfo.managerEmail || ""}
      />
    );
  }, [joinInfo.managerEmail]);

  const numberBox = useMemo(() => {
    return (
      <input
        name="telephone"
        type="text"
        className={classes.description}
        onChange={onChange}
        value={joinInfo.telephone || ""}
      />
    );
  }, [joinInfo.telephone]);

  const addressBox = useMemo(() => {
    return (
      <input
        name="address"
        type="text"
        className={classes.description}
        onChange={onChange}
        value={joinInfo.address || ""}
      />
    );
  }, [joinInfo.address]);

  return (
    <>
      <div style={{ marginBottom: 10 }}>
        <div className={classes.table}>
          <div className={classes.tableDiv}>
            <div className={classes.name}>Code</div>
            <div className={classes.value}>{joinInfo.code}</div>
          </div>
          <div className={classes.tableDiv}>
            <div className={classes.name}>Name</div>
            <div className={classes.value}>{insBox}</div>
          </div>
          <div className={classes.tableDiv}>
            <div className={classes.name}>Address</div>
            <div className={classes.value}>{addressBox}</div>
          </div>
          <div className={classes.tableDiv}>
            <div className={classes.name}>Telephone</div>
            <div className={classes.value}>{numberBox}</div>
          </div>
          <div className={classes.tableDiv}>
            <div className={classes.name}>Manager Email</div>
            <div className={classes.value}>{emailBox}</div>
          </div>
          <div className={classes.lastDiv}>
            <div className={classes.name}>Description</div>
            <div className={classes.value}>{nameBox}</div>
          </div>
        </div>
      </div>
      <div className={classes.buttonDiv}>
        <MatchButton style={{ width: 210 }} onClick={onClose} type="button">
          Cancel
        </MatchButton>
        <MatchButton style={{ width: 210 }} type="submit">
          Submit
        </MatchButton>
      </div>
    </>
  );
});

function EditInsButton({ id, code }) {
  const [open, setOpen] = useState(false);
  const {
    account,
    account: { insDetail }
  } = useStore();

  useEffect(() => {
    if (open) account.getIns(`/${code}`);
  }, [open]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  const onSubmit = useCallback(
    e => {
      e.preventDefault();

      const {
        code,
        name,
        managerEmail,
        address,
        telephone,
        description
      } = insDetail;
      account.changeIns(id, {
        code,
        name,
        managerEmail,
        address,
        telephone,
        description
      });

      onClose();
    },
    [open, insDetail]
  );

  return (
    <>
      <button
        onClick={handleClickOpen}
        style={{ background: "none", border: "none", cursor: "pointer" }}
      >
        <EditIcon />
      </button>
      <CustomDialog
        open={open}
        onClose={onClose}
        title={"Edit Institution"}
        maxWidth={"xs"}
        onSubmit={onSubmit}
      >
        <EditUserPanel onClose={onClose} joinInfo={insDetail} />
      </CustomDialog>
    </>
  );
}

export default observer(EditInsButton);
