import React, { useRef, useMemo } from "react";
import useStore from "modules/hooks/useStore";
import { observer } from "mobx-react";
import SnvTable from "./SnvTable";
import CnvTable from "./CnvTable";
import SvTable from "./SvTable";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles({
  container: {
    position: "absolute",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0
  }
});

function NgsVariantPanel({ value }) {
  // const {
  //   patient: { ngs }
  // } = useStore();

  const classes = useStyles();

  // const panelRender = useRef(0);
  // console.log("Ngs panel render count ", panelRender.current++);

  // console.log(value, ngs);

  const table = useMemo(() => {
    let component = <SnvTable />;

    if (value === "cnv") component = <CnvTable />;
    else if (value === "sv") component = <SvTable />;

    return component;
  });

  return <div className={classes.container}>{table}</div>;
}

export default observer(NgsVariantPanel);
