import React from "react";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import { makeStyles } from "@material-ui/styles";

const useCustomStyles = makeStyles({
  box: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    height: "100%",
    width: "100%"
  }
});

function TabContent({ children, useStyles }) {
  const classes = useStyles();
  const customClasses = useCustomStyles();
  return (
    <Typography component="div" classes={{ root: classes.panelRoot }}>
      <Box className={customClasses.box}>{children}</Box>
    </Typography>
  );
}

function TabPanel({ children, useStyles }) {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <TabContent useStyles={useStyles}>{children}</TabContent>
    </div>
  );
}

export default TabPanel;
