import React from "react";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Draggable from "react-draggable";

function CustomModal({ title, closeComponent, children, actions }) {
  const matches = useMediaQuery("(min-width:1500px)");

  return (
    <Draggable bounds="body">
      <div
        style={{
          position: "fixed",
          top: 15,
          // bottom: 0,
          // right: 15,
          left: 15,
          display: "flex",
          flexDirection: "column",
          // justifyContent: "center",
          // alignItems: "center",
          zIndex: 1000,
          width: 800,
          height: 750,
          boxShadow: "0px 0px 10px 0px rgba(0, 0, 0, 0.5)"
        }}
      >
        <div
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: "#fff"
          }}
        >
          <div>
            <div
              style={{
                color: "#444444",
                fontSize: "1.14rem",
                padding: 10,
                // fontWeight: "bold",
                fontFamily: "Roboto",
                fontStretch: "normal",
                fontStyle: "normal"
              }}
            >
              {title}
            </div>
            <div style={{ float: "right" }}>{closeComponent}</div>
          </div>
          <div
            style={{
              position: "absolute",
              top: 40,
              bottom: 50,
              left: 20,
              right: 20
            }}
          >
            {children}
          </div>
          <div
            style={{
              position: "absolute",
              bottom: 0,
              left: 0,
              right: 0,
              display: "flex",
              justifyContent: "flex-end",
              marginBottom: 10
            }}
          >
            {actions}
          </div>
        </div>
      </div>
    </Draggable>
  );
}

export default CustomModal;
