import { axios } from "dependencyLibrary";

class StudyRepository {
  URL = "/study";

  // fetch = () => {
  //   return axios.request("GET", `${this.URL}/study-panel-count`);
  // };

  // fetchStudyAll = () => {
  //   return axios.request("GET", `${this.URL}/study-all`);
  // };

  // fetchCtGov = query => {
  //   return axios.request("GET", `${this.URL}/ctgov${query}`);
  // };



  fetchAnnotationData = (type, query) => {
 
    return axios.request({
      method: "GET",
      url: `${this.URL}/annotations/${type}${query}`
    });

    

  };


  fetchAnnoStat = query => {

    return axios.request({
      method: "GET",
      url: `${this.URL}/annotations/stats?${query}`
    });

    
  };

  fetchAnnoVersions = query => {

    return axios.request({
      method: "GET",
      url: `${this.URL}/annotations/versions?${query}`
    });
    
  };
}

export default new StudyRepository();
