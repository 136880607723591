import React, { useRef, useMemo, useEffect, useCallback } from "react";
import MuiTable from "components/atoms/MuiTable";
import usePersonStore from "modules/hooks/usePersonStore";
import useStore from "modules/hooks/useStore";
import { withRouter } from "react-router-dom";
import { observer } from "mobx-react";
import TableHeightContainer from "../../../../lib/TableHeightContainer";
import TableTemplate from "../../../TableTemplate";

const columns = [
  {
    column: "Disease",
    title: "Disease",
    property: "matchedDisease",
    align: "left",
    sort: false,
    options: {
      link: {
        isLinked: false
      },
      isEditable: false
    },
    style: { head: { width: 200 } }
  },
  {
    column: "Gene",
    title: "Gene",
    property: "matchedGene",
    align: "left",
    sort: false,
    options: {
      link: {
        isLinked: false
      },
      isEditable: false
    },
    style: { head: { width: 200 } }
  },
  {
    column: "Variant",
    title: "Variant",
    property: "matchedVariants",
    align: "left",
    sort: false,
    options: {
      link: {
        isLinked: false
      },
      isEditable: false
    },
    style: { head: { width: 200 } }
  },
  {
    column: "Age",
    title: "Ages Eligible for Study",
    property: "matchedAge",
    align: "right",
    sort: false,
    options: {
      link: {
        isLinked: false
      },
      isEditable: false
    },
    style: { head: { width: 50 } }
  },
  {
    column: "Sex",
    title: "Sexes Eligible for Study",
    property: "matchedSex",
    align: "left",
    sort: false,
    options: {
      link: {
        isLinked: false
      },
      isEditable: false
    },
    style: { head: { width: 50 } }
  }
  // {
  //   column: "Accept Healthy Volunteers",
  //   property: "countries",
  //   align: "center",
  //   sort: false,
  //   options: {
  //     link: {
  //       isLinked: false
  //     },
  //     isEditable: false
  //   }
  // }
];

function MatchedEligibilityTable(props) {
  const parentEl = useRef(null);
  // const { patient, id } = usePersonStore();
  const {
    patient: { clinicalTrialMatchedData }
  } = useStore();

  // useEffect(() => {
  //   patient.loadCts(id);
  // }, []);

  // const handleLink = useCallback(value => {
  //   props.history.push(
  //     `/patient/${props.match.params.id}/match/${value.nctId}`
  //   );
  // }, []);

  const title = useMemo(() => {
    return <h3>Matched Eligiblity</h3>;
  }, []);

  return (
    <TableTemplate title={title} borderHide={true}>
      <div ref={parentEl}>
        {/* <TableHeightContainer maxHeight={180}> */}
        <MuiTable
          id={`MatchedEligiblity`}
          // parentEl={parentEl.current}
          columns={columns}
          data={clinicalTrialMatchedData}
          // stickyHeader
          // oddRow
          // defaultPagination={{
          //   mode: "scroll",
          //   page: 0,
          //   rowsPerPage: 10
          // }}
          // handleLink={handleLink}
        ></MuiTable>
        {/* </TableHeightContainer> */}
      </div>
    </TableTemplate>
  );
}

export default withRouter(observer(MatchedEligibilityTable));
