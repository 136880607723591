import React from "react";
import Checkbox from "@material-ui/core/Checkbox";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles({
  label: {
    margin: 0
  }
});
function CustomCheckbox({ checked, onChange, label, useCustomStyle }) {
  const customClasses = useCustomStyle && useCustomStyle();
  const classes = useStyles();

  return (
    <FormControlLabel
      control={
        <Checkbox
          checked={checked}
          onChange={onChange}
          icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
          checkedIcon={<CheckBoxIcon fontSize="small" />}
          color="default"
          classes={customClasses && customClasses}
        />
      }
      label={label}
      className={classes.label}
    />
  );
}

export default CustomCheckbox;
