import React, { useEffect, useCallback, useMemo, useState } from "react";
import QuestionAndAnswerTable from "./QuestionAndAnswerTable";
import useStore from "modules/hooks/useStore";
import { observer } from "mobx-react";
import { makeStyles } from "@material-ui/styles";
import MatchButton from "components/molecules/MatchButton";

const useStyles = makeStyles({
  main: {
    position: "absolute",
    left: 15,
    right: 15,
    top: 15,
    bottom: 15
  },
  viewtitle: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    fontSize: "1rem",
    fontWeight: "600",
    color: "#3a3a3a"
  },
  titleDiv: {
    height: "35px",
    backgroundColor: "#f5f5f5",
    borderTop: "solid 1px #c8c8c8",
    borderBottom: "solid 1px #c8c8c8",
    marginTop: "10px",
    fontSize: "12px",
    color: "#636262"
  },
  mainView: {
    fontSize: "12px",
    color: "#636262",
    padding: "10px 7px 0px 0px"
  },
  openBtn: {
    width: "100px",
    height: "25px"
  },
  answerDiv: {
    //height: 120,
    border: "solid 1px #c8c8c8",
    backgroundColor: "#f1f1f1",
    padding: "15px 10px",
    marginTop: "10px"
  },
  adminContent: {
    width: "10%",
    fontSize: "12px",
    color: "#636262",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#f5f5f5"
  },
  adminComment: {
    width: "100%",
    fontSize: "1rem",
    color: "#636262",
    display: "flex",
    alignItems: "center",
    padding: 10
  },
  openbuttonDiv: {
    textAlign: "right",
    paddingBottom: "10px",
    borderBottom: "solid 1px #c8c8c8",
    height: "30px"
  }
});

const QuestionCommentTextarea = observer(() => {
  const classes = useStyles();
  const {
    account,
    account: { comment }
  } = useStore();

  useEffect(() => {
    return () => {
      account.resetQuestionComment();
    };
  }, []);

  const onChange = e => {
    account.setQuestionComment({
      comment: e.target.value
    });
  };

  const onSubmit = e => {
    e.preventDefault();
    account.submitQuestionComment();
  };

  return (
    <div className={classes.answerDiv}>
      <form
        onSubmit={onSubmit}
        style={{ display: "flex", flexDirection: "column" }}
      >
        <textarea
          placeholder="Enter a answer"
          onChange={onChange}
          value={comment.comment}
          // style={{ width: "100%" }}
          required
          rows="8"
        />
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center"
          }}
        >
          <MatchButton
            style={{ width: "100px", margin: "10px 0px" }}
            type="submit"
          >
            Submit
          </MatchButton>
        </div>
      </form>
    </div>
  );
});

const QuestionComments = observer(({ id }) => {
  const classes = useStyles();
  const {
    account,
    account: { comment }
  } = useStore();

  useEffect(() => {
    account.loadQuestionComment({ id });
  }, []);

  return (
    <div style={{ display: "flex", borderBottom: "solid 1px #c8c8c8" }}>
      <div className={classes.adminContent}>Answer</div>
      <div className={classes.adminComment}>
        <textarea
          placeholder="Enter a answer"
          value={comment.comment}
          style={{ width: "100%" }}
          required
          rows="8"
          disabled
        />
      </div>
    </div>
  );
}, []);

/* 
    변경일자:   2020-06-16
    변경자 이름: 지시복 
    변경 내용:  textarea 컴포넌트 추가 
    변경 사유:  질문, 답변에 enter키 표현이 가능한 textarea 컴포넌트 추가                               
*/
const QuestionDetail = observer(({ id, match, history }) => {
  const classes = useStyles();

  const {
    account,
    account: { question, comment }
  } = useStore();
  const [open, setOpen] = useState(false);

  useEffect(() => {
    account.loadQuestion({ id });
    return () => {
      account.resetQuestion();
      account.resetQuestionComment();
    };
  }, [id]);

  useEffect(() => {
    setOpen(false);
  }, [comment, question]);

  const reply = useMemo(() => {
    return question.commented && <QuestionComments id={id} />;
  }, [question.commented, open]);

  const commentTextarea = useMemo(() => {
    return <QuestionCommentTextarea />;
  }, [open]);

  const onClick = useCallback(() => {
    history.goBack();
  }, []);

  const onReply = useCallback(() => {
    setOpen(!open);
  }, [open]);

  return (
    <>
      <div className={classes.main}>
        <div className={classes.viewtitle}>
          {"Q&A Board"}
          <MatchButton onClick={onClick} style={{ width: "100px" }}>
            Go to list
          </MatchButton>
        </div>
        <div className={classes.titleDiv}>
          <div style={{ float: "left", padding: "10px" }}>
            {question.type ? `[ ${question.type} ] ${question.title}` : ""}
          </div>
          <div style={{ float: "right", padding: "10px" }}>
            {question.createdDateTime
              ? question.createdDateTime.slice(0, 10)
              : ""}
          </div>
        </div>
        <div className={classes.mainView}>
          <textarea
            placeholder="Enter a answer"
            value={question.contents}
            style={{ width: "100%" }}
            required
            rows="8"
            disabled
          />
        </div>
        <div className={classes.openbuttonDiv}>
          <MatchButton
            onClick={onReply}
            style={{ width: "100px", float: "right" }}
          >
            {!open ? "Reply" : "Close"}
          </MatchButton>
        </div>
        {!open ? reply : commentTextarea}
      </div>
      {/* <button onClick={onReply}>{!open ? "Open" : "Close"}</button> */}
      <br />
    </>
  );
});

function QuestionAndAnswer(props) {
  const { account } = useStore();

  useEffect(() => {
    return () => {
      account.resetQuestionSearch({});
    };
  }, []);

  return (
    <>
      {Object.keys(props.match.params).length > 0 ? (
        <QuestionDetail id={props.match.params.id} {...props} />
      ) : (
        <QuestionAndAnswerTable />
      )}
    </>
  );
}

export default QuestionAndAnswer;
