import { extendObservable, computed } from "mobx";

class TreatmentModel {
  constructor(data) {
    extendObservable(this, data);
  }

  @computed
  get date() {
    return this.startDate === this.endDate
      ? `${this.endDate}`
      : `${this.startDate} ~ ${this.endDate}`;
  }
}

export default TreatmentModel;
