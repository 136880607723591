import React, { useState, useEffect, useMemo } from "react";
import CustomDialog from "../../atoms/ui/CustomDialog";
import CustomSelectBox from "../..//atoms/ui/CustomSelectBox";
import useInputState from "../../lib/useInputState";
import MatchButton from "../../molecules/MatchButton";
import useStore from "modules/hooks/useStore";
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";
import { makeStyles } from "@material-ui/core/styles";
import { observer } from "mobx-react";
import Loading from "components/molecules/LoadingComponent";

const useStyle = makeStyles(theme => ({
  tableDiv: {
    // margin: 10
    // display: "flex",
    // fontSize: "11px",
    // lineHeight: "3"
  },
  name: {
    margin: 10
    // width: "30%",
    // height: "30px",
    // border: "solid 0.5px #dedede",
    // backgroundColor: "#f3f3f3",
    // fontWeight: "500",
    // padding: "0px 5px"
  },
  value: {
    margin: 10
    // width: "70%",
    // height: "30px",
    // border: "solid 0.5px #dedede",
    // backgroundColor: "#ffffff",
    // color: "#ababab",
    // padding: "0px 5px"
  },
  buttonContainer: {
    display: "flex",
    margin: "40px 10px 20px 10px"
  },
  buttontitle: {
    paddingTop: "10px",
    fontSize: "11px",
    fontWeight: "500"
  },
  description: {
    // width: "-webkit-fill-available",
    width: "100%",
    height: "2rem",
    border: " solid 1px #dedede"
  },
  width: {
    width: "100%",
    height: "2rem"
  }
}));

const defaultJoinInfo = {
  firstName: "",
  institutionCode: "",
  lastName: "",
  login: "",
  password: ""
};

const title = <h1 style={{ color: "#575757", padding: 5 }}>Request Join</h1>;

const RequestJoinPanel = observer(({ onClose, joinInfo, setJoinInfo }) => {
  const {
    account,
    account: { allInsList }
  } = useStore();
  const classes = useStyle();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(false);
    account.getAllInsListOfNoAuth("?size=1000").then(value => {
      setLoading(true);
    });

    return () => {
      setJoinInfo({});
    };
  }, []);

  useEffect(() => {
    // console.log(allInsList[0].value);
    if (allInsList.length > 0)
      setJoinInfo(
        Object.assign(joinInfo, {
          institutionCode: allInsList[0].value
        })
      );
  }, [allInsList]);

  const onChange = ({ target: { name, value } }) => {
    setJoinInfo(
      Object.assign(joinInfo, {
        [name]: value
      })
    );
  };

  const emailBox = useMemo(() => {
    return (
      <input
        name="login"
        type="text"
        className={classes.description}
        onChange={onChange}
        value={joinInfo.login}
        required
      />
    );
  }, [joinInfo.login]);

  const passwordInput = useMemo(() => {
    return (
      <input
        name="password"
        type="password"
        className={classes.description}
        onChange={onChange}
        value={joinInfo.password}
        required
      />
    );
  }, [joinInfo.password]);

  const firstBox = useMemo(() => {
    return (
      <input
        name="firstName"
        type="text"
        className={classes.description}
        onChange={onChange}
        value={joinInfo.firstName}
        required
      />
    );
  }, [joinInfo.firstName]);

  const lastBox = useMemo(() => {
    return (
      <input
        name="lastName"
        type="text"
        className={classes.description}
        onChange={onChange}
        value={joinInfo.lastName}
        required
      />
    );
  }, [joinInfo.lastName]);

  const organSelectBox = useMemo(() => {
    return (
      <CustomSelectBox
        name="institutionCode"
        data={allInsList}
        onChange={onChange}
        inputStyle={classes.width}
      />
    );
  }, [allInsList]);

  return (
    <div
      style={{
        width: "30rem"
      }}
    >
      <Loading loading={loading}>
        <div className={classes.tableDiv}>
          <div className={classes.name}>E-mail</div>
          <div className={classes.value}>{emailBox}</div>
        </div>
        <div className={classes.tableDiv}>
          <div className={classes.name}>Password</div>
          <div className={classes.value}>{passwordInput}</div>
        </div>
        <div className={classes.tableDiv}>
          <div className={classes.name}>First Name</div>
          <div className={classes.value}>{firstBox}</div>
        </div>
        <div className={classes.tableDiv}>
          <div className={classes.name}>Last Name</div>
          <div className={classes.value}>{lastBox}</div>
        </div>
        <div className={classes.tableDiv}>
          <div className={classes.name}>Institution(Code)</div>
          <div className={classes.value}>{organSelectBox}</div>
        </div>
        <div className={classes.buttonContainer}>
          <MatchButton style={{ width: "50%" }} onClick={onClose} type={"button"}>
            Cancel
          </MatchButton>
          <MatchButton
            style={{ width: "50%", backgroundColor: "#14a4e7", color: "#ffffff" }}
            type={"submit"}
          >
            Submit
          </MatchButton>
        </div>
      </Loading>
      
    </div>
  );
});

function RequestJoinDialog() {
  const [open, setOpen] = useState(false);
  const [joinInfo, setJoinInfo] = useState({});

  const { account } = useStore();

  const onClose = () => {
    setOpen(false);
  };

  const onClick = () => {
    setOpen(true);
  };

  const onSubmit = e => {
    e.preventDefault();
    e.stopPropagation();
    account.registerUser(joinInfo).then(value => {
      if (value) {
        alert(value);
        onClose();
      }
    });
  };

  return (
    <>
      <button
        onClick={onClick}
        style={{
          display: "flex",
          // justifyContent: "center",
          alignItems: "center",
          color: "#acacac",
          textDecoration: "underline",
          cursor: "pointer",
          background: "none",
          border: "none"
        }}
      >
        <HelpOutlineIcon style={{ marginRight: 5 }} />
        Request Join
      </button>
      <CustomDialog open={open} onClose={onClose} maxWidth={"xs"}>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center"
          }}
        >
          {title}
          <form onSubmit={onSubmit}>
            <RequestJoinPanel
              onClose={onClose}
              joinInfo={joinInfo}
              setJoinInfo={setJoinInfo}
            />
          </form>
        </div>
      </CustomDialog>
    </>
  );
}

export default RequestJoinDialog;
