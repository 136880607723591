import React, { Component } from "react";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import { withStyles } from "@material-ui/core/styles";

const styles = theme => ({
  div: {
    width: "100%"
  },
  tabs: {
    minHeight: "0",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    background: "white",
    width: "100%"
  },
  tab: {
    minWidth: "0",
    minHeight: "0",
    fontSize: "12px",
    fontFamily: '"Roboto", "Arial", "Helvetica", sans-serif',
    color: "#b4b4b4",
    fontWeight: "400",
    // border: "1px solid #00ff0000",
    borderBottom: "1px solid #c6c6c6",
    textTransform: "none",
    "&:hover": {
      backgroundColor: "#f4f4f4"
    }
    // "&:nth-last-child(1)": {
    //   // borderRight: "none",
    //   width: "33.4%"
    // }
  },
  label: {
    padding: "0px"
  },
  indicator: {
    display: "none"
  },
  header: {
    boxShadow: "none",
    height: "30px"
  },
  selectedTab: {
    background: "#f6f6f6",
    color: "#434343",
    border: "solid 1px #c6c6c6",
    borderBottom: "0px",
    fontWeight: "500"
  },
  spanborder: {
    // minWidth: "100%!important",
    maxWidth: "inherit!important",
    width: "inherit!important",
    height: "30px",
    borderBottom: "1px solid #c6c6c6"
  },
  disabletab: {
    opacity: "0.7!important"
  }
});

class TabHeader extends Component {

  shouldComponentUpdate(nextProps) {
    // return false 하면 업데이트를 안함
    return this.props.value !== nextProps.value || this.props.tabList !== nextProps.tabList
    // return true;
  }

  handleChange = (e, value) => {
    const { onChangeTab } = this.props;
    onChangeTab(value);
  };

  render() {
    const {
      tabList,
      value,
      classes,
      tabstyle,
      tabscolor,
      seleted
    } = this.props;
    return (
      <>
        <AppBar position="static" className={classes.header}>
          <Tabs
            value={value}
            onChange={this.handleChange}
            classes={{
              root: `${classes.tabs} ${tabscolor}`,
              flexContainer: classes.div,
              indicator: classes.indicator
            }}
          // variant="fullWidth"
          >
            {tabList.map((t, i) => (
              <Tab
                key={i}
                label={t}
                classes={{
                  root: `${classes.tab} ${tabstyle}`,
                  // labelContainer: classes.label,
                  selected: `${classes.selectedTab} ${seleted}`
                }}
                disableRipple={true}
              />
            ))}
            <Tab
              disabled
              classes={{
                root: `${classes.tab} ${classes.spanborder}`,
                // labelContainer: classes.label,
                selected: classes.selectedTab,
                disabled: classes.disabletab
              }}
              disableRipple={true}
            />
          </Tabs>
        </AppBar>
      </>
    );
  }
}

export default withStyles(styles)(TabHeader);
