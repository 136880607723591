const colors = [
  "#14a4e7",
  "#7bd2fb",
  "#b2e6ff",
  "#d0d0d0",
  "#b8b8b8",
  "#6c6c6c"
];

class CancerColumnModel {
  constructor({ valueAsConceptName, totalPatients, i }) {
    //TODO: id 삽입 그에따른 onclick 이벤트 변화
    this.key = valueAsConceptName;
    this.name = valueAsConceptName.replace(" cancer", "");

    // this.x = value;
    this.y = totalPatients;
    this.color = i >= 0 ? colors[i] : "#d8d8d8";
  }
}

export default CancerColumnModel;
