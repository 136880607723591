class VariantSelectModel {
  constructor(data, i) {
    this.index = i;
    this.value = this;
    this.label = data.name;
    this.key = data.key;
    this.type = data.variantClass;
    this.variantType = data.variantClass;
  }
}

export default VariantSelectModel;
