import React, { useRef, useEffect, useMemo, useState } from "react";
import MuiTable from "components/atoms/MuiTable";
import CheckCircle from "@material-ui/icons/CheckCircle";
import useCommStore from "modules/hooks/useCommStore";
import useStore from "modules/hooks/useStore";
import { observer } from "mobx-react";
import TableTemplate from "../../../TableTemplate";
import DataFormat from "components/atoms/DataFormat";
import useMediaQuery from "@material-ui/core/useMediaQuery";

const columns = [
  {
    column: "Variant",
    title: "Variant",
    property: "name",
    align: "left",
    sort: false,
    style: { head: { width: 100 } },
    options: {
      link: {
        isLinked: false
      },
      isEditable: false,
      withCellExcute: props => WrapperComponent => {
        const values = props.value.split(", ");
        const variant = values[values.length - 1].split(": ");
        return (
          <WrapperComponent>
            <div title={props.value}>
              <DataFormat maxLength={10}>{values[0]}</DataFormat>
              {variant[0] && (
                <span style={{ marginLeft: 5, fontWeight: 600 }}>
                  <DataFormat maxLength={20}>{variant[0]}</DataFormat>
                </span>
              )}
              {/* {variant[1] && `: ${variant[1].slice(0, 5)}...`} */}
            </div>
          </WrapperComponent>
        );
      }
    }
  },
  {
    column: "PA",
    title: "Pathogenicity",
    property: "pa",
    align: "center",
    sort: false,
    style: { head: { width: 100 } },
    options: {
      link: {
        isLinked: false
      },
      isEditable: false
    }
  },
  {
    column: "AC",
    property: "ac",
    title: "Actionability",
    align: "center",
    sort: false,
    style: { head: { width: 100 } },
    options: {
      link: {
        isLinked: false
      },
      isEditable: false
    }
  }
];

function CnvSummaryTable() {
  const parentEl = useRef(null);
  const { comm, gene, versionTab } = useCommStore();
  const matches = useMediaQuery("(min-height:940px)");
  // const [loading, setLoading] = useState(false);
  const {
    study,
    study: { annoCnvSummary }
  } = useStore();

  // useEffect(() => {
  //   setLoading(false);
  //   if (gene) {
  //     study.loadAnnoCnvSummary(gene).then(value => {
  //       setLoading(true);
  //     });
  //   }
  // }, [gene]);


  const size = useMemo(() => {
    return (
      annoCnvSummary[versionTab].content && `(${annoCnvSummary[versionTab].content.length} variants found)`
    );
  }, [annoCnvSummary[versionTab].content]);

  return (
    // Reported Variants 이름 수정 - 박남준
    <TableTemplate title="Variants" size={size}>
      <div
        ref={parentEl}
        style={{ maxHeight: matches ? 680 : 450, overflowY: "auto" }}
      >
        {/* <TableHeightContainer maxHeight={180}> */}
        <MuiTable
          id={`CnvSummarys`}
          parentEl={parentEl.current}
          columns={columns}
          data={annoCnvSummary[versionTab]}
          stickyHeader
          oddRow
          // loading={loading}
          defaultPagination={{
            mode: "scroll",
            page: 0,
            rowsPerPage: 10
          }}
        />
        {/* </TableHeightContainer> */}
      </div>
    </TableTemplate>
  );
}

export default observer(CnvSummaryTable);
