import React, { useRef } from "react";
import { makeStyles } from "@material-ui/styles";
import ClinicalBriefSummary from "./ClinicalBriefSummary";
import ClinicalBriefTitle from "./ClinicalBriefTitle";
import MatchedEligibilityTable from "./MatchedEligibilityTable";
import ClinicalTrialSummaryTable from "./ClinicalTrialSummaryTable";

const useStyles = makeStyles({
  container: {
    width: "100%"
  }
});

function ClinicalTabPanel() {
  const classes = useStyles();
  const panelRender = useRef(0);
  // console.log("panel render count ", panelRender.current++);

  return (
    <div className={classes.container}>
      <ClinicalBriefTitle />
      <ClinicalBriefSummary />
      <MatchedEligibilityTable />
      <ClinicalTrialSummaryTable />
    </div>
  );
}

export default ClinicalTabPanel;
