import React, { useRef } from "react";
import TableTemplate from "components/organisms/TableTemplate";
import MuiTable from "components/atoms/MuiTable";

const columns = [
  {
    column: "Update Data",
    property: "",
    align: "right",
    sort: false,
    options: {
      link: {
        isLinked: false
      },
      isEditable: false
    }
    // style: { head: { width: 200 } }
  },
  {
    column: "Update Data File Name",
    property: "",
    align: "right",
    sort: false,
    options: {
      link: {
        isLinked: false
      },
      isEditable: false
    }
    // style: { head: { width: 200 } }
  },
  {
    column: "Patient(#report/#all)",
    property: "",
    align: "right",
    sort: false,
    options: {
      link: {
        isLinked: false
      },
      isEditable: false
    }
    // style: { head: { width: 200 } }
  },
  {
    column: "Sample(#report/#all)",
    property: "",
    align: "right",
    sort: false,
    options: {
      link: {
        isLinked: false
      },
      isEditable: false
    }
    // style: { head: { width: 200 } }
  },
  {
    column: "Report(#report/#all)",
    property: "",
    align: "right",
    sort: false,
    options: {
      link: {
        isLinked: false
      },
      isEditable: false
    }
    // style: { head: { width: 200 } }
  }
];

function DataRelease() {
  const parentEl = useRef(null);

  return (
    <TableTemplate borderHide={true} title={"Data Release"}>
      <div ref={parentEl}>
        <MuiTable
          id={`datarelease`}
          parentEl={parentEl.current}
          columns={columns}
          data={[]}
          // stickyHeader
          oddRow
          defaultPagination={{
            page: 0,
            rowsPerPage: 10
            // handlePatination: handlePagination
          }}
        ></MuiTable>
      </div>
    </TableTemplate>
  );
}

export default DataRelease;
