import React, { useState, useMemo } from "react";
import CustomDialog from "../../components/atoms/ui/CustomDialog";
import useStore from "modules/hooks/useStore";
import MatchButton from "components/molecules/MatchButton";
import CloseIcon from "@material-ui/icons/Close";

function DeleteInstitution({ id }) {
  const { account } = useStore();

  const [open, setOpen] = useState(false);

  const onClose = () => {
    setOpen(false);
  };

  const handleDeleteClickOpen = () => {
    setOpen(true);
  };

  const deleteIns = () => {
    account.deleteIns(id);
    onClose();
    account.getInsList();
  };

  const actions = useMemo(() => {
    return (
      <>
        <MatchButton style={{ width: "50%" }} onClick={onClose}>
          Cancel
        </MatchButton>
        <MatchButton style={{ width: "50%" }} onClick={deleteIns}>
          Yes
        </MatchButton>
      </>
    );
  }, []);

  return (
    <>
      <button
        onClick={handleDeleteClickOpen}
        style={{ background: "none", border: "none", cursor: "pointer" }}
      >
        <CloseIcon />
      </button>
      <CustomDialog
        open={open}
        title={"Delete Institution"}
        onClose={onClose}
        actions={actions}
        maxWidth={"xs"}
      >
        <div>Finally, please check again.</div>
      </CustomDialog>
    </>
  );
}

export default DeleteInstitution;
