import React, { useState, useCallback } from "react";
import portal from "assets/images/button/kmaster.svg";
import match from "assets/images/button/matchmaster.svg";
import MatchButton from "../MatchButton";
import useStore from "modules/hooks/useStore";
import Loading from "components/molecules/LoadingComponent";

const icon = {
  portal,
  match
};
function SyncUpdateButton({ id, type, system, disabled }) {
  const { account } = useStore();
  const [loading, setLoading] = useState(true);

  const onClick = useCallback(
    e => {
      e.stopPropagation();
      setLoading(false);
      account
        .updateCdm({
          // id,
          type,
          [system]: true
        })
        .then(value => {
          if (value) {
            setLoading(true);
            account.loadSyncData({ type: type });
            account.loadSyncUpdateData({ type: type, system: system });
          } else {
            setLoading(true);
          }
        });
    },
    [loading]
  );

  return (
    <MatchButton
      title={system}
      disabled={disabled}
      onClick={onClick}
      style={{ width: 100 }}
    >
      <div style={{ display: "flex", alignItems: "center" }}>
        <Loading loading={loading}>
          <img src={icon[system]} alt="" style={{ marginRight: 3 }} /> Update
        </Loading>
      </div>
    </MatchButton>
  );
}

export default SyncUpdateButton;
