import { extendObservable, computed } from "mobx";

class AnnoRefListModel {
  constructor(data) {
    extendObservable(this, data);
  }

  // @computed
  // get disease() {
  //   const value = this.diseases.map((m, i) => m).join(", ");

  //   return this.diseases.length > 0 ? (
  //     <div title={value}>
  //       {value.length > 18 ? `${value.slice(0, 15)}...` : value}
  //     </div>
  //   ) : (
  //     ""
  //   );
  // }

  // @computed
  // get citations() {
  //   return this.citation.length > 0 ? (
  //     <div
  //       style={{ display: "flex", flexWrap: "wrap", justifyContent: "center" }}
  //     >
  //       {this.citation.map((m, i) => (
  //         <MatchButton
  //           key={i}
  //           onClick={() => {
  //             window.open(m.url);
  //           }}
  //         >
  //           {m.type === "PubMed" ? m.referenceId : m.id}
  //         </MatchButton>
  //       ))}
  //     </div>
  //   ) : (
  //     ""
  //   );
  // }

  // @computed
  // get link() {
  //   return (
  //     <MatchButton
  //       onClick={() => {
  //         window.open(this.url);
  //       }}
  //     >
  //       url
  //     </MatchButton>
  //   );
  // }
}

export default AnnoRefListModel;
