import React, {
  useEffect,
  useMemo,
  useCallback,
  useState,
  useRef
} from "react";
import useStore from "modules/hooks/useStore";
import ColumnChart from "../../atoms/ColumnChart";
import TableTemplate from "../../organisms/TableTemplate";
import Loading from "components/molecules/LoadingComponent";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { withRouter } from "react-router-dom";
import { observer } from "mobx-react";

const layoutStyle = {
  position: "absolute",
  width: "100%",
  top: 0,
  bottom: 0,
  right: 0,
  left: 0
};

function CancerColumnChart({ history }) {
  const {
    patient,
    statistics,
    statistics: { cancerChart, search, panels }
  } = useStore();
  const divRef = useRef();
  const mathes = useMediaQuery("(min-width:1500px)");
  const [loading, setLoading] = useState(false);

  search.searchType = "overview"
  useEffect(() => {

    //async를 사용하는 함수 따로 선언
    const fetchData = async () => {
      setLoading(false);

      if (!search.cancer && panels.length > 0) {
        try {
          const response = await statistics.loadCancer();
        } catch (e) {
          console.log(e);
        }
        setLoading(true);
      }
      else {
        setLoading(true);
      }
    };
    
    fetchData();

    return () => {
      statistics.resetCancer();
    };
  }, [search, panels]);

  const height =
    divRef.current &&
    divRef.current.offsetHeight -
      divRef.current.childNodes[0].childNodes[0].offsetHeight -
      30;

  const title = useMemo(() => {
    return (
      <>
        <span>Subject Distribution by Cancer</span>
      </>
    );
  }, []);

  const onClick = useCallback(value => {
    patient.setSearch({
      cancer: value.category
    });
    history.push("/patient");
  }, []);

  const chart = useMemo(() => {
    return (
      <TableTemplate title={title} layoutStyle={layoutStyle}>
        {Object.keys(cancerChart).length > 0 && (
          <ColumnChart
            series={cancerChart.series}
            onClick={onClick}
            height={height}
            category={cancerChart.category}
            legend={true}
          />
        )}
      </TableTemplate>
    );
  }, [cancerChart, mathes, height]);

  return (
    <div ref={divRef} style={layoutStyle}>
      <Loading loading={loading}>
        {chart}
      </Loading>
    </div>
  );
}

export default withRouter(observer(CancerColumnChart));
