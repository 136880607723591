import React from "react";
import CustomButton from "../../atoms/ui/CustomButton";
import { makeStyles } from "@material-ui/core/styles";

const useCustomStyle = makeStyles(theme => ({
  button: {
    border: "none",
    height: 25,
    borderRadius: 1,
    boxShadow: "1px 1px 4px 0 rgba(121, 121, 121, 0.8)",
    backgroundColor: "#fcfcfc",
    cursor: "pointer",
    fontSize: "1rem",
    fontWeight: 400,
    margin: 5,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    color: "#343434",
    fontFamily:
      '"Roboto", -apple-system, BlinkMacSystemFont, "Segoe UI", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif',
    "&:disabled": {
      backgroundColor: "#dedede",
      opacity: "0.7"
    }
  }
}));

const useStyle = makeStyles(theme => ({
  icon: {
    color: "#979797"
  }
}));

function MatchButton(props) {
  const classes = useStyle();
  return (
    <CustomButton {...props} useCustomStyle={useCustomStyle}>
      <div style={{ display: "flex", alignItems: "center", paddingTop: 1 }}>
        <div className={classes.icon} style={{ display: "flex" }}>
          {props.icon}
        </div>
        <div>{props.children}</div>
      </div>
    </CustomButton>
  );
}

export default MatchButton;
