import React, { useState, useMemo, useEffect } from "react";
import CustomDialog from "components/atoms/ui/CustomDialog";
import MatchButton from "components/molecules/MatchButton";
import { makeStyles } from "@material-ui/core/styles";
import TableTemplate from "components/organisms/TableTemplate";
import useStore from "modules/hooks/useStore";
import { observer } from "mobx-react";
import RefreshIcon from "@material-ui/icons/Refresh";
import DoneIcon from "@material-ui/icons/Done";

const useStyle = makeStyles(theme => ({
  container: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderTop: "1px solid #dedede",
    borderLeft: "1px solid #dedede",
    borderRight: "1px solid #dedede"
  },
  lastContainer: {
    borderBottom: "1px solid #dedede"
  },
  div: {
    textAlign: "center",
    // display: "flex",
    // justifyItems: "center",
    // alignItems: "center",
    height: 15,
    padding: "10px 5px",
    borderRight: "1px solid #dedede",
    width: "50%"
  },
  title: {
    whiteSpace: "nowrap",
    backgroundColor: "#f3f3f3",
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  content: {
    display: "flex"
    // alignItems: "center",
    // justifyContent: "center"
  },
  lastDiv: {
    borderRight: "none"
  },
  input: {
    width: 171
  }
}));

function SyncApiButton() {
  const {
    account,
    account: { apiKey }
  } = useStore();

  const [open, setOpen] = useState(false);
  const [input, setInput] = useState({});
  const classes = useStyle();

  useEffect(() => {
    if (open) {
      account.loadApiKey("receive");
      account.loadApiKey("publish");
    }
  }, [open]);

  useEffect(() => {
    if (apiKey.receive) {
      setInput({
        id: apiKey.receive.id,
        apiKey: apiKey.receive.apiKey,
        apiSigKey: apiKey.receive.apiSig,
        serverName: apiKey.receive.serverName
      });
    }
  }, [apiKey.receive]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  const actions = useMemo(() => {
    return (
      <>
        <MatchButton onClick={onClose} style={{ width: 100 }}>
          Close
        </MatchButton>
        {/* <MatchButton onClick={handleSubmit} style={{ width: 100 }}>
          Confirm
        </MatchButton> */}
      </>
    );
  }, []);

  const onChange = e => {
    setInput({
      ...input,
      [e.target.name]: e.target.value
    });
  };

  const onSubmit = e => {
    e.preventDefault();
    account.changeApiKey("receive", input).then(value => {
      if (value) {
        account.loadApiKey("receive");
      }
    });
  };

  const onRefresh = e => {
    e.preventDefault();
    account.changeApiKey("publish", { id: apiKey.publish.id }).then(value => {
      if (value) {
        account.loadApiKey("publish");
      }
    });
  };

  const publish = useMemo(() => {
    return (
      apiKey.publish && (
        <TableTemplate
          title={"LIMS API KEY"}
          size={
            <MatchButton
              type="button"
              onClick={onRefresh}
              icon={<RefreshIcon />}
              style={{ height: 20 }}
            >
              Refresh
            </MatchButton>
          }
        >
          <div style={{ marginBottom: 5 }}>
            <div className={`${classes.container}`}>
              <div className={`${classes.div} ${classes.title}`}>
                Server Name
              </div>
              <div
                className={`${classes.div} ${classes.lastDiv} ${classes.content}`}
              >
                {apiKey.publish.serverName}
              </div>
            </div>
            <div className={`${classes.container}`}>
              <div className={`${classes.div} ${classes.title}`}>Api key</div>
              <div
                className={`${classes.div} ${classes.lastDiv} ${classes.content}`}
              >
                <input
                  value={apiKey.publish.apiKey}
                  readOnly
                  className={`${classes.input}`}
                />
                {/* {apiKey.publish.apiKey} */}
              </div>
            </div>
            <div className={`${classes.container} ${classes.lastContainer}`}>
              <div className={`${classes.div} ${classes.title}`}>
                Apisig Key
              </div>
              <div
                className={`${classes.div} ${classes.lastDiv} ${classes.content}`}
              >
                <input
                  value={apiKey.publish.apiSig}
                  readOnly
                  className={`${classes.input}`}
                />
                {/* {apiKey.publish.apiSig} */}
              </div>
            </div>
          </div>
        </TableTemplate>
      )
    );
  }, [apiKey.publish]);

  const recieve = useMemo(() => {
    return (
      apiKey.receive && (
        <TableTemplate
          title={"PORTAL API KEY"}
          size={
            <MatchButton
              type="submit"
              icon={<DoneIcon />}
              style={{ height: 20 }}
            >
              Submit
            </MatchButton>
          }
        >
          <div style={{ marginBottom: 5 }}>
            <div className={`${classes.container}`}>
              <div className={`${classes.div} ${classes.title}`}>
                Server Name
              </div>
              <div
                className={`${classes.div} ${classes.lastDiv} ${classes.content}`}
              >
                {input.serverName}
              </div>
            </div>
            <div className={`${classes.container}`}>
              <div className={`${classes.div} ${classes.title}`}>Api key</div>
              <div
                className={`${classes.div} ${classes.lastDiv} ${classes.content}`}
              >
                <input
                  value={input.apiKey}
                  className={`${classes.input}`}
                  name="apiKey"
                  onChange={onChange}
                  required
                />
                {/* {apiKey.receive.apiKey} */}
              </div>
            </div>
            <div className={`${classes.container} ${classes.lastContainer}`}>
              <div className={`${classes.div} ${classes.title}`}>
                Apisig Key
              </div>
              <div
                className={`${classes.div} ${classes.lastDiv} ${classes.content}`}
              >
                <input
                  value={input.apiSigKey}
                  className={`${classes.input}`}
                  name="apiSigKey"
                  onChange={onChange}
                  required
                />
                {/* {apiKey.receive.apiSig} */}
              </div>
            </div>
          </div>
        </TableTemplate>
      )
    );
  }, [input]);

  return (
    <>
      <button
        onClick={handleClickOpen}
        style={{
          backgroundColor: "#e4e4e4",
          border: "1px solid #dedede",
          fontSize: "0.9rem",
          borderRadius: 4,
          // padding: 0,
          cursor: "pointer",
          color: "#343434"
        }}
      >
        Api key
      </button>
      <CustomDialog
        open={open}
        onClose={onClose}
        title={"Api key"}
        actions={actions}
        maxWidth={"xs"}
      >
        {publish}
        <br />
        <form onSubmit={onSubmit}>{recieve}</form>
      </CustomDialog>
    </>
  );
}

export default observer(SyncApiButton);
