import React, { useRef, useEffect } from "react";
import useStore from "modules/hooks/useStore";
import { observer } from "mobx-react";
import NgsInfo from "./NgsInfo";
import { makeStyles } from "@material-ui/styles";
import NgsVariantTabRouter from "shared/NgsVariantTabRouter";

const useStyles = makeStyles({
  info: {
    width: "100%"
  },
  container: {
    width: "100%"
  }
});

function NgsTabPanel({ value, match, location, history }) {
  const {
    patient,
    patient: { ngs }
  } = useStore();
  const classes = useStyles();

  useEffect(() => {
    if (Object.keys(ngs).length > 0) {
      patient.setNgsVariantTab(ngs);
    }
  }, [ngs]);

  return (
    <>
      <div className={classes.info}>
        <NgsInfo />
      </div>
      <div className={classes.container}>
        <NgsVariantTabRouter match={match} />
      </div>
    </>
  );
}

export default observer(NgsTabPanel);
