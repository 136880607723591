import React from "react";
import { makeStyles } from "@material-ui/styles";
import LeftNav from "components/organisms/LeftNav";
import CommContent from "components/organisms/CommContent";
import Footer from "components/organisms/Footer";
import useMediaQuery from "@material-ui/core/useMediaQuery";

const useStyles = makeStyles({
  comm: {
    position: "absolute",
    width: "100%",
    top: 0,
    bottom: 40
  },
  container_1300: {
    minWidth: 1300,
    // margin: "auto"
    margin: "auto"
  },
  container_1100: {
    margin: "auto"
  },
  container: {
    width: 1070,
    position: "absolute",
    left: 0,
    right: 0,
    top: 0,
    bottom: 0
  },
  footer: {
    width: 1070,
    position: "absolute",
    left: 0,
    right: 0,
    // top: 0,
    bottom: 0
  }
});

function PatientPane(props) {
  const classes = useStyles();
  const matches = useMediaQuery("(min-width:1300px)");
  const { left, right, ltitle, rtitle } = props;

  const marginTop = document.getElementById("header").offsetHeight + 15;

  return (
    <>
      <div className={classes.comm}>
        <div
          style={{ marginTop }}
          className={`${classes.container} ${
            matches ? classes.container_1300 : classes.container_1100
          }`}
        >
          {/* {left && <LeftNav title={ltitle}>{left}</LeftNav>} */}
          <CommContent title={rtitle} isLeft={left}>
            {right}
          </CommContent>
        </div>
      </div>
      {/* <div
        className={`${classes.footer} ${
          matches ? classes.container_1500 : classes.container_1280
        }`}
      >
        <Footer />
      </div> */}
    </>
  );
}

export default PatientPane;
