class PatientExcelModel {
  constructor(data) {
    const {
      currentTumorStage,
      currentRegionalLymphNodeStage,
      currentDistantMetastasisStage,
      currentCancerStaging
    } = data.diagnosisInfo;
    const na = "n/a";

    this.Subject = data.subjectId;
    this.Study = data.personStudyCurrent.studyId;
    this.Sex = data.sex;
    this.DxAge = data.specimenList[0].diagnosisAge;
    this.VitalStatus = data.vitalStatus;
    this.Cancer = data.diagnosisInfo.primaryCancer;
    this.CancerDetail = data.diagnosisInfo.detailCancer;
    this.Differentiation = data.diagnosisInfo.currentDegreeDifferentiation;
    this.TNM = `T${currentTumorStage || na}N${currentRegionalLymphNodeStage ||
      na}M${currentDistantMetastasisStage || na}(${currentCancerStaging ||
      na})`;
    this.Review = "YES";
  }
}

export default PatientExcelModel;
