import { configure, action, observable, set, toJS } from "mobx";
import PatientReviewRepository from "../repository/PatientReviewRepository";
import DateFormatModel from "../model/DateFormatModel";
import TableFormatModel from "../model/TableFormatModel";

const requestDtoName = {
  therapies: "clinicalReviewTherapiesDTOList",
  potential: "clinicalReviewPotentialDTOList",
  comment: "clinicalReviewCommentDTOList"
};

configure({ enforceAction: "true" });
export default class PatientReviewStore {
  @observable clinicalReviews = {
    therapies: {},
    potential: {},
    comment: {}
  };

  constructor(root) {
    this.root = root;
  }

  @action
  async loadReviews(type) {
    const id = this.root.patient.person.id;
    if (id) {
      const { data } = await PatientReviewRepository.fetchReviews({
        id,
        type
      });

      const key = Object.keys(data).filter(f => f !== "personId");
      this.clinicalReviews[type] = new TableFormatModel(
        data[key].map(m => new DateFormatModel(m))
      );
    }
  }

  @action
  async submitReviews({ type, therapies }) {
    const id = this.root.patient.person.id;
    if (id) {
      const { data } = await PatientReviewRepository.submitReviews({
        type,
        payload: { [requestDtoName[type]]: [therapies], personId: id }
      });
      return data;
    }
  }
}
