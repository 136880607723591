import React, { useEffect, useCallback, useMemo } from "react";
import TableTemplate from "components/organisms/TableTemplate";
import useStore from "modules/hooks/useStore";
import { observer } from "mobx-react";
import CustomInput from "../../atoms/ui/CustomInput";
import CustomSelectBox from "../../atoms/ui/CustomSelectBox";
import useAssign from "./useAssign";
import useInputObjState from "components/lib/useInputObjState";

const formStyle = {
  display: "flex",
  flexDirection: "column"
};

const layoutStyle = {
  backgroundColor: "#eef5ff"
};

const headStyle = {
  borderBottom: "1px solid #dedede"
};

const titleStyle = {
  fontSize: "1rem",
  fontWeight: 400,
  padding: `10px 0px`,
  color: "#3a3a3a"
};

// function useInputMobx(property) {
//   const {
//     patient,
//     patient: { iidx }
//   } = useStore();

//   const handleChangeState = useCallback(({ target: { value } }) => {
//     patient.setAddedPotential(iidx, property, value);
//   }, []);

//   return useObserver(() => [patient.getAddedPotential(), handleChangeState]);
// }

// function useSelectBoxMobx() {
//   const {
//     patient,
//     patient: { iidx }
//   } = useStore();

//   const handleChangeState = useCallback(({ target: { value } }) => {
//     patient.setAddedPotentialObj(iidx, JSON.parse(value));
//   }, []);

//   return useObserver(() => [handleChangeState]);
// }

// function NctIdInput() {
//   const [nctId, handleChangeNctId] = useInputMobx("nctId");
//   return (
//     <>
//       <div style={titleStyle}>NCT Number</div>
//       <CustomInput
//         // placeholder="NCT Number"
//         type="text"
//         onChange={handleChangeNctId}
//         value={nctId}
//       // inputStyle={{ width: "100%" }}
//       />
//     </>
//   );
// }

// const SecInput = observer(() => {
//   const [handleSecId] = useSelectBoxMobx();

//   const {
//     patient,
//     patient: { ngsSelect }
//   } = useStore();

//   useEffect(() => {
//     if (ngsSelect.length > 0)
//       patient.setAddedPotentialObj(0, JSON.parse(ngsSelect[0].value));
//   }, [ngsSelect]);

//   const selectBox = useMemo(() => {
//     return (
//       <>
//         <div style={titleStyle}>Test ID</div>
//         <CustomSelectBox
//           data={ngsSelect}
//           onChange={handleSecId}
//           // inputStyle={{ width: "100%" }}
//           maxLength={30}
//         />
//       </>
//     );
//   }, [ngsSelect]);

//   return selectBox;
// });

// const VariantInput = observer(() => {
//   const [handleChangeVariantName] = useSelectBoxMobx();

//   const {
//     patient,
//     patient: { variantsOfPatient }
//   } = useStore();

//   useEffect(() => {
//     if (variantsOfPatient.length > 0)
//       patient.setAddedPotentialObj(0, JSON.parse(variantsOfPatient[0].value));
//   }, [variantsOfPatient]);

//   const selectBox = useMemo(() => {
//     return (
//       <>
//         <div style={titleStyle}>Eligible variant</div>
//         <CustomSelectBox
//           data={variantsOfPatient}
//           onChange={handleChangeVariantName}
//           // inputStyle={{ width: "100%" }}
//           maxLength={30}
//         />
//       </>
//     );
//   }, [variantsOfPatient]);

//   return selectBox;
// });

// function CommentInput() {
//   const [comment, handleChangeComment] = useInputMobx("comment");
//   return (
//     <>
//       <div style={titleStyle}>Comment</div>
//       <textarea
//         // style={{ width: "100%" }}
//         // placeholder="comment"
//         type="text"
//         onChange={handleChangeComment}
//         value={comment}
//       />
//     </>
//   );
// }

const type = "potential";
function PotentialClinicalTrialsInputs({ button }) {
  const {
    comm,
    patient,
    review,
    patient: { ngsSelect, variantsOfPatient }
  } = useStore();

  const [therapies, setTherapies, handleChangeTherapies] = useInputObjState({
    //   nctId: "",
    //   secId: "",
    //   variantId: "",
    //   variantType: "",
    //   comment: ""
  });

  useAssign(ngsSelect, setTherapies, therapies);
  useAssign(variantsOfPatient, setTherapies, therapies);

  const onSubmit = e => {
    e.preventDefault();
    review
      .submitReviews({
        type,
        therapies
      })
      .then(value => {
        if (value) {
          alert(`${value.type} ${value.success}`);
          review.loadReviews(type);
          comm.setOpen(false);
        }
      });
    // patient.submitPotentialClinicalTrials();
    // comm.setOpen(false);
  };

  const nctIdInput = useMemo(() => {
    return (
      <>
        <div style={titleStyle}>NCT Number</div>
        <CustomInput
          name="nctId"
          type="text"
          onChange={handleChangeTherapies}
          value={therapies.nctId}
        />
      </>
    );
  }, []);

  const secInput = useMemo(() => {
    return (
      <>
        <div style={titleStyle}>Test ID</div>
        <CustomSelectBox
          data={ngsSelect}
          onChange={handleChangeTherapies}
          maxLength={30}
        />
      </>
    );
  }, []);

  const variantInput = useMemo(() => {
    return (
      <>
        <div style={titleStyle}>Relevant Variant</div>
        <CustomSelectBox
          data={variantsOfPatient}
          onChange={handleChangeTherapies}
          maxLength={30}
        />
      </>
    );
  }, []);

  const commentInput = useMemo(() => {
    return (
      <>
        <div style={titleStyle}>Comment</div>
        <textarea
          type="text"
          name="comment"
          onChange={handleChangeTherapies}
          value={therapies.comment}
          required
        />
        {/* <CustomInput
        // placeholder="comment"
        type="text"
        onChange={handleChangeComment}
        value={comment}
      /> */}
      </>
    );
  }, []);

  const title = useMemo(() => {
    return (
      <div style={{ color: "#14a4e7", fontSize: "1.14rem" }}>
        {"Clinical Trial Recommendation"}
      </div>
    );
  }, []);

  return (
    <>
      <TableTemplate
        title={title}
        borderHide={true}
        layoutStyle={layoutStyle}
        headStyle={headStyle}
        // bodyStyle={bodyStyle}
      >
        <form onSubmit={onSubmit} style={formStyle}>
          {nctIdInput}
          {secInput}
          {variantInput}
          {commentInput}
          {button}
        </form>
      </TableTemplate>
    </>
  );
}

export default observer(PotentialClinicalTrialsInputs);
