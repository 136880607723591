import React, { useEffect, useCallback, useState } from "react";
import { makeStyles } from "@material-ui/styles";
import CustomTab from "components/atoms/ui/CustomTab";
import usePersonStore from "modules/hooks/usePersonStore";
import useStore from "modules/hooks/useStore";
import PatientTabPanel from "./PatientTabPanel";
import { observer } from "mobx-react";
import Loading from "components/molecules/LoadingComponent";

const useStyles = makeStyles({
  tabContainer: {
    width: "100%",
    height: "100%"
  },
  container: {
    position: "absolute",
    bottom: 0,
    width: "100%",
    top: "35px"
  },
  tabsRoot: {
    minHeight: 35
  },
  tabRoot: {
    fontSize: "1.1rem",
    borderBottom: "1px solid #c6c6c6",
    backgroundColor: "#ffffff",
    minWidth: 182,
    minHeight: 35,
    fontWeight: 400
  },
  panelRoot: {
    // overflowY: "auto",
    height: "100%",
    width: "100%",
    backgroundColor: "#f6f6f6",
    borderRight: "1px solid #c6c6c6",
    borderLeft: "1px solid #c6c6c6",
    borderTop: "none",
    borderBottom: "1px solid #c6c6c6"
  },
  selected: {
    borderRight: "1px solid #c6c6c6",
    borderLeft: "1px solid #c6c6c6",
    borderTop: "1px solid #c6c6c6",
    borderBottom: "none",
    backgroundColor: "#f6f6f6",
    fontWeight: 600
  },
  indicator: {
    display: "none"
  },
  empty: {
    borderBottom: "1px solid #c6c6c6",
    maxWidth: "100%",
    width: "100%",
    minHeight: 35
  },
  disabled: {
    opacity: "0.7!important",
    color: "darkgrey"
  },
  wrapper: {
    fontSize: "1rem"
  }
});

function PatientTab({ history, match, location }) {
  const { id } = usePersonStore();
  const {
    patient,
    patient: { personStats, tabUrl }
  } = useStore();

  const [loading, setLoading] = useState(false);

  const handleChange = useCallback(
    (e, newValue) => {
      let url = "";
      if (newValue === "match" || newValue === "ngs") {
        url = tabUrl[newValue];
      }
      history.push(`${match.url.replace(match.params.tab, newValue)}${url}`);
    },
    [match, tabUrl]
  );

  useEffect(() => {
    setLoading(false);

    if (history.action === "POP" || location.state) {
      patient.loadPerson(match.params.id).then(value => {
        setLoading(true);
      });
    }
    else {
      setLoading(true);
    }
      
  }, [match.params.id, history]);

  useEffect(() => {
    if (id) {
      patient.loadStats(id).then(value => {
        console.log('patient_personStats', patient.personStats);
      });
    }
  }, [id]);

  const defaultValue = match.params.tab;

  return (
    <CustomTab
      columns={personStats}
      onChange={handleChange}
      useStyles={useStyles}
      defaultValue={defaultValue}
    >
      <Loading loading={loading}>
        <PatientTabPanel value={defaultValue} />
      </Loading>
    </CustomTab>
  );
}

export default observer(PatientTab);
