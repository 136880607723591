import React from "react";
import { makeStyles } from "@material-ui/styles";
import MatchButton from "components/molecules/MatchButton";
import VisibilityIcon from "@material-ui/icons/Visibility";

const useCustomStyle = makeStyles({
  button: {
    height: 29,
    borderRadius: 1,
    boxShadow: "1px 1px 4px 0 rgba(121, 121, 121, 0.8)",
    backgroundColor: "#fcfcfc",
    border: 0,
    cursor: "pointer",
    fontSize: "1rem"
  }
});

function ViewDetailButton(props) {
  return (
    <MatchButton
      useCustomStyle={useCustomStyle}
      onClick={props.onClick}
      icon={<VisibilityIcon />}
    >
      View Detail
    </MatchButton>
  );
}

export default ViewDetailButton;
