import React, { useMemo, useState, useCallback, useEffect } from "react";
import useStore from "modules/hooks/useStore";
import { observer } from "mobx-react";

import InputLabel from '@material-ui/core/InputLabel';

import FormControl from '@material-ui/core/FormControl';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputAdornment from '@material-ui/core/InputAdornment';
import { makeStyles } from '@material-ui/core/styles';


// src/components/organisms/Annotation folder 아래 전체는 Annotation 을 보여주기 위한 추가 file 들
// add this file by jdy 2021-01-14 , Annotation 을 보여주기 위한 Component
// AnnotationDialog 대화 상자 내의 form 인 VaiantSelect에서 SNV 선택시 나타남

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  margin: {
    margin: theme.spacing(1),
  },
  withoutLabel: {
    marginTop: theme.spacing(3),
  },
  textField: {
    width: '20ch',
  },
  spaceField : {
    paddingLeft: 15,
  },
}));

function GenePanel({onGeneChange}) {
  const {
    comm,
    comm: { annotationQueryData }
  } = useStore();

  const classes = useStyles();

  const [values, setValues] = useState({
    gene: comm.annotationQueryData.gene
  });

  const handleChange = (prop) => (event) => {
    
    setValues({ ...values, [prop]: event.target.value.toUpperCase() });
    onGeneChange(event.target.value.toUpperCase());
    // comm.setAnnotationQueryData({[prop] : event.target.value.toUpperCase()});
  };

  return (
    <form className={classes.root} noValidate autoComplete="off">
      <div>
        <FormControl className={`${classes.margin} ${classes.textField}`} variant="outlined">
          <InputLabel htmlFor="outlined-adornment-gene">Gene ? </InputLabel>
          <OutlinedInput
            id="outlined-adornment-gene"
            value={values.gene}
            onChange={handleChange('gene')}

            endAdornment={<InputAdornment position="end">ex : KRAS</InputAdornment>}
            labelWidth={120}
          />

        </FormControl>

      </div>
    </form>
  );
}

export default observer(GenePanel);