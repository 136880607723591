import React from "react";
import { makeStyles } from "@material-ui/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";

const useStyle = makeStyles(theme => ({
  container: {
    position: "fixed",
    top: 0,
    bottom: 0,
    right: 0,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#eef5ff",
    width: 287,
    zIndex: 100,
    color: "#000",
    boxShadow: "0 2px 4px 0 rgba(0, 0, 0, 0.5)"
  },
  body: {
    position: "absolute",
    top: 50,
    bottom: 10,
    right: 0,
    left: 0,
    overflowY: "auto"
  },
  title: {
    position: "absolute",
    top: 0,
    right: 0,
    left: 0,
    height: 50,
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between"
  },
  actions: {
    position: "absolute",
    bottom: 0,
    right: 0,
    left: 0,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: 30
  }
}));

function SubContent(props) {
  const classes = useStyle();
  const matches = useMediaQuery("(min-width:1500px)");
  const marginTop = document.getElementById("header").offsetHeight;

  // const width = window.screen.width - 1500 - 20 - 20 - 15;
  // style={{ marginTop }}

  return (
    <div style={{ marginTop }} className={classes.container}>
      <div className={classes.title}>
        <div
          style={{
            color: "#444444",
            fontSize: "1.14rem",
            padding: 10,
            // fontWeight: "bold",
            fontFamily: "Roboto",
            fontStretch: "normal",
            fontStyle: "normal"
          }}
        >
          {props.title}
        </div>
        <div style={{ float: "right" }}>{props.closeComponent}</div>
      </div>
      <div className={classes.body}>{props.children}</div>
      <div>{props.actions}</div>
    </div>
  );
}

export default SubContent;
