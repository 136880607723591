import React from "react";
import { makeStyles } from "@material-ui/styles";
import Subject from "assets/images/statistic/subject.svg";

const useStyles = makeStyles({
  container: {
    fontSize: "1rem",
    fontWeight: 400,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: 0,
    "& div": {
      // padding: "3px 5px"
      // textAlign: "center"
    }
  },
  title: {
    padding: "3px 5px",
    textAlign: "center",
    backgroundColor: "#a5a5a5",
    border: "1px solid #d4d4d5",
    minWidth: 120,
    fontWeight: 600
  },
  icon: {
    display: "flex",
    width: 25,
    height: "1.728rem",
    backgroundColor: "#f3f3f3",
    borderTop: "1px solid #d4d4d5",
    borderBottom: "1px solid #d4d4d5",
    borderRight: "1px solid #d4d4d5"
  },
  content: {
    padding: "3px 5px",
    backgroundColor: "#ffffff",
    borderTop: "1px solid #d4d4d5",
    borderRight: "1px solid #d4d4d5",
    borderBottom: "1px solid #d4d4d5",
    color: "#4c4c4c",
    minWidth: 45,
    textAlign: "right",
    width: "100%"
  }
});

function StatisticPresentationer({
  title,
  icon,
  content,
  containerStyle,
  titleStyle
}) {
  const classes = useStyles();
  return (
    <div className={classes.container} style={containerStyle}>
      <div className={classes.title} style={titleStyle}>
        {title}
      </div>
      {
        <div className={classes.icon}>
          {icon || <img src={Subject} alt="" style={{ padding: 3 }} />}
        </div>
      }
      <div className={classes.content}>{content}</div>
    </div>
  );
}

export default StatisticPresentationer;
