import React from "react";
import { extendObservable, computed } from "mobx";
import { Link } from "react-router-dom";

class FollowPatientTableModel {
  constructor(data) {
    extendObservable(this, data);

    this.subObj = data.followSpecimenDTOList.map(m => {
      return {
        id: data.personSourceId,
        sample: m.specimenSourceId,
        panels: m.followPanelDTOList.map(m => m.panel),
        snvs: m.followPanelDTOList.map(m => m.snvCount),
        cnvs: m.followPanelDTOList.map(m => m.cnvCount),
        svs: m.followPanelDTOList.map(m => m.svCount),
        secId: m.followPanelDTOList.map(m => m.secId),
        reviews: "YES",
        actionableCount: "",
        pathogenicCount: ""
      };
    });
  }

  @computed
  get subject() {
    return this.personSourceId || "";
  }

  @computed
  get dxAge() {
    return new Date().getFullYear() - this.age + 1;
  }

  @computed
  get samples() {
    return this.followSpecimenDTOList.map(m => m.specimenSourceId);
  }

  @computed
  get panels() {
    return this.followSpecimenDTOList
      .map(m => m.followPanelDTOList.map(m => m.panel))
      .toString()
      .split(",");
  }

  @computed
  get snvs() {
    let arr = [];

    this.followSpecimenDTOList
      .map(m =>
        m.followPanelDTOList.map(m => {
          return (
            <Link
              to={{
                pathname: `/patient/${this.id}/ngs/${m.secId}/snv`,
                state: { from: "/my-page" }
              }}
              style={{
                // padding: 0,
                // border: "none",
                // backgroundColor: "transparent",
                // cursor: "pointer",
                color: "rgb(69, 136, 252)",
                textDecoration: "underline",
                pointerEvents: !m.snvCount && "none"
              }}
            >
              {m.snvCount}
            </Link>
          );
        })
      )
      .forEach(el => {
        el.forEach(e => {
          arr.push(e);
        });
      });

    return arr;
  }

  @computed
  get cnvs() {
    let arr = [];

    this.followSpecimenDTOList
      .map(m =>
        m.followPanelDTOList.map(m => {
          return (
            <Link
              to={{
                pathname: `/patient/${this.id}/ngs/${m.secId}/cnv`,
                state: { from: "/my-page" }
              }}
              style={{
                // padding: 0,
                // border: "none",
                // backgroundColor: "transparent",
                // cursor: "pointer",
                color: "rgb(69, 136, 252)",
                textDecoration: "underline",
                pointerEvents: !m.cnvCount && "none"
              }}
            >
              {m.cnvCount}
            </Link>
          );
        })
      )
      .forEach(el => {
        el.forEach(e => {
          arr.push(e);
        });
      });

    return arr;
  }

  @computed
  get svs() {
    let arr = [];

    this.followSpecimenDTOList
      .map(m =>
        m.followPanelDTOList.map(m => {
          return (
            <Link
              to={{
                pathname: `/patient/${this.id}/ngs/${m.secId}/sv`,
                state: { from: "/my-page" }
              }}
              style={{
                // padding: 0,
                // border: "none",
                // backgroundColor: "transparent",
                // cursor: "pointer",
                color: "rgb(69, 136, 252)",
                textDecoration: "underline",
                pointerEvents: !m.svCount && "none"
              }}
            >
              {m.svCount}
            </Link>
          );
        })
      )
      .forEach(el => {
        el.forEach(e => {
          arr.push(e);
        });
      });

    return arr;
  }

  @computed
  get reviews() {
    return this.followSpecimenDTOList.map(m => "YES");
  }

  @computed
  get acs() {
    return this.followSpecimenDTOList.map(m => m.actionableCount || "");
  }

  @computed
  get pas() {
    return this.followSpecimenDTOList.map(m => m.pathogenicCount || "");
  }
}

export default FollowPatientTableModel;
