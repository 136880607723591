import React, {
  useRef,
  useEffect,
  useCallback,
  useMemo,
  useState
} from "react";
import MuiTable from "components/atoms/MuiTable";
import usePersonStore from "modules/hooks/usePersonStore";
import useStore from "modules/hooks/useStore";
import { withRouter } from "react-router-dom";
import { observer } from "mobx-react";
import TableTemplate from "../../../TableTemplate";
import TableHeightContainer from "../../../../lib/TableHeightContainer";
import useMediaQuery from "@material-ui/core/useMediaQuery";

const columns = [
  {
    column: "NCT Number",
    title: "NCT Number",
    property: "nctId",
    align: "left",
    sort: false,
    options: {
      link: {
        isLinked: true,
        style: {
          padding: 0,
          border: "none",
          backgroundColor: "transparent",
          cursor: "pointer",
          color: "#4588fc",
          textDecoration: "underline",
          fontSize: "1rem",
          fontFamily: "Roboto"
        }
      },
      isEditable: false
    },
    style: { head: { width: 100 } }
  },
  {
    column: "Phase",
    title: "Phase",
    property: "phases",
    align: "left",
    sort: false,
    options: {
      link: {
        isLinked: false
      },
      isEditable: false
    },
    style: { head: { width: 100 } }
  },
  {
    column: "Drug",
    title: "Drug",
    property: "drugs",
    align: "left",
    sort: false,
    options: {
      link: {
        isLinked: false
      },
      isEditable: false
    },
    style: { head: { width: 200 } }
  },
  {
    column: "Disease or Conditions",
    title: "Disease or Conditions",
    property: "conditions",
    align: "left",
    sort: false,
    options: {
      link: {
        isLinked: false
      },
      isEditable: false
    },
    style: { head: { width: 200 } }
  },
  {
    column: "Institution",
    title: "Institution",
    property: "institute",
    align: "left",
    sort: false,
    options: {
      link: {
        isLinked: false
      },
      isEditable: false
    },
    style: { head: { width: 200 } }
  },
  {
    column: "Country",
    title: "Country",
    property: "countries",
    align: "left",
    sort: false,
    options: {
      link: {
        isLinked: false
      },
      isEditable: false
    },
    style: { head: { width: 200 } }
  }
];

// 2021.10.13 modify by jdy : replace ctsList with ctsKoreaList

function CtsSummaryTable(props) {
  const parentEl = useRef(null);
  const { patient, id } = usePersonStore();
  const [loading, setLoading] = useState(false);
  const {
    patient: { ctsKoreaList }
  } = useStore();

  const matches = useMediaQuery("(min-height:940px)");

  useEffect(() => {
    setLoading(false);
    if (id) {
      // 2021.10.13 modify by jdy : replace setTimeOut with Promise
      // patient.loadCts(id);
      // setTimeout(() => {
      //   setLoading(true);
      // }, 500);

      patient.loadCts(id).then(value => {
        setLoading(true);
      });

    }
  }, [id]);

  const size = useMemo(() => {
    return (
      ctsKoreaList.content && `(${ctsKoreaList.content.length} clinical trials found in Korea)`
    );
  }, [ctsKoreaList.content]);

  const handleLink = useCallback(({ data }) => {
    props.history.push(`/patient/${props.match.params.id}/match/${data.nctId}`);
  }, []);

  return (
    <TableTemplate title="Clinical Trial Recommendations for Korea" size={size}>
      <div
        ref={parentEl}
        style={{ maxHeight: matches ? 180 : 150, overflowY: "auto" }}
      >
        {/* <TableHeightContainer maxHeight={180}> */}
        <MuiTable
          id={`CtsSummarys`}
          parentEl={parentEl.current}
          columns={columns}
          data={ctsKoreaList}
          stickyHeader
          oddRow
          loading={loading}
          defaultPagination={{
            mode: "scroll",
            page: 0,
            rowsPerPage: 10
          }}
          handleLink={handleLink}
          tt={"cts"}
        ></MuiTable>
        {/* </TableHeightContainer> */}
      </div>
    </TableTemplate>
  );
}

export default withRouter(observer(CtsSummaryTable));
