import React from "react";
import Checkbox from "../../atoms/ui/CustomCheckbox";
import { makeStyles } from "@material-ui/styles";

const useCustomStyle = makeStyles({
  root: {
    padding: `3px 0px`,
    paddingRight: 3,
    color: "#ffffff",
    "&$checked": {
      color: "#ffffff"
    }
  },
  checked: {}
});

function LoginCheckbox(props) {
  return <Checkbox {...props} useCustomStyle={useCustomStyle} />;
}

export default LoginCheckbox;
