import React from "react";
import { extendObservable } from "mobx";
import EditInsButton from "pages/admin/EditIns";
import DeleteInstitution from "pages/admin/DeleteInstitution";

class AccountInsTableModel {
  constructor(data) {
    extendObservable(this, data);
  }

  get control() {
    return (
      <>
        <EditInsButton id={this.id} code={this.code}>
          Edit
        </EditInsButton>
        <DeleteInstitution id={this.id}>Delete</DeleteInstitution>
      </>
    );
  }
}

export default AccountInsTableModel;
