import React, { useEffect, useRef, useState, useMemo } from "react";
import { FixedSizeList as List } from "react-window";
import useStore from "modules/hooks/useStore";
import { observer } from "mobx-react";
import VariantFilterRadioButton from "../../molecules/VariantFilterRadioButton";
import TableTemplate from "components/organisms/TableTemplate";
import VariantAutoSelect from "../../molecules/VariantAutoSelect";
import { makeStyles } from "@material-ui/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Loading from "components/molecules/LoadingComponent";
import Checkbox from "@material-ui/core/Checkbox";
import CheckBoxOutlineBlankIcon from "assets/images/checkbox.svg"
import CheckBoxIcon from "assets/images/checkbox_select.svg"

const useStyles = makeStyles({
  list: {
    borderBottom: "1px solid #dddddd",
    display: "flex",
    alignItems: "center"
  },
  listContent: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between"
  },
  count: {
    margin: "0px 10px",
    backgroundColor: "#EBECF0",
    borderRadius: "2em",
    color: "#172B4D",
    display: "inline-block",
    fontSize: "1rem",
    fontWeight: "normal",
    lineHeight: "1",
    minWidth: 1,
    padding: "0.16666666666667em 0.5em",
    textAlign: "center"
  },
  icon: {
    marginLeft: "5px",
    width: '15px',
    height: "15px",
    marginTop: "6px",
    color: "#9d9d9d"
  },
  root: {
    // padding: "1px",
    // width: "100%",
    // height: "250",
    margin: "0px 0px",
    // border: "solid 1px #dddddd"
  },
});

function VariantListFilter(props) {
  const {
    patient,
    patient: { search },
    statistics,
    statistics: { variants, selectedCancer, selectedVariantIndex }
  } = useStore();
  const listRef = useRef();
  const classes = useStyles();
  const divRef = useRef();
  const mathes = useMediaQuery("(min-width:1500px)");
  // const mathesheight = useMediaQuery("(max-height:800px)");

  const [loading, setLoading] = useState(false);

  const height = 180;
  // const height =
  //   divRef.current &&
  //   divRef.current.offsetHeight -
  //     divRef.current.childNodes[0].childNodes[0].offsetHeight -
  //     30 -
  //     5;

  useEffect(() => {
    setLoading(false);
    if (selectedCancer) {
      const firstVariant = statistics.loadVariants();
      console.log("1111 : " + JSON.stringify(firstVariant));
      firstVariant.then(value => {
        statistics.setSelectedVariant(value.key);
        delete search.snv;
        delete search.cnv;
        delete search.sv;
        console.log("2222: " + JSON.stringify(value.variantType));
        patient.resetSearch({
          ...search,
          cancer: selectedCancer,
          [value.variantType.toLowerCase()]: value.key
        });
        setLoading(true);
      });
    }
  }, [selectedCancer]);



  useEffect(() => {
    if (listRef.current)
      listRef.current.scrollToItem(selectedVariantIndex, "top");
  }, [selectedVariantIndex]);

  const Row = ({ index, style }) => {
    const value = variants[index].key;
    const name = variants[index].name;
    const variantType = variants[index].variantType;
    const count = variants[index].value;

    const icon = <img src={CheckBoxOutlineBlankIcon} alt="" />
    const checkedIcon = <img src={CheckBoxIcon} alt="" />
    return (
      variants && (
        <div style={style} className={classes.list}>
          {/* VariantFilter 라디오 버튼 -> 체크박스로 변경하는 부분 - 박남준 */}
          
          <VariantFilterRadioButton value={value} variantType={variantType} />
          {/* <Checkbox
            // disabled={disable}
            // checked={isCheck}
            className={classes.smallCheckboxs}
            icon={icon}
            checkedIcon={checkedIcon}
            classes={{ checked: classes.selectCancerreencolor, disabled: classes.disablecheckbox }}
            value={value}
            // onChange={this.cancerCheck}
          /> */}
          <div className={classes.listContent} title={name}>
            <span>{name.length > 30 ? `${name.slice(0, 25)}...` : name}</span>
            <span className={classes.count}>{count}</span>
          </div>
        </div>
      )
    );
  };

  const example = useMemo(
    () =>
      height && (
        <Loading loading={loading} style={{ height }}>
          <div
            style={{
              // padding: "6px 0px"
              paddingTop: 3
            }}
          >
            <List
              height={height}
              itemCount={variants.length}
              itemSize={30}
              width={"100%"}
              ref={listRef}
            >
              {Row}
            </List>
            <div style={{ border: "1px solid #c1c1c1" }}></div>
          </div>
        </Loading>
      ),
    [loading, variants, mathes, height]
  );

  return (
    <div >
      <VariantAutoSelect />
      {example}
    </div>
  );
}

export default observer(VariantListFilter);

// return (
//   <div className={classes.root} ref={divRef}>
//     <TableTemplate
//       title={`Variant (${Number(variants.length)
//         .toString()
//         .replace(regexp, ",")})`}
//       bodyPadding={"none"}
//       headStyle={{ backgroundColor: "rgb(237, 238, 241)" }}
//     >
//       <VariantAutoSelect />
//       {example}
//     </TableTemplate>
//   </div>
// );