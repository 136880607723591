import React, { useEffect, useRef } from "react";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { makeStyles } from "@material-ui/styles";

const useCustomStyle = makeStyles({
  container: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    position: "absolute",
    width: "100%",
    top: 0,
    bottom: 0
  },
  layout_1500: {
    minWidth: 1470
  },
  layout: {
    position: "absolute",
    right: 15,
    left: 15,
    top: 15,
    bottom: 0,
    border: "1px solid #c6c6c6",
    backgroundColor: "#f6f6f6",
    width: 1220
  },
  innerLayout: {
    position: "absolute",
    right: 15,
    left: 15,
    top: 15,
    bottom: 15,
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },
  panelContainer_1500: {
    minWidth: 558
    // border: "1px solid black"
  },
  panelContainer: {
    backgroundColor: "#fff",
    width: 680,
    height: 380,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    padding: 40,
    border: "1px solid #dedede"
  }
});

function ChangePasswordContent({ targetRef, children }) {
  const classes = useCustomStyle();
  const matches = useMediaQuery("(min-width:1500px)");

  return (
    <div className={`${classes.container}`}>
      <div className={`${classes.layout} ${matches && classes.layout_1500}`}>
        <div className={`${classes.innerLayout}`} ref={targetRef}>
          <div
            className={`${classes.panelContainer} ${matches &&
              classes.panelContainer_1500}`}
          >
            {children}
          </div>
        </div>
      </div>
    </div>
  );
}

export default ChangePasswordContent;
