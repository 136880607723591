import React, { useState, useEffect, useMemo } from "react";
import CustomSelectBox from "../../components/atoms/ui/CustomSelectBox";
import useStore from "modules/hooks/useStore";
import MatchButton from "components/molecules/MatchButton";
import { observer } from "mobx-react";
import { makeStyles } from "@material-ui/core/styles";

const useStyle = makeStyles(theme => ({
  name: {
    margin: 10
  },
  value: {
    margin: 10
  },
  buttonContainer: {
    display: "flex",
    margin: 10
  },
  buttontitle: {
    paddingTop: "10px",
    fontSize: "11px",
    fontWeight: "500"
  },
  description: {
    width: "100%",
    height: "2rem",
    border: " solid 1px #dedede"
  },
  width: {
    width: "100%",
    height: "2rem"
  }
}));

const EditUserPanel = observer(({ onClose, joinInfo, setJoinInfo }) => {
  const {
    account,
    account: { authList, allInsList }
  } = useStore();

  const classes = useStyle();

  useEffect(() => {
    account.getAuthList();
    account.getAllInsList("?size=1000");
  }, []);

  useEffect(() => {
    if (allInsList.length > 0)
      setJoinInfo(
        Object.assign(joinInfo, {
          instituteCode: allInsList[0].value,
          authority: authList[0].value
        })
      );
  }, [allInsList]);

  const onChange = ({ target: { name, value } }) => {
    setJoinInfo(
      Object.assign(joinInfo, {
        [name]: value
      })
    );
  };

  const passwordBox = useMemo(() => {
    return (
      <input
        name="password"
        type="password"
        className={classes.description}
        onChange={onChange}
        value={joinInfo.password}
        required
      />
    );
  }, [joinInfo.password]);

  const firstBox = useMemo(() => {
    return (
      <input
        name="firstName"
        type="text"
        className={classes.description}
        onChange={onChange}
        value={joinInfo.firstName}
        required
      />
    );
  }, [joinInfo.firstName]);
  const lastBox = useMemo(() => {
    return (
      <input
        name="lastName"
        type="text"
        className={classes.description}
        onChange={onChange}
        value={joinInfo.lastName}
        required
      />
    );
  }, [joinInfo.lastName]);
  const emailBox = useMemo(() => {
    return (
      <input
        name="login"
        type="text"
        className={classes.description}
        onChange={onChange}
        value={joinInfo.login}
        required
      />
    );
  }, [joinInfo.login]);
  const roleSelectBox = useMemo(() => {
    return (
      <CustomSelectBox
        name="authority"
        data={authList}
        onChange={onChange}
        inputStyle={classes.width}
      />
    );
  }, [authList]);

  const organSelectBox = useMemo(() => {
    return (
      <CustomSelectBox
        name="instituteCode"
        data={allInsList}
        onChange={onChange}
        inputStyle={classes.width}
      />
    );
  }, [allInsList]);

  return (
    <div style={{ width: "50rem" }}>
      <div className={classes.tableDiv}>
        <div className={classes.name}>E-mail</div>
        <div className={classes.value}>{emailBox}</div>
      </div>
      <div className={classes.tableDiv}>
        <div className={classes.name}>Password</div>
        <div className={classes.value}>{passwordBox}</div>
      </div>
      <div className={classes.tableDiv}>
        <div className={classes.name}>First Name</div>
        <div className={classes.value}>{firstBox}</div>
      </div>
      <div className={classes.tableDiv}>
        <div className={classes.name}>Last Name</div>
        <div className={classes.value}>{lastBox}</div>
      </div>
      <div className={classes.tableDiv}>
        <div className={classes.name}>Institution(Code)</div>
        <div className={classes.value}>{organSelectBox}</div>
      </div>
      <div className={classes.tableDiv}>
        <div className={classes.name}>Role</div>
        <div className={classes.value}>{roleSelectBox}</div>
      </div>
      <div className={classes.buttonContainer}>
        <MatchButton style={{ width: "50%" }} onClick={onClose} type="button">
          Cancel
        </MatchButton>
        <MatchButton style={{ width: "50%" }} type="submit">
          Submit
        </MatchButton>
      </div>
    </div>
  );
});

function RegisterButton({ history }) {
  const { account } = useStore();
  const [joinInfo, setJoinInfo] = useState({});

  const onClose = () => {
    history.goBack();
  };

  /* 
	    변경일자:   2020-07-02
	    변경자 이름: 지신복 
	    변경 내용:  사용자 생성 성공시에만 화면 닫기 
	    변경 사유:  사용자 편리성 제공                               
	 */
  const onSubmit = e => {
    e.preventDefault();
    account.creatUser(joinInfo).then(value => {
      try{
        alert(value.data);
        onClose();
      }catch(e){
        console.log(e)
      }

    });
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        height: "100%"
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "baseline"
        }}
      >
        <h2 style={{ margin: 10, fontSize: "2rem" }}>Register User</h2>
        <form onSubmit={onSubmit}>
          <EditUserPanel
            onClose={onClose}
            joinInfo={joinInfo}
            setJoinInfo={setJoinInfo}
          />
        </form>
      </div>
    </div>
  );
}

export default observer(RegisterButton);
