import React from "react";
import ActionableDialogButton from "components/molecules/ActionableDialogButton";
import { extendObservable, computed } from "mobx";
// import Ac from "assets/images/button/ac.svg";
// import Pa from "assets/images/button/pa.svg";

function transferNull(fn, obj) {
  const retObj = {};
  for (let [k, v] of Object.entries(obj)) {
    Object.assign(retObj, {
      [k]: fn(v)
    });
  }

  return retObj;
}

class SnvListModel {
  constructor(data) {
    extendObservable(
      this,
      transferNull(v => v || "", data)
    );
  }

  @computed
  get pa() {
    return (
      this.pathogenicity && (
        <ActionableDialogButton
          // variantId={this.variantId}
          gene={this.gene}
          variant={this.hgvsP1digit}
          mutType={this.sequenceAlteration}
          hgvsc={this.hgvsC}
          type={"pa"}
        >
          {/* <img src={Pa} alt="" title="Pathogenic" /> */}
        </ActionableDialogButton>
      )
    );
  }

  @computed
  get ac() {
    return (
      this.actionability && (
        <ActionableDialogButton
          // variantId={this.variantId}
          gene={this.gene}
          variant={this.hgvsP1digit}
          mutType={this.sequenceAlteration}
          hgvsc={this.hgvsC}
          type={"ac"}
        >
          {/* <img src={Ac} alt="" title="Actionable" /> */}
        </ActionableDialogButton>
      )
    );
  }
}

export default SnvListModel;
