import React, { useEffect } from "react";
// import Logo from "../../molecules/Logo";
import FooterLogo from "assets/images/footer/footerlogo.svg";
import FamilySiteSelect from "components/molecules/FamilySiteSelect";
import { makeStyles } from "@material-ui/styles";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import dateFormat from "dateformat";
import { observer } from "mobx-react";
import useStore from "modules/hooks/useStore";
import ContactUs from "../ContactUs";
import { useLocation } from "react-router-dom";
//  아래 add by jdy 2021-01-14 , Annotation 을 보여주기 위한 Component
//import AnnotationShow from "components/organisms/Annotation/AnnotationShow"; 

const useStyles = makeStyles({
  footer: {
    position: "absolute",

    right: 0,
    left: 0,
    bottom: 0,
    height: 40,

    backgroundColor: "#979797"
  },
  img: {
    // padding: 10,
    // margin: 10,
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  }
});

function Footer() {
  const classes = useStyles();
  const location = useLocation();

  const {
    account,
    account: { overviewStat }
  } = useStore();

  useEffect(() => {
    if (overviewStat.lastUpdateDate === "" && location.pathname !== "/change")
      account.loadAdminStatus({ type: "lastUpdateDate" });
  }, []);

  return (
    <div className={classes.footer}>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          // width: "100%",
          height: "100%",
          position: "absolute",
          right: 15,
          left: 15
        }}
      >
        <div className={classes.img}>
          <img src={FooterLogo} alt="" />
        </div>
        <div
          style={{
            fontSize: 10,
            display: "flex",
            // width: "100%",
            color: "#d1d1d1",
            // justifyContent: "space-around",
            alignItems: "center"
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center"
            }}
          >
            <span
              style={{ marginRight: 10, display: "flex", alignItems: "center" }}
            >
              <LocationOnIcon style={{ fontSize: "1rem" }} /> Korea University
              Medical Center 73, Inchon-ro, Seongbuk-gu, Seoul 02841, Korea
            </span>
            <span style={{ marginRight: 3 }}>
              Copyrightⓒ2019 by KOREA UNIVERSITY MEDICAL CENTER All rights
              reserved.
            </span>

            {location.pathname !== "/change" && (
              <span
                style={{ marginLeft: 3 }}
              >{`| Last Data Release - ${dateFormat(
                overviewStat.lastUpdateDate,
                "mediumDate"
              )}`}</span>
            )}
          </div>
        </div>
        {location.pathname !== "/change" && <ContactUs />}
        {/* <AnnotationShow /> add by jdy 2021-01-14 , Annotation 을 보여주기 위한 Component*/}
        {/* 2021-03-18 add 1 line by jdy */}
        <button onClick={()=>window.open('/annotation-show', '_blank') }> Annotation </button>
        <FamilySiteSelect />
      </div>
    </div>
  );
}

export default observer(Footer);
