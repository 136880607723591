const dataFormat = ({ children, type, maxLength }) => {
  let retStr = children;

  if (typeof children === "number") {
    const regexp = /\B(?=(\d{3})+(?!\d))/g;
    return Number(retStr)
      .toString()
      .replace(regexp, ",");
  }

  if (typeof children === "boolean") {
    return String(retStr);
  }

  if (typeof children === "string") {
    return children.length > maxLength
      ? `${children.slice(0, maxLength)}...`
      : children;
  }

  if (!children) {
    return "-";
  }

  return retStr;
};

function DataFormat(props) {
  return dataFormat(props);
}

export default DataFormat;
