import React from "react";
import civic_non from "assets/images/siteicon/civic_non.png";
import civic from "assets/images/siteicon/civic.png";
import clinvar_non from "assets/images/siteicon/clinvar_non.png";
import clinvar from "assets/images/siteicon/clinvar.png";
import onco_non from "assets/images/siteicon/onco_non.png";
import onco from "assets/images/siteicon/onco.png";

const refStie = {
  Oncokb: {
    icon: onco,
    deSelectIcon: onco_non
  },
  Clinvar: {
    icon: clinvar,
    deSelectIcon: clinvar_non
  },
  Civic: {
    icon: civic,
    deSelectIcon: civic_non
  }
};

class RefSiteTabModel {
  constructor(data) {
    // console.log(data.referenceSiteName);
    this.id = data.id;
    this.icon = (
      <img
        src={refStie[data.referenceSiteName].icon}
        alt=""
        style={{ width: 20, height: 20 }}
      />
    );
    this.deSelectIcon = (
      <img
        src={refStie[data.referenceSiteName].deSelectIcon}
        alt=""
        style={{ width: 20, height: 20 }}
      />
    );
    this.label = data.referenceSiteName;
    this.count = data.count;
    this.value = data.id;
  }
}

export default RefSiteTabModel;
