import React, { useMemo, useState, useEffect } from "react";
import { makeStyles } from "@material-ui/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import CommTemplatePage from "../templates";
import PersonIcon from "@material-ui/icons/Person";
import PageContent from "components/organisms/PageContent";
import UserManager from "pages/admin/UserManager";
import AccessLog from "pages/admin/AccessLog";
import DataManage from "pages/admin/DataManage";
import LeftMenu from "components/organisms/PageContent/LeftMenu";
import InstitutionManager from "./InstitutionManager";
import ApartmentIcon from "@material-ui/icons/Apartment";
import RecentActorsIcon from "@material-ui/icons/RecentActors";
import RightMenu from "../../components/organisms/PageContent/RightMenu";
import AdminRouter from "shared/AdminRouter";
import useStore from "modules/hooks/useStore";

const useStyles = makeStyles({
  title: {
    display: "flex"
  },
  titleIcon: {
    fontSize: "1.4rem"
  }
});


const list = [
  {
    label: "OVERVIEW",
    url: "/admin"
    // icon: <RecentActorsIcon style={{ paddingRight: "5px" }} />
  },
  {
    label: "USER MANAGEMENT",
    url: "/admin/users"
    // icon: <RecentActorsIcon style={{ paddingRight: "5px" }} />
  },
  // {
  //   label: "ACCESS LOG",
  //   url: "/admin/logs"
  // icon: <RecentActorsIcon style={{ paddingRight: "5px" }} />
  // },
  {
    label: "INSTITUTION MANAGEMENT",
    url: "/admin/institutions"
    // icon: <RecentActorsIcon style={{ paddingRight: "5px" }} />
  },
  {
    label: "SYNC LOG",
    url: "/admin/sync"
    // icon: <RecentActorsIcon style={{ paddingRight: "5px" }} />
  },
  {
    label: "Q&A BOARD",
    url: "/admin/qna"
    // icon: <RecentActorsIcon style={{ paddingRight: "5px" }} />
  }
  // { label: 'empty', value: 'empty' }
];

function admin(props) {
  const classes = useStyles();
  const matches = useMediaQuery("(min-width:1500px)");
  const { patient, statistics } = useStore();

  const [selected, setSelected] = useState(
    list.findIndex(f => {
      return f.url.includes(props.location.pathname.split("/")[2] || "/admin");
    })
  );

  useEffect(() => {
    patient.resetInstituteSearch({});
    patient.resetSearch({});
    statistics.resetSelected();
    statistics.resetSearch();
  }, []);

  useEffect(() => {
    setSelected(
      list.findIndex(f => {
        return f.url.includes(
          props.location.pathname.split("/")[2] || "/admin"
        );
      })
    );
  }, [props.location.pathname]);

  const handleChange = idx => {
    setSelected(idx);
  };

  const content = useMemo(() => {
    const back =
      props.location.pathname.split("/").pop() === "admin" ||
      props.location.pathname.split("/")[2] === "sync";

    return <PageContent back={!back} router={<AdminRouter {...props} />} />;
  }, [props.match]);

  const right = useMemo(() => {
    return (
      <div
        style={{
          display: "flex",
          position: "absolute",
          top: 10,
          right: 10,
          left: 10,
          bottom: 0,
          borderLeft: "1px solid #c6c6c6",
          borderRight: "1px solid #c6c6c6",
          borderTop: "1px solid #c6c6c6"
        }}
      >
        <LeftMenu list={list} handleChange={handleChange} selected={selected} />
        {content}
        <RightMenu />
      </div>
    );
  }, [selected]);

  const rtitle = useMemo(() => {
    return (
      <div className={classes.container}>
        <div className={classes.title}>
          {/* <PersonIcon className={classes.titleIcon} /> CHANGE */}
          <span style={{ marginLeft: 5 }}>{"ADMIN"}</span>
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center"
          }}
        ></div>
      </div>
    );
  }, []);

  return (
    <>
      <CommTemplatePage right={right} rtitle={rtitle} />
    </>
  );
}

export default admin;
