import React from "react";
import PersonSearchInput from "components/molecules/PersonSearchInput";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles({
  container: {
    // padding: "10px 10px",
    width: 262,
    height: 25
  }
});

function SearchKeywordInputOfExplorer() {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <PersonSearchInput />
    </div>
  );
}

export default SearchKeywordInputOfExplorer;
