import { extendObservable } from "mobx";

class VariantSelectModel {
  constructor(data, i) {
    extendObservable(this, data);
    this.variantType = data.variantClass;
  }
}

export default VariantSelectModel;
