import React, { useCallback, useState } from "react";
import CustomSelectBox from "components/atoms/ui/CustomSelectBox";
import CustomInput from "components/atoms/ui/CustomInput";
import MatchButton from "components/molecules/MatchButton";

function SearchAdminForm(props) {
  // const { account } = useStore();
  const [type, setType] = useState(props.default);
  const [keyword, setKeyword] = useState("");

  const onSubmit = e => {
    e.preventDefault();
    // console.log({ type, keyword });
    props.getList({ type, keyword });
  };

  const onChange = useCallback(({ target: { value } }) => {
    setType(value);
  }, []);

  const onInputChange = useCallback(({ target: { value } }) => {
    setKeyword(value);
  }, []);

  return (
    <form
      onSubmit={onSubmit}
      style={{
        display: "flex",
        width: "inherit",
        height: 40,
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "#d8d8d8",
        margin: "10px"
      }}
    >
      <span>Search</span>
      <span style={{ padding: "0px 5px" }}>
        <CustomSelectBox data={props.types} onChange={onChange} />
      </span>
      <CustomInput
        type={"text"}
        onChange={onInputChange}
        value={keyword}
        inputStyle={{ height: "19px", width: "270px" }}
      />
      <MatchButton type={"submit"} onClick={onSubmit} style={{ width: 150 }}>
        Search
      </MatchButton>
    </form>
  );
}

export default SearchAdminForm;
