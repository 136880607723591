import React, { useRef, useMemo, useEffect, useCallback } from "react";
import TableTemplate from "../../../TableTemplate";
import ViewDetailsDialogButton from "../../../../molecules/ViewDetailsDialogButton";
import MuiTable from "components/atoms/MuiTable";
import useStore from "modules/hooks/useStore";
import { withRouter } from "react-router-dom";
import { observer } from "mobx-react";

const columns = [
  {
    column: "Sponsor",
    title: "Sponsor",
    property: "sponsor",
    align: "left",
    sort: false,
    options: {
      link: {
        isLinked: false
      },
      isEditable: false
    },
    style: { head: { width: 100 } }
  },
  {
    column: "Condition or Disease",
    title: "Condition or Disease",
    property: "conditions",
    align: "left",
    sort: false,
    options: {
      link: {
        isLinked: false
      },
      isEditable: false,
      withCellExcute: ({ value }) => WrapperComponent => {
        const list = (
          <ul style={{ padding: `0px 13px`, margin: 0 }}>
            {value.map((v, i) => (
              <li key={i}>{v}</li>
            ))}
          </ul>
        );
        return (
          <WrapperComponent>{value.length > 0 ? list : "-"}</WrapperComponent>
        );
      }
    },
    style: { head: { width: 254 } }
  },
  {
    column: "Intervention/Treatment",
    title: "Intervention/Treatment",
    property: "drugs",
    align: "left",
    sort: false,
    options: {
      link: {
        isLinked: false
      },
      isEditable: false,
      withCellExcute: ({ value }) => WrapperComponent => {
        const list = (
          <ul style={{ padding: `0px 13px`, margin: 0 }}>
            {value.map((v, i) => (
              <li key={i}>{v}</li>
            ))}
          </ul>
        );
        return (
          <WrapperComponent>{value.length > 0 ? list : "-"}</WrapperComponent>
        );
      }
    },
    style: { head: { width: 150 } }
  },
  {
    column: "Phase",
    title: "Phase",
    property: "phases",
    align: "left",
    sort: false,
    options: {
      link: {
        isLinked: false
      },
      isEditable: false
    },
    style: { head: { width: 70 } }
  },
  {
    column: "Age",
    title: "Ages Eligible for Study",
    property: "ages",
    align: "left",
    sort: false,
    options: {
      link: {
        isLinked: false
      },
      isEditable: false
    },
    style: { head: { width: 120 } }
  },
  {
    column: "Sex",
    title: "Sexes Eligible for Study",
    property: "sexs",
    align: "left",
    sort: false,
    options: {
      link: {
        isLinked: false
      },
      isEditable: false
    },
    style: { head: { width: 70 } }
  },
  {
    column: "Status",
    title: "Statue",
    property: "overallStatus",
    align: "left",
    sort: false,
    options: {
      link: {
        isLinked: false
      },
      isEditable: false
    },
    style: { head: { width: 70 } }
  }
];

function ClinicalTrialSummaryTable(props) {
  const {
    patient: { clinicalTrialSummaryData }
  } = useStore();
  const parentEl = useRef(null);

  const title = useMemo(() => {
    return (
      <div style={{ display: "flex", alignItems: "center" }}>
        <h3>Clinical Trial Summary</h3>
        <ViewDetailsDialogButton>View Details</ViewDetailsDialogButton>
      </div>
    );
  }, []);

  return (
    <TableTemplate title={title} borderHide={true}>
      <div ref={parentEl}>
        <MuiTable
          id={`MatchedEligiblity`}
          // parentEl={parentEl.current}
          columns={columns}
          data={clinicalTrialSummaryData}
          stickyHeader
          defaultPagination={{
            mode: "scroll",
            page: 0,
            rowsPerPage: 10
          }}
        ></MuiTable>
      </div>
    </TableTemplate>
  );
}

export default withRouter(observer(ClinicalTrialSummaryTable));
