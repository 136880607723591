  /* 
    생성일자:   2020-06-03
    생성자 이름: 지시복 
    생성 내용:  조회조건 추가
    생성 사유:                                 
	*/      

import React, { useCallback, useState } from "react";
import CustomSelectBox from "components/atoms/ui/CustomSelectBox";
import CustomInput from "components/atoms/ui/CustomInput";
import MatchButton from "components/molecules/MatchButton";

function SearchUserForm(props) {
  const [type, setType] = useState(props.default);
  const [keyword, setKeyword] = useState("");      
  const [authority, setAuthority] = useState("");
  const [activated, setActivated] = useState("");

  const onSubmit = e => {
    e.preventDefault();
    props.getList({ type, keyword, authority, activated });
  };

  const onChange = useCallback(({ target: { value } }) => {
    setType(value);
  }, []);

  const onInputChange = useCallback(({ target: { value } }) => {
    setKeyword(value);
  }, []);

  const onChangeAuthority = useCallback(({ target: { value } }) => {
    setAuthority(value);
  }, []);

  const onChangeActivated = useCallback(({ target: { value } }) => {
    setActivated(value);
  }, []);

  const authorityTypes = [
    {
      value: "",
      label: "All"
    },
    {
      value: "ROLE_ADMIN",
      label: "ADMIN"
    },
    {
      value: "ROLE_DM_USER",
      label: "DM_USER"
    },
    {
      value: "ROLE_INS_USER",
      label: "INS_USER"
    },
    {
      value: "ROLE_CRB_MEMBER",
      label: "CRB_MEMBER"
    }
  ];

  const activatedTypes = [
    {
      value: "",
      label: "All"
    },
    {
      value: "true",
      label: "Activate"
    },
    {
      value: "false",
      label: "Deactivate"
    }
  ];

  return (
    <form
      onSubmit={onSubmit}
      style={{
        display: "flex",
        width: "inherit",
        height: 40,
        justifyContent: "flex-start",
        alignItems: "center",
        backgroundColor: "#d8d8d8",
        margin: "10px"
      }}
    >
      <span style={{ padding: "0px 0px 0px 20px" }}>Search</span>
      <span style={{ padding: "0px 0px 0px 5px", width: "300px" }}>
        <CustomSelectBox data={props.types} onChange={onChange} />
      </span>
      <span style={{ padding: "0px 0px 0px 5px"}}>
        <CustomInput
          type={"text"}
          onChange={onInputChange}
          value={keyword}
          inputStyle={{ height: "19px", width: "200px" }}
        />
      </span>
      <span style={{ padding: "0px 0px 0px 20px" }}>Role</span>
      <span style={{ padding: "0px 0px 0px 5px", width: "350px" }}>
        <CustomSelectBox data={authorityTypes} onChange={onChangeAuthority} />
      </span>

      <span style={{ padding: "0px 0px 0px 20px" }}>Status</span>
      <span style={{ padding: "0px 0px 0px 5px", width: "350px" }}>
        <CustomSelectBox data={activatedTypes} onChange={onChangeActivated} />
      </span>
      <span style={{ width: '100%'}}>
        <span style={{ display: "flex", justifyContent: "flex-end"}}>
          <MatchButton type={"submit"} onClick={onSubmit} style={{ width: 100}}>
            Search
          </MatchButton>
        </span>
      </span >
      
    </form>
  );
}

export default SearchUserForm;
