import React from "react";
import MenuList from "@material-ui/core/MenuList";
import MenuItem from "@material-ui/core/MenuItem";
import CheckPermission from "components/lib/CheckPermission";
import { makeStyles } from "@material-ui/styles";
import { Link } from "react-router-dom";

const useStyles = makeStyles({
  selected: {
    color: "#ffffff !important",
    backgroundColor: "#5a5a5a !important"
  }
});

function LeftMenu({ list, handleChange, selected }) {
  const handleClick = (e, v) => {
    handleChange(v);
  };
  const classes = useStyles();

  return (
    <MenuList
      style={{
        position: "absolute",
        width: "17%",
        paddingTop: 0,
        marginTop: 0,
        backgroundColor: "#494949",
        bottom: 0,
        top: 0
      }}
    >
      {list.map((m, i) => (
        <CheckPermission permission={m.permission} reverse={true} key={i}>
          <Link to={m.url} style={{ textDecoration: "none", color: "white" }}>
            <MenuItem
              onClick={e => handleClick(e, i)}
              selected={i === selected}
              classes={{ selected: classes.selected }}
              style={{
                color: "#7d7d7d",
                backgroundColor: "#00ff0000",
                borderBottom: "solid 1px #737272"
              }}
            >
              {m.icon}
              {m.label}
            </MenuItem>
          </Link>
        </CheckPermission>
      ))}
    </MenuList>
  );
}

export default LeftMenu;
