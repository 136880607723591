import React, { useEffect, useState, useRef } from "react";
import AutoSelect from "../../atoms/AutoSelect";
import useStore from "modules/hooks/useStore";
import { observer } from "mobx-react";

const customStyle = {
  control: base => ({
    ...base,
    borderRadius: 0,
    minHeight: 30,
    maxHeight: 30,
    border: "none"
  }),
  menu: base => ({
    ...base,
    borderRadius: 0,
    zIndex: 50,
    minHeight: 30,
    color: "#3a3a3a"
  }),
  placeholder: base => ({
    ...base
  })
};

const components = {
  DropdownIndicator: null
};

function CancerTypeAutoSelect() {
  const {
    statistics,
    statistics: { cancerSelects, open }
  } = useStore();

  const select = useRef();
  const [autoCompleteList, setAutoCompletList] = useState([]);

  const handleChange = (newValue, { action }) => {
    if (newValue) {
      const {
        label,
        value: { index }
      } = newValue;
      statistics.setSearch({
        cancer: label
      });
      statistics.setSelectedCancer(label);
      statistics.setSelectedCancerIndex(index);
    }
  };

  const handleInputChange = (newValue, { action }) => {
    if (action === "set-value") statistics.setOpen(true);
    if (newValue.length > 2) {
      setAutoCompletList(cancerSelects);
    } else {
      setAutoCompletList([]);
    }
  };

  useEffect(() => {
    if (!open) select.current.select.clearValue();
  }, [open]);

  return (
    <div
      style={{
        boxShadow: "0px 3px 4px -1px rgba(121, 121, 121, 0.8)"
      }}
    >
      <AutoSelect
        select={select}
        open={open}
        autoCompleteList={autoCompleteList}
        handleChange={handleChange}
        // inputValue={inputValue}
        // onMenuOpen={onMenuOpen}
        components={components}
        handleInputChange={handleInputChange}
        placeholder={"Search Tumor Type"}
        styles={customStyle}
      />
    </div>
  );
}

export default observer(CancerTypeAutoSelect);
