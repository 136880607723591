import React, {
  useEffect,
  useRef,
  useCallback,
  useState,
  useMemo
} from "react";
import MuiTable from "components/atoms/MuiTable";
import { makeStyles } from "@material-ui/styles";
import { withRouter } from "react-router-dom";
import useStore from "modules/hooks/useStore";
import { observer } from "mobx-react";

const useStyles = makeStyles({
  container: {
    position: "absolute",
    overflowY: "scroll",
    left: 10,
    right: 10,
    bottom: 10,
    top: 50
  }
});

const columns = [
  {
    column: "",
    title: "Notice",
    property: "noticed",
    align: "center",
    sort: true,
    options: {
      link: {
        isLinked: false
      },
      isEditable: false
    }
  },
  {
    column: "",
    title: "Marked",
    property: "followed",
    align: "center",
    sort: true,
    options: {
      link: {
        isLinked: false
      },
      isEditable: false
    }
  },
  {
    column: "Subject",
    title: "Subject",
    property: "subjectId",
    align: "left",
    sort: true,
    options: {
      link: {
        isLinked: false
      },
      isEditable: false
    }
  },
  {
    column: "Study",
    title: "Study",
    property: "studyId",
    align: "left",
    sort: true,
    options: {
      link: {
        isLinked: false
      },
      isEditable: false
    }
  },
  {
    column: "Inst",
    title: "Institution",
    property: "institute",
    align: "left",
    sort: true,
    options: {
      link: {
        isLinked: false
      },
      isEditable: false
    }
  },
  {
    column: "Review",
    title: "Review",
    property: "reviewed",
    align: "left",
    sort: true,
    options: {
      link: {
        isLinked: false
      },
      isEditable: false
    },
    style: { head: { width: 50 } }
  }
];

function PersonTableOfPatient(props) {
  const {
    comm,
    comm: { isReviewOpen },
    patient,
    patient: { persons, selected, search }
  } = useStore();
  const [loading, setLoading] = useState(false);
  const classes = useStyles();
  const parentEl = useRef(null);

  // const handleLink = useCallback(value => {
  //   patient.setSelected(value);
  // }, []);

  const handleSort = useCallback(
    ({ orderBy, order }) => {
      patient.resetSearch({
        ...search,
        sort: `${orderBy},${order}`
      });
    },
    [search]
  );

  const handleClickCheckbox = useCallback(
    ({ data, count }) => {
      function callTransformPerson() {
        props.history.push(`${props.match.url}/${data.id}/summary`);
        patient.setPerson(data);
        if (data.notiValue) {
          patient.changeNotice(data.id);
          patient.loadPersons();
        }
      }

      comm.setActionableOpen(false);

      if (isReviewOpen) {
        if (
          window.confirm(
            `이 페이지를 벗어나면 작성된 내용은 저장되지 않습니다.`
          )
        ) {
          callTransformPerson();
        }
      } else {
        callTransformPerson();
      }
    },
    [isReviewOpen]
  );

  useEffect(() => {
    setLoading(false);
    const firstPerson = patient.loadPersons();
    firstPerson.then(value => {
      if (
        !props.location.pathname.split("/")[2] || // patient로 진입하거나
        !props.location.state || // 이전 path 확인
        props.history.action === "POP" // 새로고침
      ) {
        patient.setPerson(value);
        props.history.push(`${props.match.url}/${value.id}/summary`);
      }
      setLoading(true);
    });
  }, [search]);

  const table = useMemo(() => {
    return (
      <MuiTable
        id={`persons`}
        parentEl={parentEl.current}
        columns={columns}
        data={persons}
        stickyHeader
        oddRow
        selectRow={{
          mode: "radio",
          handleClickCheckbox: handleClickCheckbox,
          setSelect: patient.setSelected,
          selected: selected,
          hide: true
        }}
        defaultSort={{
          order: "desc",
          orderBy: "personId",
          handleSort: handleSort
        }}
        defaultPagination={{
          mode: "scroll",
          page: 0,
          rowsPerPage: 20
        }}
        // handleLink={handleLink}
        loading={loading}
      />
    );
  }, [persons, selected, loading, isReviewOpen]);

  return (
    <div ref={parentEl} className={classes.container}>
        {table}
    </div>
  );
}

export default React.memo(withRouter(observer(PersonTableOfPatient)));
