import React, { useCallback } from "react";
import CustomRadioButton from "../../atoms/ui/CustomRadiobutton";
import useStore from "modules/hooks/useStore";
import { observer } from "mobx-react";

function CancerFilterRadioButton({ value }) {
  const {
    statistics,
    statistics: { selectedCancer }
  } = useStore();

  const handleChange = useCallback(e => {
    statistics.setOpen(false);
    statistics.setSelectedCancer(e.target.value);
    statistics.setSearch({
      cancer: e.target.value
    });
  }, []);

  return (
    <CustomRadioButton
      selectedValue={selectedCancer}
      handleChange={handleChange}
      value={value}
      name={"cancers"}
    />
  );
}

export default observer(CancerFilterRadioButton);
