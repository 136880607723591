import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";

import Button from "@material-ui/core/Button";

import CancelIcon from '@material-ui/icons/Cancel';


const styles = theme => ({
  button: {
    align: 'right',
    margin: "5px 10px 10px 5px"
  },
  contain: {
    // height: "40vh",
    // width: "30vw",
    boxShadow: "0 2px 5px 0 rgba(99, 99, 99, 0.5)",
    border: "solid 2px #cdcccc",
    backgroundColor: "#ffffff",
    padding: "0px !important"
  },
  title: {
    borderBottom: "solid 2px #cdcccc",
    height: "2vw",
    lineHeight: '2.3',
    fontSize: "14px",
    padding: "0px 8px"
  },
  titlename: {
    float: 'left'
  },
  main: {
    padding: "20px 10px"
  },
  button: {
    width: "49%",
    height: "40px",
    borderRadius: '0px',
    backgroundColor: '#ffffff',
    border: 'solid 1px #979797',
    color: "#979797",
    fontSize: "14px",
    "&:hover": {
      backgroundColor: '#26a1a4',
      color: "#fff",
      borderColor: "#fff"
    }
  },
  buttonpadding: {
    marginRight: "2px"
  },
  container: {
    display: "flex"
  },
  helpContents: {
    width: '600px',
    fontSize: 12
  },
  helpTitle: {
    backgroundColor: "#97f797",
    color: '#2651a4',
    padding: '10px',
    fontSize: "15px",
    height: "26px"
  }
})

class SampleIdContent extends Component {

  state = { src: '', err: '' };

//   componentDidMount = () => {
//     this.loadImage();
//   }
  
//   loadImage = async () => {
//     const { imageUrl } = this.props;
//     try {
//       const { default: src } = await import(/* webpackMode: "lazy" */ `../../../../public/${imageUrl}`);
//       this.setState({ src });
//     } catch (err) {
//       this.setState({ err: err.toString() });
//     }
//   }

  handleClose = () => {
    const { close } = this.props;

    close();
  }

  render() {
    const { classes, messageResponse } = this.props;
    
    let htmlTitle = "Subject is not found";

    let listContent = [];

    listContent.push(
        <br />
    );

    listContent.push(
        <div align="center">
            {messageResponse} is not valid for the subject Id.
        </div>
    );

    return (
              
      <div className={classes.helpContents}>
        <br />
        <div align="right" style={{marginRight:20}}>
          <Button onClick={this.handleClose} variant="contained" color="primary" size="small" startIcon={<CancelIcon />}>Close</Button>
        </div>

        <br />
        <div align="center" className={classes.helpTitle} > {htmlTitle} </div>
        <br />
            {listContent}
        <br />
        <div align="right" style={{marginRight:20}}>
          <Button onClick={this.handleClose} variant="contained" color="primary" size="small" startIcon={<CancelIcon />}>Close</Button>
        </div>
        <br />
      </div>

    );
  }
}

export default withStyles(styles)(SampleIdContent);

