import React, { useEffect, useCallback, useMemo, useState } from "react";
import CustomSelectBox from "components/atoms/ui/CustomSelectBox";
import Loading from "components/molecules/LoadingComponent";
import LineChart from "../../atoms/LineChart";
import { observer } from "mobx-react";

import useStore from "modules/hooks/useStore";

const types = [
  {
    value: "hourVisitors",
    label: "Hour"
  },
  {
    value: "dayVisitors",
    label: "Day"
  },
  {
    value: "weekVisitors",
    label: "Week"
  },
  {
    value: "monthVisitors",
    label: "Month"
  }
  // {
  //   value: "",
  //   label: "Manager Name"
  // },
  // {
  //   value: "",
  //   label: "Manager E-mail"
  // },
  // {
  //   value: "",
  //   label: "Manager Number"
  // }
];

function AdminLineChart() {
  const {
    account,
    account: { visitorsCount }
  } = useStore();
  const [type, setType] = useState("dayVisitors");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(false);
    account.loadVisitorCount(type);
    setTimeout(() => {
      setLoading(true);
    }, []);
  }, []);

  const onChange = useCallback(({ target: { value } }) => {
    setType(value);
    setLoading(false);
    account.getVisitor(() => value);
    setTimeout(() => {
      setLoading(true);
    }, []);
  }, []);

  const selectBox = useMemo(() => {
    return (
      <div style={{ textAlign: "right" }}>
        <CustomSelectBox data={types} defaultValue={type} onChange={onChange} />
      </div>
    );
  }, [type]);

  return (
    <>
      {selectBox}
      <Loading loading={loading}>
        {Object.keys(visitorsCount).length > 0 && (
          <LineChart
            series={visitorsCount.series}
            category={visitorsCount.category}
            height={300}
          />
        )}
      </Loading>
    </>
  );
}

export default observer(AdminLineChart);
