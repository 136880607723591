import React, { useRef, useMemo, useState, useEffect } from "react";
import MuiTable from "components/atoms/MuiTable";
import useStore from "modules/hooks/useStore";
import { observer } from "mobx-react";
import TableTemplate from "../../../../TableTemplate";
import { makeStyles } from "@material-ui/styles";
import DataFormat from "components/atoms/DataFormat";
import Loading from "components/molecules/LoadingComponent";

const useStyles = makeStyles({
  container: {
    overflow: "auto",
    position: "absolute",
    top: "3rem",
    bottom: 0,
    left: 20,
    right: 20
  },
  title: {
    margin: "0px 10px",
    fontSize: "1.1rem",
    color: "#454444"
  }
});

const columns = [
  {
    column: "PA",
    title: "Pathogenicity",
    property: "pa",
    align: "center",
    sort: false,
    options: {
      link: {
        isLinked: false
      },
      isEditable: false
    },
    style: { head: { width: 50 } }
  },
  {
    column: "AC",
    title: "Actionability",
    property: "ac",
    align: "center",
    sort: false,
    options: {
      link: {
        isLinked: false
      },
      isEditable: false
    },
    style: { head: { width: 50 } }
  },
  {
    column: "Gene",
    title: "Gene",
    property: "gene",
    align: "left",
    sort: false,
    options: {
      link: {
        isLinked: false
      },
      isEditable: false
    },
    style: { head: { width: 100 } }
  },
  {
    column: "HGVS.p",
    title: "HGVS.p",
    property: "hgvsP1digit",
    align: "left",
    sort: false,
    options: {
      link: {
        isLinked: false
      },
      isEditable: false
      // withCellExcute: ({ value }) => WrapperComponent => {
      //   return (
      //     <WrapperComponent>
      //       <div title={value}>
      //         <DataFormat>{value}</DataFormat>
      //       </div>
      //     </WrapperComponent>
      //   );
      // }
    },
    style: { head: { width: 100 } }
  },

  {
    column: "Drug",
    title: "Drug",
    property: "drug",
    align: "center",
    sort: false,
    options: {
      link: {
        isLinked: false
      },
      isEditable: false
    },
    style: { head: { width: 100 } }
  },
  // {
  //   column: "CS",
  //   title: "Clinical Significance",
  //   property: "clinicalSignificance",
  //   align: "center",
  //   sort: false,
  //   options: {
  //     link: {
  //       isLinked: false
  //     },
  //     isEditable: false
  //   },
  //   style: { head: { width: 50 } }
  // },
  {
    column: "HGVC.c",
    title: "HGVC.c",
    property: "hgvsC",
    align: "left",
    sort: false,
    options: {
      link: {
        isLinked: false
      },
      isEditable: false
      // withCellExcute: ({ value }) => WrapperComponent => {
      //   return (
      //     <WrapperComponent>
      //       <div title={value}>
      //         <DataFormat>{value}</DataFormat>
      //       </div>
      //     </WrapperComponent>
      //   );
      // }
    },
    style: { head: { width: 100 } }
  },
  {
    column: "chr",
    title: "chromosome",
    property: "chromosome",
    align: "left",
    sort: false,
    options: {
      link: {
        isLinked: false
      },
      isEditable: false
    },
    style: { head: { width: 50 } }
  },
  {
    column: "Start",
    title: "Start",
    property: "startPosition",
    align: "right",
    sort: false,
    options: {
      link: {
        isLinked: false
      },
      isEditable: false
    },
    style: { head: { width: 100 } }
  },
  {
    column: "End",
    title: "End",
    property: "endPosition",
    align: "right",
    sort: false,
    options: {
      link: {
        isLinked: false
      },
      isEditable: false
    },
    style: { head: { width: 100 } }
  },
  {
    column: "Ref",
    title: "Ref",
    property: "referenceAllele",
    align: "left",
    sort: false,
    options: {
      link: {
        isLinked: false
      },
      isEditable: false
    },
    style: { head: { width: 50 } }
  },
  {
    column: "Alt",
    title: "Alt",
    property: "alternateAllele",
    align: "left",
    sort: false,
    options: {
      link: {
        isLinked: false
      },
      isEditable: false
    },
    style: { head: { width: 50 } }
  },
  {
    column: "AD",
    title: "Alt Depth",
    property: "tAlternateAlleleDepth",
    align: "right",
    sort: false,
    options: {
      link: {
        isLinked: false
      },
      isEditable: false
    },
    style: { head: { width: 50 } }
  },
  {
    column: "TD",
    title: "Total Depth",
    property: "tTotalDepth",
    align: "right",
    sort: false,
    options: {
      link: {
        isLinked: false
      },
      isEditable: false
    },
    style: { head: { width: 50 } }
  },
  {
    column: "AF",
    title: "Allele Frequency(%)",
    property: "variantAlleleFrequency",
    align: "right",
    sort: false,
    options: {
      link: {
        isLinked: false
      },
      isEditable: false
    },
    style: { head: { width: 50 } }
  },
  {
    column: "KF(%)",
    title: "Frequency in K-MASTER",
    property: "percent",
    align: "right",
    sort: false,
    options: {
      link: {
        isLinked: false
      },
      isEditable: false
    },
    style: { head: { width: 50 } }
  }
];

function SnvTable() {
  const parentEl = useRef(null);
  const classes = useStyles();
  const {
    patient: { snvList, ngsSnvReported }
  } = useStore();
  const [loading, setLoading] = useState(false);

  const title = useMemo(() => {
    return (
      snvList.totalElements && (
        <span className={classes.title}>
          {`${snvList.totalElements} SNV/INDEL detected`}
        </span>
      )
    );
  }, [snvList.totalElements]);

  const reported = useMemo(() => {
    return (
      <div style={{ display: "flex", margin: "0px 10px" }}>
        <div
          style={{
            height: "1.5rem",
            width: "1.5rem",
            backgroundColor: "#cff2ff"
          }}
        ></div>
        <div
          style={{
            marginLeft: 5,
            fontSize: "0.8rem",
            fontWeight: 400,
            display: "flex",
            alignItems: "center",
            color: "#454444"
          }}
        >
          Reported
        </div>
      </div>
    );
  }, []);

  useEffect(() => {
    setLoading(false);
    setTimeout(() => {
      setLoading(true);
    });
  }, [ngsSnvReported]);

  const table = useMemo(() => {
    return (
      // <Loading loading={loading}>
      <MuiTable
        id={`snv/indel`}
        parentEl={parentEl.current}
        columns={columns}
        data={snvList}
        stickyHeader
        oddRow
        selectRow={{
          mode: "none",
          defaultSelected: ngsSnvReported,
          hide: true
        }}
        defaultPagination={{
          mode: "scroll",
          page: 0,
          rowsPerPage: 10
        }}
      />
      // </Loading>
    );
  }, [ngsSnvReported, snvList]);

  return (
    <TableTemplate title={title} borderHide={true} size={reported}>
      <div ref={parentEl} className={classes.container}>
        {table}
      </div>
    </TableTemplate>
  );
}

export default observer(SnvTable);
