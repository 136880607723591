import React, { useCallback, useMemo } from "react";
import CustomSelectBox from "../../atoms/ui/CustomSelectBox";

import { observer } from "mobx-react";

const familySizeList = [
  {
    value: "Family Site",
    label: "Family Site",
    url: "none"
  },
  {
    value: "보건복지부",
    label: "보건복지부",
    url: "http://www.mohw.go.kr/react/index.jsp"
  },
  {
    value: "한국보건산업진흥원",
    label: "한국보건산업진흥원",
    url: "https://www.khidi.or.kr/kps"
  },
  {
    value: "고려대학교의료원",
    label: "고려대학교의료원",
    url: "http://www.kumc.or.kr/main/index.do"
  }
];

function FamilySizeSelect() {
  const onChange = useCallback(({ target: { value } }) => {
    if (value !== "none") window.open(value, "_blank");
  }, []);

  const select = useMemo(() => {
    return <CustomSelectBox data={familySizeList} onChange={onChange} />;
  }, []);

  return select;
}

export default observer(FamilySizeSelect);
