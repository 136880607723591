import React, { useMemo, useEffect } from "react";
import usePersonStore from "modules/hooks/usePersonStore";
import useStore from "modules/hooks/useStore";
import TableHead from "@material-ui/core/TableHead";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import { Link } from "react-router-dom";
import { makeStyles } from "@material-ui/styles";
import { observer } from "mobx-react";
import { withRouter } from "react-router-dom";

const useStyles = makeStyles({
  cell: {
    height: "35px",
    color: "#3a3a3a",
    backgroundColor: "#f3f3f3",
    padding: 0,
    fontSize: "1rem",
    borderLeft: "1px solid #dedede",
    borderTop: "1px solid #dedede",
    fontWeight: 600,
    "&:last-child": {
      borderRight: "1px solid #dedede"
    }
  },
  p: {
    margin: 0
  }
});

function ngsColumns({ match }) {
  const { patient, id } = usePersonStore();
  const {
    patient: { ngsHeader }
  } = useStore();

  const classes = useStyles();
  useEffect(() => {
    if (id) patient.loadNgsHeader(id);
  }, [id]);

  const customHeaderList = useMemo(() => {
    let headerList = ngsHeader
      .slice(0, 2)
      .map(({ genomicDate, secId, panel }) => {
        return {
          name: (
            <Link to={`/patient/${match.params.id}/ngs/${secId}/snv`}>
              <p className={classes.p}>{genomicDate || "-"}</p>
              <p className={classes.p}>({panel})</p>
            </Link>
          ),
          style: { width: 100, height: 30 }
        };
      });

    if (ngsHeader.length <= 1) {
      const list = new Array(2 - ngsHeader.length);
      list.fill({ name: "-", style: { width: 100, heigth: 30 } });
      headerList = headerList.concat(list);
    }

    return headerList;
  }, [ngsHeader]);

  return customHeaderList;
}

function NgsHeader(props) {
  const classes = useStyles();
  let columns = [
    {
      name: "Pathogenicity",
      title: "Pathogenicity",
      style: { width: 100, height: 30 }
    },
    {
      name: "Actionability",
      title: "Actionability",
      style: { width: 100, height: 30 }
    },
    {
      name: "Variant",
      title: "Variant"
      // style: { width: 300, height: 30 }
    },
    {
      name: "Frequency",
      title: "Frequency in K-MASTER",
      style: { width: 160, height: 30 }
    }
  ];

  columns = columns.concat(ngsColumns(props));

  const headerCellList = useMemo(() => {
    return columns.map((c, i) => {
      return (
        <TableCell
          key={i}
          align="center"
          padding="none"
          classes={{
            root: classes.cell
          }}
          style={c.style}
          title={c.title}
        >
          {c.name}
        </TableCell>
      );
    });
  }, [columns]);

  const headRow = useMemo(() => {
    return (
      <TableRow
        classes={{
          root: classes.cell
        }}
      >
        {headerCellList}
      </TableRow>
    );
  }, [headerCellList]);

  return <TableHead>{headRow}</TableHead>;
}

export default withRouter(observer(NgsHeader));
