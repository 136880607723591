import React from "react";
import { withStyles } from "@material-ui/core/styles";
import { observer, inject } from "mobx-react";
import VariantListFilter from "../VariantListFilter";
import CancerListFilter from "../CancerListFilter";
import FilterButtonList from "./FilterButtonList";
import { Component } from "react";
import SamplePrimary from "./SamplePrimary";
import GenderAge from "./GenderAge";
import TableTemplate from "components/organisms/TableTemplate";
import LeftTabPanel from "components/organisms/LeftTabPanel/LeftTabPanel";


const styles = theme => ({
  layout: {
    height: "100%",
    position: "absolute",
    top: 0,
    right: 0,
    left: 0,
    width: "100%"
  },
  panelmargin: {
    margin: 0,
    fontSize: "1.2rem",
    width: "100%"
    // margin: "3px 0.7vw 0.5vw 0.7vw"
  },
});

@inject("statistics")
@observer
class SearchFilter extends Component {

  constructor(props) {
    super(props)

    // Create the ref
    // this.divRef = React.createRef();
  }

  state = {
    resize : true
  }

  // window size에 따라 cancer 및 variant list를 보여주는 창의 높이 변화를 위해 
  // window resize event handler 추가 by jdy, 2021-01

  componentDidMount() {
    // window.addEventListener("resize", resizeThrottler, false);
    window.addEventListener("resize", this.handleResize, false);
  }

//  resizeThrottler = () => {
//     // ignore resize events as long as an actualResizeHandler execution is in the queue
//     if ( !resizeTimeout ) {
//       resizeTimeout = setTimeout(function() {
//         resizeTimeout = null;
//         actualResizeHandler();

//        // The actualResizeHandler will execute at a rate of 15fps
//        }, 66);
//     }
//   }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleResize);
  }


  handleResize = (event) => {
        
    //let height = window.innerHeight;
    //let height = this.divRef.current.offsetHeight;

    this.setState({ 
      resize: !this.state.resize
     });
  }


  // actualResizeHandler = (event) => {
  //   if (window.scrollY === 0 && this.state.scrolling === true) {
  //     this.setState({ resize: true });
  //   }
  //   else if (window.scrollY !== 0 && this.state.scrolling !== true) {
  //     this.setState({ scrolling: true });
  //   }
  // }

  render () {
    const {
      classes,
      statistics: { cancers, variants }
    } = this.props;

    const {resize} = this.state;
    const regexp = /\B(?=(\d{3})+(?!\d))/g;

    // console.log('Height :', inHeight);
    return (
      <div className={classes.layout} >
        <div className={classes.panelmargin}>
          <LeftTabPanel
            title={`Cancer Type (${cancers.length - 1})`}
            Detail={CancerListFilter}
            expandedInit={true}
          />
        </div>
        <div className={classes.panelmargin}>
          <LeftTabPanel
            title={`Variant (${Number(variants.length)
              .toString()
              .replace(regexp, ",")})`}
            Detail={VariantListFilter}
            expandedInit={true}
          />
        </div>
        <div className={classes.panelmargin}>
          <LeftTabPanel
            title={"Sample / Primary"}
            Detail={SamplePrimary}
            expandedInit={true}
          />
        </div>
        <div className={classes.panelmargin}>
          <LeftTabPanel
            title={"Demography"}
            Detail={GenderAge}
            expandedInit={true}
          />
        </div>
      </div>
    );

  }
}

export default withStyles(styles)(SearchFilter);

// return (
//   <div>
//     <CancerListFilter checkSize={resize} />
//     <VariantListFilter checkSize={resize} />
//     <FilterButtonList  checkSize={resize} />
//   </div>
// );

// function SearchFilter() {
//   return (
//     <>
//       <CancerListFilter />
//       <VariantListFilter />
//       <FilterButtonList />
//     </>
//   );
// }
