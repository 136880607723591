import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import AnnoVariantProfile from "./AnnoVariantProfile";
import AnnoVariantTabPanel from "./AnnoVariantTabPanel";

import TabHeader from "components/atoms/TabHeader";

const styles = theme => ({
  container: {
    display: "flex"
  },
  root: {
    // display: 'flex',
    flexWrap: 'wrap',
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
  },
  column: {
    display: 'flex',
    flexDirection: 'col',
  },
  margin: {
    margin: theme.spacing(1),
  },
  titleFont: {
    fontSize: '16px',
  },
  withoutLabel: {
    marginTop: theme.spacing(3),
  },
  textField: {
    width: '20ch',
  },
  spaceField : {
    paddingLeft: 200,
  },
  table: {
    minWidth: 650,
  },
  tableTitle: {
    fontSize: '20px',
    fontStyle: 'italic'
  },
  nav: {
    padding: "15px 15px 0px 100px",
    width: '290px',
    // verticalAlign: 'top',
    // width: 'auto',
    // bottom: '10px',
    // left: '11vw',
    // overflowY: 'auto'
    "@media (max-width: 1500px)": {
      padding: "15px 15px 0px 0px",
      //   margin: 'auto'
    },
  },
  tabhead: { // tabsize 에 의해 default 설정되는 것 같음.
    width: "291px",
    height: "30px",
    padding: "0px"
  },
  tabsize: {
    width: "200px",
    height: "30px",
    padding: "0px"
  },
  // tabcontainer: {
  //   width: '288px',
  //   minHeight: "800px",
  //   height: 'calc(100% - 130px)',
  //   background: "#f6f6f6",
  //   // height: "auto",
  //   border: "solid 1px #c6c6c6",
  //   borderTop: "0px",
  // },
  tabContainer1: {
    width: "100%",
    top: 10,
    bottom: 0,
    position: "absolute"
  },
  tabContainer2: {
    width: "100%",
    top: 50,
    bottom: 0,
    position: "absolute"
  },
  div: {
    width: "100%",
    position: "absolute",
    top: 125,
    bottom: 0
  }
});

const tabList = ["Guide-K", "Current Version", "Reference Version"];

class AnnoVariantShow extends Component {

  state = {
    variantTab: 0
  };

  onChangeVariantTab = (valueTab) => {

    this.setState({
      variantTab: valueTab
    });

    console.log('variantTab : ', this.state.versionTab);
  };


  render() {
    const {
      classes,
      title,
      history,
      match,
      location
    } = this.props;

    const {variantTab} = this.state;

    return (
      <div>
        <AnnoVariantProfile />
        
        <div className={classes.div}>
          <div className={classes.tabContainer1}>
            <TabHeader
              tabList={tabList}
              value={variantTab}
              onChangeTab={this.onChangeVariantTab}
              tabstyle={classes.tabsize}
            />
          </div>
          <div className={classes.tabContainer2}>
            <AnnoVariantTabPanel value={variantTab} />
          </div>
        </div>

      </div>
    );
  }
}

export default withStyles(styles)(AnnoVariantShow);
