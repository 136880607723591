import React, { useCallback, useMemo, useEffect } from "react";
import useStore from "modules/hooks/useStore";
import TableTemplate from "components/organisms/TableTemplate";
import MatchButton from "components/molecules/MatchButton";
import { observer } from "mobx-react";
import CustomSelectBox from "../../atoms/ui/CustomSelectBox";
import useInputObjState from "components/lib/useInputObjState";
import useAssign from "./useAssign";

const titleStyle = {
  fontSize: "1rem",
  fontWeight: 400,
  padding: `10px 0px`,
  color: "#3a3a3a"
};

const formStyle = {
  display: "flex",
  flexDirection: "column"
};

const layoutStyle = {
  backgroundColor: "#eef5ff"
};

const headStyle = {
  borderBottom: "1px solid #dedede"
};

// function useInputMobx(property) {
//   const { patient } = useStore();

//   const handleChangeState = useCallback(({ target: { value } }) => {
//     patient.setAddedComment(property, value);
//   }, []);

//   return useObserver(() => [
//     patient.getAddedComment(property),
//     handleChangeState
//   ]);
// }

// function useSelectBoxMobx() {
//   const { patient } = useStore();

//   const handleChangeState = useCallback(({ target: { value } }) => {
//     patient.setAddedCommentObj(JSON.parse(value));
//   }, []);

//   return useObserver(() => [handleChangeState]);
// }

// const SecInput = observer(() => {
//   const [handleSecId] = useSelectBoxMobx();

//   const {
//     patient,
//     patient: { ngsSelect }
//   } = useStore();

//   useEffect(() => {
//     if (ngsSelect.length > 0)
//       patient.setAddedCommentObj(JSON.parse(ngsSelect[0].value));
//   }, [ngsSelect]);

//   const selectBox = useMemo(() => {
//     return (
//       <>
//         <div style={titleStyle}>Test ID</div>
//         <CustomSelectBox
//           data={ngsSelect}
//           onChange={handleSecId}
//           // inputStyle={{ width: "100%" }}
//           maxLength={30}
//         />
//       </>
//     );
//   }, [ngsSelect]);

//   return selectBox;
// });

// function CommentInput() {
//   const [comment, handleChangeComment] = useInputMobx("comment");
//   return (
//     <>
//       <div style={titleStyle}>Comment</div>
//       <textarea
//         // style={{ width: "100%" }}
//         // placeholder="comment"
//         type="text"
//         onChange={handleChangeComment}
//         value={comment.comment}
//       />
//       {/* <CustomInput
//         // placeholder="comment"
//         type="text"
//         onChange={handleChangeComment}
//         value={comment}
//       /> */}
//     </>
//   );
// }

const type = "comment";
function AdditionalComment({ button }) {
  const {
    comm,
    patient,
    review,
    patient: { ngsSelect }
  } = useStore();

  const [therapies, setTherapies, handleChangeTherapies] = useInputObjState({
    //   secId: "",
    //   comment: ""
  });

  useAssign(ngsSelect, setTherapies, therapies);

  const onSubmit = useCallback(e => {
    e.preventDefault();
    review
      .submitReviews({
        type,
        therapies
      })
      .then(value => {
        if (value) {
          alert(`${value.type} ${value.success}`);
          review.loadReviews(type);
          comm.setOpen(false);
        }
      });
    // patient.submitAdditionalComment();
    // comm.setOpen(false);
  });

  const secInput = useMemo(() => {
    return (
      <>
        <div style={titleStyle}>Test ID</div>
        <CustomSelectBox
          data={ngsSelect}
          onChange={handleChangeTherapies}
          maxLength={30}
        />
      </>
    );
  }, []);

  const commentInput = useMemo(() => {
    return (
      <>
        <div style={titleStyle}>Comment</div>
        <textarea
          type="text"
          name="comment"
          onChange={handleChangeTherapies}
          value={therapies.comment}
          required
        />
        {/* <CustomInput
        // placeholder="comment"
        type="text"
        onChange={handleChangeComment}
        value={comment}
      /> */}
      </>
    );
  }, []);

  const title = useMemo(() => {
    return (
      <div style={{ color: "#14a4e7", fontSize: "1.14rem" }}>
        {"Additional Comment"}
      </div>
    );
  }, []);

  return (
    <>
      <TableTemplate
        title={title}
        borderHide={true}
        layoutStyle={layoutStyle}
        headStyle={headStyle}
        // bodyStyle={bodyStyle}
      >
        <form onSubmit={onSubmit} style={formStyle}>
          {secInput}
          {commentInput}
          {button}
        </form>
      </TableTemplate>
    </>
  );
}

export default observer(AdditionalComment);
