import { extendObservable, computed } from "mobx";

class ClinicalTrialSummaryTableModel {
  constructor(data) {
    extendObservable(this, data);
  }

  @computed
  get sponsor() {
    return this.institute;
  }

  @computed
  get phases() {
    return this.phase ? this.phase : "";
  }

  @computed
  get conditions() {
    return this.conditionList;
  }

  @computed
  get drugs() {
    return this.drugList;
  }

  @computed
  get ages() {
    return `${
      this.minimumAgeList <= 0 ? "Up to" : this.minimumAgeList + " and"
    } ${this.maximumAgeList}`;
  }

  @computed
  get sexs() {
    return Array.from(this.genderList).toString();
  }

  @computed
  get accept() {
    return Array.from(this.healthyVolunteersList).toString();
  }
}

export default ClinicalTrialSummaryTableModel;
