import React, { useMemo, useRef, useEffect } from "react";
import CommTemplatePage from "../templates";
import ChangePasswordContent from "./ChangePasswordContent";
import { makeStyles } from "@material-ui/styles";
import useStore from "modules/hooks/useStore";
import OverviewIcon from "assets/images/tab/overview.svg";
import CustomInput from "components/atoms/ui/CustomInput";
import useInputState from "components/lib/useInputState";
import MatchButton from "components/molecules/MatchButton";
import { withRouter } from "react-router-dom";

const useStyles = makeStyles({
  container: {
    display: "flex",
    alignItems: "center",
    width: "100%",
    justifyContent: "space-between"
  }
});

const title = <h1 style={{ color: "#575757", padding: 5 }}>Change password</h1>;
const desc = (
  <p
    style={{
      fontSize: "1.3rem",
      color: "#6c6c6c",
      padding: 5
    }}
  >
    <span>If you forgot your password, here you can request a new one. </span>
    <span>
      You will receive an email containing a link to set a new password.
    </span>
  </p>
);

function ChangePassword(props) {
  const { account } = useStore();
  const [password, setPassword, handleChangePassword] = useInputState("");
  const [
    correctPassword,
    setCorrectPassword,
    handleChangeCorrectPassword
  ] = useInputState("");
  const classes = useStyles();
  const targetRef = useRef();

  const onHome = () => {
    props.history.push("/");
  };

  const onSubmit = e => {
    e.preventDefault();
    e.stopPropagation();
    account
      .resrtPasswordFinish({
        skey: props.location.search,
        newPassword: correctPassword
      })
      .then(value => {
        if (value) alert(value);
      });
    // props.history.push("/");
  };

  const right = useMemo(() => {
    return (
      <ChangePasswordContent targetRef={targetRef}>
        {title}
        <form onSubmit={onSubmit}>
          <div
            style={{
              padding: 5,
              display: "flex",
              flexDirection: "column"
            }}
          >
            <CustomInput
              placeholder="Enter your new password"
              type="password"
              onChange={handleChangePassword}
              value={password}
              inputStyle={{ height: 25, padding: 10, margin: "5px 0px" }}
            />
            <CustomInput
              placeholder="Enter your correct password"
              type="password"
              onChange={handleChangeCorrectPassword}
              value={correctPassword}
              inputStyle={{ height: 25, padding: 10, margin: "5px 0px" }}
            />
          </div>
          <div style={{ display: "flex", marginTop: 20 }}>
            <MatchButton
              type={"button"}
              onClick={onHome}
              style={{ width: "50%", height: 35, fontWeight: 100 }}
            >
              Cancel
            </MatchButton>
            <MatchButton
              type={"submit"}
              style={{
                width: "50%",
                height: 35,
                backgroundColor: "#14a4e7",
                color: "#fff"
              }}
              disabled={password !== correctPassword}
            >
              Submit
            </MatchButton>
          </div>
        </form>
      </ChangePasswordContent>
    );
  }, [password, correctPassword]);

  const rtitle = useMemo(() => {
    return (
      <div className={classes.container}>
        <div>
          <img src={OverviewIcon} alt="" />
          <span style={{ marginLeft: 5 }}>{"CHANGE PASSWORD"}</span>
        </div>
      </div>
    );
  }, []);

  return (
    <>
      <CommTemplatePage right={right} rtitle={rtitle} />
    </>
  );
}

export default ChangePassword;
